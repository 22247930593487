import { CloseButton } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

const CambiarGeneralesModal = (props) => {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
      <CloseButton className="fs-4" style={{paddingLeft:"10px", top: "10px"}}>
        x
      </CloseButton>
        <Modal.Title id="contained-modal-title-vcenter" className="fs-6 text-center">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="mb-6">
        {props.children}
      </Modal.Body>
    </Modal>
  );
};

export default CambiarGeneralesModal;