import DataTable from "react-data-table-component";
import Loader from "../../../../Common/Loader";
import Vacio from "../../../../Common/Vacio";

const DetalleTable = ({ formData, handleProductDelete, handleProductEdit }) => {
  const queriesColumns = [
    // {
    //   name: "Ordered",
    //   selector: (row) => row.qordered,
    //   center: false,
    //   compact: true,
    //   wrap: false,
    // },
    // {
    //   name: "Shipped",
    //   selector: (row) => row.qshipped,
    //   center: false,
    //   compact: true,
    //   wrap: false,
    // },
    {
      name: "Cantidad",
      selector: (row) => row.qborder,
      center: true,
      compact: true,
      wrap: false,
    },
    {
      name: "ItemNumber",
      selector: (row) => row.itemNumber,
      center: true,
      compact: true,
      wrap: false
    },
    {
      name: "Descripción",
      selector: (row) => row.Descripcion,
      center: false,
      compact: true,
      wrap: false,
      grow: 4
    },
    {
      name: "Net price",
      selector: (row) => `USD ${row.netPrice}`,
      center: false,
      compact: true,
      wrap: false,
    },
    {
      name: "Subtotal",
      selector: (row) => `USD ${row.netPrice * row.qordered}`,
      center: false,
      compact: true,
      wrap: false,
    },
    {
      name: "Acciones",
      selector: (row) => [
        <button
          key={3}
          className="btn text-dark w-auto p-1 mx-1 my-0"
          type="button"
          title="Borrar"
          onClick={() => {
            handleProductDelete(row)
          }}
        >
          <i className="fa fa-trash f-16 px-1" />
        </button>,
        <button
          key={2}
          className="btn text-dark w-auto p-1 mx-1 my-0"
          type="button"
          title="Editar"
          onClick={() => handleProductEdit(row)}
        >
          <i className="fa fa-pencil-square-o f-16 px-1" />
        </button>,
      ],
      center: false,
      compact: true,
      wrap: false,
    },
  ];

  return (
    <div className="my-3 table-queries" style={{ overflow: "hidden" }}>
      <DataTable
        columns={queriesColumns}
        data={formData}
        striped
        customStyles={{
          rows: {
            highlightOnHoverStyle: {
              backgroundColor: "rgb(230, 244, 244)",
              borderBottomColor: "#FFFFFF",
              outline: "1px solid #FFFFFF",
            },
          },
          pagination: {
            style: {
              border: "none",
            },
          },
        }}
        pointerOnHover
        highlightOnHover
        progressComponent={<Loader />}
        noDataComponent={<Vacio />}
        persistTableHead
      />
    </div>
  );
};

export default DetalleTable;
