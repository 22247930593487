import { useContext, useState, useEffect } from "react";
import { Form, Row, Button, Col, Container } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { Breadcrumbs } from "../../../AbstractElements";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const NewCustomer = () => {
  const history = useNavigate();
  const [disableButton, setDisableButton] = useState(false);
  const token = localStorage.getItem("token");
  const [formData, setFormData] = useState({});

  const [dataPaisesEndpoint, setDataPaisesEndpoint] = useState([]);
  const [dataVendedoresEndpoint, setDataVendedoresEndpoint] = useState([]);
  const initialENDPOINT = process.env.REACT_APP_ENDPOINT;

  const [isProspectoChecked, setIsProspectoChecked] = useState(true);
  const [prospecto, setProspecto] = useState(false);
  const [metodosDeEnvio, setMetodosDeEnvio] = useState([]);
  const [userAlreadyExist, setUserAlreadyExist] = useState();

  const getMetodosDeEnvio = async () => {
    try {
      const res = await axios(`${initialENDPOINT}/tipodeenvio`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setMetodosDeEnvio(res.data.data?.original.results);
    } catch (error) {
      console.log(error);
    }
  };

  const handleProspectoChange = (event) => {
    setIsProspectoChecked(!prospecto);
    setProspecto(!prospecto);
  };

  const notify = () => toast("Cliente creado!");

  const sendForm = async (e) => {
    e.preventDefault();
    //setDisableButton(true);

    ///////////////////////////////////////////////////////////////////////////////////////

    const dataToSend = JSON.stringify({
      nombre: formData.nombre || "",
      direccion: formData.direccion || "",
      codigoPostal: formData.codigoPostal || "",
      telefono: formData.telefono || 0,
      email: formData.email || "",
      contactoApellido: formData.contactoApellido || 0,
      contacto: formData.contacto || 0,
      puestoContacto: formData.contactoPuesto || 0,
      transportadora: formData.nombreEmpresaEnvio || 0,
      telefonoTransportadora: formData.telefonoEmpresaEnvio || "",
      observaciones: formData.observaciones || "",
      usuario: formData.usuario || 0,
      web: formData.web || 0,
      direccionShape: formData.direccionEnvio || 0,
      direccionBill: formData.direccion || "",
      vendedor: formData.vendedor || 0,
      ciudad: formData.ciudad || 0,
      pais: formData.pais || 0,
      cpShape: formData.codigoPostalEnvio || 0,
      paisShape: formData.paisEnvio || "",
      montoMaximoDePago: formData.montoMaxCompra || 0,
      WhatsApp: formData.WhatsApp || 0,
      Notas: formData.observaciones || 0,
      tipoDeEnvio: formData.tipoDeEnvio || 0,
      prospecto: prospecto === true ? 1 : 0,
      clave: formData.clave || 0,
      checkboxNotificarUsuario: formData.checkboxNotificarUsuario || 0,
      suspendido: formData.suspendido === true ? 1 : 0,
      estadoCliente: formData.suspendido === true ? 1 : 0,
    });

    try {
      let resp = await axios({
        url: `${initialENDPOINT}/cliente`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: dataToSend,
        redirect: "follow",
      });

      //-------------------------- comprobación si el usuario no existe -----------
      if (resp.data.status == 203) {
        setUserAlreadyExist(true);
      } else {
        setUserAlreadyExist(false);
        notify();
        history(`${process.env.PUBLIC_URL}/clientes`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    setDisableButton(false);
    let value = e.target.value;
    let name = e.target.name;

    setFormData((prevalue) => {
      return {
        ...prevalue,
        [name]: value,
      };
    });
    // console.log(formData);
  };

  const pedidoPaises = async () => {
    try {
      let pedido = await axios(`${initialENDPOINT}/pais?cantidad=240`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        redirect: "follow",
      });

      setDataPaisesEndpoint(pedido.data.data.original.results);
      // console.log("pedido", pedido);
    } catch (error) {
      console.log("Ha ocurrido un error", error);
    }
  };

  const pedidoVendedor = async () => {
    try {
      let pedido = await axios(`${initialENDPOINT}/usuario`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        redirect: "follow",
      });

      setDataVendedoresEndpoint(pedido.data.data?.original.results);
    } catch (error) {
      console.log("Ha ocurrido un error", error);
    }
  };

  useEffect(() => {
    getMetodosDeEnvio();
    pedidoPaises();
    pedidoVendedor();
  }, []);

  return (
    <Container fluid={true}>
      <Breadcrumbs
        mainTitle="Nuevo cliente"
        parent="Cliente"
        title="Nuevo cliente"
      />
      <Form onSubmit={sendForm} className="container">
        <Row>
          <Col className="col-12 col-lg-12 col-xxl-11 bg-white p-4 mb-5 rounded border shadow">
            <Form.Group as={Row} className="align-items-center mb-3">
              <Col className="col-4 col-md-2">
                <Form.Label>Nombre</Form.Label>
              </Col>
              <Col className="col-8 col-md-4">
                <Form.Control
                  type="text"
                  name="nombre"
                  onChange={handleChange}
                  required
                />
              </Col>
              <Col className="col-4 col-md-2">
                <Form.Label>Dirección</Form.Label>
              </Col>
              <Col className="col-8 col-md-4">
                <Form.Control
                  type="text"
                  name="direccion"
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="align-items-center mb-3">
              <Col className="col-4 col-md-2">
                <Form.Label>Teléfono</Form.Label>
              </Col>
              <Col className="col-8 col-md-4">
                <Form.Control
                  type="tel"
                  name="telefono"
                  onChange={handleChange}
                />
              </Col>
              <Col className="col-4 col-md-2">
                <Form.Label>WhatsApp</Form.Label>
              </Col>
              <Col className="col-8 col-md-4">
                <Form.Control
                  type="tel"
                  name="WhatsApp"
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="align-items-center mb-3">
              <Col className="col-4 col-md-2">
                <Form.Label>País</Form.Label>
              </Col>
              <Col className="col-8 col-md-4">
                <Form.Select
                  defaultValue={"Elija su país"}
                  name="pais"
                  onChange={handleChange}
                >
                  <option disabled>Elija su país</option>
                  {dataPaisesEndpoint.map((item, i) => (
                    <option key={i} value={item.id}>
                      {item.nombre}
                    </option>
                  ))}
                </Form.Select>
              </Col>
              <Col className="col-4 col-md-2">
                <Form.Label>Ciudad</Form.Label>
              </Col>
              <Col className="col-8 col-md-4">
                <Form.Control
                  type="text"
                  name="ciudad"
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="align-items-center mb-3">
              <Col className="col-4 col-md-2">
                <Form.Label>Código postal</Form.Label>
              </Col>
              <Col className="col-8 col-md-3">
                <Form.Control
                  type="number"
                  name="codigoPostal"
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="align-items-center mb-3">
              <Col className="col-4 col-md-2">
                <Form.Label>Email</Form.Label>
              </Col>
              <Col className="col-8 col-md-5">
                <Form.Control
                  type="email"
                  name="email"
                  onChange={handleChange}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="align-items-center mb-3">
              <Col className="col-4 col-md-2">
                <Form.Label>Web</Form.Label>
              </Col>
              <Col className="col-8 col-md-5">
                <Form.Control type="text" name="web" onChange={handleChange} />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="align-items-center mb-3">
              <Col className="col-4 col-md-2">
                <Form.Label>Notas</Form.Label>
              </Col>
              <Col className="col-8 col-md-5">
                <Form.Control as="textarea" rows={3} name="notas" onChange={handleChange} />
              </Col>
            </Form.Group>
            <br />
            <br />
            <Form.Group as={Row} className="align-items-center mb-3">
              <Col className="col-4 col-md-2">
                <Form.Label>Contacto</Form.Label>
              </Col>
              <Col className="col-4 col-md-3">
                <Form.Label>Nombre Contacto</Form.Label>
                <Form.Control
                  type="text"
                  name="contactoNombre"
                  placeholder="Nombre"
                  onChange={handleChange}
                />
              </Col>
              <Col className="col-4 col-md-3">
                <Form.Label>Apellido Contacto</Form.Label>
                <Form.Control
                  type="text"
                  name="contactoApellido"
                  placeholder="Apellido"
                  onChange={handleChange}
                />
              </Col>
              <Col className="col-4 col-md-3">
                <Form.Label>Puesto Contacto</Form.Label>
                <Form.Control
                  type="text"
                  name="contactoPuesto"
                  placeholder="Puesto"
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="align-items-center mb-3">
              <Col className="col-4 col-md-2">
                <Form.Label>Vendedor</Form.Label>
              </Col>
              <Col className="col-8 col-md-4">
                <Form.Select
                  defaultValue={"Elija su vendedor"}
                  name="vendedor"
                  onChange={handleChange}
                >
                  <option disabled>Elija su vendedor</option>
                  {dataVendedoresEndpoint?.map((item, i) => (
                    <option key={i} value={item.id}>
                      {item.nombre}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="align-items-center mb-3">
              <Col className="col-4 col-md-2">
                <Form.Label>Monto máximo de compra</Form.Label>
              </Col>
              <Col className="col-8 col-md-4">
                <Form.Control
                  type="number"
                  name="montoMaxCompra"
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="align-items-center mb-3">
              <Col className="col-4 col-md-2">
                <Form.Label>Usuario</Form.Label>
              </Col>
              <Col className="col-8 col-md-4">
                <Form.Control
                  type="text"
                  name="usuario"
                  onChange={handleChange}
                  className={userAlreadyExist ? "is-invalid" : ""}
                  required
                />
                {userAlreadyExist ? (
                  <small style={{ color: "red" }}>Ese usuario ya existe</small>
                ) : (
                  ""
                )}
              </Col>
              <Col className="col-4 col-md-2">
                <Form.Label>Contraseña</Form.Label>
              </Col>
              <Col className="col-8 col-md-4">
                <Form.Control
                  type="password"
                  name="clave"
                  onChange={handleChange}
                  required
                  minLength={8}
                  maxLength={20}
                />
                <Form.Text id="passwordHelpBlock" muted>
                  Su contraseña debe tener entre 8 y 20 caracteres.
                </Form.Text>
              </Col>
            </Form.Group>
            <div className="d-flex gap-5">
              <br />
              {/* <div className=""> */}
              {/* <div className="d-flex gap-3"> */}
              <Form.Check
                id="prospecto"
                type="checkbox"
                label="Prospecto"
                checked={isProspectoChecked}
                onChange={handleProspectoChange}
              />

              {/* </div> */}
              {/* <small>Estado del cliente: {estadoCliente}</small> */}
              {/* </div> */}
              <Form.Group as={Row} className="align-items-center mb-3">
                <Col className="col-8 col-md-9">
                  <Form.Check
                    type="checkbox"
                    id="suspendido"
                    label="Suspendido"
                    name="suspendido"
                    onChange={handleChange}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="align-items-center mb-3">
                <Col className="col-8 col-md-12">
                  <Form.Check
                    type="checkbox"
                    id="nofitication"
                    label="Enviar notificación al usuario"
                    onChange={handleChange}
                    name="checkboxNotificarUsuario"
                  />
                </Col>
              </Form.Group>
            </div>
            <br />
            <br />
            <h6 className="pb-3">Envío</h6>
            <Form.Group as={Row} className="align-items-center mb-3">
              <Col className="col-4 col-md-2">
                <Form.Label>Tipo de envío</Form.Label>
              </Col>
              <Col className="col-8 col-md-5">
                <Form.Group className="mb-3 col-9">
                  {/* <Form.Label>Metodo de envío</Form.Label> */}
                  <Form.Select
                    className="mb-3 col-4"
                    defaultValue={"Seleccione"}
                    name="tipoDeEnvio"
                    onChange={(e) => handleChange(e)}
                  >
                    <option disabled>Seleccione</option>
                    {metodosDeEnvio?.map((item, i) => (
                      <option key={i} value={item.id}>
                        {item.nombre}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="align-items-center mb-3">
              <Col className="col-4 col-md-2">
                <Form.Label>Transportadora</Form.Label>
              </Col>
              <Col className="col-8 col-md-5">
                <Form.Control
                  type="text"
                  name="nombreEmpresaEnvio"
                  placeholder="Nombre"
                  onChange={handleChange}
                />
              </Col>
              <Col className="col-8 col-md-5">
                <Form.Control
                  type="tel"
                  name="telefonoEmpresaEnvio"
                  placeholder="Teléfono"
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="align-items-center mb-3">
              <Col className="col-4 col-md-2">
                <Form.Label>Dirección</Form.Label>
              </Col>
              <Col className="col-8 col-md-4">
                <Form.Control
                  type="text"
                  name="direccionEnvio"
                  onChange={handleChange}
                />
              </Col>
              <Col className="col-4 col-md-2">
                <Form.Label>Código postal</Form.Label>
              </Col>
              <Col className="col-8 col-md-4">
                <Form.Control
                  type="number"
                  name="codigoPostalEnvio"
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="align-items-center mb-3">
              <Col className="col-4 col-md-2">
                <Form.Label>País</Form.Label>
              </Col>
              <Col className="col-8 col-md-4">
                <Form.Select
                  defaultValue={"Elija país de destino"}
                  name="paisEnvio"
                  onChange={handleChange}
                >
                  <option disabled>Elija país de destino</option>
                  {dataPaisesEndpoint.map((item, i) => (
                    <option key={i} value={item.id}>
                      {item.nombre}
                    </option>
                  ))}
                </Form.Select>
              </Col>
              <Col className="col-4 col-md-2">
                <Form.Label>Ciudad</Form.Label>
              </Col>
              <Col className="col-8 col-md-4">
                <Form.Control
                  type="text"
                  name="ciudadEnvio"
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>
            <Form.Group>
              <Form.Label>Observaciones</Form.Label>
              <Form.Control
                as="textarea"
                name="observaciones"
                onChange={handleChange}
                rows={3}
              />
            </Form.Group>
            <div className="d-flex justify-content-end">
              <Button
                disabled={disableButton}
                type="submit"
                className="mt-2 mt-md-4 col-2 col-lg-2"
              >
                Crear
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default NewCustomer;
