import { useMemo } from "react";
import DataTable from "react-data-table-component";
import { downloadCSV, Export } from "../../../_helper/hooks/useExportCsv";
import Loader from "../../Common/Loader";
import Vacio from "../../Common/Vacio";
import TableTitle from "../../Common/TableTitle";
import { Link } from "react-router-dom";

const paginacionOpciones = {
  rowsPerPageText: "Filas por página",
  rangeSeparatorText: "de",
};
const contextOpciones = {
  singular: "",
  plural: "",
  message: "",
};
const customStyles = {
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: "rgb(230, 244, 244)",
      borderBottomColor: "#FFFFFF",
      outline: "1px solid #FFFFFF",
    },
  },
  pagination: {
    style: {
      border: "none",
    },
  },
};

const InvoiceTable = ({
  data,
  errMsg,
  loading,
  totalRows,
  perPage,
  setPerPage,
  page,
  setPage,
  setENDPOINT,
  filterENDPOINT,
  showCancellations,
  handleShowCancellations,
}) => {
  const initialENDPOINT = process.env.REACT_APP_ENDPOINT;
  const queriesColumns = [
    {
      name: "Código",
      selector: (row) => row.id,
      sortable: true,
      center: false,
      wrap: true,
      reorder: true,
      grow: 0,
    },
    {
      name: "Fecha de Emisión",
      selector: (row) => row.fecha,
      center: false,
      wrap: true,
    },
    {
      name: "Nombre de Cliente",
      selector: (row) => row.clienteNombre,
      center: false,
      wrap: true,
    },
    {
      name: "Total",
      selector: (row) => row.total,
      center: false,
      wrap: true,
    },
    {
      name: "Ver",
      selector: (row) => [
        // <Link key={1} to={`/detalle-invoice/${row.id}`}>
        //   <button
        //     key={2}
        //     className="btn text-dark w-auto p-1 mx-1 my-0"
        //     type="button"
        //     title="Ver Invoice"
        //   >
        //     <i className="fa fa-file-text f-16 px-1" />
        //   </button>
        // </Link>,
        <a href={`${initialENDPOINT}/pdf/invoice/${row.id}`} target="_blank" rel="noopener noreferrer">
          <button
            key={2}
            className="btn text-dark w-auto p-1 mx-1 my-0"
            type="button"
            title="Ver Invoice"
          >
            <i className="fa fa-file-text f-16 px-1" />
          </button>
        </a>,
                <a href={`${initialENDPOINT}/cotizacion/excel/${row.id}`} target="_blank" rel="noopener noreferrer">
                <button
                  key={2}
                  className="btn text-dark w-auto p-1 mx-1 my-0"
                  type="button"
                  title="Descargar Excel"
                >
                  <i className="fa fa-file-excel-o f-16 px-1" />
                </button>
              </a>,
      ],
      center: true,
      // hide: "md",
      compact: true,
    },
  ];

  const handlePageChange = (pag) =>
    setENDPOINT(
      `${initialENDPOINT}/invoice?pagina=${pag}&cantidad=${perPage}${filterENDPOINT}&mostrarBajas=${showCancellations}`
    );
  const handlePerRowsChange = async (newPerPage, pag) => {
    setPerPage(newPerPage);
    setPage(pag);
    await setENDPOINT(
      `${initialENDPOINT}/invoice?pagina=${pag}&cantidad=${newPerPage}${filterENDPOINT}&mostrarBajas=${showCancellations}`
    );
  };
  const actionsMemo = useMemo(
    () => (
      <Export
        onExport={() => downloadCSV(data, "Consultas")}
        cancallations={handleShowCancellations}
      />
    ),
    [data]
  );

  return (
    <div
      className="App mb-5 table-queries border rounded"
      class="overflow-hidden overflow-x-auto"
    >
      {errMsg && <p>{errMsg}</p>}
      <DataTable
        title={<TableTitle value="Lista de Invoices" />}
        columns={queriesColumns}
        data={data}
        striped
        customStyles={customStyles}
        highlightOnHover
        progressPending={loading}
        progressComponent={<Loader />}
        noDataComponent={<Vacio />}
        pagination
        paginationServer
        paginationComponentOptions={paginacionOpciones}
        paginationDefaultPage
        paginationTotalRows={totalRows}
        contextMessage={contextOpciones}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerRowsChange}
        actions={actionsMemo}
        persistTableHead
      />
    </div>
  );
};

export default InvoiceTable;
