import { useState, useEffect } from "react";
import { Form, Row, Button, Col, Container, Image } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Breadcrumbs } from "../../../../AbstractElements";

const EditSlider = () => {
    const navigate = useNavigate();
    const token = localStorage.getItem("token");
    const initialENDPOINT = process.env.REACT_APP_ENDPOINT;
    const bannerENDPOINT = 'http://phpstack-1091339-3819555.cloudwaysapps.com/storage/banners';
    //const bannerENDPOINT = 'http://127.0.0.1:8080/storage/banners';
    const { sliderID } = useParams();
    const [formData, setFormData] = useState({
        suspendido: false,
        tipoUbicacion: "",
        orden: "",
        link: "",
        nombre: "",
        textoPrincipal: "",
        textoSecundario: "",
    });
    const [disableButton, setDisableButton] = useState(false);
    const [tipoUbicacionOptions, setTipoUbicacionOptions] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [isImageBroken, setIsImageBroken] = useState(false);

    useEffect(() => {
        const fetchSliderData = async () => {
            try {
                const response = await axios.get(`${initialENDPOINT}/banner/${sliderID}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                const sliderData = response.data.data;

                if (sliderData) {
                    setFormData({
                        tipoUbicacion: sliderData.tipoUbicacion ? sliderData.tipoUbicacion.toString() : "",
                        orden: sliderData.orden ? sliderData.orden.toString() : "",
                        link: sliderData.link || "",
                        nombre: sliderData.nombre || "",
                        textoPrincipal: sliderData.texto1 || "",
                        textoSecundario: sliderData.texto2 || "",
                        suspendido: sliderData.suspendido === 0,
                    });

                    const imageUrl = `${bannerENDPOINT}/${sliderData.id}.jpg`;
                    //alert(imageUrl)
                    setImagePreview(imageUrl);
                }
            } catch (error) {
                console.error("Error fetching slider data", error);
            }
        };

        fetchSliderData();
    }, [initialENDPOINT, sliderID, token]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${initialENDPOINT}/tipobanner`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const parsedData = response.data.data.original.results.map(({ id, nombre }) => ({
                    id,
                    nombre,
                }));
                setTipoUbicacionOptions(parsedData);
            } catch (error) {
                console.error("Error fetching tipoUbicacionOptions", error);
            }
        };

        fetchData();
    }, [initialENDPOINT, token]);

    const suspendidoOptions = [
        { id: 1, nombre: "si" },
        { id: 0, nombre: "no" },
    ];

    const notify = () => toast("Slider actualizado!");

    const sendForm = async (e) => {
        e.preventDefault();

        const formDataToSend = new FormData();
        formDataToSend.append('tipoUbicacion', formData.tipoUbicacion);
        formDataToSend.append('orden', formData.orden);
        formDataToSend.append('link', formData.link);
        formDataToSend.append('nombre', formData.nombre);
        formDataToSend.append('textoPrincipal', formData.textoPrincipal);
        formDataToSend.append('textoSecundario', formData.textoSecundario);
        formDataToSend.append('suspendido', formData.suspendido ? 1 : 0);
        if (selectedImage) {
            formDataToSend.append('imagen', selectedImage);
        }

        try {
            await axios.post(`${initialENDPOINT}/banner/update/${sliderID}`, formDataToSend, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });

            notify();
            navigate('/sliders');
        } catch (error) {
            console.error("Error updating slider", error);
        }
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        setFormData((prevValues) => {
            if (type === "checkbox") {
                return {
                    ...prevValues,
                    [name]: checked,
                };
            }

            return {
                ...prevValues,
                [name]: value,
            };
        });
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            setSelectedImage(file);

            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
                setIsImageBroken(false);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleImageError = () => {
        setIsImageBroken(true);
    };

    return (
        <Container fluid={true}>
            <Breadcrumbs
                mainTitle={formData.nombre || "Editar Slider"}
                parent="Sliders"
                title="Editar Slider"
            />
            <Form onSubmit={sendForm} className="container">
                <Row>
                    <Col className="col-11 col-lg-9 col-xxl-8 bg-white p-4 mb-5 rounded border shadow">
                        <Form.Group className="d-flex align-items-center mb-3">
                            <Form.Label className="col-4 col-md-4">Tipo de Ubicacion</Form.Label>
                            <Form.Select
                                defaultValue={"Seleccione"}
                                name="tipoUbicacion"
                                onChange={handleChange}
                                value={formData.tipoUbicacion}
                                className="w-100"
                            >
                                <option disabled>Seleccione</option>
                                {tipoUbicacionOptions.map((item) => (
                                    <option key={item.id} value={item.id}>
                                        {item.nombre}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="d-flex align-items-center mb-3">
                            <Form.Label className="col-4 col-md-4">Link</Form.Label>
                            <Form.Control
                                type="text"
                                value={formData.link}
                                name="link"
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group className="d-flex align-items-center mb-3">
                            <Form.Label className="col-4 col-md-4">Nombre</Form.Label>
                            <Form.Control
                                type="text"
                                value={formData.nombre}
                                name="nombre"
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group className="d-flex align-items-center mb-3">
                            <Form.Label className="col-4 col-md-4">Orden</Form.Label>
                            <Form.Control
                                type="number"
                                value={formData.orden}
                                name="orden"
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group className="d-flex align-items-center mb-3">
                            <Form.Label className="col-4 col-md-4">Suspendido</Form.Label>
                            <Form.Select
                                value={formData.suspendido}
                                name="suspendido"
                                onChange={handleChange}
                                className="w-100"
                            >
                                {suspendidoOptions.map((item) => (
                                    <option key={item.id} value={item.id}>
                                        {item.nombre}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="d-flex align-items-center mb-3">
                            <Form.Label className="col-4 col-md-4">Texto Principal</Form.Label>
                            <Form.Control
                                type="text"
                                value={formData.textoPrincipal}
                                name="textoPrincipal"
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group className="d-flex align-items-center mb-3">
                            <Form.Label className="col-4 col-md-4">Texto Secundario</Form.Label>
                            <Form.Control
                                type="text"
                                value={formData.textoSecundario}
                                name="textoSecundario"
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Subir Imagen</Form.Label>
                            <Form.Control type="file" name="imagen" onChange={handleImageChange} />
                            {imagePreview && !isImageBroken && (
                                <Image
                                    src={imagePreview}
                                    alt="Preview"
                                    className="mt-2"
                                    thumbnail
                                    style={{ height: '200px', width: 'auto' }}
                                    onError={handleImageError}
                                />
                            )}
                            {isImageBroken && (
                                <div className="mt-2 text-danger">Imagen no encontrada.</div>
                            )}
                        </Form.Group>

                        <Button
                            disabled={disableButton}
                            type="submit"
                            className="mt-2 mt-md-2 col-6 col-lg-2"
                        >
                            Guardar
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Container>
    );
};

export default EditSlider;
