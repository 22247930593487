import { useState } from "react";
import { Form, Image } from "react-bootstrap";
import { Heart, Trash2 } from "react-feather";

const GaleryComponent = ({ item, handleImg }) => {
  const [hovered, setHovered] = useState(false);

  return (
    <Form.Group
      className="col-6"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      key={item.id}
    >
      <Image src={item.url} alt={item.url} style={{ width: "100%" }} />
      <div title="Borrar imagen">
        <Trash2
          color="grey"
          style={{
            position: "absolute",
            top: 4,
            right: 4,
            zIndex: 100,
            cursor: "pointer",
            display: hovered ? "block" : "none",
          }}
          onClick={() => handleImg(item.id, "eliminar")}
        />
      </div>
      <div title="Marcar como imagen principal">
        <Heart
          color="grey"
          style={{
            position: "absolute",
            top: 4,
            right: 40,
            zIndex: 100,
            cursor: "pointer",
            display: hovered ? "block" : "none",
          }}
          onClick={() => handleImg(item.id, "principal")}
        />
      </div>
    </Form.Group>
  );
};

export default GaleryComponent;
