import { useEffect } from "react";
import { useContext, useState } from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Breadcrumbs } from "../../../AbstractElements";
import DataContext from "../../../_helper/DataContext";
import useGoToLogin from "../../../_helper/hooks/useGoToLogin";
import NewFilterForm from "./NewFilterForm";
import SliderTable from "./SliderTable";

const initialForm = {
  suspended: 0,
  name: "",
  code: 0,
  priceFrom: 0,
  priceTo: 0,
  stockFrom: 0,
  stockTo: 0,
  brand: 0,
  product: 0,
  color: 0,
  category: 0,
  material: 0,
  size: 0,
};

const Sliders = () => {
  const [newQuery, setNewQuery] = useState(false);
  const [newFilter, setNewFilter] = useState(false);
  const [formData, setFormData] = useState(initialForm);
  const [showCancellations, setShowCancellations] = useState(false);
  const [isMounted, setIsMounted] = useState(true);
  const token = localStorage.getItem("token");
  const initialENDPOINT = process.env.REACT_APP_ENDPOINT;
  const { goToLogin } = useGoToLogin();

  const {
    data,
    errMsg,
    loading,
    totalRows,
    perPage,
    setPerPage,
    page,
    setPage,
    setENDPOINT,
    setFilterENDPOINT,
    filterENDPOINT,
  } = useContext(DataContext);

  const resetAll = () => {
    const random = Date.now();
    setFormData(initialForm);
    // setFilterENDPOINT(`&random=${random}`);
    setENDPOINT(
      `${initialENDPOINT}/banner?pagina=1&cantidad=10&random=${random}`
    );
    setFilterENDPOINT(``);
    setPage(1);
  };
  const handleShowCancellations = () => {
    setShowCancellations((prev) => !prev);
    setENDPOINT(`${initialENDPOINT}/banner?pagina=1&cantidad=10`);
  };
  const showFilter = () => {
    setNewFilter(!newFilter);
    setNewQuery(false);
    setENDPOINT(`${initialENDPOINT}/banner?pagina=1`);
  };

  useEffect(() => {
    !token && goToLogin();
    setPage(1);
    const random = Date.now();
    if (isMounted) {
      setENDPOINT(
        `${initialENDPOINT}/banner?pagina=1&cantidad=10&random=${random}`
      );
      setFilterENDPOINT("");
    }
    return () => {
      setIsMounted(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container fluid={true}>
      <Breadcrumbs mainTitle="Sliders" parent="Sliders" title="Listado" />
      <div className="d-flex justify-content-around justify-content-sm-end gap-2">
        <Link to="/nuevo-slider">
          <Button
            className="mb-3 px-3"
            variant={newQuery ? "primary" : "outline-primary"}
          >
            <i className="fa fa-plus f-12 pe-2 m-0" />
            Nuevo Slider
          </Button>
        </Link>
        <Button
          onClick={showFilter}
          className="mb-3 px-3"
          variant={newFilter ? "primary" : "outline-primary"}
        >
          <i className="fa fa-filter f-12 pe-2 m-0" />
          Filtros avanzados
        </Button>
        <Button
          onClick={resetAll}
          className="mb-3 px-3"
          variant={newFilter ? "primary" : "outline-primary"}
        >
          <i className="fa fa-refresh f-12 m-0" />
        </Button>
      </div>
      <Row>
        <Col xl="12" className="des-xl-100 box-col-12">
          {newFilter && (
            <NewFilterForm
              showFilter={showFilter}
              setPage={setPage}
              setFilterENDPOINT={setFilterENDPOINT}
              resetAll={resetAll}
              formData={formData}
              setFormData={setFormData}
            />
          )}
          <SliderTable
            data={data}
            errMsg={errMsg}
            loading={loading}
            totalRows={totalRows}
            perPage={perPage}
            setPerPage={setPerPage}
            page={page}
            setPage={setPage}
            setENDPOINT={setENDPOINT}
            filterENDPOINT={filterENDPOINT}
            showCancellations={showCancellations}
            handleShowCancellations={handleShowCancellations}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Sliders;
