import { useEffect, useState } from "react";
import { Form, Row } from "react-bootstrap";
import useGoToLogin from "../../../../../_helper/hooks/useGoToLogin";
import axios from "axios";

const Envio = ({ handleChange, formData, setFormData }) => {
  const initialENDPOINT = process.env.REACT_APP_ENDPOINT;
  const [metodosDeEnvio, setMetodosDeEnvio] = useState([]);
  const [transportadoras, setTransportadoras] = useState([]);
  const [paisesDeEnvio, setPaisesDeEnvio] = useState([]);
  const [isMounted, setIsMounted] = useState(true);
  const token = localStorage.getItem("token");
  const { goToLogin } = useGoToLogin();

  const getData = async (url, setState) => {
    try {
      const res = await axios(`${initialENDPOINT}/${url}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      isMounted && setState(res.data.data?.original.results);
    } catch (error) {
      let status = error.response.status;
      console.log("error.response", error.response);
      goToLogin(status);
    }
  };

  useEffect(() => {
    getData("tipodeenvio", setMetodosDeEnvio);
    getData("empresatransportadora", setTransportadoras);
    getData("pais?cantidad=240", setPaisesDeEnvio);

    return () => {
      setIsMounted(false);
    };
  }, []);

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      idTransportadora: 2
    }));
  }, []);

  return (
    <Row className="bg-white mb-3 p-4 pb-2 border rounded m-5">
      <Row>
        <h6 className="py-3">Envío | Transportadora</h6>
        <Form.Group className="mb-3 col-4">
          <Form.Label>Empresa transportadora</Form.Label>
          <Form.Control
            type="text"
            name="transportadoraNombre"
            defaultValue={formData.transportadoraNombre}
            placeholder="Ingrese la empresa"
            onChange={(e) => handleChange(e)}
          ></Form.Control>
        </Form.Group>
        <Form.Group className="mb-3 col-4">
          <Form.Label>Teléfono transportadora</Form.Label>
          <Form.Control
            type="number"
            name="transportadoraTelefono"
            defaultValue={formData.transportadoraTelefono}
            placeholder="Ingrese el teléfono"
            onChange={(e) => handleChange(e)}
            min={0}
          ></Form.Control>
        </Form.Group>
        <Form.Group className="mb-3 col-4">
          <Form.Label>Código de seguimiento</Form.Label>
          <div className="d-flex">
            <Form.Control
              type="text"
              name="codigoSeguimiento"
              defaultValue={formData.codigoSeguimiento}
              placeholder="Ingrese el código"
              onChange={(e) => handleChange(e)}
            ></Form.Control>
            <Form.Select
              name="idTransportadora"
              value={formData.idTransportadora || 0}
              onChange={(e) => handleChange(e)}
              className="w-50"
            >
              {transportadoras?.map((item, i) => (
                <option key={i} value={item.id}>
                  {item.nombre}
                </option>
              ))}
            </Form.Select>
          </div>
        </Form.Group>
        <Form.Group className="mb-3 col-4">
          <Form.Label>Metodo de envío</Form.Label>
          <Form.Select
            name="tipoDeEnvio"
            value={formData.tipoDeEnvio || 0}
            onChange={(e) => handleChange(e)}
          >
            <option disabled value={0}>SELECCIONE UN MÉTODO</option>
            {metodosDeEnvio?.map((item, i) => (
              <option key={i} value={item.id}>
                {item.nombre}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Row>
      <Row>
        <h6 className="py-3">Envío | Cliente</h6>
        <Form.Group className="mb-3 col-4">
          <Form.Label>Nombre</Form.Label>
          <Form.Control
            type="text"
            name="envioNombre"
            defaultValue={formData.envioNombre}
            placeholder="Ingrese el nombre del destinatario"
            onChange={(e) => handleChange(e)}
          ></Form.Control>
        </Form.Group>
        <Form.Group className="mb-3 col-4">
          <Form.Label>País</Form.Label>
          <Form.Select
            name="envioPais"
            value={formData.envioPais || 0}
            onChange={(e) => handleChange(e)}
          >
            <option disabled value={0}>SELECCIONE UN PAIS</option>
            {paisesDeEnvio?.map((item, i) => (
              <option key={i} value={item.id}>
                {item.nombre}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3 col-4">
          <Form.Label>Estado</Form.Label>
          <Form.Control
            type="text"
            name="envioRegion"
            defaultValue={formData.envioRegion}
            placeholder="Ingrese el estado"
            onChange={(e) => handleChange(e)}
          ></Form.Control>
        </Form.Group>
        <Form.Group className="mb-3 col-4">
          <Form.Label>Ciudad</Form.Label>
          <Form.Control
            type="text"
            name="envioCiudad"
            defaultValue={formData.envioCiudad}
            placeholder="Ingrese la ciudad"
            onChange={(e) => handleChange(e)}
          ></Form.Control>
        </Form.Group>
        <Form.Group className="mb-3 col-4">
          <Form.Label>Domicilio</Form.Label>
          <Form.Control
            type="text"
            name="envioDomicilio"
            defaultValue={formData.envioDomicilio}
            placeholder="Ingrese el domicilio"
            onChange={(e) => handleChange(e)}
          ></Form.Control>
        </Form.Group>
        <Form.Group className="mb-3 col-4">
          <Form.Label>Código postal</Form.Label>
          <Form.Control
            type="text"
            name="envioCp"
            defaultValue={formData.envioCp}
            placeholder="Ingrese el código postal"
            onChange={(e) => handleChange(e)}
          ></Form.Control>
        </Form.Group>
      </Row>
    </Row>
  );
};

export default Envio;
