import { useState, useEffect } from "react";
import axios from "axios";
import useAxiosFilter from "./useAxiosFilter";
import { Redirect } from "react-router-dom";
// import useValidateStatus from "./useValidateStatus";

const useAxios = (endPoint) => {
  // const { validate } = useValidateStatus();
  const initialENDPOINT = process.env.REACT_APP_ENDPOINT;
  const token = localStorage.getItem("token");
  const [data, setData] = useState([]);
  const [errMsg, setErrMsg] = useState("");
  const [isMounted, setIsMounted] = useState(true);

  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const getData = async () => {
    setLoading(true);
    try {
      const res = await axios.get(endPoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (isMounted && res.status === 200) {
        setData(res.data.data.original.results);
        setTotalRows(res.data.data.original.cantidad_total);
        setPerPage(res.data.data.original.cantidad_por_pagina);
        setPage(res.data.data.original.pagina);
        setErrMsg("");
      }
    } catch (error) {
      if (isMounted && error.request.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("name");
        localStorage.removeItem("idUser");
        window.location.href = "/login";
      }
      // else {
      //   window.location.href = "/404";
      // }
      setData([]);
      setErrMsg(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    token && isMounted && getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endPoint]);

  const {
    data: dataGlobal,
    // errMsg: errMsgCategorias,
    // isLoading: isLoadingCategorias,
  } = useAxiosFilter(`${initialENDPOINT}/global`);

  return {
    data,
    setData,
    dataGlobal,
    errMsg,
    totalRows,
    loading,
    perPage,
    setPerPage,
    page,
    setPage,
  };
};

export default useAxios;
