import DataTable from "react-data-table-component";
import Loader from "../../../../Common/Loader";
import Vacio from "../../../../Common/Vacio";
import { Form } from "react-bootstrap";
import { useEffect } from "react";
import { useParams } from 'react-router-dom';

const ProductsTable = ({
  data,
  errMsg,
  formData,
  setFormData,
  isLoadingExcel,
  loading,
  totalRows,
  perPage,
  setPerPage,
  setPage,
  setENDPOINT,
  filterENDPOINT,
}) => {
  const initialENDPOINT = process.env.REACT_APP_ENDPOINT;

  const handleCheckChange = (e, productId) => {
    const { checked } = e.target;
    const updatedProducts = formData.productos.map((p) => {
      if (p.id === productId) {
        return { ...p, enDeposito: checked === true ? 1 : 0};
      }
      return p;
    });

    setFormData({
      ...formData,
      productos: updatedProducts,
    });
  };

  const queriesColumns = [
    {
      name: "ID",
      selector: (row) => row.producto,
      center: false,
      wrap: false,
      grow: 0,
    },
    {
      name: "Codigo",
      selector: (row) => row.productoCodigo,
      center: false,
      wrap: false,
    },
    {
      name: "Producto",
      selector: (row) => row.productoNombre,
      center: false,
      wrap: false,
      grow: 2,
    },
    {
      name: "Precio Regular",
      selector: (row) => `USD ${parseFloat(row.precioVenta).toFixed(2)}`,
      center: true,
      wrap: false,
      grow: 2,
    },
    {
      name: "Cantidad",
      selector: (row) => row.cantidad,
      center: false,
      compact: true,
      wrap: false,
    },
    {
      name: "P / Unitario",
      selector: (row) => `USD ${parseFloat(row.precioUnitario).toFixed(2)}`,
      center: false,
      compact: true,
      wrap: false,
    },
    {
      name: "Total",
      selector: (row) => `USD ${parseFloat(row.precioUnitario * row.cantidad).toFixed(2)}`,
      center: false,
      compact: true,
      wrap: false,
    },
    {
      name: "En depósito",
      cell: (row) => (
        <Form.Check
          type="checkbox"
          id={row.id}
          name="enDeposito"
          onChange={(e) => handleCheckChange(e, row.id)}
          checked={row.enDeposito === 1 || row.enDeposito === true}
        />
      ),
      center: true,
      compact: true,
    },
  ];
  const { purchaseID } = useParams();
  const handlePageChange = (pag) =>
  setENDPOINT(
    `${initialENDPOINT}/compradetalle?compra=${purchaseID}&pagina=${pag}&cantidad=${perPage}`
  );
const handlePerRowsChange = async (newPerPage, pag) => {
  setPerPage(newPerPage);
  setPage(pag);
  await setENDPOINT(
    `${initialENDPOINT}/compradetalle?compra=${purchaseID}&pagina=${pag}&cantidad=${newPerPage}`
  );
};

  // useEffect(() => {

  // }, [formData.productos]);

  return (
    <div className="my-3 table-queries" style={{ overflow: "hidden" }}>
      {errMsg && <p>{errMsg}</p>}
      <DataTable
        columns={queriesColumns}
        data={formData.productos}
        striped
        pointerOnHover
        highlightOnHover
        progressPending={isLoadingExcel}
        progressComponent={<Loader />}
        noDataComponent={<Vacio />}
        persistTableHead
        pagination
        paginationComponentOptions={{
          rowsPerPageText: "Filas por página",
          rangeSeparatorText: "de",
        }}
      />
      {/* <DataTable
        columns={queriesColumns}
        data={formData.productos}
        striped
        customStyles={{
          rows: {
            highlightOnHoverStyle: {
              backgroundColor: "rgb(230, 244, 244)",
              borderBottomColor: "#FFFFFF",
              outline: "1px solid #FFFFFF",
            },
          },
          pagination: {
            style: {
              border: "none",
            },
          },
        }}
        highlightOnHover
        progressPending={loading}
        progressComponent={<Loader />}
        noDataComponent={<Vacio />}
        pagination
        paginationServer
        paginationComponentOptions={{
          rowsPerPageText: "Filas por página",
          rangeSeparatorText: "de",
        }}
        paginationDefaultPage
        paginationTotalRows={totalRows}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerRowsChange}
        persistTableHead
      /> */}
    </div>
  );
};

export default ProductsTable;
