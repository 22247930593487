import CheckContext from "../../_helper/Customizer";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { AlignCenter } from "react-feather";
import useWindowSize from "../../_helper/hooks/useWindowSize";
// import { useState } from "react";

const Leftbar = () => {
  const { mixLayout, toggleSidebar, IsOpen, setIsClose } =
    useContext(CheckContext);
  const { width } = useWindowSize();
  // const [toggle, setToggle] = useState(true);

  const openCloseSidebar = () => {
    // setToggle(!toggle);
    setIsClose(!IsOpen);
    toggleSidebar(IsOpen);
  };

  return (
    <div className="main-header-left">
      {mixLayout ? (
        <div className="logo-wrapper">
          <Link to={`${process.env.PUBLIC_URL}/escritorio`}>
            <h1 hidden>Trompo Agencia</h1>
            <img
              className="img-fluid d-inline px-md-1 mx-lg-3"
              src={require("../../assets/images/logo/logo.png")}
              alt="Trompo Agencia"
              // width="100%"
              style={{ maxWidth: 175 }}
            />
          </Link>
        </div>
      ) : (
        <div className="dark-logo-wrapper">
          <Link to={`${process.env.PUBLIC_URL}/escritorio`}>
            <img
              className="img-fluid d-inline px-md-1 mx-lg-3"
              src={require("../../assets/images/logo/dark-logo.png")}
              alt="Trompo Agencia"
              // width="100%"
              style={{ maxWidth: 175 }}
            />
          </Link>
        </div>
      )}
      {width < 800 && (
        <div className="toggle-sidebar">
          <AlignCenter
            className="status_toggle middle"
            id="sidebar-toggle"
            onClick={openCloseSidebar}
          />
        </div>
      )}
    </div>
  );
};

export default Leftbar;
