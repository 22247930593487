import { useState } from "react";
import useAxios from "../hooks/useAxios";
import Context from "./index";

const DataProvider = (props) => {
  const [ENDPOINT, setENDPOINT] = useState("");
  const [filterENDPOINT, setFilterENDPOINT] = useState("");

  const {
    data,
    dataGlobal,
    errMsg,
    loading,
    totalRows,
    perPage,
    setPerPage,
    page,
    setPage,
  } = useAxios(`${ENDPOINT}${filterENDPOINT}`);

  const dataContext = {
    data,
    dataGlobal,
    errMsg,
    loading,
    totalRows,
    perPage,
    setPerPage,
    page,
    setPage,
    setENDPOINT,
    ENDPOINT,
    setFilterENDPOINT,
    filterENDPOINT,
  };
  return (
    <Context.Provider value={dataContext}>{props.children}</Context.Provider>
  );
};

export default DataProvider;
