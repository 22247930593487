import GaleryComponent from "./GaleryComponent";
import { toast } from "react-toastify";
import axios from "axios";

const GaleryContainer = ({ formData, getData }) => {
  const initialENDPOINT = process.env.REACT_APP_ENDPOINT;
  const token = localStorage.getItem("token");
  const notify = (text) => toast(text);

  const handleImg = async (idImagen, action) => {
    try {
      const url = `${initialENDPOINT}/fotoproducto`;

      const params = new URLSearchParams();
      params.append("idProducto", formData.id);
      params.append("idImagen", idImagen);
      params.append("action", action);

      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/x-www-form-urlencoded",
      };

      await axios.post(url, params.toString(), { headers });
      getData();
    } catch (error) {
      notify("Ha ocurrido un error", error);
    } finally {
      action === "principal" && notify("Imagen principal cambiada");
      action === "eliminar" && notify("Imagen eliminada");
    }
  };

  return (
    <>
      {formData.imagenes &&
        formData.imagenes.map((item, i) => (
          <GaleryComponent key={i} item={item} handleImg={handleImg} />
        ))}
    </>
  );
};

export default GaleryContainer;
