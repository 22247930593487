import { Dropdown } from "react-bootstrap";

function convertArrayOfObjectsToCSV(array) {
  let result;

  const columnDelimiter = ";";
  const lineDelimiter = "\n";
  const keys = Object.keys(array[0]);

  result = "";
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  array.forEach((item) => {
    let ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;
      result += item[key];
      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
}

export function downloadCSV(array, name) {
  const link = document.createElement("a");
  let csv = convertArrayOfObjectsToCSV(array);
  if (csv == null) return;

  const filename = `${name}.csv`;

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute("href", encodeURI(csv));
  link.setAttribute("download", filename);
  link.click();
}

export const Export = ({ onExport }) => (
  <Dropdown>
    <Dropdown.Toggle
      variant="success"
      id="dropdown-basic"
      className="px-2 px-md-3"
    >
      <i className="fa fa-cog me-2" aria-hidden="true"></i>
    </Dropdown.Toggle>

    <Dropdown.Menu className="shadow">
      <Dropdown.Item onClick={(e) => onExport(e.target.value)}>
        Exportar Excel
      </Dropdown.Item>
    </Dropdown.Menu>
  </Dropdown>
);