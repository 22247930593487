import ConfigDB from '../../../../Config/ThemeConfig';
const primary = localStorage.getItem('default_color') || ConfigDB.data.color.primary_color;

export const NuevosClientesData = {
    series: [{
      data: [21, 22, 10, 28, 16, 21, 13, 30]
    }],
    options: {
      chart: {
        height: 350,
        type: 'bar',
        toolbar: {
          show: false,
        },
        events: {
          click: function (chart, w, e) {
          }
        }
      },
      colors: primary,
      plotOptions: {
        bar: {
          borderRadius: 10,
          rangeBarOverlap: true,
          columnWidth: '30%',
          colors: {
            backgroundBarColors: ['#e5edef', '#e5edef', '#e5edef', '#e5edef'],
            backgroundBarOpacity: 1,
            backgroundBarRadius: 9
          },
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        }
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false
      },
      xaxis: {
        categories: [
          ['Ene'],
          ['Feb'],
          ['Mar'],
          ['Abr'],
          ['May'],
          ['Jun'],
          ['Jul'],
          ['Ago'],
          ['Sep']
        ],
        labels: {
          style: {
            fontSize: '12px'
          }
        },
      },
    },
  };