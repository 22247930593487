import DataTable from "react-data-table-component";
import Loader from "../../../../Common/Loader";
import Vacio from "../../../../Common/Vacio";
import { Form, InputGroup } from "react-bootstrap";
import { useEffect } from "react";

const ProductsTable = ({
  errMsg,
  loading,
  formData,
  setFormData,
  handleProductDelete,
  isLoadingExcel,
}) => {
  const queriesColumns = [
    {
      name: "ID",
      selector: (row) => row.producto,
      center: false,
      wrap: false,
      grow: 0,
    },
    {
      name: "Codigo",
      selector: (row) => row.codigo,
      center: false,
      wrap: false,
      grow: 1,
    },
    {
      name: "Producto",
      selector: (row) => row.nombreProducto,
      center: false,
      wrap: false,
      grow: 2,
    },
    {
      name: "Precio Regular",
      selector: (row) => `USD ${row.precioVenta || '0.00'}`,
      center: false,
      compact: true,
      wrap: false,
    },
    {
      name: "Cantidad",
      selector: (row) => row.cantidad,
      center: true,
      compact: true,
      wrap: false,
    },
    {
      name: "Costo Unit",
      selector: (row) => `USD ${row.precioUnitario}`,
      center: false,
      compact: true,
      wrap: false,
    },
    {
      name: "Total",
      selector: (row) => `USD ${parseFloat(row.precioUnitario * row.cantidad).toFixed(2)}`,
      center: false,
      compact: true,
      wrap: false,
    },
    {
      name: "Acciones",
      selector: (row) =>
          <button
            key={3}
            className="btn text-dark w-auto p-1 mx-1 my-0"
            type="button"
            title="Borrar"
            onClick={() => handleProductDelete(row)}
          >
            <i className="fa fa-trash f-16 px-1" />
          </button>,
      center: true,
      hide: "md",
      compact: true,
    },
  ];

  const subtotalProductos = (array) => {
    return array?.reduce(
      (acc, curr) =>
        acc + parseFloat(curr.precioUnitario) * parseInt(curr.cantidad),
      0
    );
  };

  const calcularTotales = () => {
    setFormData((prevFormData) => {
      const productos = prevFormData.productos;
      const subtotal = subtotalProductos(productos)?.toFixed(2);

      return {
        ...prevFormData,
        subtotal: subtotal,
      };
    });
  };

  useEffect(() => {
    calcularTotales();
  }, [formData.productos]);

  return (
    <div className="my-3 table-queries" style={{ overflow: "hidden" }}>
      {errMsg && <p>{errMsg}</p>}
      <DataTable
        columns={queriesColumns}
        data={formData.productos}
        striped
        customStyles={{
          rows: {
            highlightOnHoverStyle: {
              backgroundColor: "rgb(230, 244, 244)",
              borderBottomColor: "#FFFFFF",
              outline: "1px solid #FFFFFF",
            },
          },
          pagination: {
            style: {
              border: "none",
            },
          },
        }}
        pointerOnHover
        highlightOnHover
        paginationComponentOptions={{
          rowsPerPageText: "Filas por página",
          rangeSeparatorText: "de",
        }}
        progressPending={isLoadingExcel}
        progressComponent={<Loader />}
        noDataComponent={<Vacio />}
        persistTableHead
        pagination
      />
      <InputGroup className="mt-3 w-25 ms-auto">
        <InputGroup.Text>Total USD</InputGroup.Text>
        <Form.Control
          aria-label="Total"
          value={formData.subtotal}
          readOnly
          className="text-end"
        />
      </InputGroup>
    </div>
  );
};

export default ProductsTable;
