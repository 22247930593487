import { ToastContainer } from "react-toastify";
import { useContext } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Loader from "./Loader";
import Taptop from "./TapTop";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import CustomizerContext from "../_helper/Customizer";
import AnimationThemeContext from "../_helper/AnimationTheme";
import ConfigDB from "../Config/ThemeConfig";

const AppLayout = ({ children, classNames, ...rest }) => {
  const { sidebar_types, toggleIcon } = useContext(CustomizerContext);
  const settings1 =
    localStorage.getItem("sidebar_Settings") ||
    ConfigDB.data.settings.sidebar_setting;
  const sidebar_types1 =
    localStorage.getItem("sidebar_types") ||
    ConfigDB.data.settings.sidebar.type ||
    sidebar_types;

  const location = useLocation();
  const { animation } = useContext(AnimationThemeContext);
  const animationTheme =
    localStorage.getItem("animation") ||
    animation ||
    ConfigDB.data.router_animation;

  return (
    <>
      <Loader />
      <Taptop />
      <div
        className={`page-wrapper ${sidebar_types1} ${settings1}`}
        id="pageWrapper"
      >
        <Header />
        <div className="page-body-wrapper horizontal-menu">
          <Sidebar />
          <TransitionGroup {...rest}>
            <CSSTransition
              key={location.key}
              timeout={100}
              classNames={animationTheme}
              unmountOnExit
            >
              <div
                className={toggleIcon ? "" : "page-body"}
                style={{ marginTop: toggleIcon && "12vh" }}
              >
                  <Outlet />
              </div>
            </CSSTransition>
          </TransitionGroup>
          <Footer />
        </div>
      </div>
      <ToastContainer />
    </>
  );
};
export default AppLayout;
