import { Row, Form, Button, Col } from "react-bootstrap";
import { toast } from "react-toastify";

const NewFilterForm = ({
  showFilter,
  setPage,
  setFilterENDPOINT,
  resetAll,
  formData,
  setFormData,
  handleNombreMarcaChange,
  handleDesdeChange,
  handleHastaChange
}) => {
  const notify = () => toast("¡Datos filtrados!");

  const itemFilter = (e) => {
    e.preventDefault();
    setPage(1);

    setFilterENDPOINT(
      `&nombreMarca=${formData.nombre}${formData.desde === "" ? "" : `&desde=${formData.desde}`}${
        formData.hasta === "" ? "" : `&hasta=${formData.hasta}`
      }`
    );
    showFilter();
    notify();
  };

  const resetAllFilters = () => {
    resetAll();
    showFilter();
  };

  const handleChange = (e) => {
    let newValue = e.target.value;
    let inputName = e.target.name;
    setFormData((prevValue) => {
      return {
        ...prevValue,
        [inputName]: newValue,
      };
    });

    if (inputName === "nombre") {
      handleNombreMarcaChange(newValue);
    } else if (inputName === "desde") {
      handleDesdeChange(newValue);
    } else if (inputName === "hasta") {
      handleHastaChange(newValue);
    }
  };

  return (
    <Form
      onSubmit={itemFilter}
      className="bg-white p-4 rounded mb-3 border justify-content-evenly "
    >
      <Row>
        {/* <Form.Group className="col-12 col-md-3 ">
          <Form.Label>Marca</Form.Label>
          <Form.Control
            type="text"
            placeholder="Nombre Marca"
            name="nombre"
            id="nombreMarca"
            value={formData.nombre}
            onChange={handleChange}
          />
        </Form.Group> */}

        <Form.Group className="col-12 col-md-4 mb-3">
          <div style={{ display: "flex" }}>
            <Col>
              <Form.Label>Desde Fecha</Form.Label>
              <Form.Control
                type="date"
                placeholder="Desde.."
                name="desde"
                id="desde"
                onChange={handleChange}
                value={formData.desde}
              />
            </Col>
            <Col>
              <Form.Label>Hasta Fecha</Form.Label>
              <Form.Control
                type="date"
                placeholder="Hasta.."
                name="hasta"
                id="hasta"
                onChange={handleChange}
                value={formData.hasta}
              />
            </Col>
          </div>
        </Form.Group>
      </Row>

      <Form.Group className="d-flex align-items-center justify-content-center">
        <Button
          variant="info"
          className="mt-2 mt-md-4 me-auto col-5 col-md-2 col-xxl-1 d-flex align-items-center justify-content-center"
          onClick={resetAllFilters}
        >
          Limpiar
        </Button>
        <Button
          type="submit"
          className="mt-2 mt-md-4 col-5 col-md-2 col-xxl-1 d-flex align-items-center justify-content-center"
        >
          Filtrar
        </Button>
      </Form.Group>
    </Form>
  );
};

export default NewFilterForm;
