import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Row, Button, Col, Container } from "react-bootstrap";
import { toast } from "react-toastify";
import { Breadcrumbs } from "../../../../AbstractElements";
import { useEffect } from "react";
import axios from "axios";
import DataContext from "../../../../_helper/DataContext";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const TemplateProveedores = () => {
  let { providerID } = useParams();
  const history = useNavigate();
  const token = localStorage.getItem("token");
  const initialENDPOINT = process.env.REACT_APP_ENDPOINT;
  const [formData, setFormData] = useState({
    nombre: "",
    direccion: "",
    email: "",
    ciudad: "",
    codigoPostal: "",
    telefono: "",
    movil: "",
    contacto: "",
    transportadora: 0,
    telefonoTransportadora: "",
    observaciones: "",
    formasDePago: 0,
    suspendido: 0,
  });
  const [disableButton, setDisableButton] = useState(false);
  const [transportadoras, setTransportadoras] = useState([]);
  const [isMounted, setIsMounted] = useState(true);

  // const notify = () => toast("Usuario creado!");
  const notify = () =>
    toast(`Usuario ${providerID != 0 ? "editado" : "creado"}!`);

  const handleChange = (e) => {
    let value;
    e.target.type === "checkbox"
      ? (value = e.target.checked)
      : (value = e.target.value);
    let name = e.target.name;

    setFormData((prevalue) => {
      return {
        ...prevalue,
        [name]: value,
      };
    });
  };

  // console.log(formData);

  const sendForm = async (e) => {
    e.preventDefault();
    // setDisableButton(true);

    const dataToSend = JSON.stringify({
      nombre: formData.nombre,
      direccion: formData.direccion || "-",
      email: formData.email,
      ciudad: formData.ciudad || "-",
      codigoPostal: formData.codigoPostal,
      telefono: formData.telefono || 0,
      movil: formData.movil,
      contacto: formData.contacto,
      transportadora: formData.transportadora,
      telefonoTransportadora: formData.telefonoTransportadora,
      observaciones: formData.observaciones,
      formaDePago: formData.formaDePago,
      suspendido: formData.suspendido ? 1 : 0,
    });

    try {
      await axios({
        // url: `${initialENDPOINT}/proveedor/${providerID != 0 ? providerID : ""}`,
        url: `${initialENDPOINT}/proveedor${
          providerID != 0 ? `/${providerID}` : ""
        }`,
        method: `${providerID != 0 ? "PUT" : "POST"}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: dataToSend,
        redirect: "follow",
      });
    } catch (error) {
      console.log("Ha ocurrido un error", error);
    } finally {
      notify();
      history(`${process.env.PUBLIC_URL}/proveedores`);
    }
  };

  // const pedidoProveedor = async () => {
  //   try {
  //     let pedido = await axios(`${initialENDPOINT}/proveedor/${providerID}`, {
  //       method: "GET",
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //       redirect: "follow",
  //     });
  //     isMounted && setFormData(pedido.data.data);
  //   } catch (error) {
  //     console.log("Ha ocurrido un error", error);
  //   }
  // };

  const getData = async () => {
    try {
      const res = await axios.get(
        `${initialENDPOINT}/proveedor/${providerID}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      isMounted && setFormData(res.data.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const getTransportadoras = async () => {
    try {
      const res = await axios(`${initialENDPOINT}/empresatransportadora`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      isMounted && setTransportadoras(res.data.data.original.results);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    isMounted && providerID != 0 && getData();
    isMounted && getTransportadoras();
    return () => {
      setIsMounted(false);
    };
  }, []);

  return (
    <Container fluid={true}>
      <Breadcrumbs
        mainTitle={`${providerID != 0 ? "Editar" : "Crear"} proveedor`}
        parent="Proveedores"
        title={`${providerID != 0 ? "Editar" : "Crear"} proveedor`}
      />
      <Form onSubmit={sendForm} className="container col-7">
        <Row>
          <Col className="bg-white p-4 mb-5 rounded border shadow">
            <Form.Group className="d-flex align-items-center mb-3">
              <Form.Label className="col-4 col-md-4">Nombre</Form.Label>
              <Form.Control
                type="text"
                name="nombre"
                onChange={handleChange}
                defaultValue={formData?.nombre}
                required
              />
            </Form.Group>

            <Form.Group className="d-flex align-items-center mb-3">
              <Form.Label className="col-4 col-md-4">Dirección</Form.Label>
              <Form.Control
                type="text"
                name="direccion"
                onChange={handleChange}
                defaultValue={formData?.direccion}
              />
            </Form.Group>

            <Form.Group className="d-flex align-items-center mb-3">
              <Form.Label className="col-4 col-md-4">Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                onChange={handleChange}
                defaultValue={formData?.email}
              />
            </Form.Group>

            <Form.Group className="d-flex align-items-center mb-3">
              <Form.Label className="col-4 col-md-4">Ciudad</Form.Label>
              <div className="w-100">
                <Form.Control
                  type="text"
                  name="ciudad"
                  onChange={handleChange}
                  defaultValue={formData?.ciudad}
                />
              </div>
            </Form.Group>
            <Form.Group className="d-flex align-items-center mb-3">
              <Form.Label className="col-4 col-md-4">Código Postal</Form.Label>
              <Form.Control
                type="text"
                defaultValue={formData.codigoPostal}
                name="codigoPostal"
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="d-flex align-items-center mb-3">
              <Form.Label className="col-4 col-md-4">Teléfono</Form.Label>
              <Form.Control
                type="tel"
                name="telefono"
                onChange={handleChange}
                defaultValue={formData?.telefono}
              />
            </Form.Group>

            <Form.Group className="d-flex align-items-center mb-3">
              <Form.Label className="col-4 col-md-4">Móvil</Form.Label>
              <Form.Control
                type="tel"
                name="movil"
                onChange={handleChange}
                defaultValue={formData?.movil}
              />
            </Form.Group>
            <Form.Group className="d-flex align-items-center mb-3">
              <Form.Label className="col-4 col-md-4">Contacto</Form.Label>
              <Form.Control
                type="text"
                name="contacto"
                onChange={handleChange}
                defaultValue={formData?.contacto}
              />
            </Form.Group>

            <Form.Group className="d-flex align-items-center mb-3">
              <Form.Label className="col-4 col-md-4">Transportadora</Form.Label>
              <Form.Select
                name="transportadora"
                onChange={(e) => handleChange(e)}
                className="w-75"
                value={
                  formData.transportadora
                    ? formData.transportadora
                    : "Seleccione"
                }
              >
                <option disabled>Seleccione</option>
                {transportadoras?.map((item, i) => (
                  <option key={i} value={item.id}>
                    {item.nombre}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="d-flex align-items-center mb-3">
              <Form.Label className="col-4 col-md-4">
                Teléfono Transportadora
              </Form.Label>
              <Form.Control
                type="tel"
                name="telefonoTransportadora"
                onChange={handleChange}
                defaultValue={formData?.telefonoTransportadora}
              />
            </Form.Group>

            <Form.Group className="d-flex align-items-center mb-3">
              <Form.Label className="col-4 col-md-4">Observaciones</Form.Label>
              <Form.Control
                type="text"
                name="observaciones"
                onChange={handleChange}
                defaultValue={formData?.observaciones}
              />
            </Form.Group>
            <Form.Group className="d-flex align-items-center mb-3">
              <Form.Label className="col-4 col-md-4">Forma de Pago</Form.Label>
              <Form.Control
                type="text"
                name="formaDePago"
                onChange={handleChange}
                defaultValue={formData?.formaDePago}
              />
            </Form.Group>

            <Form.Group className="d-flex align-items-center mb-3">
              <Form.Label className="col-4 col-md-4">Suspendido</Form.Label>
              <Form.Check
                type="switch"
                name="suspendido"
                onChange={handleChange}
                // defaultValue={formData?.suspendido}
                checked={formData?.suspendido}
              />
            </Form.Group>
            <div className="d-flex justify-content-end">
              <Button
                disabled={disableButton}
                type="submit"
                className="d-flex justify-content-center mt-2 mt-md-2 col-2 col-lg-2"
              >
                {providerID != 0 ? "Guardar" : "Crear"}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default TemplateProveedores;
