import { useContext, useState } from "react";
import { Form, Row, Button, Col, Container } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import DataContext from "../../../_helper/DataContext";
import { Breadcrumbs } from "../../../AbstractElements";
import { useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const NewUser = () => {
  const history = useNavigate();
  const token = localStorage.getItem("token");
  const [formData, setFormData] = useState({});
  const [dataPerfilesEndpoint, setDataPerfilesEndpoint] = useState([]);
  const [disableButton, setDisableButton] = useState(false);
  const [permisosEmpty, setPermisosEmpty] = useState();
  const initialENDPOINT = process.env.REACT_APP_ENDPOINT;
  const notify = () => toast("Usuario creado!");

  const sendForm = async (e) => {
    e.preventDefault();
    if (formData.permisos === undefined) {
      setPermisosEmpty(true);
    } else {
      setPermisosEmpty(false);
      setDisableButton(true);
      let dataToSend = JSON.stringify({
        nombre: formData.nombre.trim(),
        // apellido: formData.apellido.trim(),
        clave: formData.clave,
        // email: formData.email.trim(),
        permisos: formData.permisos,
        suspendido: formData.suspendido === true ? 1 : 0,
      });
      try {
        await axios(
          // `${initialENDPOINT}/usuario?nombre=${formData.nombre}&apellido=${formData.apellido}&clave=${formData.clave}&email=${formData.email}&perfil=${formData.perfil}`,
          `${initialENDPOINT}/usuario`,
          {
            method: "POST",
            headers: {
              //     token: token,
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            data: dataToSend,
            redirect: "follow",
          }
        );
      } catch (error) {
        console.log("Ha ocurrido un error", error);
      } finally {
        notify();
        history(`${process.env.PUBLIC_URL}/usuarios`);
      }
    }
  };

  const handleChange = (e) => {
    let value;

    let name = e.target.name;

    if (e.target.name === "suspendido") {
      e.target.type === "checkbox"
        ? (value = e.target.checked)
        : (value = e.target.value);
    } else {
      value = e.target.value;
    }
    setFormData((prevalue) => {
      return {
        ...prevalue,
        [name]: value,
      };
    });
  };

  const pedidoPerfiles = async () => {
    try {
      let pedido = await axios(`${initialENDPOINT}/perfil`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        redirect: "follow",
      });

      dataPerfilesEndpoint &&
        setDataPerfilesEndpoint(pedido.data.data.original.results);
    } catch (error) {
      console.log("Ha ocurrido un error", error);
    }
  };

  useEffect(() => {
    pedidoPerfiles();
  }, []);

  return (
    <Container fluid={true}>
      <Breadcrumbs
        mainTitle="Nuevo usuario"
        parent="Usuarios"
        title="Nuevo usuario"
      />
      <Form onSubmit={sendForm} className="container col-12">
        <Row>
          <Col className="col-11 col-lg-9 col-xxl-8 bg-white p-4 mb-5 rounded border shadow">
            <Form.Group className="d-flex align-items-center mb-3">
              <Form.Label className="col-4 col-md-4">Nombre</Form.Label>
              <Form.Control
                type="text"
                name="nombre"
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group className="d-flex align-items-center mb-3">
              <Form.Label className="col-4 col-md-4">Contraseña</Form.Label>
              <div className="w-100">
                <Form.Control
                  type="password"
                  name="clave"
                  onChange={handleChange}
                  required
                  minLength={8}
                  maxLength={20}
                />
                <Form.Text id="passwordHelpBlock" muted>
                  Su contraseña debe tener entre 8 y 20 caracteres.
                </Form.Text>
              </div>
            </Form.Group>
            <Form.Group className="d-flex align-items-center mb-3">
              <Form.Label className="col-4 col-md-4">
                Perfil/Permisos
              </Form.Label>
              <Form.Select
                defaultValue={"Elija una opción"}
                name="permisos"
                onChange={handleChange}
                className={permisosEmpty && `is-invalid`}
              >
                <option disabled>Elija una opción</option>
                {dataPerfilesEndpoint?.map((item, i) => (
                  <option key={i} value={item.id}>
                    {item.nombre}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="d-flex align-items-center mb-3">
              <Form.Label className="col-4 col-md-4">Suspendido</Form.Label>
              <Form.Check
                type="checkbox"
                name="suspendido"
                onChange={handleChange}
                checked={formData?.suspendido}
              />
            </Form.Group>
            <div className="d-flex justify-content-end">
              <Button
                disabled={disableButton}
                type="submit"
                className="mt-2 mt-md-2 col-2 col-lg-2"
              >
                Crear
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default NewUser;
