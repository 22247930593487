import { Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import Loader from "../../Common/Loader";
import Vacio from "../../Common/Vacio";
import TableTitle from "../../Common/TableTitle";

const initialENDPOINT = process.env.REACT_APP_ENDPOINT;

const PurchasesTable = ({
  data,
  errMsg,
  loading,
  totalRows,
  perPage,
  setPerPage,
  setPage,
  setENDPOINT,
  filterENDPOINT,
}) => {
  const queriesColumns = [
    {
      name: "Código",
      selector: (row) => row.id,
      wrap: false,
      grow: 0,
    },
    {
      name: "Proveedor",
      selector: (row) =>
        row.nombreProveedor ? row.nombreProveedor : "Sin nombre cargado",
      wrap: true,
      grow: 1,
    },
    {
      name: "Monto",
      selector: (row) => `USD ${row.precio}`,
    },
    {
      name: "Ingreso",
      selector: (row) => row.fechaDeIngreso,
      // hide: "sm",
      wrap: true,
    },
    {
      name: "Pago",
      selector: (row) => row.fechaDePago,
      // hide: "sm",
      wrap: true,
    },
    {
      name: "Estado",
      selector: (row) => (
        <Badge key={row.id} bg={row.pagado === 0 ? "danger" : "primary"}>
          {row.pagado === 0 ? "Impago" : "Pagado"}
        </Badge>
      ),
      center: true,
    },
    {
      name: "Acciones",
      selector: (row) => [
        <Link key={row.id} to={`/editar-compra/${row.id}`}>
          <button
            className="btn text-dark w-auto p-1 mx-1 my-0"
            type="button"
            title="Editar"
          >
            <i className="fa fa-pencil-square-o f-16 px-1" />
          </button>
        </Link>,
      ],
      center: true,
      // hide: "md",
    },
  ];

  const handlePageChange = (pag) => {
    setENDPOINT(
      `${initialENDPOINT}/compra?pagina=${pag}&cantidad=${perPage}${filterENDPOINT}`
    );
  };
  const handlePerRowsChange = async (newPerPage, pag) => {
    setPerPage(newPerPage);
    setPage(pag);
    await setENDPOINT(
      `${initialENDPOINT}/compra?pagina=${pag}&cantidad=${newPerPage}${filterENDPOINT}`
    );
  };

  return (
    <div
      className="App mb-5 table-queries border rounded "
      class="overflow-hidden overflow-x-auto"
    >
      {errMsg ? (
        <p>{errMsg}</p>
      ) : (
        <DataTable
          title={<TableTitle value="Lista de compras" />}
          columns={queriesColumns}
          data={data}
          striped
          customStyles={{
            rows: {
              highlightOnHoverStyle: {
                backgroundColor: "rgb(230, 244, 244)",
                borderBottomColor: "#FFFFFF",
                outline: "1px solid #FFFFFF",
              },
            },
            pagination: {
              style: {
                border: "none",
              },
            },
          }}
          highlightOnHover
          progressPending={loading}
          progressComponent={<Loader />}
          noDataComponent={<Vacio />}
          pagination
          paginationServer
          paginationComponentOptions={{
            rowsPerPageText: "Filas por página",
            rangeSeparatorText: "de",
          }}
          paginationDefaultPage
          paginationTotalRows={totalRows}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handlePerRowsChange}
          persistTableHead
        />
      )}
    </div>
  );
};

export default PurchasesTable;
