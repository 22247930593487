import ConfigDB from "../../../../Config/ThemeConfig";
const primary =
  localStorage.getItem("default_color") || ConfigDB.data.color.primary_color;

const costoPorVentaArray = [
  "Calle",
  "Llamados y Oficina",
  "Google Ads",
  "Leads",
  "Facebook",
  "Msn/Instagram Direct",
];

export const costoPorVenta = {
  series: [
    {
      data: [10, 68, 86, 21, 13, 70],
    },
  ],
  options: {
    chart: {
      height: 350,
      type: "bar",
      toolbar: {
        show: false,
      },
      events: {
        click: function (chart, w, e) {},
      },
    },
    colors: primary,
    plotOptions: {
      bar: {
        borderRadius: 10,
        rangeBarOverlap: true,
        horizontal: true,
        columnWidth: "30%",
        colors: {
          backgroundBarColors: ["#e5edef"],
          backgroundBarOpacity: 1,
          backgroundBarRadius: 9,
        },
        dataLabels: {
          position: "center",
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: costoPorVentaArray,
      labels: {
        style: {
          fontSize: "12px",
        },
      },
    }
  },
};
