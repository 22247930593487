import { useState } from "react";
import { Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Btn } from "../AbstractElements";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const LoginTab = () => {
  const [email, setEmail] = useState("admin@admin.com");
  const [password, setPassword] = useState("123456");
  const [errEmailMsg, setEmailMsg] = useState("");
  const [errPasswordMsg, setPasswordMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [togglePassword, setTogglePassword] = useState(false);
  const initialENDPOINT = process.env.REACT_APP_ENDPOINT;
  //const initialENDPOINT = "https://phpstack-1091339-3819555.cloudwaysapps.com/api";
  const history = useNavigate();

  const loginMetrics = (e) => {
    e.preventDefault();
    setLoading(true);

    const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!regexEmail.test(email)) {
      setEmailMsg("Formato de email incorrecto");
      setLoading(false);
      return;
    } else {
      setEmailMsg("");
      setLoading(false);
    }

    if (password.length < 6) {
      setPasswordMsg("La conteaseña debe tener más de 6 caracteres.");
      setLoading(false);
      return;
    } else {
      setPasswordMsg("");
      setLoading(false);
    }

    axios
      .post(`${initialENDPOINT}/login`, {
        nombre: email,
        clave: password,
      })
      .then((res) => {
        if (res.data.user.permiso !== 1) {
          setEmailMsg("Tu usuario no posee los permisos necesarios");
        } else {
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("name", `${res.data.user.nombre}`);
          history(`${process.env.PUBLIC_URL}/escritorio`);
        }
      })
      .catch(() => {
        setEmailMsg("Email o password incorrectos");
        setPasswordMsg("Email o password incorrectos");
      });
  };

  localStorage.getItem("token") &&
    history(`${process.env.PUBLIC_URL}/escritorio`);

  return (
    <Form className="theme-form">
      <h4>Iniciar sesión</h4>
      <p>Ingresa tu email y contraseña</p>
      <FormGroup>
        <Label className="col-form-label">{"Email"}</Label>
        <Input
          className="form-control"
          type="email"
          required=""
          onChange={(e) => setEmail(e.target.value)}
          defaultValue={email}
        />
        {errEmailMsg && <span style={{ color: "red" }}>{errEmailMsg}</span>}
      </FormGroup>
      <FormGroup className="position-relative">
        <Label className="col-form-label">Contraseña</Label>
        <Input
          className="form-control"
          type={togglePassword ? "text" : "password"}
          onChange={(e) => setPassword(e.target.value)}
          defaultValue={password}
          required=""
        />
        {errPasswordMsg && (
          <span style={{ color: "red" }}>{errPasswordMsg}</span>
        )}
        <div
          className="show-hide"
          onClick={() => setTogglePassword(!togglePassword)}
        >
          <span className={togglePassword ? "" : "show"}></span>
        </div>
      </FormGroup>
      <div className="form-group mb-0">
        <Row className="mb-2">
          <div className="col-md-6 checkbox my-auto">
            <Input id="checkbox1" type="checkbox" />
            <Label className="text-muted" for="checkbox1">
              Recordar contraseña
            </Label>
          </div>
          <a className="col-md-6 my-auto" href="#pass">
            ¿Olvidaste tu contraseña?
          </a>
        </Row>
        <Btn
          attrBtn={{
            color: "primary",
            className: "btn-block",
            disabled: loading ? loading : loading,
            onClick: (e) => loginMetrics(e),
          }}
        >
          {loading ? "Cargando..." : "Iniciar sesión"}
        </Btn>
      </div>
      <p className="mt-4 mb-0">
        ¿No estas registrado?
        <a className="ms-2" href="#javascript">
          Crear cuenta
        </a>
      </p>
    </Form>
  );
};

export default LoginTab;
