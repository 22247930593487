import { Container, Row, Col } from "reactstrap";
import { useLocation } from "react-router-dom";

const FooterClass = () => {
  const location = useLocation();

  return (
    <footer
      className={`footer ${
        location.pathname === "/viho/page-layout/footer-dark"
          ? "footer-dark"
          : location.pathname === "/viho/page-layout/footer-fixed"
          ? "footer-fix"
          : ""
      }`}
    >
      <Container fluid={true}>
        <Row>
          <Col md="6" className="footer-copyright">
            <p className="mb-0">
              {`Copyright ${new Date().getFullYear()} | © Cobax Sistemas | Todos los derechos reservados.`}
            </p>
          </Col>
          <Col md="6" className="d-flex justify-content-end">
            <p className="mb-0">V24.08.15.0</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default FooterClass;
