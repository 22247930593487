import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Loader from "../../Common/Loader";
import Vacio from "../../Common/Vacio";
import MyModal from "../../Common/MyModal";
import axios from "axios";
import Swal from "sweetalert2";
import { Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";

const TabComponent = ({
  setChosenEdit,
  data,
  totalRows,
  setPerPage,
  setPage,
  loading,
  setData,
  modalTitle,
  chosenEdit,
  getData,
}) => {
  const notify = () => toast(`${chosenEdit} Cargado!`);
  const queriesColumns = [
    {
      name: "Código",
      selector: (row) => row.id,
      center: true,
      wrap: false,
      sortable: true,
      maxWidth: "9%",
      background: "green",
    },
    {
      name: "Nombre",
      selector: (row) => row.nombre,
      center: false,
      wrap: false,
      sortable: true,
    },
    {
      name: "Acciones",
      selector: (row) => [
        <button
          key={3}
          className="btn text-dark w-auto p-1 mx-1 my-0"
          type="button"
          title="Borrar"
          onClick={(e) => handleClickSuspender(e, row)}
        >
          <i className="fa fa-trash f-16 px-1" />
        </button>,
        <button
          key={2}
          className="btn text-dark w-auto p-1 mx-1 my-0"
          type="button"
          title="Editar"
          onClick={(e) => handleClickEdit(e, row)}
        >
          <i className="fa fa-edit f-16 px-1" />
        </button>,
      ],
      center: true,
      compact: true,
    },
  ];

  const [baseEndpoint, setBaseEndpoint] = useState("");

  const [newItem, setNewItem] = useState(false);
  const [editItem, setEditItem] = useState(false);
  const [deleteItem, setDeleteItem] = useState(false);
  const [formData, setFormData] = useState({});
  const [newFilter, setNewFilter] = useState(false);
  const [emptyName, setEmptyName] = useState();
  const [cantidadMinimaEmpty, setCantidadMinimaEmpty] = useState();
  const token = localStorage.getItem("token");
  const initialENDPOINT = process.env.REACT_APP_ENDPOINT;

  const handlePageChange = (pag) => {
    setPage(pag);
  };

  const handlePerRowsChange = async (newPerPage, pag) => {
    setPerPage(newPerPage);
  };

  // Funciones básicas
  function handleClickAdd(e) {
    e.preventDefault();
    setNewItem(true);
    setChosenEdit(e.target.value);
  }

  const handleModalAdd = async () => {
    let dataToSend;
    if (!formData.nombre) {
      setEmptyName(true);
    } else {
      if (chosenEdit === "tipo" && !formData.CantidadMinima) {
        setCantidadMinimaEmpty(true);
      }
      if (formData.CantidadMinima) {
        dataToSend = JSON.stringify({
          nombre: formData.nombre,
          CantidadMinima: formData.CantidadMinima,
          suspendido: formData.suspendido === true ? 1 : 0,
        });
      } else if (chosenEdit === "marca") {
        dataToSend = JSON.stringify({
          nombre: formData.nombre,
          propia: formData.propia === true ? 1 : 0,
          VIP: formData.vip === true ? 1 : 0,
          logo: formData.logo === true ? 1 : 0,
          MostrarEnWeb: formData.mostrarWeb === true ? 1 : 0,
          suspendido: formData.suspendido === true ? 1 : 0,
        });
      } else {
        dataToSend = JSON.stringify({
          nombre: formData.nombre,
          suspendido: formData.suspendido === true ? 1 : 0,
        });
      }
      try {
        await axios({
          url: `${baseEndpoint}`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          data: dataToSend,
          redirect: "follow",
        });
      } catch (error) {
      } finally {
        notify();
        // handleData();
        setNewItem(false);
        setFormData({});
      }
    }
  };

  function handleClickEdit(e, row) {
    e.preventDefault();
    setFormData((prevalue) => {
      return {
        ...prevalue,
        ...row,
      };
    });
    setEditItem(true);
  }
  const handleModalEdit = async () => {
    let dataToSend;

    if (formData.CantidadMinima) {
      dataToSend = JSON.stringify({
        nombre: formData.nombre,
        CantidadMinima: formData.CantidadMinima,
        suspendido: formData.suspendido === true ? 1 : 0,
      });
    } else if (chosenEdit === "marca") {
      dataToSend = JSON.stringify({
        nombre: formData.nombre,
        propia: formData.propia === true ? 1 : 0,
        VIP: formData.vip === true ? 1 : 0,
        logo: formData.logo === true ? 1 : 0,
        MostrarEnWeb: formData.mostrarWeb === true ? 1 : 0,
        suspendido: formData.suspendido === true ? 1 : 0,
      });
    } else {
      dataToSend = JSON.stringify({
        nombre: formData.nombre,
        suspendido: formData.suspendido === true ? 1 : 0,
      });
    }
    try {
      await axios({
        url: `${baseEndpoint}/${formData.id}`,
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: dataToSend,
        redirect: "follow",
      });
    } catch (error) {
    } finally {
      notify();
      setEditItem(false);
      getData(chosenEdit);
    }
  };

  function handleClickSuspender(e, row) {
    e.preventDefault();

    setFormData((prevalue) => {
      return {
        ...prevalue,
        nombre: row.nombre,
        id: row.id,
      };
    });
    setDeleteItem(true);
  }

  const handleModalSuspender = async () => {
    try {
      await axios({
        url: `${baseEndpoint}/${formData.id}`,
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        redirect: "follow",
      });
    } catch (error) {
      console.log("Ha ocurrido un error", error);
    } finally {
      setDeleteItem(false);
      getData(chosenEdit);
      setFormData({});
    }
  };

  const handleChange = async (e) => {
    let value = e.target.value;
    let name = e.target.name;

    if (e.target.type === "checkbox") {
      setFormData((prevalue) => {
        return {
          ...prevalue,
          [name]: e.target.checked,
        };
      });
    } else {
      setFormData((prevalue) => {
        return {
          ...prevalue,
          [name]: value,
        };
      });
    }
  };

  useEffect(() => {
    let url = "";
    chosenEdit === "tipo" && (url = `${initialENDPOINT}/tipoproducto`);
    chosenEdit === "marca" && (url = `${initialENDPOINT}/marcaproducto`);
    chosenEdit === "color" && (url = `${initialENDPOINT}/color`);
    chosenEdit === "categoria" &&
      (url = `${initialENDPOINT}/categoriaproducto`);
    chosenEdit === "material" && (url = `${initialENDPOINT}/materialproducto`);
    chosenEdit === "tamaño" && (url = `${initialENDPOINT}/tamanoproducto`);
    setBaseEndpoint(`${url}`);
  }, [chosenEdit]);

  return (
    <>
      <Button
        className="mb-3 px-3"
        variant={newFilter ? "primary" : "outline-primary"}
        onClick={(e) => handleClickAdd(e)}
        value={chosenEdit}
      >
        Agregar {chosenEdit} de producto
      </Button>
      <div
        className="my-2 table-queries "
        class="overflow-hidden overflow-x-auto"
      >
        <DataTable
          columns={queriesColumns}
          data={data}
          striped
          customStyles={{
            rows: {
              highlightOnHoverStyle: {
                backgroundColor: "rgb(230, 244, 244)",
                borderBottomColor: "#FFFFFF",
                outline: "1px solid #FFFFFF",
              },
            },
            pagination: {
              style: {
                border: "none",
              },
            },
          }}
          pointerOnHover
          highlightOnHover
          progressPending={loading}
          progressComponent={<Loader />}
          noDataComponent={<Vacio />}
          persistTableHead
          pagination
          paginationServer
          paginationComponentOptions={{
            rowsPerPageText: "Filas por página",
            rangeSeparatorText: "de",
          }}
          paginationDefaultPage
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handlePerRowsChange}
          paginationTotalRows={totalRows}
        />
      </div>
      {/* -----------------------------------------------Modal AGREGAR*/}
      <MyModal
        key={"add"}
        show={newItem}
        onHide={() => setNewItem(false)}
        title={modalTitle}
        // className="d-flex align-items-center justify-content-center"
      >
        <div className="input-group mb-3 ">
          <span className="input-group-text mb-0 form-text mt-0">
            Nombre del nuevo ítem
          </span>{" "}
          <br />
          <Form.Control
            type="text"
            placeholder="Ingresa nombre"
            name="nombre"
            value={formData.nombre}
            onChange={handleChange}
            required
            className={emptyName ? "is-invalid" : ""}
          />
        </div>
        {chosenEdit?.toLowerCase() === "tipo" && (
          <div className="input-group mb-3 ">
            <span className="input-group-text mb-0 form-text mt-0">
              Venta mínima
            </span>
            <Form.Control
              type="number"
              placeholder="Cantidad mínima"
              min="0"
              name="CantidadMinima"
              value={formData.CantidadMinima}
              onChange={handleChange}
              className={cantidadMinimaEmpty ? `w-70 is-invalid` : `w-70`}
              required
            />
          </div>
        )}

        {chosenEdit?.toLowerCase() === "marca" && (
          <div class="form-check">
            <br />
            <Form.Check
              className=" mb-0"
              type="checkbox"
              id="vip"
              name="vip"
              label="vip"
              checked={formData.vip}
              onChange={handleChange}
            />
            <br />
            <Form.Check
              type="checkbox"
              id="mostrarWeb"
              name="mostrarWeb"
              label="Mostrar en Web"
              checked={formData.mostrarWeb}
              onChange={handleChange}
            />

            <br />
            <Form.Check
              className=" mb-4"
              type="checkbox"
              id="propia"
              name="propia"
              label="Propia"
              checked={formData.propia}
              onChange={handleChange}
            />
          </div>
        )}
        <div className="mx-5">
          <Form.Check
            className=" mb-0 d-flex align-items-center gap-2 mb-3"
            type="switch"
            id="suspendido"
            name="suspendido"
            label=" Suspendido"
            checked={formData.suspendido}
            onChange={handleChange}
          />
        </div>
        <div className="d-flex justify-content-end">
          <Button
            onClick={(e) => {
              handleModalAdd(e);
            }}
            className="d-flex-end"
          >
            Agregar
          </Button>
        </div>
      </MyModal>

      {/* -----------------------------------------------------Modal EDITAR*/}
      <MyModal
        key={"edit"}
        show={editItem}
        onHide={() => setEditItem(false)}
        title={"Editar"}
        className="d-flex align-items-center justify-content-center"
      >
        <span
          className="input-group-text mx-5 justify-content-center"
          id="basic-addon1"
        >
          Modifique el valor deseado:
        </span>
        <div className="mx-5">
          <Form.Check
            className=" mt-3 d-flex align-items-center gap-2 "
            type="switch"
            id="suspendido"
            name="suspendido"
            label="Suspendido"
            checked={formData.suspendido == "1" ? true : false}
            onChange={handleChange}
          />
          {console.log(formData)}
        </div>
        <br />
        {chosenEdit?.toLowerCase() === "tipo" && (
          <div className="input-group mb-3 ">
            <span className="input-group-text mb-0 form-text mt-0">
              Venta mínima
            </span>
            <Form.Control
              type="number"
              placeholder="Cantidad mínima"
              min="0"
              name="CantidadMinima"
              value={formData.CantidadMinima}
              onChange={handleChange}
              className="w-70"
              required
            />
          </div>
        )}
{console.log('datos '+formData.propia+' '+formData.vip+' '+formData.mostrarWeb)}
{chosenEdit?.toLowerCase() === "marca" && (
  <div className="form-check">
    <br />
    <Form.Check
      className="mb-0"
      type="checkbox"
      id="vip"
      name="vip"
      label="VIP"
      checked={formData.vip}
      onChange={handleChange}
    />
    <br />
    <Form.Check
      type="checkbox"
      id="mostrarWeb"
      name="mostrarWeb"
      label="Mostrar en Web"
      checked={formData.mostrarWeb}
      onChange={handleChange}
    />
    <br />
    <Form.Check
      className="mb-4"
      type="checkbox"
      id="propia"
      name="propia"
      label="Propia"
      checked={formData.propia}
      onChange={handleChange}
    />
  </div>
)}


        {/* {chosenEdit?.toLowerCase() === "marca" && (
          <div class="form-check">
            <br />
            <Form.Check
              className=" mb-0"
              type="checkbox"
              id="VIP"
              name="VIP"
              label="VIP"
              checked={formData.vip}
              onChange={handleChange}
            />

            <br />
            <Form.Check
              type="checkbox"
              id="casilla_verificacion_mostrar_en_web"
              name="MostrarEnWeb"
              label="Mostrar en Web"
              checked={formData.mostrarWeb == "1" ? true : false}
              onChange={handleChange}
            />

            <br />
            <Form.Check
              className=" mb-4"
              type="checkbox"
              id="propia"
              name="propia"
              label="Propia"
              checked={formData.propia}
              onChange={handleChange}
            />
          </div>
        )} */}
        <div className="input-group mb-3 px-5">
          <Form.Control
            type="text"
            name="nombre"
            value={formData.nombre ? formData.nombre : ""}
            onChange={handleChange}
            required
          />

          <br />
          <Button
            onClick={(e) => {
              handleModalEdit(e);
            }}
          >
            Aplicar cambios
          </Button>
        </div>
      </MyModal>

      {/*------------------------------------------------- Modal BORRAR*/}
      <MyModal
        key={"borrar"}
        show={deleteItem}
        onHide={() => setDeleteItem(false)}
        title={"Eliminar"}
        className="d-flex align-items-center justify-content-center"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 10,
          }}
        >
          <span
            className="input-group-text mx-auto text-wrap text-break "
            id="basic-addon1"
          >
            ¿Confirma que desea eliminar esté ítem?
          </span>
          <Button
            className="btn btn-danger rounded mx-auto "
            onClick={(e) => {
              handleModalSuspender(e);
            }}
          >
            Borrar
          </Button>
        </div>
      </MyModal>
    </>
  );
};

export default TabComponent;


