import { Row, Form, Button, Col } from "react-bootstrap";
import { toast } from "react-toastify";

const NewFilterForm = ({
  showFilter,
  setPage,
  setFilterENDPOINT,
  resetAll,
  formData,
  setFormData,
}) => {
  const notify = () => toast("¡Datos filtrados!");

  const itemFilter = (e) => {
    e.preventDefault();
    setPage(1);

    setFilterENDPOINT(
      `&nombreCliente=${formData.nombre}${
        formData.id === 0 ? "" : `&id=${formData.id}`
      }${formData.desde === undefined ? "" : `&desde=${formData.desde}`}${
        formData.hasta === undefined ? "" : `&hasta=${formData.hasta}`
      }`
    );
    showFilter();
    notify();
  };

  const resetAllFilters = () => {
    resetAll();
    showFilter();
  };

  const handleChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    setFormData((prevalue) => {
      return {
        ...prevalue,
        [name]: value,
      };
    });
  };

  return (
    <Form
      onSubmit={itemFilter}
      className="bg-white p-4 rounded mb-3 border justify-content-evenly "
    >
      <Row>
        <Form.Group className="col-12 col-md-3 ">
          <Form.Label>Cliente</Form.Label>
          <Form.Control
            type="text"
            placeholder="Nombre Cliente"
            name="nombre"
            value={formData.nombre}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group className="col-12 col-md-3 ">
          <Form.Label>Invoice</Form.Label>
          <Form.Control
            type="number"
            placeholder="Numero de Invoice"
            name="id"
            value={formData.id}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group className="col-12 col-md-4 mb-3">
          {/* <Form.Label>Fecha</Form.Label> */}
          <div style={{ display: "flex" }}>
            <Col>
              <Form.Label>Desde Fecha</Form.Label>
              <Form.Control
                type="date"
                placeholder="Desde.."
                name="desde"
                onChange={handleChange}
                value={formData.desde}
              />
            </Col>
            <Col>
              <Form.Label>Hasta Fecha</Form.Label>
              <Form.Control
                type="date"
                placeholder="Hasta.."
                name="hasta"
                onChange={handleChange}
              />
            </Col>
          </div>
        </Form.Group>
      </Row>

      <Form.Group className="d-flex align-items-center justify-content-center">
        <Button
          variant="info"
          className="mt-2 mt-md-4 me-auto col-5 col-md-2 col-xxl-1 d-flex align-items-center justify-content-center"
          onClick={resetAllFilters}
        >
          Limpiar
        </Button>
        <Button
          type="submit"
          className="mt-2 mt-md-4 col-5 col-md-2 col-xxl-1 d-flex align-items-center justify-content-center"
        >
          Filtrar
        </Button>
      </Form.Group>
    </Form>
  );
};

export default NewFilterForm;
