import { useEffect, useState } from "react";
import { Row, Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";
import useGoToLogin from "../../../_helper/hooks/useGoToLogin";

const initialENDPOINT = process.env.REACT_APP_ENDPOINT;

const NewFilterForm = ({
  showFilter,
  setPage,
  setFilterENDPOINT,
  resetAll,
  formData,
  setFormData,
}) => {
  const [proveedores, setProveedores] = useState([]);
  const { goToLogin } = useGoToLogin();
  const token = localStorage.getItem("token");
  const notify = () => toast("¡Datos filtrados!");

  const itemFilter = (e) => {
    e.preventDefault();
    setPage(1);
    setFilterENDPOINT(
      `&nombreProveedor=${formData.proveedor}&desde=${formData.dateFrom}&hasta=${formData.dateTo}
      ${formData.code !== 0 ? `&codigo=${formData.code}` : ""}`
    );
    showFilter();
    notify();
  };

  const resetAllFilters = () => {
    setFormData({
      proveedor: "",
      code: "",
      dateFrom: "",
      dateTo: ""
    });
    resetAll();
  };

  const handleChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    setFormData((prevalue) => {
      return {
        ...prevalue,
        [name]: value,
      };
    });
  };

  const getData = async (url, setState) => {
    try {
      const res = await axios(`${initialENDPOINT}/${url}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // console.log("res", res);
      res.status === 200 && setState(res.data.data.original.results);
      res.status === 250 && goToLogin();
      res.status === 251 && setState([{ id: 0, nombre: "Sin resultados" }]);
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    if (formData.dateFrom !== "") {
      var d = new Date(),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      const today = [year, month, day].join("-");

      setFormData({ ...formData, dateTo: today });
    }
  }, [formData.dateFrom]);

  useEffect(() => {
    getData("proveedor?cantidad=999", setProveedores);
  }, [showFilter]);

  return (
    <Form onSubmit={itemFilter} className="bg-white p-4 rounded mb-3 border">
      <Row>
        <Form.Group className="col-12 col-md-3 mb-2">
          <Form.Label>Proveedores</Form.Label>
          <Form.Select
            value={formData.proveedor}
            name="proveedor"
            onChange={handleChange}
          >
            <option value={0}>Seleccione</option>
            {proveedores?.map((item, i) => (
              <option key={i} value={item.id}>
                {item.nombre}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group className="col-12 col-md-4 mb-3">
          <Form.Label>Fecha</Form.Label>
          <div style={{ display: "flex", gap: "10px" }}>
            <Form.Control
              type="date"
              placeholder="Desde.."
              name="dateFrom"
              value={formData.dateFrom}
              onChange={handleChange}
            />
            <Form.Control
              type="date"
              placeholder="Hasta.."
              name="dateTo"
              value={formData.dateTo}
              onChange={handleChange}
            />
          </div>
        </Form.Group>
        <Form.Group className="col-12 col-md-2 mb-3">
          <Form.Label>Código de producto</Form.Label>
          <Form.Control
            type="text"
            placeholder="Buscar.."
            name="code"
            value={formData.code}
            onChange={handleChange}
          />
        </Form.Group>
      </Row>
      <Form.Group className="d-flex justify-content-end">
        <Button
          variant="info"
          className="mt-2 mt-md-4 me-lg-auto col-6 col-lg-1 d-flex align-items-center justify-content-center"
          onClick={resetAllFilters}
        >
          Limpiar
        </Button>
        <Button
          type="submit"
          className="mt-2 mt-md-4 col-6 col-lg-1 d-flex align-items-center justify-content-center"
        >
          Filtrar
        </Button>
      </Form.Group>
    </Form>
  );
};

export default NewFilterForm;
