import { Container } from 'react-bootstrap'

const Vacio = () => {
  return (
    <Container className='my-5 d-flex justify-content-center'>
        <p>No hemos encontrado nada</p>
    </Container>
  )
}

export default Vacio