import { Card } from "reactstrap";
import { LogOut } from "react-feather";
import { Link, useNavigate } from "react-router-dom";
import useWindowSize from "../../../_helper/hooks/useWindowSize";

const LogoutClass = () => {
  const history = useNavigate();
  const { width } = useWindowSize();
  const Logout = () => {
   localStorage.removeItem("token");
   localStorage.removeItem("name");
   localStorage.removeItem("idUser");
    history(`${process.env.PUBLIC_URL}/login`);
  };

  return (
    <li className="onhover-dropdown p-0" onClick={Logout}>
      <button
        as={Card.Header}
        className="btn btn-primary-light"
        color="default"
      >
        <Link
          to={`${process.env.PUBLIC_URL}/login`}
          className="d-flex align-items-center gap-2 py-1"
        >
          <LogOut size={16} />
          {width > 800 && "Cerrar sesión"}
        </Link>
      </button>
    </li>
  );
};

export default LogoutClass;
