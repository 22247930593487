import { useEffect, useState } from "react";
import { Form, Row, Button, Container } from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Breadcrumbs } from "../../../../AbstractElements";
// import Proveedor from "./Proveedor";
// import Gastos from "./Gastos";
import Productos from "./Productos";

const TemplatePurchase = () => {
  
  let { purchaseID } = useParams();
  const history = useNavigate();
  const [formData, setFormData] = useState({
    proveedor: "",
    fechaDeIngreso: "",
    fechaDePago: "",
    numeroLote: "",
    detalle: "",
    precio: 0,
    precioGasto: 0,
    observaciones: "",
    pagado: false,
    gastos: [],
    productos: [],
    idProducto: "",
    nombreProducto: "",
    precioProducto: 0,
    cantidadProducto: "",
    cantidadDeArticulos: 0,
    cantidadDeUnidades: 0,
  });
  const [disableButton, setDisableButton] = useState(false);
  const [isMounted, setIsMounted] = useState(true);
  const [emptyProveedor, setEmptyProveedor] = useState();
  const [emptyProductos, setEmptyProductos] = useState();
  const token = localStorage.getItem("token");
  const initialENDPOINT = process.env.REACT_APP_ENDPOINT;

  const notify = () =>
    toast(purchaseID != 0 ? "Compra editada!" : "Compra creada!");

  const handleChange = (e) => {
    setEmptyProveedor(false);
    setEmptyProductos(false);
    let value;
    e.target.type === "checkbox"
      ? (value = e.target.checked)
      : (value = e.target.value);
    let name = e.target.name;

    setFormData((prevalue) => {
      return {
        ...prevalue,
        [name]: value,
      };
    });
  };

  async function getData() {
    try {
      const response = await axios({
        url: `${initialENDPOINT}/compradetalle?compra=${purchaseID}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },

        redirect: "follow",
      });

      isMounted &&
        setFormData({
          ...formData,
          productos: response.data.data.original.results,
        });
    } catch (error) {
      console.log(error);
    }
  }

  const sendForm = async (e) => {
    e.preventDefault();
    let prodInDep = formData.productos.map((prod) => {
      return {
        producto: prod.id,
        productoId: prod.producto,
        cantidad: prod.cantidad,
        enDeposito: prod.enDeposito,
        precioUnitario : parseFloat(prod.precioUnitario).toFixed(2),
        precioVenta : parseFloat(prod.precioVenta).toFixed(2),
        isChecked: prod.enDeposito
      };
    });
    const dataToSend = {
      compra: purchaseID,
      productos: prodInDep,
    };    
    try {
      let resp = await axios({
        url: `${initialENDPOINT}/deposito/ingreso`,
        method: `POST`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: dataToSend,
        redirect: "follow",
      });
      console.log(resp);
      notify();
      history(`${process.env.PUBLIC_URL}/ingreso-a-deposito`);
    } catch (error) {
      console.log(error);
    }
    // }
  };

  useEffect(() => {
    isMounted && purchaseID != 0 && getData(purchaseID);
    return () => {
      setIsMounted(false);
    };
  }, [purchaseID]);

  return (
    <Container fluid={true}>
      <Breadcrumbs
        mainTitle={
          purchaseID == 0 ? "Nueva compra" : `Ingreso a deposito: ${purchaseID}`
        }
        parent="Compras"
        title={purchaseID == 0 ? "Nueva compra" : `Editar compra`}
      />
      <Form onSubmit={sendForm}>
        <Row className="g-3">
          {/* <Proveedor
            emptyProveedor={emptyProveedor}
            handleChange={handleChange}
            formData={formData}
            setFormData={setFormData}
          />
          <Gastos
            handleChange={handleChange}
            formData={formData}
            setFormData={setFormData}
          /> */}
          <Productos
            emptyProductos={emptyProductos}
            handleChange={handleChange}
            formData={formData}
            setFormData={setFormData}
          />
        </Row>
        <Row className="d-flex justify-content-end px-3">
          <Button
            disabled={disableButton}
            type="submit"
            className="mb-5 mt-2 col-2 col-lg-1 col-sm-2"
          >
            Guardar
          </Button>
        </Row>
      </Form>
    </Container>
  );
};

export default TemplatePurchase;
