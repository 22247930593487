import DataTable from "react-data-table-component";
import Loader from "../../Common/Loader";
import Vacio from "../../Common/Vacio";
import TableTitle from "../../Common/TableTitle";
import { Link } from "react-router-dom";



const OrdersTable = ({
  data,
  errMsg,
  loading,
  totalRows,
  perPage,
  setPerPage,
  setPage,
  setENDPOINT,
  filterENDPOINT,
  handlePedidoDelete,
}) => {
  const initialENDPOINT = process.env.REACT_APP_ENDPOINT;
  const queriesColumns = [
    {
      name: "ID",
      selector: (row) => row.id,
      center: false,
      wrap: true,
      grow: 0
    },
    {
      name: "Fecha",
      selector: (row) => row.fecha,
      center: false,
      wrap: true,
    },
    {
      name: "Cliente",
      selector: (row) => row.nombreCliente,
      center: false,
      wrap: true,
    },
    {
      name: "Código cliente",
      selector: (row) => row.cliente,
      center: false,
      wrap: true,
    },
    {
      name: "Vendedor",
      selector: (row) => row.nombreEmpleado,
      center: false,
      wrap: true,
    },
    {
      name: "Estado",
      selector: (row) => row.nombreEstado,
      center: false,
      wrap: true,
    },
    {
      name: "Monto",
      selector: (row) => row.total,
      wrap: true,
    },
    {
      name: "Acciones",
      selector: (row) => [
        <Link key={1} to={`/editar-pedido/${row.id}`}>
          <button
            key={2}
            className="btn text-dark w-auto p-1 mx-1 my-0"
            type="button"
            title="Editar"
          >
            <i className="fa fa-pencil-square-o f-16 px-1" />
          </button>
        </Link>,
        <a href={`${initialENDPOINT}/pdf/proforma/${row.id}`} rel="noopener noreferrer">
          <button
            key={4}
            className="btn text-dark w-auto p-1 mx-1 my-0"
            type="button"
            title="PDF"
          >
            <i className="fa fa-print f-16 px-1" />
          </button>
        </a>,
        <button
          key={3}
          className="btn text-dark w-auto p-1 mx-1 my-0"
          type="button"
          title="Borrar"
          onClick={() => handlePedidoDelete({ id: row.id })}
        >
          <i className="fa fa-trash f-16 px-1" />
        </button>,

      ],
      center: true,
      // hide: "md",
      compact: true,
    },
  ];
  const handlePageChange = (pag) =>
    setENDPOINT(
      `${initialENDPOINT}/pedido?pagina=${pag}&cantidad=${perPage}${filterENDPOINT}`
    );
  const handlePerRowsChange = async (newPerPage, pag) => {
    setPerPage(newPerPage);
    setPage(pag);
    await setENDPOINT(
      `${initialENDPOINT}/pedido?pagina=${pag}&cantidad=${newPerPage}${filterENDPOINT}`
    );
  };

  return (
    <div className="my-3 table-queries" class="overflow-hidden overflow-x-auto">
      <DataTable
        title={<TableTitle value="Lista de pedidos" />}
        columns={queriesColumns}
        data={data}
        striped
        customStyles={{
          rows: {
            highlightOnHoverStyle: {
              backgroundColor: "rgb(230, 244, 244)",
              borderBottomColor: "#FFFFFF",
              outline: "1px solid #FFFFFF",
            },
          },
          pagination: {
            style: {
              border: "none",
            },
          },
        }}
        highlightOnHover
        progressPending={loading}
        progressComponent={<Loader />}
        noDataComponent={<Vacio />}
        pagination
        paginationServer
        paginationComponentOptions={{
          rowsPerPageText: "Filas por página",
          rangeSeparatorText: "de",
        }}
        paginationDefaultPage
        paginationTotalRows={totalRows}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerRowsChange}
        persistTableHead
        handlePedidoDelete={handlePedidoDelete}
      />
    </div>
  );
};

export default OrdersTable;
