import ConfigDB from "../../../../Config/ThemeConfig";
const primary =
  localStorage.getItem("default_color") || ConfigDB.data.color.primary_color;
const secondary =
  localStorage.getItem("secondary_color") || ConfigDB.data.color.secondary_color;

const objetivosPorOrigenArray = [
  "Web",
  "Msn/Instagram Direct",
  "Manual",
  "Llamados y Oficina",
  "Leads",
  "Google Ads",
  "Facebook",
  "Calle"
];

export const objetivosPorOrigen = {
  series: [
    {
      name: "Objetivos",
      data: [76, 85, 101, 98, 87, 105, 91, 114],
    },
    {
      name: "Ventas",
      data: [44, 55, 57, 56, 61, 58, 63, 60],
    },
  ],
  options: {
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: [primary, secondary],
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: objetivosPorOrigenArray,
    },
    fill: {
      opacity: 1,
    },
  },
};
