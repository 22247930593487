import { Suspense } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Loader from "../Layout/Loader";
import LayoutRoutes from "../Route/LayoutRoutes";
import Signin from "../Login/Signin";
import PrivateRoute from "./PrivateRoute";
import { authRoutes } from "./AuthRoutes";

const Routers = () => {
  const token = localStorage.getItem("token");

  return (
    <BrowserRouter basename={"/"}>
      <>
          <Suspense fallback={<Loader />}>
            <Routes>
              <Route path={"/"} element={<PrivateRoute />}>
                {token ? (
                  <>
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}`}
                      element={
                        <Navigate to={`${process.env.PUBLIC_URL}/escritorio`} />
                      }
                    />
                    <Route
                      exact
                      path={`/`}
                      element={
                        <Navigate to={`${process.env.PUBLIC_URL}/escritorio`} />
                      }
                    />
                  </>
                ) : (
                  ""
                )}
                <Route path={`/*`} element={<LayoutRoutes />} />
              </Route>
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/login`}
                element={<Signin />}
              />
              {authRoutes.map(({ path, Component }, i) => (
                <Route path={path} element={Component} key={i} />
              ))}
            </Routes>
          </Suspense>
      </>
    </BrowserRouter>
  );
};

export default Routers;
