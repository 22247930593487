import { useEffect, useState } from "react";
import { Button, Form, InputGroup, Row } from "react-bootstrap";
import DetalleTable from "./DetalleTable";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

const Detalle = ({ handleChange, formData, setFormData }) => {
  // const [inputMsg, setInputMsg] = useState("");
  const initialENDPOINT = process.env.REACT_APP_ENDPOINT;
  const history = useNavigate();
  const token = localStorage.getItem("token");
  const [reciboPDF, setReciboPDF] = useState();
  const { reciboID } = useParams();

  const getPDF = async () => {
    try {
      let response = await axios({
        // url: `${initialENDPOINT}/pdf/recibo/`,
        url: `${initialENDPOINT}/pdf/recibo/${reciboID}`,

        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        // data: dataToSend,
        redirect: "follow",
      });
      console.log("response.data", response.data);
      setReciboPDF(response.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getPDF();
  }, []);

  return (
    <Row className="bg-white mb-3 p-4 pb-2 border rounded m-5">
      <h6 className="pb-3">Detalle</h6>

      <Form.Group className="mb-3 col-12">
        <object
          data={`${initialENDPOINT}/pdf/recibo/`}
          // data={`${initialENDPOINT}/pdf/recibo/${reciboID}`}
          type="application/pdf"
          width="100%"
          height="600"
        >
          <p>
            No es posible mostrar el PDF. Puede que tu navegador no sea
            compatible o que el archivo no se haya cargado correctamente desde
            el endpoint.
          </p>
        </object>
        {/* <embed
          src="files/Brochure.pdf"
          type="application/pdf"
          width="100%"
          height="600px"
        /> */}
      </Form.Group>
    </Row>
  );
};

export default Detalle;
