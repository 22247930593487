import DataTable from "react-data-table-component";
import Loader from "../../Common/Loader";
import Vacio from "../../Common/Vacio";
import TableTitle from "../../Common/TableTitle";
import { Link } from "react-router-dom";
import { Fragment } from "react";

const isDateGreaterThan5Days = (dateString) => {
  const currentDate = new Date();
  const rowDate = new Date(dateString);

  // Calcula la diferencia en milisegundos
  const timeDifference = rowDate.getTime() - currentDate.getTime();
  // Convierte la diferencia a días
  const daysDifference = timeDifference / (1000 * 3600 * 24);

  return daysDifference > 5;
};

const MarketRateTable = ({
  data,
  errMsg,
  loading,
  totalRows,
  perPage,
  setPerPage,
  setPage,
  setENDPOINT,
  filterENDPOINT,
}) => {
  const initialENDPOINT = process.env.REACT_APP_ENDPOINT;

  const customRowStyles = (row) => {
    return isDateGreaterThan5Days(row.fecha)
      ? {
        style: {
          backgroundColor: "red",
          color: "white",
        },
      }
      : {};
  };

  const queriesColumns = [
    {
      name: "Código",
      selector: (row) => row.id,
      center: false,
      wrap: false,
    },
    {
      name: "Fecha",
      selector: (row) => row.fecha,
      center: false,
      wrap: false,
    },
    {
      name: "Nombre Cliente",
      selector: (row) => (row.clienteNombre ? row.clienteNombre : "No cargado"),
      center: false,
      wrap: false,
    },
    {
      name: "Código Cliente",
      selector: (row) => (row.cliente ? row.cliente : "Sin código"),
      center: false,
      wrap: false,
    },
    {
      name: "Total",
      selector: (row) => row.total,
      wrap: false,
    },
    {
      name: "Acciones",
      selector: (row) => [
        <Link to={`/editar-cotizacion/${row.id}`} key={1}>
          <button
            className="btn text-dark w-auto p-1 mx-1 my-0"
            type="button"
            title="Editar"
          >
            <i className="fa fa-pencil-square-o f-16 px-1" />
          </button>
        </Link>,
        <a href={`${initialENDPOINT}/pdf/cotizacion/${row.id}`} target="_blank" >
          <button
            className="btn text-dark w-auto p-1 mx-1 my-0"
            type="button"
            title="Ver PDF"
          >
            <i className="fa fa-file-text f-16 px-1" />
          </button>
        </a>,
      ],
      center: true,
      compact: true,
    },
  ];

  const handlePageChange = (pag) =>
    setENDPOINT(
      `${initialENDPOINT}/cotizacion?pagina=${pag}&cantidad=${perPage}${filterENDPOINT}`
    );
  const handlePerRowsChange = async (newPerPage, pag) => {
    setPerPage(newPerPage);
    setPage(pag);
    await setENDPOINT(
      `${initialENDPOINT}/cotizacion?pagina=${pag}&cantidad=${newPerPage}${filterENDPOINT}`
    );
  };

  return (
    <div className="my-3 table-queries" class="overflow-hidden overflow-x-auto">
      {errMsg && <p>{errMsg}</p>}
      <DataTable
        title={<TableTitle value="Lista de cotizaciones" />}
        columns={queriesColumns}
        data={data}
        striped
        customStyles={{
          rows: {
            highlightOnHoverStyle: {
              backgroundColor: "rgb(230, 244, 244)",
              borderBottomColor: "#FFFFFF",
              outline: "1px solid #FFFFFF",
            },
          },
          pagination: {
            style: {
              border: "none",
            },
          },
        }}
        highlightOnHover
        progressPending={loading}
        progressComponent={<Loader />}
        noDataComponent={<Vacio />}
        pagination
        paginationServer
        paginationComponentOptions={{
          rowsPerPageText: "Filas por página",
          rangeSeparatorText: "de",
        }}
        paginationDefaultPage
        paginationTotalRows={totalRows}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerRowsChange}
        persistTableHead
        customRowStyles={customRowStyles} // Aplicar el estilo dinámico a las filas
      />
    </div>
  );
};

export default MarketRateTable;
