import { useContext, useEffect, useState } from "react";
import { Button, Form, InputGroup, Row } from "react-bootstrap";
import MyModal from "../../../../Common/MyModal";
import Loader from "../../../../Common/Loader";
import Vacio from "../../../../Common/Vacio";
import DataContext from "../../../../../_helper/DataContext";
import useGoToLogin from "../../../../../_helper/hooks/useGoToLogin";
import DataTable from "react-data-table-component";
import axios from "axios";

const OrderData = ({ handleChange, formData, setFormData }) => {
  const initialENDPOINT = process.env.REACT_APP_ENDPOINT;
  const [isMounted, setIsMounted] = useState(true);

  const [newQuery, setNewQuery] = useState(false);
  const token = localStorage.getItem("token");
  const { goToLogin } = useGoToLogin();
  const {
    data,
    errMsg,
    loading,
    totalRows,
    perPage,
    setPerPage,
    setPage,
    setENDPOINT,
  } = useContext(DataContext);

  const queriesColumns = [
    {
      name: "ID",
      selector: (row) => row.id,
      center: false,
      wrap: true,
      grow: 0,
    },
    {
      name: "Nombre",
      selector: (row) => row.nombre,
      center: false,
      wrap: false,
    },
    {
      name: "Seleccionar",
      selector: (row) => [
        <button
          key={2}
          className="btn text-dark w-auto p-1 mx-1 my-0"
          type="button"
          title="Seleccionar"
          onClick={(e) => handleClient(row.id, row.nombre, row.ctacte)}
        >
          <i className="fa fa-check f-16 px-1" />
        </button>,
      ],
      center: true,
      compact: true,
    },
  ];

  const getCliente = async (e) => {
    if (e.keyCode !== 13) {
      return;
    }
    try {
      const response = await axios(
        `${initialENDPOINT}/cliente/${formData.cliente}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          redirect: "follow",
        }
      );
      if (response.data.status === 200) {
        isMounted &&
          setFormData((prevalue) => {
            return {
              ...prevalue,
              cliente: response.data.results.nombre,
              creditoCtaCte: response.data.results.ctacte,
            };
          });
      } else {
        isMounted &&
          setFormData((prevalue) => {
            return {
              ...prevalue,
              cliente: "Cliente no encontrado",
            };
          });
      }
    } catch (error) {
      console.log(error);
      isMounted &&
        setFormData((prevalue) => {
          return {
            ...prevalue,
            cliente: "Cliente no encontrado",
          };
        });
    }
  };

  const handleClient = (id, nombre, ctacte) => {
    setFormData((prevalue) => {
      return {
        ...prevalue,
        cliente: nombre,
        idCliente: id,
        creditoCtaCte: ctacte,
      };
    });
    setNewQuery(false);
  };

  const handlePageChange = (pag) => {
    setENDPOINT(
      `${initialENDPOINT}/cliente?pagina=${pag}&cantidad=${perPage}${
        formData.cliente ? "&nombre=" + formData.cliente : ""
      }&asdasd`
    );
  };

  const handlePerRowsChange = async (newPerPage, pag) => {
    setPerPage(newPerPage);
    setPage(pag);
    await setENDPOINT(
      `${initialENDPOINT}/cliente?pagina=${pag}&cantidad=${newPerPage}${
        formData.cliente ? "&nombre=" + formData.cliente : ""
      }`
    );
  };

  useEffect(() => {
    !token && goToLogin();
    const random = Date.now();
    isMounted &&
      setENDPOINT(`${initialENDPOINT}/cliente?pagina=1&random=${random}`);
  }, [newQuery]);

  useEffect(() => {
    return () => {
      setIsMounted(false);
    };
  }, []);

  return (
    <div className="d-flex justify-content-center">
      <Row className="bg-white mb-3 p-4 pb-2 border rounded m-5 justify-content-center col-9">
        <Form.Group className="mb-3 col-3">
          <Form.Label>Cliente:</Form.Label>
          <InputGroup className="col-9" style={{ width: "115%" }}>
            <Form.Control
              type="text"
              name="cliente"
              defaultValue={formData?.cliente || ""}
              onChange={(e) => handleChange(e)}
              onKeyDown={getCliente}
            ></Form.Control>
            <InputGroup.Text
              style={{ cursor: "pointer" }}
              onClick={() => setNewQuery(true)}
            >
              <i className="fa fa-search" aria-hidden="true"></i>
            </InputGroup.Text>
          </InputGroup>
          <div>
            <Form.Text className="me-2">
              {formData.clienteNombre || ""}
            </Form.Text>
          </div>
        </Form.Group>
        <MyModal
          show={newQuery}
          onHide={() => setNewQuery(false)}
          title="Buscar cliente"
        >
          <Row>
            <Form.Group className="mb-3 col-6">
              <Form.Label>Cliente</Form.Label>
              <Form.Control
                type="text"
                name="cliente"
                placeholder="Ingrese el nombre"
                onChange={(e) => handleChange(e)}
              ></Form.Control>
            </Form.Group>
            <Button
              type="submit"
              className="mt-auto mb-3 col-3"
              onClick={() =>
                setENDPOINT(
                  `${initialENDPOINT}/cliente?pagina=1&nombre=${formData.cliente}`
                )
              }
            >
              Buscar
            </Button>
          </Row>
          {errMsg && <p>{errMsg}</p>}
          <DataTable
            columns={queriesColumns}
            data={data}
            striped
            customStyles={{
              rows: {
                highlightOnHoverStyle: {
                  backgroundColor: "rgb(230, 244, 244)",
                  borderBottomColor: "#FFFFFF",
                  outline: "1px solid #FFFFFF",
                },
              },
              pagination: {
                style: {
                  border: "none",
                },
              },
            }}
            pointerOnHover
            highlightOnHover
            progressPending={loading}
            progressComponent={<Loader />}
            noDataComponent={<Vacio />}
            pagination
            paginationServer
            paginationComponentOptions={{
              rowsPerPageText: "Filas por página",
              rangeSeparatorText: "de",
            }}
            paginationDefaultPage
            paginationTotalRows={totalRows}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
            persistTableHead
          />
        </MyModal>
      </Row>
    </div>
  );
};

export default OrderData;
