import { useEffect, useState } from "react";
import { Row, Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";
const token = localStorage.getItem("token");

const NewFilterForm = ({
  showFilter,
  dataGlobal,
  setPage,
  setFilterENDPOINT,
  resetAll,
  formData,
  setFormData,
}) => {
  const [dataPerfilesEndpoint, setDataPerfilesEndpoint] = useState([]);
  const initialENDPOINT = process.env.REACT_APP_ENDPOINT;

  const notify = () => toast("¡Datos filtrados!");

  const itemFilter = (e) => {
    e.preventDefault();
    setPage(1);
    setFilterENDPOINT(
      `&nombre=${formData.nombre}&contacto=${formData.contacto}`
    );
    showFilter();
    notify();
  };

  const resetAllFilters = () => {
    resetAll();
    showFilter();
  };

  const handleChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    setFormData((prevalue) => {
      return {
        ...prevalue,
        [name]: value.trim(),
      };
    });
  };

  const pedidoPerfiles = async () => {
    try {
      const res = await axios(`${initialENDPOINT}/perfil`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        redirect: "follow",
      });
      setDataPerfilesEndpoint(res.data.results);
      console.trace(res.data.results);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    pedidoPerfiles();
  }, []);

  return (
    <Form
      onSubmit={itemFilter}
      className="bg-white p-4 rounded mb-3 border ustify-content-evenly "
    >
      <Row>
        <Form.Group className="col-12 col-md-6 ">
          <Form.Label>Nombre</Form.Label>
          <Form.Control
            type="text"
            placeholder="Nombre"
            name="nombre"
            value={formData.nombre}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group className="col-12 col-md-6 ">
          <Form.Label>Contacto</Form.Label>
          <Form.Control
            type="text"
            placeholder="Nombre Contacto"
            name="contacto"
            value={formData.contacto === "" ? "" : formData.contacto}
            onChange={handleChange}
          />
        </Form.Group>
      </Row>

      <Form.Group className="d-flex align-items-center justify-content-center">
        <Button
          variant="info"
          className="mt-2 mt-md-4 me-auto col-5 col-md-2 col-xxl-1 d-flex align-items-center justify-content-center"
          onClick={resetAllFilters}
        >
          Limpiar
        </Button>
        <Button
          type="submit"
          className="mt-2 mt-md-4 col-5 col-md-2 col-xxl-1 d-flex align-items-center justify-content-center"
        >
          Filtrar
        </Button>
      </Form.Group>
    </Form>
  );
};

export default NewFilterForm;
