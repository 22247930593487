import { useMemo } from "react";
import DataTable from "react-data-table-component";
import { downloadCSV, Export } from "../../../_helper/hooks/useExportCsv";
import Loader from "../../Common/Loader";
import Vacio from "../../Common/Vacio";
import TableTitle from "../../Common/TableTitle";
import { Link } from "react-router-dom";

const paginacionOpciones = {
  rowsPerPageText: "Filas por página",
  rangeSeparatorText: "de",
};
const contextOpciones = {
  singular: "",
  plural: "",
  message: "",
};
const customStyles = {
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: "rgb(230, 244, 244)",
      borderBottomColor: "#FFFFFF",
      outline: "1px solid #FFFFFF",
    },
  },
  pagination: {
    style: {
      border: "none",
    },
  },
};

const ProvidersTable = ({
  data,
  errMsg,
  loading,
  totalRows,
  perPage,
  setPerPage,
  page,
  setPage,
  setENDPOINT,
  filterENDPOINT,
  showCancellations,
  handleShowCancellations,
}) => {
  // {
  //   "id": "5",
  //   "nombre": "305-05",
  //   "direccion": "2618 NW 112 Ave",
  //   "ciudad": "212975",
  //   "codigoPostal": "33172",
  //   "telefono": "3056102025",
  //   "movil": "33172",
  //   "email": "doralice.gonzale@gmail.com",
  //   "contacto": "DORALICE",
  //   "transportadora": "UPS",
  //   "telefonoTransportadora": "",
  //   "observaciones": "",
  //   "formaDePago": "Cheque",
  //   "suspendido": "0"
  // }

  const initialENDPOINT = process.env.REACT_APP_ENDPOINT;
  const queriesColumns = [
    {
      name: "Código",
      selector: (row) => row.id,
      sortable: true,
      center: false,
      wrap: true,
      reorder: true,
      grow: 0,
    },
    {
      name: "Nombre",
      selector: (row) => row.nombre,
      center: false,
      wrap: true,
    },
    {
      name: "Contacto",
      selector: (row) => row.contacto,
      center: false,
      wrap: true,
    },
    {
      name: "Dirección",
      selector: (row) => row.direccion,
      center: false,
      wrap: true,
    },
    {
      name: "Teléfono",
      selector: (row) => row.telefono,
      center: false,
      wrap: true,
    },
    {
      name: "Forma de Pago",
      selector: (row) => row.formaDePago,
      center: false,
      wrap: true,
    },
    {
      name: "Transportadora",
      selector: (row) => row.transportadora,
      center: false,
      wrap: true,
    },
    {
      name: "Estado",
      selector: (row) => (row.suspendido === "1" ? "Suspendido" : "Activo"),
      center: false,
    },
    {
      name: "Acciones",
      selector: (row) => [
        <Link key={1} to={`/editar-proveedor/${row.id}`}>
          <button
            key={2}
            className="btn text-dark w-auto p-1 mx-1 my-0"
            type="button"
            title="Editar"
          >
            <i className="fa fa-pencil-square-o f-16 px-1" />
          </button>
        </Link>,
      ],
      center: true,
      // hide: "md",
      compact: true,
    },
  ];

  const handlePageChange = (pag) =>
    setENDPOINT(
      `${initialENDPOINT}/proveedor?pagina=${pag}&cantidad=${perPage}${filterENDPOINT}&mostrarBajas=${showCancellations}`
    );
  const handlePerRowsChange = async (newPerPage, pag) => {
    setPerPage(newPerPage);
    setPage(pag);
    await setENDPOINT(
      `${initialENDPOINT}/proveedor?pagina=${pag}&cantidad=${newPerPage}${filterENDPOINT}&mostrarBajas=${showCancellations}`
    );
  };
  const actionsMemo = useMemo(
    () => (
      <Export
        onExport={() => downloadCSV(data, "Consultas")}
        cancallations={handleShowCancellations}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  return (
    <div
      className="App mb-5 table-queries border rounded"
      class="overflow-hidden overflow-x-auto"
    >
      <DataTable
        title={<TableTitle value="Lista de proveedores" />}
        columns={queriesColumns}
        data={data}
        striped
        customStyles={customStyles}
        highlightOnHover
        progressPending={loading}
        progressComponent={<Loader />}
        noDataComponent={<Vacio />}
        pagination
        paginationServer
        paginationComponentOptions={paginacionOpciones}
        paginationDefaultPage
        paginationTotalRows={totalRows}
        contextMessage={contextOpciones}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerRowsChange}
        actions={actionsMemo}
        persistTableHead
      />
    </div>
  );
};

export default ProvidersTable;
