import { useEffect } from "react";
import { useContext, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { Breadcrumbs } from "../../../AbstractElements";
import DataContext from "../../../_helper/DataContext";
import useGoToLogin from "../../../_helper/hooks/useGoToLogin";
import OrdersProductModal from "../../Common/OrdersProductModal";
import NewFilterForm from "./NewFilterForm";
import OrdersTable from "./OrdersTable";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const initialForm = {
  id: "",
  client: "",
  state: 0,
  dateFrom: "",
  dateTo: "",
};

const Orders = () => {
  const [showModal, setShowModal] = useState(false);
  const notify = (message) => toast(message);
  const [newQuery, setNewQuery] = useState(false);
  const [newFilter, setNewFilter] = useState(false);
  const [formData, setFormData] = useState(initialForm);
  const token = localStorage.getItem("token");
  const initialENDPOINT = process.env.REACT_APP_ENDPOINT;
  const { goToLogin } = useGoToLogin();
  const history = useNavigate();

  const {
    data,
    errMsg,
    loading,
    totalRows,
    perPage,
    setPerPage,
    page,
    setPage,
    setENDPOINT,
    setFilterENDPOINT,
    filterENDPOINT,
  } = useContext(DataContext);

  const resetAll = () => {
    const random = Date.now();
    setFormData(initialForm);
    setFilterENDPOINT(`&random=${random}`);
    setPage(1);
  };
  const showFilter = () => {
    setNewFilter(!newFilter);
    setNewQuery(false);
    setENDPOINT(`${initialENDPOINT}/pedido?pagina=1`);
  };
  useEffect(() => {
    const random = Date.now();
    !token && goToLogin();
    setENDPOINT(`${initialENDPOINT}/pedido?pagina=1&random=${random}`);
    setFilterENDPOINT("");

  }, []);

  const generarPedido = async () => {

    const myHeaders = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const response = await axios.post(`${initialENDPOINT}/pedido/nuevo`, [], {
        headers: myHeaders,
      });
      const id = response.data.id;

      history(`/editar-pedido/${id}`);
    } catch (error) {
      console.error("Error creating slider", error);

    }
  };

  const confirmDelete = async () => {

    const id = formData.idToDelete;

    axios.delete(`${initialENDPOINT}/pedido/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(response => {
        //Orders();
        notify(`Pedido eliminado!`);
        history(`/pedidos`)
      });

  };

  const handlePedidoDelete = async ({ id }) => {

    setFormData({ ...formData, idToDelete: id });
    setShowModal(true);
  };

  return (
    <Container fluid={true}>
      <Breadcrumbs mainTitle="Pedidos" parent="Pedidos" title="Listado" />
      <div className="d-flex justify-content-around justify-content-sm-end gap-2">

        <Button
          className="mb-3 px-3"
          variant={newQuery ? "primary" : "outline-primary"}
          onClick={generarPedido}
        >
          <i className="fa fa-plus f-12 pe-2 m-0" />
          Nuevo pedido
        </Button>
        <Button
          onClick={showFilter}
          className="mb-3 px-3"
          variant={newFilter ? "primary" : "outline-primary"}
        >
          <i className="fa fa-filter f-12 pe-2 m-0" />
          Filtros avanzados
        </Button>
        <Button
          onClick={resetAll}
          className="mb-3 px-3"
          variant={newFilter ? "primary" : "outline-primary"}
        >
          <i className="fa fa-refresh f-12 m-0" />
        </Button>
      </div>
      <Row>
        <Col xl="12" className="des-xl-100 box-col-12">
          {newFilter && (
            <NewFilterForm
              showFilter={showFilter}
              setPage={setPage}
              setFilterENDPOINT={setFilterENDPOINT}
              resetAll={resetAll}
              formData={formData}
              setFormData={setFormData}
            />
          )}
          <OrdersTable
            data={data}
            errMsg={errMsg}
            loading={loading}
            totalRows={totalRows}
            perPage={perPage}
            setPerPage={setPerPage}
            page={page}
            setPage={setPage}
            setENDPOINT={setENDPOINT}
            filterENDPOINT={filterENDPOINT}
            handlePedidoDelete={handlePedidoDelete}
          />
        </Col>
      </Row>
      <Row>
        <OrdersProductModal
          show={showModal}
          onHide={() => setShowModal(false)}
          title="¿Seguro que deseas eliminar este pedido?"
        >
          <Row>
            <div className="d-flex align-items-end justify-content-center">
              <Button
                type="submit"
                className="mt-auto mb-2 col-4 me-4"
                onClick={confirmDelete}
              >
                Eliminar
              </Button>
              <Button
                variant="danger"
                onClick={() => setShowModal(false)}
                className="mt-auto mb-2 col-4"
              >
                Cancelar
              </Button>
            </div>
          </Row>
        </OrdersProductModal>
      </Row>
    </Container>
  );
};

export default Orders;
