import { useContext, useEffect, useState } from "react";
import CustomizerContext from "../../../_helper/Customizer";
import LogoutClass from "./Logout";
import axios from "axios";
import useGoToLogin from "../../../_helper/hooks/useGoToLogin";
import { useNavigate } from "react-router-dom";

// import MessageDrop from "./MessageDrop";
// import MoonLight from "./MoonLight";
// import Notifications from "./Notifiations";

const Rightbar = () => {
  const initialENDPOINT = process.env.REACT_APP_ENDPOINT;
  const history = useNavigate();

  const token = localStorage.getItem("token");
  token === "" && history(`${process.env.PUBLIC_URL}/login`);

  const { sidebarResponsive } = useContext(CustomizerContext);
  const [welcomeName, setWelcomeName] = useState("");
  const { goToLogin } = useGoToLogin();

  let getMe = async () => {
    // console.log("initialENDPOINT", initialENDPOINT);
    // let resp = await axios.get(`${initialENDPOINT}/me`);
    let resp;
    try {
      resp = await axios({
        url: `${initialENDPOINT}/me`,
        method: `POST`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        redirect: "follow",
      });
      // notify();
      // history(`${process.env.PUBLIC_URL}/compras`);
      // console.log("resp", resp.data.code);

      resp.status === 200 && setWelcomeName(resp.data.user.nombre);
      if (resp.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("name");
        localStorage.removeItem("idUser");
        history(`${process.env.PUBLIC_URL}/login`);
      }
      resp.status === 250 && goToLogin();
    } catch (error) {
      console.log(error);
    }
    // console.log("resp", resp);
  };

  useEffect(() => {
    getMe();
  }, []);

  return (
    <div className="col pull-right right-menu p-0">
      {/* <div className="nav-right col pull-right right-menu p-0"> */}
      <ul
        className={`simple-list d-flex flex-row justify-content-end pe-3 nav-menus ${
          sidebarResponsive ? "open" : ""
        }`}
      >
        {/* <Notifications />
        <MessageDrop />
        <MoonLight /> */}
        {welcomeName && (
          <span className={`px-3 pt-3`}>
            Bienvenid@ <strong>{welcomeName}</strong>{" "}
          </span>
        )}
        {/* {welcomeName !== "" ? (
          <h7 className={`px-3 pt-3`}>
            Bienvenid@ <strong>{welcomeName}</strong>{" "}
          </h7>
        ) : (
          goToLogin()
        )} */}
        <LogoutClass />
      </ul>
    </div>
  );
};

export default Rightbar;
