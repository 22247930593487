import { useContext, useEffect, useState } from "react";
import { Button, Form, InputGroup, Col, Row } from "react-bootstrap";
import ProductsTable from "./ProductsTable";
import MyModal from "../../../../Common/MyModal";
import Loader from "../../../../Common/Loader";
import Vacio from "../../../../Common/Vacio";
import DataContext from "../../../../../_helper/DataContext";
import useGoToLogin from "../../../../../_helper/hooks/useGoToLogin";
import axios from "axios";
import DataTable from "react-data-table-component";

const Productos = ({ handleChange, formData, setFormData, emptyProductos }) => {
  const initialENDPOINT = process.env.REACT_APP_ENDPOINT;
  const token = localStorage.getItem("token");
  const [newQuery, setNewQuery] = useState(false);
  const [inputMsg, setInputMsg] = useState("");
  const [isMounted, setIsMounted] = useState(true);
  const [isLoadingExcel, setIsLoadingExcel] = useState(false);
  const { goToLogin } = useGoToLogin();
  const {
    data,
    errMsg,
    loading,
    totalRows,
    perPage,
    setPerPage,
    setPage,
    setENDPOINT,
  } = useContext(DataContext);

  const queriesColumns = [
    {
      name: "ID",
      selector: (row) => row.id,
      center: false,
      wrap: true,
      grow: 0,
    },
    {
      name: "Codigo",
      selector: (row) => row.codigo,
      center: false,
      wrap: true,
      grow: 0,
    },
    {
      name: "Producto",
      selector: (row) => row.nombre,
      center: false,
      wrap: true,
    },
    {
      name: "Marca",
      selector: (row) => row.nombreMarca,
      center: false,
      wrap: true,
    },
    {
      name: "Color",
      selector: (row) => row.colorNombre,
      center: false,
      wrap: true,
    },
    {
      name: "Seleccionar",
      selector: (row) => [
        <button
          key={2}
          className="btn text-dark w-auto p-1 mx-1 my-0"
          type="button"
          title="Seleccionar"
          onClick={(e) => handleProduct(row)}
        >
          <i className="fa fa-check f-16 px-1" />
        </button>,
      ],
      center: true,
      compact: true,
    },
  ];

  const getId = async (e) => {
    if (e.keyCode !== 13) {
      return;
    }

    e.preventDefault();

    try {
      const response = await axios(
        `${initialENDPOINT}/producto/${formData.idProducto}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          redirect: "follow",
        }
      );
      if (response.data.data.status === 200) {
        setFormData((prevalue) => {
          return {
            ...prevalue,
            idProducto: response.data.data.message[0].id,
            nombreProducto: response.data.data.message[0].nombre,
            codigoProducto: response.data.data.message[0].codigo,
            //costoProducto: response.data.data.message[0].precio,
            marcaProducto: response.data.data.message[0].nombreMarca,
            colorProducto: response.data.data.message[0].color,
            stockProducto: response.data.data.message[0].stock,
            producto: response.data.data.message[0],
          };
        });
      }
    } catch (error) {
      setFormData((prevalue) => {
        return {
          ...prevalue,
          nombreProducto: "Producto no encontrado",
          codigoProducto: "",
          costoProducto: "",
          precioVenta: "",
          marcaProducto: "",
          colorProducto: "",
          tamanoProducto: "",
          cantidadProducto: "",
          stockProducto: "",
        };
      });
    }
  };

  const handleCodigoProducto = async (codigo, e) => {

    if (e.keyCode !== 13) {
      return;
    }

    /** ---------------------------------------- **/

    e.preventDefault();

    try {
      const response = await axios(
        `${initialENDPOINT}/producto/codigo/${codigo}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          redirect: "follow",
        }
      );
      if (response.data.data.status === 200) {
        setFormData((prevalue) => {
          return {
            ...prevalue,
            idProducto: response.data.data.message[0].id,
            nombreProducto: response.data.data.message[0].nombre,
            codigoProducto: response.data.data.message[0].codigo,
            //costoProducto: response.data.data.message[0].precio,
            marcaProducto: response.data.data.message[0].nombreMarca,
            colorProducto: response.data.data.message[0].color,
            stockProducto: response.data.data.message[0].stock,
            producto: response.data.data.message[0],
          };
        });
      }
    } catch (error) {
      setFormData((prevalue) => {
        return {
          ...prevalue,
          nombreProducto: "Producto no encontrado",
          codigoProducto: "",
          costoProducto: "",
          precioVenta: "",
          marcaProducto: "",
          colorProducto: "",
          tamanoProducto: "",
          cantidadProducto: "",
          stockProducto: "",
        };
      });
    }

    /** ---------------------------------------- **/
  }

  // Funcion para agregar el producto desde el popup (lo carga a los inputs)
  const handleProduct = ({
    id,
    codigo,
    nombre,
    precio,
    marca,
    color,
    tamano,
    stock,
  }) => {
    setFormData((prevalue) => {
      return {
        ...prevalue,
        idProducto: id,
        codigoProducto: codigo,
        nombreProducto: nombre,
        costoProducto: precio,
        //precioVenta: precio,
        marcaProducto: marca,
        colorProducto: color,
        tamanoProducto: tamano,
        stockProducto: stock,
      };
    });
    setNewQuery(false);
  };

  // Agrega el producto a la tabla
  const handleProductAdd = () => {
    // if (
    //   !formData.idProducto ||
    //   !formData.costoProducto ||
    //   //!formData.precioVenta ||
    //   !formData.cantidadProducto
    // ) {
    //   setInputMsg("Debes completar todos los campos.");
    //   return;
    // }
    // Verificar si el producto con el mismo código ya existe
    const productoExistenteIndex = formData.productos?.findIndex(
      (producto) => producto.producto === formData.idProducto
    );
    if (productoExistenteIndex >= 0) {
      const productosActualizados = [...formData.productos];
      const productoExistente = productosActualizados[productoExistenteIndex];

      productoExistente.cantidad =
        parseInt(productoExistente.cantidad) +
        parseInt(formData.cantidadProducto);
      setFormData((prevalue) => ({
        ...prevalue,
        productos: productosActualizados,
        idProducto: "",
        nombreProducto: "",
        codigoProducto: "",
        costoProducto: "",
        precioVenta: "",
        marcaProducto: "",
        colorProducto: "",
        tamanoProducto: "",
        cantidadProducto: "",
      }));
      setInputMsg("");
      return;
    }
    setFormData((prevalue) => {
      const newProduct = {
        producto: formData.idProducto,
        cantidad: formData.cantidadProducto,
        precioUnitario: formData.costoProducto,
        precioVenta: formData.precioVenta,
        codigo: formData.codigoProducto,
        enDeposito: 0,
        nombreProducto: formData.nombreProducto,
      };
      return {
        ...prevalue,
        productos: [...prevalue.productos, newProduct],
        idProducto: "",
        cantidadProducto: "",
        nombreProducto: "",
        codigoProducto: "",
        costoProducto: "",
        precioVenta: "",
        marcaProducto: "",
        colorProducto: "",
      };
    });

    setInputMsg("");

    var codigoProducto = document.getElementById('codigoProducto');
    codigoProducto.value = '';
  };
  // Borra un porducto de la tabla
  const handleProductDelete = ({ producto }) => {
    const productosActualizados = formData.productos?.filter(
      (item) => item.producto !== producto
    );
    setFormData((prevalue) => ({
      ...prevalue,
      productos: productosActualizados,
    }));
  };

  // Calcula las cantidades de artículos y unidades
  const handleQuantity = () => {
    const cantidadDeArticulos = formData.productos?.reduce(
      (acc, curr) => acc + 1,
      0
    );
    const cantidadDeUnidades = formData.productos?.reduce(
      (acc, curr) => acc + parseInt(curr.cantidad),
      0
    );

    setFormData((prevalue) => {
      return {
        ...prevalue,
        cantidadDeArticulos: cantidadDeArticulos,
        cantidadDeUnidades: cantidadDeUnidades,
      };
    });
  };

  const handlePageChange = (pag) => {
    newQuery &&
      setENDPOINT(
        `${initialENDPOINT}/producto?pagina=${pag}&cantidad=${perPage}${formData.nombre ? "&nombre=" + formData.nombre : ""
        }`
      );
  };

  const handlePerRowsChange = async (newPerPage, pag) => {
    newQuery && setPerPage(newPerPage);
    newQuery && setPage(pag);
    newQuery &&
      (await setENDPOINT(
        `${initialENDPOINT}/producto?pagina=${pag}&cantidad=${newPerPage}${formData.cliente ? "&nombre=" + formData.cliente : ""
        }`
      ));
  };

  const handleFileChange = async (e) => {
    setIsLoadingExcel(true);
    let dataToSend = new FormData();
    let productosActualizados = [];
    dataToSend.append("file", e.target.files[0]);
    try {
      const res = await axios(`${initialENDPOINT}/procesar-excel`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
          // "Content-Type": "application/json",
        },
        data: dataToSend,
        redirect: "follow",
      });

      setFormData((prevalue) => ({
        ...prevalue,
        productos: [...prevalue.productos, ...res.data.data],
      }));
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingExcel(false);
    }
  };

  useEffect(() => {
    !token && goToLogin();
    const random = Date.now();
    newQuery &&
      setENDPOINT(`${initialENDPOINT}/producto?pagina=1&random=${random}`);
    setInputMsg("");
  }, [newQuery]);

  useEffect(() => {
    return () => {
      setIsMounted(false);
    };
  }, []);

  useEffect(() => {
    isMounted && formData.productos && handleQuantity();
  }, [formData.productos]);

  return (
    <Col className="col-12">
      {/* {console.log("emptyProductos", emptyProductos)} */}
      <div
        className="bg-white p-4 rounded"
        style={
          emptyProductos === true
            ? { border: "1px solid red" }
            : { border: "1px solid #E6EDEF" }
        }
      >
        <div className="row">
          <div className="mb-3 d-flex justify-content-between ">
            <h6>Detalles del pedido</h6>

            <div
              className={`btn btn-rounded p-0 w-8 pt-1 ${!formData.excel && "btn-primary px-3 "
                }`}
            >
              <label
                style={{ cursor: "pointer", color: "white" }}
                htmlFor="excel"
              >
                Importar Excel
              </label>
              <input
                type="file"
                onChange={(e) => handleFileChange(e)}
                className="btn btn-info slackinform-control d-none filetype"
                id="excel"
                name="excel"
                multiple
                accept=".xls, .xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              />
            </div>
          </div>

          <Form.Group className="mb-3 col-3">
            <br />
            <Form.Label>Codigo de producto</Form.Label>
            <InputGroup>
              <Form.Control
                type="text"
                name="codigoProducto"
                id="codigoProducto"
                defaultValue={formData.codigoProducto}
                placeholder="Ingrese el codigo"
                onChange={(e) => handleChange(e)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleCodigoProducto(e.target.value, e);
                  }
                }}
              />
              <InputGroup.Text
                style={{ cursor: "pointer" }}
                onClick={() => setNewQuery(true)}
              >
                <i className="fa fa-search" aria-hidden="true"></i>
              </InputGroup.Text>
            </InputGroup>
            {formData.nombreProducto && (
              <Form.Text
                className={
                  formData.nombreProducto !== "Producto no encontrado"
                    ? `text-success ms-2`
                    : `text-danger ms-2`
                }
              >
                {formData.nombreProducto}
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group className="mb-3 col-2">
            <br />
            <Form.Label>Costo</Form.Label>
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control
                type="number"
                name="costoProducto"
                value={formData.costoProducto}
                onChange={(e) => handleChange(e)}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3 col-2">
            <br />
            <Form.Label>Precio Regular</Form.Label>
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control
                type="number"
                name="precioVenta"
                value={formData.precioVenta}
                onChange={(e) => handleChange(e)}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3 col-2">
            <br />
            <Form.Label>Cantidad</Form.Label>
            <Form.Control
              type="number"
              name="cantidadProducto"
              value={formData.cantidadProducto}
              onChange={(e) => handleChange(e)}
            />
          </Form.Group>
          <Form.Group className="d-flex align-items-end justify-content-end mb-4 col-2">
            <Button onClick={handleProductAdd}>Agregar</Button>
          </Form.Group>
          {inputMsg && (
            <Form.Text className="text-danger mt-0">{inputMsg}</Form.Text>
          )}
          <ProductsTable
            formData={formData}
            setFormData={setFormData}
            handleProductDelete={handleProductDelete}
            isLoadingExcel={isLoadingExcel}
          />
          <div>
            <Form.Text className="me-2">
              Cantidad de articulos: {formData.cantidadDeArticulos || 0}
            </Form.Text>
            <Form.Text> | </Form.Text>
            <Form.Text className="ms-2">
              Cantidad de unidades: {formData.cantidadDeUnidades || 0}
            </Form.Text>
          </div>
          {emptyProductos === true ? (
            <small className="text-danger">Debe cargar productos</small>
          ) : (
            ""
          )}
          <MyModal
            show={newQuery}
            onHide={() => setNewQuery(false)}
            title="Buscar producto"
          >
            <Row>
              <Form.Group className="mb-3 col-6">
                <Form.Label>Productos</Form.Label>
                <Form.Control
                  type="text"
                  name="nombreProducto"
                  placeholder="Ingrese el nombre"
                  onChange={(e) => handleChange(e)}
                ></Form.Control>
              </Form.Group>
              <Button
                type="submit"
                className="mt-auto mb-3 col-3"
                onClick={() =>
                  setENDPOINT(
                    `${initialENDPOINT}/producto?pagina=1&nombre=${formData.nombreProducto}`
                  )
                }
              >
                Buscar
              </Button>
            </Row>
            {errMsg && <p>{errMsg}</p>}
            <DataTable
              columns={queriesColumns}
              data={data}
              striped
              customStyles={{
                rows: {
                  highlightOnHoverStyle: {
                    backgroundColor: "rgb(230, 244, 244)",
                    borderBottomColor: "#FFFFFF",
                    outline: "1px solid #FFFFFF",
                  },
                },
                pagination: {
                  style: {
                    border: "none",
                  },
                },
              }}
              pointerOnHover
              highlightOnHover
              progressPending={loading}
              progressComponent={<Loader />}
              noDataComponent={<Vacio />}
              pagination
              paginationServer
              paginationComponentOptions={{
                rowsPerPageText: "Filas por página",
                rangeSeparatorText: "de",
              }}
              paginationDefaultPage
              paginationTotalRows={totalRows}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePerRowsChange}
              persistTableHead
            />
          </MyModal>
        </div>
      </div>
    </Col>
  );
};

export default Productos;
