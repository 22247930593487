import StatisticsList from "./StatisticsList";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";

const SupportTickitContain = () => {
  return (
    <Container fluid={true}>
      <Row>
        <Col sm="12" className="p-0">
          <Card className="shadow">
            <CardHeader className="pb-0">
              <h5>Estadísticas</h5>
              <span>{"Basados en todo tu historial"}</span>
            </CardHeader>
            <CardBody>
              <StatisticsList />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
export default SupportTickitContain;
