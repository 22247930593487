import { useEffect, useState } from "react";
import { Row, Col, Nav, Tab } from "react-bootstrap";
import { Breadcrumbs } from "../../../AbstractElements";
import TabComponent from "./TabComponent.jsx";
import axios from "axios";
import useGoToLogin from "../../../_helper/hooks/useGoToLogin";
import { useNavigate } from "react-router-dom";

const Configuraciones = () => {
  const initialENDPOINT = process.env.REACT_APP_ENDPOINT;
  const token = localStorage.getItem("token");
  const [chosenEdit, setChosenEdit] = useState("tipo");
  const [isMounted, setIsMounted] = useState(true);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);

  const { goToLogin } = useGoToLogin();
  const history = useNavigate();

  const getMe = async () => {
    try {
      await axios({
        url: `${initialENDPOINT}/me`,
        method: `POST`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        redirect: "follow",
      });
    } catch (error) {
      if (error.response.status === 401 || error.response.status === 250) {
        localStorage.removeItem("token");
        localStorage.removeItem("name");
        localStorage.removeItem("idUser");
        history(`${process.env.PUBLIC_URL}/login`);
      }
      console.log(error);
    }
  };

  const getData = async (chosenEdit) => {
    setLoading(true);

    let url = "";
    chosenEdit === "tipo" &&
      (url = `${initialENDPOINT}/tipoproducto?cantidad=${perPage}&pagina=${page}`);
    chosenEdit === "marca" &&
      (url = `${initialENDPOINT}/marcaproducto?cantidad=${perPage}&pagina=${page}&src=config`);
    chosenEdit === "color" &&
      (url = `${initialENDPOINT}/color?cantidad=${perPage}&pagina=${page}`);
    chosenEdit === "categoria" &&
      (url = `${initialENDPOINT}/categoriaproducto?cantidad=${perPage}&pagina=${page}`);
    chosenEdit === "material" &&
      (url = `${initialENDPOINT}/materialproducto?cantidad=${perPage}&pagina=${page}`);
    chosenEdit === "tamaño" &&
      (url = `${initialENDPOINT}/tamanoproducto?cantidad=${perPage}&pagina=${page}`);

    try {
      getMe();
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setData(response.data.data.original.results);
      setTotalRows(response.data.data.original.cantidad_total);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    !token && goToLogin();
    getData(chosenEdit);
  }, [chosenEdit, page, perPage]);

  return (
    <Tab.Container id="left-tabs-example" defaultActiveKey="1">
      <Breadcrumbs
        mainTitle="Configuraciones Generales"
        parent="Configuraciones"
        title="Listado"
      />
      <Row>
        <Col
          sm={3}
          className="border border-black border- border-end border-bottom-0 pe-0 bg-white pt-3 "
        >
          <Nav variant="tabs" className="flex-column bg-white">
            <Nav.Item>
              <Nav.Link eventKey="1" onClick={() => setChosenEdit("tipo")}>
                Tipo de Producto
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="2" onClick={() => setChosenEdit("marca")}>
                Marcas
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="3" onClick={() => setChosenEdit("color")}>
                Colores
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="4" onClick={() => setChosenEdit("categoria")}>
                Categoría
              </Nav.Link>
            </Nav.Item>
            {/* <Nav.Item>
              <Nav.Link eventKey="5" onClick={() => setChosenEdit("material")}>
                Material
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="6"
                onClick={() => {
                  setChosenEdit("tamaño");
                }}
              >
                Tamaños
              </Nav.Link>
            </Nav.Item> */}
          </Nav>
        </Col>
        <Col sm={9}>
          <Tab.Content>
            <Tab.Pane eventKey="1">
              <TabComponent
                data={data}
                getData={getData}
                totalRows={totalRows}
                setPerPage={setPerPage}
                setPage={setPage}
                loading={loading}
                setData={setData}
                chosenEdit={chosenEdit}
                setChosenEdit={setChosenEdit}
                modalTitle={"Agregar " + chosenEdit + " de producto"}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="2">
              <TabComponent
                data={data}
                getData={getData}
                totalRows={totalRows}
                setPerPage={setPerPage}
                setPage={setPage}
                loading={loading}
                setData={setData}
                chosenEdit={chosenEdit}
                setChosenEdit={setChosenEdit}
                modalTitle={"Agregar " + chosenEdit + " de producto"}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="3">
              <TabComponent
                data={data}
                getData={getData}
                totalRows={totalRows}
                setPerPage={setPerPage}
                setPage={setPage}
                loading={loading}
                setData={setData}
                chosenEdit={chosenEdit}
                setChosenEdit={setChosenEdit}
                modalTitle={"Agregar " + chosenEdit + " de producto"}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="4">
              <TabComponent
                data={data}
                getData={getData}
                totalRows={totalRows}
                setPerPage={setPerPage}
                setPage={setPage}
                loading={loading}
                setData={setData}
                chosenEdit={chosenEdit}
                setChosenEdit={setChosenEdit}
                modalTitle={"Agregar " + chosenEdit + " de producto"}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="5">
              <TabComponent
                data={data}
                getData={getData}
                totalRows={totalRows}
                setPerPage={setPerPage}
                setPage={setPage}
                loading={loading}
                setData={setData}
                chosenEdit={chosenEdit}
                setChosenEdit={setChosenEdit}
                modalTitle={"Agregar " + chosenEdit + " de producto"}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="6">
              <TabComponent
                data={data}
                getData={getData}
                totalRows={totalRows}
                setPerPage={setPerPage}
                setPage={setPage}
                loading={loading}
                setData={setData}
                chosenEdit={chosenEdit}
                setChosenEdit={setChosenEdit}
                modalTitle={"Agregar " + chosenEdit + " de producto"}
              />
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
};

export default Configuraciones;
