import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Row, Button, Col, Container } from "react-bootstrap";
import { toast } from "react-toastify";
import { Breadcrumbs } from "../../../../AbstractElements";
import MarketRateData from "./Client/MarketRateData";
import ProductDetails from "./Products/ProductDetails";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-quill/dist/quill.snow.css";
import axios from "axios";

const NewMarketRate = () => {
  const initialENDPOINT = process.env.REACT_APP_ENDPOINT;
  const token = localStorage.getItem("token");
  const [formData, setFormData] = useState({ subtotal: 0 });
  const [emptyClient, setEmptyClient] = useState();
  const [emptyProducts, setEmptyProducts] = useState();
  const [disableButton, setDisableButton] = useState(false);
  const notify = () => toast("Nueva cotización guardada!");
  const history = useNavigate();

  const productosArray = formData.productos?.map((p) => ({
    cantidad: p.cantidad,
    idProducto: p.idProducto,
    precio: p.precio,
  }));

  const handleChange = (e) => {
    let value;
    e.target.type === "checkbox"
      ? (value = e.target.checked)
      : (value = e.target.value);
    let name = e.target.name;
    setFormData((prevalue) => {
      return {
        ...prevalue,
        [name]: value,
      };
    });
  };

  const sendForm = (e) => {
    e.preventDefault();
    if (e.keyCode === 13) {
      return;
    }
    sendData();
  };

  const sendData = async () => {
    // console.log("productosArray", productosArray);
    if (!formData.idCliente) {
      setEmptyClient(true);
    } else if (productosArray.length === 0) {
      setDisableButton(true);
      alert("Debe seleccionar al menos un producto.");
    } else {
      setDisableButton(true);
      const dataToSend = JSON.stringify({
        cliente: formData.idCliente,
        productos: productosArray,
      });
      try {
        const response = await axios({
          url: `${initialENDPOINT}/cotizacion`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          ////////// data: hardCode,
          data: dataToSend,
          redirect: "follow",
        });
        notify();
        history(`${process.env.PUBLIC_URL}/cotizaciones`);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const generarPedido = async () => {
    const dataToSend = JSON.stringify({
      //--------Armar objeto para crear pedido con la cotización
    });

    try {
      const response = await axios({
        url: `${initialENDPOINT}/cotizacion/pedido`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        ////////// data: hardCode,
        data: dataToSend,
        redirect: "follow",
      });
      notify();
      history(`${process.env.PUBLIC_URL}/pedidos`);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (productosArray?.length !== 0) {
      setDisableButton(false);
    }
  }, [productosArray]);

  return (
    <Container fluid={true}>
      <Breadcrumbs
        mainTitle="Nueva cotización"
        parent="Cotizaciones"
        title="Nueva cotización"
      />
      <Form>
        <Row>
          <Col className="col-12">
            {/* Cliente | Origen | Vendedor | Etapa de preparacion */}
            <MarketRateData
              emptyClient={emptyClient}
              handleChange={handleChange}
              formData={formData}
              setFormData={setFormData}
            />
            <br />
            {/* Detalles del pedido */}
            <ProductDetails
              handleChange={handleChange}
              formData={formData}
              setFormData={setFormData}
            />
          </Col>
          <Col className="col-12 col-lg-8 col-xxl-6"></Col>
        </Row>
        <div className="d-flex justify-content-between">
          <Link key={1} to={`/cotizaciones`}>
            <Button variant="danger" className="mb-5 mt-2">
              Cancelar
            </Button>
          </Link>
          <div>
            <Button
              variant="outline-primary"
              onClick={(e) => generarPedido(e)}
              className="mb-5 mt-2"
            >
              Generar Pedido
            </Button>
            {/* <Button
              variant="outline-primary"
              onClick={(e) => alert("Eliminar Cotización")}
              className="mb-5 mt-2 ms-3"
            >
              Eliminar Cotización
            </Button> */}
            <Button
              disabled={formData.idCliente === 0 ? true : disableButton}
              onClick={(e) => sendForm(e)}
              className="mb-5 mt-2 ms-3"
              type="submit"
            >
              Guardar
            </Button>
          </div>
        </div>
      </Form>
    </Container>
  );
};

export default NewMarketRate;
