import { useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { downloadCSV, Export } from "../../../_helper/hooks/useExportCsv";
import Loader from "../../Common/Loader";
import Vacio from "../../Common/Vacio";
import TableTitle from "../../Common/TableTitle";
import { Link } from "react-router-dom";

const CustomerTable = ({
  data,
  errMsg,
  loading,
  totalRows,
  perPage,
  setPerPage,
  setPage,
  setENDPOINT,
  filterENDPOINT,
  showCancellations,
  handleShowCancellations,
}) => {
  const initialENDPOINT = process.env.REACT_APP_ENDPOINT;
  const queriesColumns = [
    {
      name: "Código",
      selector: (row) => row.id,
      center: false,
      wrap: true,
      grow: 0,
    },
    {
      name: "Nombre",
      selector: (row) => row.nombre,
      center: false,
      wrap: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      center: false,
      // hide: "sm",
      wrap: true,
    },
    {
      name: "Teléfono",
      selector: (row) => row.telefono,
      center: false,
      // hide: "sm",
      wrap: true,
    },
    {
      name: "Contacto",
      selector: (row) => row.contacto,
      center: false,
      // hide: "sm",
      wrap: true,
    },
    {
      name: "Cta. Cte.",
      selector: (row) => row.ctacte,
      center: false,
      // hide: "sm",
      wrap: true,
    },
    {
      name: "Acciones",
      selector: (row) => [
        <Link key={2} to={`/editar-cliente/${row.id}`}>
          <button
            className="btn text-dark w-auto p-1 mx-1 my-0"
            type="button"
            title="Editar"
          >
            <i className="fa fa-pencil-square-o f-16 px-1" />
          </button>
        </Link>,
        <Link key={3} to={`/detalle-cta-cte/${row.id}`}>
          <button
            className="btn text-dark w-auto p-1 mx-1 my-0"
            type="button"
            title="Detalle Cta. Cte."
          >
            <i className="fa fa-file-text f-16 px-1" />
          </button>
        </Link>,
      ],
      center: true,
      // hide: "md",
      compact: true,
    },
  ];

  const handlePageChange = (pag) =>
    setENDPOINT(
      `${initialENDPOINT}/cliente?pagina=${pag}&cantidad=${perPage}${filterENDPOINT}&mostrarBajas=${showCancellations}`
    );
  const handlePerRowsChange = async (newPerPage, pag) => {
    setPerPage(newPerPage);
    setPage(pag);
    await setENDPOINT(
      `${initialENDPOINT}/cliente?pagina=${pag}&cantidad=${newPerPage}${filterENDPOINT}&mostrarBajas=${showCancellations}`
    );
  };
  const actionsMemo = useMemo(
    () => (
      <Export
        onExport={() => downloadCSV(data, "Consultas")}
        cancallations={handleShowCancellations}
      />
    ),

    [data]
  );

  return (
    <div
      className="App mb-5 table-queries border rounded"
      class="overflow-hidden overflow-x-auto"
    >
      {errMsg && <p>{errMsg}</p>}
      <DataTable
        title={<TableTitle value="Lista de clientes" />}
        columns={queriesColumns}
        data={data}
        striped
        customStyles={{
          rows: {
            highlightOnHoverStyle: {
              backgroundColor: "rgb(230, 244, 244)",
              borderBottomColor: "#FFFFFF",
              outline: "1px solid #FFFFFF",
            },
          },
          pagination: {
            style: {
              border: "none",
            },
          },
        }}
        highlightOnHover
        progressPending={loading}
        progressComponent={<Loader />}
        noDataComponent={<Vacio />}
        pagination
        paginationServer
        paginationComponentOptions={{
          rowsPerPageText: "Filas por página",
          rangeSeparatorText: "de",
        }}
        paginationDefaultPage
        paginationTotalRows={totalRows}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerRowsChange}
        actions={actionsMemo}
        persistTableHead
      />
    </div>
  );
};

export default CustomerTable;
