import { useContext, useState } from "react";
import { Form, Row, Button, Col, Container } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import DataContext from "../../../_helper/DataContext";
import { Breadcrumbs } from "../../../AbstractElements";
import { useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

const NewCupon = () => {
  const history = useNavigate();
  const token = localStorage.getItem("token");
  const [formData, setFormData] = useState({});
  const [dataMarcasEndpoint, setMarcasEndpoint] = useState([]);
  const [disableButton, setDisableButton] = useState(false);
  const initialENDPOINT = process.env.REACT_APP_ENDPOINT;
  const { cuponID } = useParams();
  const notify = () => toast("Usuario creado!");

  const sendForm = async (e) => {


    e.preventDefault();

    let fechaHoy = new Date();
    let year = fechaHoy.getFullYear();
    let month = String(fechaHoy.getMonth() + 1).padStart(2, '0');
    let day = String(fechaHoy.getDate()).padStart(2, '0');
    let fechaFormateada = `${year}-${month}-${day}`;

    //setPermisosEmpty(false);
    setDisableButton(true);
    let dataToSend = JSON.stringify({
      nombre: formData.nombre,
      descripcion: formData.descripcion,
      descuentoFijo: formData.descuentoFijo,
      descuentoPorcentual: formData.descuentoPorcentual,
      marca: formData.marca,
      combinable: "",
      inicio: fechaFormateada,
      vencimiento: formData.fechaVencimiento,
      stock: formData.stock,
      suspendido: ""

    });
    console.log(dataToSend);
    try {
      await axios(
        `${initialENDPOINT}/cupondescuento/${cuponID}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          data: dataToSend,
          redirect: "follow",
        }
      );
    } catch (error) {
      console.log("Ha ocurrido un error", error);
    } finally {
      notify();
      history(`${process.env.PUBLIC_URL}/cupones`);
    }

  };


  const getData = async () => {
    try {
      const res = await axios(`${initialENDPOINT}/cupondescuento/${cuponID}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        redirect: "follow",
      });

      setFormData(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    let value;

    let name = e.target.name;

    if (e.target.name === "suspendido") {
      e.target.type === "checkbox"
        ? (value = e.target.checked)
        : (value = e.target.value);
    } else {
      value = e.target.value;
    }
    setFormData((prevalue) => {
      return {
        ...prevalue,
        [name]: value,
      };
    });
  };

  const marcas = async () => {
    try {
      let pedido = await axios(`${initialENDPOINT}/marcaproducto`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        redirect: "follow",
      });

      dataMarcasEndpoint &&
        setMarcasEndpoint(pedido.data.data.original.results);
    } catch (error) {
      console.log("Ha ocurrido un error", error);
    }
  };

  // const productos = async () => {
  //   try {
  //     let pedido = await axios(`${initialENDPOINT}/producto`, {
  //       method: "GET",
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //       redirect: "follow",
  //     });

  //     dataProductosEndpoint &&
  //       setProductosEndpoint(pedido.data.data.original.results);
  //   } catch (error) {
  //     console.log("Ha ocurrido un error", error);
  //   }
  // };

  useEffect(() => {
    marcas();
    getData();
    //productos();
  }, []);

  return (
    <Container fluid={true}>
      <Breadcrumbs
        mainTitle="Nuevo cupon"
        parent="Cupones"
        title="Nuevo cupon"
      />
      <Form onSubmit={sendForm} className="container col-12">
        <Row>
          <Col className="col-11 col-lg-9 col-xxl-8 bg-white p-4 mb-5 rounded border shadow">
            <Form.Group className="d-flex align-items-center mb-3">
              <Form.Label className="col-4 col-md-4">Nombre</Form.Label>
              <Form.Control
                type="text"
                name="nombre"
                value={formData.nombre}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group className="d-flex align-items-center mb-3">
              <Form.Label className="col-4 col-md-4">Descripción</Form.Label>
              <textarea
                name="descripcion"
                value={formData.descripcion}
                onChange={handleChange}
                required
                rows={3} // Ajusta el número de filas según necesites
                cols={100}
              />
            </Form.Group>

            <Form.Group className="d-flex align-items-center mb-3">
              <Form.Label className="col-4 col-md-4">Descuento fijo</Form.Label>
              <Form.Control
                type="number"
                name="descuentoFijo"
                value={formData.descuentoFijo}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group className="d-flex align-items-center mb-3">
              <Form.Label className="col-4 col-md-4">Descuento porcentual</Form.Label>
              <Form.Control
                type="number"
                name="descuentoPorcentual"
                value={formData.descuentoPorcentual}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group className="d-flex align-items-center mb-3">
              <Form.Label className="col-4 col-md-4">
                Marca
              </Form.Label>
              <Form.Select
                name="marca"
                value={formData.marca}
                onChange={handleChange}
              >
                <option disabled>Elija una opción</option>
                <option key={0} value="0">Todas las marcas</option>
                {dataMarcasEndpoint?.map((item, i) => (
                  <option key={i} value={item.id}>
                    {item.nombre}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            {/* <Form.Group className="d-flex align-items-center mb-3">
              <Form.Label className="col-4 col-md-4">
                Producto
              </Form.Label>
              <Form.Select
                defaultValue={"Elija una opción"}
                name="producto"
                onChange={handleChange}
                //className={permisosEmpty && `is-invalid`}
              >
                <option disabled>Elija una opción</option>
                {dataProductosEndpoint?.map((item, i) => (
                  <option key={i} value={item.id}>
                    {item.nombre}
                  </option>
                ))}
              </Form.Select>
            </Form.Group> */}

            <Form.Group className="d-flex align-items-center mb-3">
              <Form.Label className="col-4 col-md-4">Minimo</Form.Label>
              <Form.Control
                type="number"
                name="minimo"
                value={formData.montoMinimo}
                onChange={handleChange}
                required
              />
            </Form.Group>


            <Form.Group className="d-flex align-items-center mb-3">
              <Form.Label className="col-4 col-md-4">Maximo</Form.Label>
              <Form.Control
                type="number"
                name="maximo"
                value={formData.maximo}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group className="d-flex align-items-center mb-3">
              <Form.Label className="col-4 col-md-4">Stock</Form.Label>
              <Form.Control
                type="number"
                name="stock"
                value={formData.stock}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group className="d-flex align-items-center mb-3">
              <Form.Label className="col-4 col-md-4">Fecha de vencimiento</Form.Label>
              <div className="w-100">
                <Form.Control
                  type="date"
                  name="fechaVencimiento"
                  value={formData.vencimiento}
                  onChange={handleChange}
                  required
                />
              </div>
            </Form.Group>


            <div className="d-flex justify-content-end">
              <Button
                disabled={disableButton}
                type="submit"
                className="mt-2 mt-md-2 col-2 col-lg-2"
              >
                Guardar
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default NewCupon;
