import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

const Breadcrumbs = (props) => {
  return (
    <>
      <Container fluid={true}>
        <div className="page-header mt-3">
          <Row>
            <Col sm="6">
              <h3>{props.mainTitle}</h3>
            </Col>
            <Col sm="6">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={`${process.env.PUBLIC_URL}/escritorio`}>{props.title}</Link>
                </li>
                <li className="breadcrumb-item">{props.parent}</li>
                {props.subParent ? (
                  <li className="breadcrumb-item">{props.subParent}</li>
                ) : (
                  ""
                )}
              </ol>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default Breadcrumbs;
