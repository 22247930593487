import { useEffect, useState } from "react";
import { Row, Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";
const token = localStorage.getItem("token");

const NewFilterForm = ({
  showFilter,
  dataGlobal,
  setPage,
  setFilterENDPOINT,
  resetAll,
  formData,
  setFormData,
}) => {
  const [dataPerfilesEndpoint, setDataPerfilesEndpoint] = useState([]);
  const initialENDPOINT = process.env.REACT_APP_ENDPOINT;

  const notify = () => toast("¡Datos filtrados!");

  const itemFilter = (e) => {
    e.preventDefault();
    setPage(1);
    // setFilterENDPOINT(
    //   `&nombre=${formData.nombre}&id=${formData.id}&perfil=${formData.perfil}`
    // );
    setFilterENDPOINT(
      `&nombre=${formData.nombre}`
    );
    showFilter();
    notify();
  };

  const resetAllFilters = () => {
    resetAll();
    showFilter();
  };

  const handleChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    setFormData((prevalue) => {
      return {
        ...prevalue,
        [name]: value.trim(),
      };
    });
  };

  const pedidoPerfiles = async () => {
    try {
      const res = await axios(`${initialENDPOINT}/perfil`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        redirect: "follow",
      });
      setDataPerfilesEndpoint(res.data.results);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    pedidoPerfiles();
  }, []);

  return (
    <Form
      onSubmit={itemFilter}
      className="bg-white p-4 rounded mb-3 border ustify-content-evenly "
    >
      <Row>
        <Form.Group className="col-12 col-md-4 ">
          <Form.Label>Nombre</Form.Label>
          <Form.Control
            type="text"
            placeholder="Nombre"
            name="nombre"
            value={formData.nombre}
            onChange={handleChange}
          />
        </Form.Group>
        {/* <Form.Group className="col-12 col-md-4 ">
          <Form.Label>Código</Form.Label>
          <Form.Control
            type="text"
            placeholder="Código"
            name="id"
            value={formData.id === 0 ? "" : formData.id}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group className="col-12 col-md-4 ">
          <Form.Label>Perfil</Form.Label>
          <div style={{ display: "flex" }}>
            <Form.Select
              defaultValue={"Elija una opción"}
              name="perfil"
              onChange={handleChange}
            >
              l<option disabled>Elija una opción</option>
              {dataPerfilesEndpoint?.map((item, i) => (
                <option key={i} value={item.id}>
                  {item.nombre}
                </option>
              ))}
            </Form.Select>
          </div>
        </Form.Group> */}
      </Row>

      <Form.Group className="d-flex align-items-center justify-content-center">
        <Button
          variant="info"
          className="mt-2 mt-md-4 me-auto col-5 col-md-2 col-xxl-1 d-flex align-items-center justify-content-center"
          onClick={resetAllFilters}
        >
          Limpiar
        </Button>
        <Button
          type="submit"
          className="mt-2 mt-md-4 col-5 col-md-2 col-xxl-1 d-flex align-items-center justify-content-center"
        >
          Filtrar
        </Button>
      </Form.Group>
    </Form>
  );
};

export default NewFilterForm;
