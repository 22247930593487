import { useEffect, useState } from "react";
import { Button, Form, InputGroup, Row } from "react-bootstrap";
import NnProductsTable from "./NnProductsTable";

const ProductosNN = ({ handleChange, formData, setFormData }) => {
  const [inputMsg, setInputMsg] = useState("");

  const handleProductAdd = () => {
    if (
      !formData.descripcionNN ||
      !formData.precioPedido ||
      !formData.cantidadProductoNN
    ) {
      setInputMsg("Debes completar todos los campos");
      return;
    }

    setFormData((prevalue) => {
      return {
        ...prevalue,
        detalleNN: [
          ...prevalue?.detalleNN,
          {
            descripcion: formData.descripcionNN,
            precio: formData.precioPedido,
            cantidad: formData.cantidadProductoNN,
          },
        ],
      };
    });

    setFormData((prevalue) => {
      return {
        ...prevalue,
        // ...prevalue.detalleNN,
        descripcionNN: "",
        precioPedido: "",
        cantidadProductoNN: "",
      };
    });

    setInputMsg("");
    // console.log("formData", formData);
  };

  const handleProductDelete = ({ descripcion }) => {
    const productosActualizados = formData.detalleNN?.filter(
      (producto) => producto.descripcion !== descripcion
    );
    setFormData((prevalue) => ({
      ...prevalue,
      detalleNN: productosActualizados,
    }));
  };

  useEffect(() => {
    setFormData((prevalue) => {
      return {
        ...prevalue,
        detalleNN: [],
      };
    });
  }, []);

  return (
    <Row className="bg-white mb-3 p-4 pb-2 border rounded m-5">
      <h6 className="pb-3">Productos NN</h6>
      <Form.Group className="mb-3 col-4">
        <Form.Label>Descripción</Form.Label>
        <Form.Control
          type="text"
          name="descripcionNN"
          value={formData.descripcionNN || ""}
          placeholder="Ingrese la descripción"
          onChange={(e) => handleChange(e)}
        ></Form.Control>
      </Form.Group>
      <Form.Group className="mb-3 col-3">
        <Form.Label>Precio</Form.Label>
        <InputGroup>
          <InputGroup.Text>$</InputGroup.Text>
          <Form.Control
            type="number"
            name="precioPedido"
            value={formData.precioPedido || ""}
            onChange={(e) => handleChange(e)}
            min="0"
          />
        </InputGroup>
      </Form.Group>
      <Form.Group className="mb-3 col-3">
        <Form.Label>Cantidad</Form.Label>
        <Form.Control
          type="number"
          name="cantidadProductoNN"
          value={formData.cantidadProductoNN || ""}
          onChange={(e) => handleChange(e)}
          min="0"
        />
      </Form.Group>
      <Form.Group className="d-flex align-items-end justify-content-end mb-3 col-2">
        <Button onClick={handleProductAdd}>Agregar</Button>
      </Form.Group>
      {inputMsg && (
        <Form.Text className="text-danger mt-0">{inputMsg}</Form.Text>
      )}
      <NnProductsTable
        formData={formData.detalleNN}
        handleProductDelete={handleProductDelete}
      />
    </Row>
  );
};

export default ProductosNN;
