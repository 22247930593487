import { useState, useEffect } from "react";
import { Form, Row, Container, Button } from "react-bootstrap";
import { Breadcrumbs } from "../../../AbstractElements";
import CambiarGeneralesModal from "../../Common/CambiarGeneralesModal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";


const EdicionGralProductos = () => {
    const initialENDPOINT = process.env.REACT_APP_ENDPOINT;
    const token = localStorage.getItem("token");
    const [modalOpen, setModalOpen] = useState(false);
    const history = useNavigate();

    const handleModal = () => {
        setModalOpen(true);

    };


    /** 
     * checkboxes 
     **/

    const [checkboxStates, setCheckboxStates] = useState({
        marca: 0,
        tipo: 0,
        color: 0,
        destacado: 0,
        estuche: 0,
        stock: 0,
        precio: 0,
        suspendido: 0,
    });

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setCheckboxStates(prevState => ({
            ...prevState,
            [name]: checked ? 1 : 0
        }));
    };

    /** 
     * selectboxes
     **/

    const [selectedOptions, setSelectedOptions] = useState({
        marca: '',
        tipo: '',
        color: '',
        destacado: '',
        estuche: '',
        estucheModificacion: '',
        optionsDestacado: 0,
        optionsSuspendido: 0,
        optionsSuspendidoModificado: 0,
        radioValue: 1
    });

    const handleSelectChange = (event) => {
        const { name, value } = event.target;
        setSelectedOptions(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    /** 
     * input text
     **/

    const [inputs, setInputs] = useState({
        stockDesde: '',
        stockHasta: '',
        precioDesde: '',
        precioHasta: '',
        montoFijo: '',
        descuentoAumentoFijo: '',
        descuentoAumentoPorcentual: '',
        montoFijoRegular: '',
        descuentoAumentoFijoRegular: '',
        descuentoAumentoPorcentualRegular: '',
        costo: '',
        stockModificacionDesde: '',
        stockModificacionHasta: ''
    });

    const handleInputChange = (name, value) => {
        setInputs(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    /*
    * marcas
    */

    const [marcas, setMarcas] = useState([]);

    useEffect(() => {
        const fetchMarcas = async () => {
            try {

                const response = await fetch(`${initialENDPOINT}/marcaproducto?cantidad=1000000`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                const data = await response.json();
                const results = data.data.original.results;
                setMarcas(results.map(marca => ({
                    value: marca.id,
                    label: marca.nombre
                })));
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchMarcas();
    }, []);

    /*
    * tipos
    */

    const [tipos, setTipos] = useState([]);

    useEffect(() => {
        const fetchTipos = async () => {
            try {

                const response = await fetch(`${initialENDPOINT}/tipoproducto?cantidad=1000000`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                const data = await response.json();
                const results = data.data.original.results;
                setTipos(results.map(tipo => ({
                    value: tipo.id,
                    label: tipo.nombre
                })));
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchTipos();
    }, []);

    /*
    * colores
    */

    const [colores, setColores] = useState([]);

    useEffect(() => {
        const fetchColores = async () => {
            try {

                const response = await fetch(`${initialENDPOINT}/color?cantidad=1000000`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                const data = await response.json();
                const results = data.data.original.results;
                setColores(results.map(color => ({
                    value: color.id,
                    label: color.nombre
                })));
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchColores();
    }, []);

    /**
     * radio buttons
     */

    const [radioValue, setRadioValue] = useState('montoFijoRegular');


    const handleRadioChange = (e) => {
        const value = e.target.value;
        setRadioValue(value);
        handleOptionChange("modificacion", value);
    };

    const handleOptionChange = (name, value) => {
        setSelectedOptions(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async () => {

        const notify = (text) => toast(text);

        if (!Object.values(checkboxStates).some(checked => checked)) {
            notify("Debe seleccionar al menos 1 producto a aplicar para continuar");
            return false;
        }

        try {

            const selectedOptionsModificacion = { modificacion: radioValue };

            const requestBody = {

                //Filtros de productos a aplicar:
                marcaCheckbox: checkboxStates.marca,
                marca: selectedOptions.marca,
                tipoCheckbox: checkboxStates.tipo,
                tipo: selectedOptions.tipo,
                colorCheckbox: checkboxStates.color,
                color: selectedOptions.color,
                destacadoCheckbox: checkboxStates.destacado,
                destacado: selectedOptions.optionsDestacado,
                estucheCheckbox: checkboxStates.estuche,
                estuche: selectedOptions.estuche,
                stockCheckbox: checkboxStates.stock,
                stockDesde: inputs.stockDesde,
                stockHasta: inputs.stockHasta,
                precioCheckbox: checkboxStates.precio,
                precioDesde: inputs.precioDesde,
                precioHasta: inputs.precioHasta,
                suspendidoCheckbox: checkboxStates.suspendido,
                suspendido: selectedOptions.optionsSuspendido,


                //Modificación:
                modificacion: selectedOptionsModificacion.modificacion,
                montoFijo: inputs.montoFijo,
                montoFijoRegular: inputs.montoFijoRegular,
                descuentoAumentoFijo: inputs.descuentoAumentoFijo,
                descuentoAumentoPorcentual: inputs.descuentoAumentoPorcentual,
                costo: inputs.costo,
                estucheModificacion: selectedOptions.estucheModificacion,
                optionsSuspendidoModificado: selectedOptions.optionsSuspendidoModificado,
                stockModificacionDesde: inputs.stockModificacionDesde,
                stockModificacionHasta: inputs.stockModificacionHasta,


            };

            const response = await fetch(`${initialENDPOINT}/descuentos/generales`, {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });
            notify("La edicion general de productos fue exitosa!");
            history(`${process.env.PUBLIC_URL}/productos`);
        } catch (error) {
            console.error("Error:", error);
        }
    };

    return (

        <Container fluid={true}>
            <Breadcrumbs mainTitle="Edición General de Productos" parent="EdicionGralProductos" title="Editar" />

            <Row className="bg-white mb-3 p-4 pb-2 border rounded m-5">

                <Form.Group >
                    <Form.Label>Filtros de productos a aplicar:</Form.Label>
                    <div className="container mt-2">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-check">

                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={checkboxStates.marca}
                                        onChange={handleCheckboxChange}
                                        name="marca"
                                    />
                                    <label className="form-check-label ml-2">Marca:</label>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <select
                                    name="marca"
                                    className="form-select"
                                    value={selectedOptions.marca}
                                    onChange={handleSelectChange}
                                >
                                    <option value="">Seleccione...</option>
                                    {marcas.map((marca) => (
                                        <option key={marca.value} value={marca.value}>
                                            {marca.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="container mt-2">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-check">

                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={checkboxStates.tipo}
                                        onChange={handleCheckboxChange}
                                        name="tipo"
                                    />
                                    <label className="form-check-label ml-2">Tipo:</label>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <select
                                    name="tipo"
                                    className="form-select"
                                    value={selectedOptions.tipo}
                                    onChange={handleSelectChange}
                                >
                                    <option value="">Seleccione...</option>
                                    {tipos.map((tipo) => (
                                        <option key={tipo.value} value={tipo.value}>
                                            {tipo.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="container mt-2">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-check">

                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={checkboxStates.color}
                                        onChange={handleCheckboxChange}
                                        name="color"
                                    />
                                    <label className="form-check-label ml-2">Color:</label>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <select
                                    name="color"
                                    className="form-select"
                                    value={selectedOptions.color}
                                    onChange={handleSelectChange}
                                >
                                    <option value="">Seleccione...</option>
                                    {colores.map((color) => (
                                        <option key={color.value} value={color.value}>
                                            {color.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="container mt-2">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={checkboxStates.destacado}
                                        onChange={handleCheckboxChange}
                                        name="destacado"
                                    />
                                    <label className="form-check-label ml-2">Destacado:</label>
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="optionsDestacado"
                                        id="opcionSiDestacado"
                                        checked={selectedOptions.optionsDestacado === 1}
                                        onChange={() => handleOptionChange('optionsDestacado', 1)}
                                    />
                                    <label className="form-check-label" htmlFor="opcionSiDestacado">Sí</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="optionsDestacado"
                                        id="opcionNoDestacado"
                                        checked={selectedOptions.optionsDestacado === 0}
                                        onChange={() => handleOptionChange('optionsDestacado', 0)}
                                    />
                                    <label className="form-check-label" htmlFor="opcionNoDestacado">No</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container mt-2">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-check">

                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={checkboxStates.estuche}
                                        onChange={handleCheckboxChange}
                                        name="estuche"
                                    />
                                    <label className="form-check-label ml-2">Estuche:</label>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <select
                                    name="estuche"
                                    className="form-select"
                                    value={selectedOptions.estuche}
                                    onChange={handleSelectChange}
                                >
                                    <option value="">Seleccione...</option>
                                    <option value="1">Si</option>
                                    <option value="0">No</option>
                                    <option value="2">Si Genérico</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="container mt-2">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-check">

                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={checkboxStates.stock}
                                        onChange={handleCheckboxChange}
                                        name="stock"
                                    />
                                    <label className="form-check-label ml-2">Stock:</label>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="input-group mb-3">
                                    <span className="input-group-text" id="inputGroupPrepend1">Desde</span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={inputs.stockDesde}
                                        onChange={(e) => handleInputChange('stockDesde', e.target.value)}
                                    />
                                    <span className="input-group-text" id="inputGroupPrepend2">Hasta</span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={inputs.stockHasta}
                                        onChange={(e) => handleInputChange('stockHasta', e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container mt-1">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-check">

                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={checkboxStates.precio}
                                        onChange={handleCheckboxChange}
                                        name="precio"
                                    />
                                    <label className="form-check-label ml-2">Precio:</label>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="input-group mb-3">
                                    <span className="input-group-text" id="inputGroupPrepend1">Desde</span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={inputs.precioDesde}
                                        onChange={(e) => handleInputChange('precioDesde', e.target.value)}
                                    />
                                    <span className="input-group-text" id="inputGroupPrepend2">Hasta</span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={inputs.precioHasta}
                                        onChange={(e) => handleInputChange('precioHasta', e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container mt-2">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={checkboxStates.suspendido}
                                        onChange={handleCheckboxChange}
                                        name="suspendido"
                                    />
                                    <label className="form-check-label ml-2">Suspendido:</label>
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="optionsSuspendido"
                                        id="opcionSiSuspendido"
                                        checked={selectedOptions.optionsSuspendido === 1}
                                        onChange={() => handleOptionChange('optionsSuspendido', 1)}
                                    />
                                    <label className="form-check-label" htmlFor="opcionSiSuspendido">Sí</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="optionsSuspendido"
                                        id="opcionNoSuspendido"
                                        checked={selectedOptions.optionsSuspendido === 0}
                                        onChange={() => handleOptionChange('optionsSuspendido', 0)}
                                    />
                                    <label className="form-check-label" htmlFor="opcionNoSuspendido">No</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form.Group>
            </Row>
            <br />
            <Row className="bg-white mb-3 p-4 pb-2 border rounded m-5">

                <Form.Group >

                    <Form.Label>Modificación:</Form.Label>
                    <div className="container mt-4">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="modificacion"
                                        onChange={handleRadioChange}
                                        checked={radioValue === 'montoFijoRegular'}
                                        value="montoFijoRegular"
                                    />
                                    <label className="form-check-label ml-2">Monto Fijo en Precio Regular</label>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={inputs.montoFijoRegular}
                                    onChange={(e) => handleInputChange('montoFijoRegular', e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    {/* <div className="container mt-4">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="modificacion"
                                        onChange={handleRadioChange}
                                        checked={radioValue === 'descuentoAumentoFijoRegular'}
                                        value="descuentoAumentoFijoRegular"
                                    />
                                    <label className="form-check-label ml-2">Descuento Fijo en Precio Regular:</label>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={inputs.descuentoAumentoFijoRegular}
                                    onChange={(e) => handleInputChange('descuentoAumentoFijoRegular', e.target.value)}
                                />
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="container mt-4">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="modificacion"
                                        onChange={handleRadioChange}
                                        checked={radioValue === 'descuentoAumentoPorcentualRegular'}
                                        value="descuentoAumentoPorcentualRegular"
                                    />
                                    <label className="form-check-label ml-2">Descuento % en Precio Regular:</label>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={inputs.descuentoAumentoPorcentualRegular}
                                    onChange={(e) => handleInputChange('descuentoAumentoPorcentualRegular', e.target.value)}
                                />
                            </div>
                        </div>
                    </div> */}
                    <div className="container mt-4">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="modificacion"
                                        onChange={handleRadioChange}
                                        checked={radioValue === 'montoFijo'}
                                        value="montoFijo"
                                    />
                                    <label className="form-check-label ml-2">Monto Fijo <strong>Promocional:</strong></label>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={inputs.montoFijo}
                                    onChange={(e) => handleInputChange('montoFijo', e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="container mt-4">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="modificacion"
                                        onChange={handleRadioChange}
                                        checked={radioValue === 'descuentoAumentoFijo'}
                                        value="descuentoAumentoFijo"
                                    />
                                    <label className="form-check-label ml-2">Descuento Fijo en precio <strong>promocional</strong>:</label>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={inputs.descuentoAumentoFijo}
                                    onChange={(e) => handleInputChange('descuentoAumentoFijo', e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="container mt-4">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="modificacion"
                                        onChange={handleRadioChange}
                                        checked={radioValue === 'descuentoAumentoPorcentual'}
                                        value="descuentoAumentoPorcentual"
                                    />
                                    <label className="form-check-label ml-2">Descuento % en precio <strong>promocional</strong>:</label>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={inputs.descuentoAumentoPorcentual}
                                    onChange={(e) => handleInputChange('descuentoAumentoPorcentual', e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="container mt-4">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="modificacion"
                                        onChange={handleRadioChange}
                                        checked={radioValue === 'costo'}
                                        value="costo"
                                    />
                                    <label className="form-check-label ml-2">Costo:</label>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={inputs.costo}
                                    onChange={(e) => handleInputChange('costo', e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="container mt-4">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="modificacion"
                                        onChange={handleRadioChange}
                                        checked={radioValue === 'estuche'}
                                        value="estuche"
                                    />
                                    <label className="form-check-label ml-2">Estuche:</label>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <select
                                    name="estucheModificacion"
                                    className="form-select"
                                    value={selectedOptions.estucheModificacion}
                                    onChange={handleSelectChange}
                                >
                                    <option value="">Seleccione...</option>
                                    <option value="1">Si</option>
                                    <option value="0">No</option>
                                    <option value="2">Si Genérico</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="container mt-3">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="modificacion"
                                        onChange={handleRadioChange}
                                        checked={radioValue === 'suspendidoModificacion'}
                                        value="suspendidoModificacion"
                                    />
                                    <label className="form-check-label ml-2">Suspendido:</label>
                                </div>
                            </div>

                            <div className="col-md-8">
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="optionsSuspendidoModificado"
                                        id="opcionSiSuspendidoModificado"
                                        checked={selectedOptions.optionsSuspendidoModificado === 1}
                                        onChange={() => handleOptionChange('optionsSuspendidoModificado', 1)}
                                    />
                                    <label className="form-check-label" htmlFor="opcionSiSuspendidoModificado">Sí</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="optionsSuspendidoModificado"
                                        id="opcionNoSuspendidoModificado"
                                        checked={selectedOptions.optionsSuspendidoModificado === 0}
                                        onChange={() => handleOptionChange('optionsSuspendidoModificado', 0)}
                                    />
                                    <label className="form-check-label" htmlFor="opcionNoSuspendidoModificado">No</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form.Group>
                <CambiarGeneralesModal
                    show={modalOpen}
                    onHide={() => setModalOpen(false)}
                    title="¿Seguro que realizar esta cambio?"
                >
                    <Row>
                        <div style={{ textAlign: 'center' }}>
                            <p>Se trata de un cambio masivo irreversible. ¿desea continuar?</p>
                        </div>
                        <br /> <br />
                        <div className="d-flex align-items-end justify-content-center">
                            <Button
                                type="submit"
                                className="mt-auto mb-2 col-4 me-4"
                                onClick={handleSubmit}
                            >
                                Continuar
                            </Button>
                            <Button
                                variant="danger"
                                onClick={() => setModalOpen(false)}
                                className="mt-auto mb-2 col-4"
                            >
                                Cancelar
                            </Button>
                        </div>
                    </Row>

                </CambiarGeneralesModal>

            </Row>
            <div className="d-flex justify-content-end">
                <Button
                    onClick={handleModal}
                    className="mt-2 mt-md-4 col-2 col-lg-2"
                >
                    Generar cambios
                </Button>
            </div>
            <br />
        </Container >
    );
};

export default EdicionGralProductos;
