import { Container } from 'react-bootstrap'
import { PuffLoader } from 'react-spinners'

const Loader = () => {
  return (
    <Container className='my-5 d-flex justify-content-center'>
        <PuffLoader color="#392EB8" />
    </Container>
  )
}

export default Loader