import { useContext, useState, useRef } from "react";
import {
  Form,
  Row,
  Button,
  Col,
  Accordion,
  Nav,
  Tab,
  InputGroup,
  Container,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Breadcrumbs } from "../../../../AbstractElements";
import ReactQuill from "react-quill";
import DataContext from "../../../../_helper/DataContext";
import ImgProduct from "./ImgProduct";
import TreeSelect from "./TreeSelect";
import axios from "axios";
import "react-quill/dist/quill.snow.css";

const NewProduct = () => {
  const initialENDPOINT = process.env.REACT_APP_ENDPOINT;
  const [formData, setFormData] = useState({});
  const [descripcionLarga, setDescripcionLarga] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  const { dataGlobal } = useContext(DataContext);
  const [emptyTipoProducto, setEmptyTipoProducto] = useState();
  const [emptyMarcaProducto, setEmptyMarcaProducto] = useState();
  const [emptyColorProducto, setEmptyColorProducto] = useState();
  const [emptyEstucheProducto, setEmptyEstucheProducto] = useState();
  const [defaultActiveKeyAccordion, setDefaultActiveKeyAccordion] = useState(
    "3"
  );
  const [emptyTamanoProducto, setEmptyTamanoProducto] = useState();
  const [tabEmptyField, setTabEmptyField] = useState("1");
  const refTamanoDeProducto = useRef();

  const history = useNavigate();
  const token = localStorage.getItem("token");
  const notify = () => toast("¡Producto creado!");

  const sendForm = (e) => {
    e.preventDefault();
    if (!formData.tipoDeProducto) {
      setEmptyTipoProducto(true);
      setTabEmptyField("3");
    } else if (!formData.marca) {
      setEmptyMarcaProducto(true);
      setTabEmptyField("3");
    } else if (!formData.colorPrimario) {
      setEmptyColorProducto(true);
      setTabEmptyField("3");
    } else if (!formData.estuche) {
      setEmptyEstucheProducto(true);
      setTabEmptyField("3");
    } else if (!formData.tamano || formData.tamano === "") {
      setTabEmptyField("3");
    }
    else {
      sendData();
      notify();
      //history(`${process.env.PUBLIC_URL}/productos`); //linea modificada
    }
  };

  const handleChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    // console.log("name", name);
    if (name === "tipoDeProducto") setEmptyTipoProducto(false);
    if (name === "marca") setEmptyMarcaProducto(false);
    if (name === "colorPrimario") setEmptyColorProducto(false);
    if (name === "estuche") setEmptyEstucheProducto(false);
    if (name === "tamano") {
      setEmptyTamanoProducto(false);
    }

    setFormData((prevalue) => {
      return {
        ...prevalue,
        [name]: value,
      };
    });
  };

  const handleChangeDescription = (value) => {
    setDescripcionLarga(value);
  };

  const sendData = async () => {
    if (!formData.tipoDeProducto) {
      setEmptyTipoProducto(true);
      // setDefaultActiveKeyAccordion("3");
    } else if (!formData.marca) {
      setEmptyMarcaProducto(true);
      // setDefaultActiveKeyAccordion("3");
    } else if (!formData.colorPrimario) {
      setEmptyColorProducto(true);
      // setDefaultActiveKeyAccordion("3");
    } else if (!formData.tamano) {
      setEmptyTamanoProducto(true);
      // setDefaultActiveKeyAccordion("3");
    } else if (!formData.estuche) {
      setEmptyEstucheProducto(true);
      // setDefaultActiveKeyAccordion("3");
    } else {
      const dataToSend = new FormData();
      dataToSend.append("nombre", formData.nombre || "");
      dataToSend.append("descripcion", formData.descripcionCorta || "-");
      dataToSend.append("tipo", formData.tipoDeProducto || 0);
      dataToSend.append("categoria", 0);
      dataToSend.append("marca", formData.marca || 0);
      dataToSend.append("material", formData.material || 0);
      dataToSend.append("estuche", formData.estuche || 0);
      dataToSend.append("sexo", formData.sexo || 0);
      dataToSend.append("proveedor", formData.proveedor || 0);
      dataToSend.append("precio", formData.precio || 0);
      dataToSend.append("suspendido", formData.suspendido || "0");
      dataToSend.append("comision", formData.comision || "0");
      dataToSend.append("stock", formData.stock || 0);
      dataToSend.append("stockMinimo", formData.stockMinimo || 0);
      dataToSend.append("codigo", formData.codigo || "-");
      dataToSend.append(
        "alarmaStockMinimo",
        formData.alarmaStockMinimo === "on" ? "1" : "0"
      );
      dataToSend.append("color", formData.color || 0);
      dataToSend.append("tamano", formData.tamano || 0);
      dataToSend.append(
        "ubicacion",
        formData.ubicacionEnDeposito || "Sin ubicación"
      );
      dataToSend.append("grupo", formData.grupo || 0);
      dataToSend.append("pagina", formData.pagina || 0);
      dataToSend.append("costo", formData.costo || 0);
      dataToSend.append("bestBrasil", formData.bestBrasil || 0);
      dataToSend.append("posicion", formData.posicion || 0);
      dataToSend.append("stockRoto", formData.stockDanado || 0);

      //  formatear la fecha
      const opcionesFecha = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      };
      let newDate = new Date();
      newDate = newDate
        .toLocaleDateString("es-ES", opcionesFecha)
        .replace(/\//g, "");
      //

      dataToSend.append(
        "ultimoIngresoDeMercaderia",
        formData.ultimoIngresoDeMercaderia || newDate
      );

      dataToSend.append(
        "ultimaVentaDeMercaderia",
        formData.ultimaVentaDeMercaderia || newDate
      );

      dataToSend.append("genero", formData.genero || 0);

      /////////////////////////////////////////////////////////////////////
      // dataToSend.append("imagenPrincipal", formData.imagenPrincipal || 0);

      dataToSend.append("UPCreal", formData.upc || "");
      dataToSend.append("mdoNet", formData.mdo === "on" ? "1" : "0");
      dataToSend.append("jet", formData.jet === "on" ? "1" : "0");

      ///////////////////////////////////////////////////////////////////////
      dataToSend.append("precioJet", formData.precioJet || 0);
      dataToSend.append("stockJet", formData.stockJet || 0);
      dataToSend.append("multipack", formData.multipack || 0);
      dataToSend.append("nodeJet", formData.nodeJet || "");
      dataToSend.append("nombreEN", formData.nombreEN || "");
      dataToSend.append("descripcionEN", formData.descripcionEN || "");

      dataToSend.append("peso", formData.peso || 0);

      ///////////////////////////////////////////////////////////////////////
      dataToSend.append("enviadoAJet", formData.enviadoAJet || 0);
      dataToSend.append("stockFalabella", formData.stockFalabella || 0);
      dataToSend.append("precioFalabella", formData.precioFalabella || 0);

      dataToSend.append(
        "verEnFalabella",
        formData.falabella === "on" ? "1" : "0"
      );

      //////////////////////////////////////////////////////////////////////////////
      dataToSend.append("enviadoAFalabella", formData.enviadoAFalabella || 0);

      dataToSend.append("categoriaFalabella", formData.categoriaFalabella || 0);

      //--------------------------------------------------------------------------
      dataToSend.append("marcaFalabella", formData.marcaFalabella || 0);
      dataToSend.append(
        "descripcionFalabella",
        formData.descripcionFalabella || 0
      );

      dataToSend.append("precioPromocional", formData.precioPromocional || 0);
      dataToSend.append("destacado", formData.destacado === "on" ? "1" : "0");
      dataToSend.append("nuevo", formData.nuevo === "on" ? "1" : "0");
      dataToSend.append("largo", formData.largo || 0);
      dataToSend.append("alto", formData.alto || 0);
      dataToSend.append("ancho", formData.ancho || 0);
      dataToSend.append("descripcionLarga", descripcionLarga || "");
      dataToSend.append("colorPrincipal", formData.colorPrincipal || 0);
      dataToSend.append("colorSecundario", formData.colorSecundario || 0);

      for (var i = 0; i < formData.imagenes?.length; i++) {
        dataToSend.append("images[]", formData.imagenesParaEnviar[i]);
      }

      // try {
      //   const response = await axios({
      //     url: `${initialENDPOINT}/producto`,
      //     method: "POST",
      //     headers: {
      //       Authorization: `Bearer ${token}`,
      //       "Content-Type": "multipart/form-data",
      //     },
      //     data: dataToSend,
      //     redirect: "follow",
      //   });
      //   notify();
      //   history(`${process.env.PUBLIC_URL}/productos`);

      // } catch (error) {
      //   console.log(error);
      // }

      try {
        const response = await axios({
          url: `${initialENDPOINT}/producto`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
          data: dataToSend,
          redirect: "follow",
          //timeout: 5000,
        });
        notify();
        history(`${process.env.PUBLIC_URL}/productos`);
      
      } catch (error) {
        if (error.response) {
          // El servidor respondió con un estado fuera del rango de 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // La solicitud se realizó pero no se recibió ninguna respuesta
          console.log(error.request);
        } else {
          // Algo sucedió en la configuración de la solicitud que provocó un error
          console.log('Error', error.message);
        }
        console.log(error.config);
      }

    }
  };

  const logTimeoutError = async (error, usuario, fecha) => {
    const logData = {
        error: error,
        usuario: usuario,
        fecha: fecha
    };

    try {
        // Realizar la solicitud POST a la API con los datos de registro
        await axios.post('http://cbx.com.ar/mdoLogs/', logData);
        console.log('Error de timeout registrado con éxito');
    } catch (err) {
        console.error('Error al registrar el error de timeout:', err);
    }
};

  return (
    <Container fluid={true}>
      <Breadcrumbs
        mainTitle="Nuevo producto"
        parent="Productos"
        title="Nuevo producto"
      />
      <Row>
        <Col xl="12" className="des-xl-100 box-col-12">
          <Form onSubmit={sendForm}>
            <Row>
              <Col className="col-9">
                <Row>
                  {/* Nombre y descricion corta */}
                  <Form.Group className="col-12 bg-white mb-3 p-4 pb-2 border rounded">
                    <Form.Label>Nombre</Form.Label>
                    <Form.Control
                      type="text"
                      name="nombre"
                      placeholder="Ingresa el nombre"
                      onChange={handleChange}
                      className="mb-3"
                      required
                    />
                    <Form.Label>
                      Descripcion corta (Detalle del producto en la tienda)
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      name="descripcionCorta"
                      onChange={handleChange}
                      rows={3}
                      className="mb-3"
                    />
                  </Form.Group>
                  {/* Descripcion larga */}
                  <Form.Group className="col-12 mb-3 p-0">
                    <Accordion>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          Descripcion larga (Descripción del producto en la
                          tienda)
                        </Accordion.Header>
                        <Accordion.Body>
                          <ReactQuill
                            value={descripcionLarga}
                            onChange={handleChangeDescription}
                            modules={{ toolbar: true }}
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Form.Group>
                  {/* Datos del producto */}
                  <Form.Group className="col-12 mb-3 p-0">
                    {/* <Accordion defaultActiveKey={defaultActiveKeyAccordion}> */}

                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>Datos del producto</Accordion.Header>
                        <Accordion.Body className="p-0">
                          <Tab.Container
                            id="left-tabs-example"
                            //  {/* ---------------------------señala la pestaña por defecto------------------------------------------------ */}
                            defaultActiveKey={tabEmptyField}
                            // defaultActiveKey={"2"}
                          >
                            <Row>
                              <Col
                                sm={3}
                                className="border-end border-bottom-0 pe-0"
                              >
                                <Nav variant="tabs" className="flex-column">
                                  <Nav.Item>
                                    <Nav.Link eventKey="1">General</Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item>
                                    <Nav.Link eventKey="2">Inventario</Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item>
                                    <Nav.Link eventKey="3">
                                      Características
                                    </Nav.Link>
                                  </Nav.Item>
                                  {/* <Nav.Item>
                                    <Nav.Link eventKey="4">Envio</Nav.Link>
                                  </Nav.Item> */}
                                  {/* <Nav.Item>
                                    <Nav.Link eventKey="5">Tiendas</Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item>
                                    <Nav.Link eventKey="6">Falabella</Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item>
                                    <Nav.Link eventKey="7">JET</Nav.Link>
                                  </Nav.Item> */}
                                </Nav>
                              </Col>
                              <Col sm={9}>
                                <Tab.Content>
                                  <Tab.Pane eventKey="1" className="py-3">
                                    <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Col className="col-3">
                                        <Form.Label>Precio de lista</Form.Label>
                                      </Col>
                                      <Col className="col-6">
                                        <InputGroup className="col-10 mb-3">
                                          <InputGroup.Text>$</InputGroup.Text>
                                          <Form.Control
                                            type="number"
                                            step="any"
                                            name="precio"
                                            onChange={handleChange}
                                            required
                                          />
                                        </InputGroup>
                                      </Col>
                                    </Form.Group>
                                    <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Col className="col-3">
                                        <Form.Label>
                                          Precio de promoción
                                        </Form.Label>
                                      </Col>
                                      <Col className="col-6">
                                        <InputGroup className="mb-3">
                                          <InputGroup.Text>$</InputGroup.Text>
                                          <Form.Control
                                            type="number"
                                            step="any"
                                            name="precioPromocional"
                                            onChange={handleChange}
                                          />
                                        </InputGroup>
                                      </Col>
                                    </Form.Group>
                                    <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Col className="col-3">
                                        <Form.Label>Costo</Form.Label>
                                      </Col>
                                      <Col className="col-6">
                                        <InputGroup className="mb-3">
                                          <InputGroup.Text>$</InputGroup.Text>
                                          <Form.Control
                                            type="number"
                                            step="any"
                                            name="costo"
                                            onChange={handleChange}
                                          />
                                        </InputGroup>
                                      </Col>
                                    </Form.Group>
                                    <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Col className="col-3">
                                        <Form.Label>Posición en web</Form.Label>
                                      </Col>
                                      <Col className="col-6">
                                        <Form.Control
                                          type="number"
                                          name="posicionEnWeb"
                                          placeholder="Ingresa la posición en web"
                                          onChange={handleChange}
                                          className="mb-3"
                                        />
                                      </Col>
                                    </Form.Group>
                                    <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      {/* <Col className="col-3">
                                  <Form.Label>Destacado</Form.Label>
                                </Col> */}
                                      <Col className="col-2">
                                        <Form.Check
                                          type="checkbox"
                                          id="destacado"
                                          name="destacado"
                                          label="Destacado"
                                          onChange={handleChange}
                                          className="mb-3"
                                        />
                                      </Col>
                                      <Col className="col-6">
                                        <Form.Check
                                          type="checkbox"
                                          id="nuevo"
                                          name="nuevo"
                                          label="Nuevo"
                                          // checked={formData.nuevo}
                                          onChange={handleChange}
                                          className="mb-3"
                                        />
                                      </Col>
                                    </Form.Group>
                                  </Tab.Pane>

                                  <Tab.Pane eventKey="2" className="py-3">
                                    <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Col className="col-3">
                                        <Form.Label>UPC</Form.Label>
                                      </Col>
                                      <Col className="col-6">
                                        <Form.Control
                                          type="text"
                                          name="upc"
                                          placeholder="Ingresa el UPC"
                                          onChange={handleChange}
                                          className="mb-3"
                                        />
                                      </Col>
                                    </Form.Group>
                                    <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Col className="col-3">
                                        <Form.Label>Código</Form.Label>
                                      </Col>
                                      <Col className="col-6">
                                        <Form.Control
                                          type="text"
                                          name="codigo"
                                          placeholder="Ingresa el código"
                                          onChange={handleChange}
                                          className="mb-3"
                                        />
                                      </Col>
                                    </Form.Group>
                                    <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Col className="col-3">
                                        <Form.Label>Stock</Form.Label>
                                      </Col>
                                      <Col className="col-6">
                                        <Form.Control
                                          type="number"
                                          name="stock"
                                          placeholder="Ingresa la cantidad de stock"
                                          onChange={handleChange}
                                          className="mb-3"
                                        />
                                      </Col>
                                    </Form.Group>
                                    <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Col className="col-3">
                                        <Form.Label>Stock dañado</Form.Label>
                                      </Col>
                                      <Col className="col-6">
                                        <Form.Control
                                          type="number"
                                          name="stockDanado"
                                          placeholder="Ingresa la cantidad de stock dañado"
                                          onChange={handleChange}
                                          className="mb-3"
                                        />
                                      </Col>
                                    </Form.Group>
                                    <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Col className="col-3">
                                        <Form.Label>Stock minimo</Form.Label>
                                      </Col>
                                      <Col className="col-6">
                                        <Form.Control
                                          type="number"
                                          name="stockMinimo"
                                          placeholder="Ingresa la cantidad de stock minimo"
                                          onChange={handleChange}
                                          className="mb-3"
                                        />
                                      </Col>
                                    </Form.Group>
                                    <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Col className="col-12">
                                        <Form.Check
                                          type="checkbox"
                                          id="alarmaStockMinimo"
                                          name="alarmaStockMinimo"
                                          label="Activar alarma para stock mínimo"
                                          onChange={handleChange}
                                          className="mb-3"
                                        />
                                      </Col>
                                    </Form.Group>
                                  </Tab.Pane>

                                  <Tab.Pane eventKey="3" className="py-3">
                                    <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                      id="tipoDeProducto"
                                    >
                                      <Col className="col-3">
                                        <Form.Label>
                                          Tipo de producto
                                        </Form.Label>
                                      </Col>
                                      <Col className="col-6">
                                        <Form.Select
                                          defaultValue={"SELECCIONE UNA OPCIÓN"}
                                          id="tipoDeProducto"
                                          name="tipoDeProducto"
                                          onChange={handleChange}
                                          // className={`mb-3 is-invalid`}
                                          className={
                                            emptyTipoProducto
                                              ? `mb-3 is-invalid`
                                              : "mb-3"
                                          }
                                        >
                                          <option disabled>
                                            SELECCIONE UNA OPCIÓN
                                          </option>
                                          {dataGlobal.tiposProducto?.map(
                                            (item, i) => (
                                              <option key={i} value={item.id}>
                                                {item.nombre}
                                              </option>
                                            )
                                          )}
                                        </Form.Select>
                                      </Col>
                                    </Form.Group>
                                    {/* <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Col className="col-3">
                                        <Form.Label>Categoría</Form.Label>
                                      </Col>
                                      <Col className="col-6">
                                        <Form.Select
                                          defaultValue={"SELECCIONE UNA OPCIÓN"}
                                          name="categoria"
                                          onChange={handleChange}
                                          className="mb-3"
                                        >
                                          <option disabled>
                                            SELECCIONE UNA OPCIÓN
                                          </option>
                                          {dataGlobal.categoria?.map(
                                            (item, i) => (
                                              <option key={i} value={item.id}>
                                                {item.nombre}
                                              </option>
                                            )
                                          )}
                                        </Form.Select>
                                      </Col>
                                    </Form.Group> */}
                                    <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Col className="col-3">
                                        <Form.Label>Tamaño</Form.Label>
                                      </Col>
                                      <Col className="col-6">
                                        <Form.Control
                                          type="text"
                                          name="tamano"
                                          href={refTamanoDeProducto}
                                          placeholder="Ingresa el tamaño"
                                          onChange={handleChange}
                                          className="mb-3"
                                          required
                                        />
                                      </Col>
                                    </Form.Group>
                                    {/* <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Col className="col-3">
                                        <Form.Label>Grupo web</Form.Label>
                                      </Col>
                                      <Col className="col-6">
                                        <Form.Select
                                          defaultValue={"SELECCIONE UNA OPCIÓN"}
                                          name="grupo"
                                          onChange={handleChange}
                                          className="mb-3"
                                        >
                                          <option disabled>
                                            SELECCIONE UNA OPCIÓN
                                          </option>
                                          {dataGlobal.grupo?.map((item, i) => (
                                            <option key={i} value={item.id}>
                                              {item.nombre}
                                            </option>
                                          ))}
                                        </Form.Select>
                                      </Col>
                                    </Form.Group> */}

                                    <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Col className="col-3">
                                        <Form.Label>Marca</Form.Label>
                                      </Col>
                                      <Col className="col-6">
                                        <Form.Select
                                          defaultValue={"SELECCIONE UNA OPCIÓN"}
                                          name="marca"
                                          onChange={handleChange}
                                          // className="mb-3"
                                          className={
                                            emptyMarcaProducto
                                              ? `mb-3 is-invalid`
                                              : "mb-3"
                                          }
                                        >
                                          <option disabled>
                                            SELECCIONE UNA OPCIÓN
                                          </option>
                                          {dataGlobal.marcas?.map((item, i) => (
                                            <option key={i} value={item.id}>
                                              {item.nombre}
                                            </option>
                                          ))}
                                        </Form.Select>
                                      </Col>
                                    </Form.Group>

                                    {/* <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Col className="col-3">
                                        <Form.Label>Material</Form.Label>
                                      </Col>
                                      <Col className="col-6">
                                        <Form.Select
                                          defaultValue={"SELECCIONE UNA OPCIÓN"}
                                          name="material"
                                          onChange={handleChange}
                                          className="mb-3"
                                        >
                                          <option disabled>
                                            SELECCIONE UNA OPCIÓN
                                          </option>
                                          {dataGlobal.materiales?.map(
                                            (item, i) => (
                                              <option key={i} value={item.id}>
                                                {item.nombre}
                                              </option>
                                            )
                                          )}
                                        </Form.Select>
                                      </Col>
                                    </Form.Group> */}

                                    <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Col className="col-3">
                                        <Form.Label>Material</Form.Label>
                                      </Col>
                                      <Col className="col-6">
                                        <Form.Control
                                          type="text"
                                          name="material"
                                          placeholder="Ingresa el material"
                                          onChange={handleChange}
                                          className="mb-3"
                                        />
                                      </Col>
                                    </Form.Group>

                                    <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Col className="col-3">
                                        <Form.Label>
                                          Color de fábrica
                                        </Form.Label>
                                      </Col>
                                      <Col className="col-6">
                                        <Form.Control
                                          type="text"
                                          name="color"
                                          placeholder="Ingresa el color"
                                          onChange={handleChange}
                                          className="mb-3"
                                        />
                                      </Col>
                                    </Form.Group>

                                    <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Col className="col-3">
                                        <Form.Label>Color</Form.Label>
                                      </Col>
                                      <Col className="col-6">
                                        <Form.Select
                                          defaultValue={"SELECCIONE UNA OPCIÓN"}
                                          name="colorPrimario"
                                          onChange={handleChange}
                                          className={
                                            emptyColorProducto
                                              ? `mb-3 is-invalid`
                                              : "mb-3"
                                          }
                                        >
                                          <option disabled>
                                            SELECCIONE UNA OPCIÓN
                                          </option>
                                          {dataGlobal.colores?.map(
                                            (item, i) => (
                                              <option key={i} value={item.id}>
                                                {item.nombre}
                                              </option>
                                            )
                                          )}
                                        </Form.Select>
                                      </Col>
                                    </Form.Group>
                                    {/* <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Col className="col-3">
                                        <Form.Label>
                                          Color secundario
                                        </Form.Label>
                                      </Col>
                                      <Col className="col-6">
                                        <Form.Select
                                          defaultValue={"SELECCIONE UNA OPCIÓN"}
                                          name="colorSecundario"
                                          onChange={handleChange}
                                          className="mb-3"
                                        >
                                          <option disabled>
                                            SELECCIONE UNA OPCIÓN
                                          </option>
                                          {dataGlobal.colores?.map(
                                            (item, i) => (
                                              <option key={i} value={item.id}>
                                                {item.nombre}
                                              </option>
                                            )
                                          )}
                                        </Form.Select>
                                      </Col>
                                    </Form.Group> */}

                                    {/* <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Col className="col-3">
                                        <Form.Label>Genero</Form.Label>
                                      </Col>
                                      <Col className="col-6">
                                        <Form.Select
                                          defaultValue={"SELECCIONE UNA OPCIÓN"}
                                          name="genero"
                                          onChange={handleChange}
                                          className="mb-3"
                                        >
                                          <option disabled>
                                            SELECCIONE UNA OPCIÓN
                                          </option>
                                          {dataGlobal.genero?.map((item, i) => (
                                            <option key={i} value={item.id}>
                                              {item.nombre}
                                            </option>
                                          ))}
                                        </Form.Select>
                                      </Col>
                                    </Form.Group> */}
                                    <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Col className="col-3">
                                        <Form.Label>Estuche</Form.Label>
                                      </Col>
                                      <Col className="col-6">
                                        <Form.Select
                                          defaultValue={"SELECCIONE UNA OPCIÓN"}
                                          name="estuche"
                                          onChange={handleChange}
                                          className={
                                            emptyEstucheProducto
                                              ? `mb-3 is-invalid`
                                              : "mb-3"
                                          }
                                        >
                                          <option disabled>
                                            SELECCIONE UNA OPCIÓN
                                          </option>
                                          {dataGlobal.estuche?.map(
                                            (item, i) => (
                                              <option key={i} value={item.id}>
                                                {item.nombre}
                                              </option>
                                            )
                                          )}
                                        </Form.Select>
                                      </Col>
                                    </Form.Group>
                                  </Tab.Pane>

                                  {/* <Tab.Pane eventKey="4" className="py-3">
                                    <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >                                     
                                    </Form.Group>
                                    <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Col className="col-3">
                                        <Form.Label>Peso (Kg)</Form.Label>
                                      </Col>
                                      <Col className="col-6">
                                        <Form.Control
                                          type="number"
                                          name="peso"
                                          placeholder="Ingresa el peso"
                                          onChange={handleChange}
                                          className="mb-3"
                                        />
                                      </Col>
                                    </Form.Group>
                                    <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Col className="col-3">
                                        <Form.Label>Tamaño (Cm)</Form.Label>
                                      </Col>
                                      <Col className="col-2">
                                        <Form.Control
                                          type="number"
                                          name="largo"
                                          placeholder="Largo"
                                          onChange={handleChange}
                                          className="mb-3"
                                        />
                                      </Col>
                                      <Col className="col-2">
                                        <Form.Control
                                          type="number"
                                          name="ancho"
                                          placeholder="Ancho"
                                          onChange={handleChange}
                                          className="mb-3"
                                        />
                                      </Col>
                                      <Col className="col-2">
                                        <Form.Control
                                          type="number"
                                          name="alto"
                                          placeholder="Alto"
                                          onChange={handleChange}
                                          className="mb-3"
                                        />
                                      </Col>
                                    </Form.Group>
                                    <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Col className="col-3">
                                        <Form.Label>
                                          Ubicación en depósito
                                        </Form.Label>
                                      </Col>
                                      <Col className="col-6">
                                        <Form.Control
                                          type="text"
                                          name="ubicacionEnDeposito"
                                          placeholder="Ingresa la ubicación"
                                          onChange={handleChange}
                                          className="mb-3"
                                        />
                                      </Col>
                                    </Form.Group>
                                  </Tab.Pane> */}

                                  {/* <Tab.Pane eventKey="5" className="py-3">
                                    <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Col className="col-3">
                                        <Form.Label>Publicar en:</Form.Label>
                                      </Col>
                                    </Form.Group>
                                    <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Col className="col-6 ms-4">
                                        <Form.Check
                                          type="checkbox"
                                          id="mdo"
                                          name="mdo"
                                          label="mdo.net"
                                          onChange={handleChange}
                                        />
                                      </Col>
                                    </Form.Group>
                                    <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Col className="col-6 ms-4">
                                        <Form.Check
                                          type="checkbox"
                                          id="jet"
                                          name="jet"
                                          label="jet.com"
                                          onChange={handleChange}
                                        />
                                      </Col>
                                    </Form.Group>
                                    <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Col className="col-6 ms-4">
                                        <Form.Check
                                          type="checkbox"
                                          id="falabella"
                                          name="falabella"
                                          label="Falabella"
                                          onChange={handleChange}
                                          className="mb-3"
                                        />
                                      </Col>
                                    </Form.Group>
                                  </Tab.Pane> */}
                                  {/* <Tab.Pane eventKey="6" className="py-3">
                                    <TreeSelect
                                      formData={formData}
                                      setFormData={setFormData}
                                    />
                                  </Tab.Pane>
                                  <Tab.Pane eventKey="7" className="py-3">
                                    <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Col className="col-3">
                                        <Form.Label>Categoria JET</Form.Label>
                                      </Col>
                                      <Col className="col-6">
                                        <Form.Select
                                          defaultValue={"SELECCIONE UNA OPCIÓN"}
                                          name="categoriaJet"
                                          onChange={handleChange}
                                          className="mb-3"
                                        >
                                          <option disabled>
                                            SELECCIONE UNA OPCIÓN
                                          </option>
                                          
                                        </Form.Select>
                                      </Col>
                                    </Form.Group>
                                  </Tab.Pane> */}
                                </Tab.Content>
                              </Col>
                            </Row>
                          </Tab.Container>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Form.Group>
                  {/* Guardar */}
                  <Form.Group className="d-flex justify-content-end">
                    <Button
                      disabled={disableButton}
                      type="submit"
                      className="mt-2 mt-md-2 col-4 col-lg-2 "
                      required
                    >
                      Guardar
                    </Button>
                  </Form.Group>
                </Row>
              </Col>
              <Col className="col-3">
                <Row>
                  <Form.Group className="mb-3">
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>Publicar</Accordion.Header>
                        <Accordion.Body>
                          <Form.Label>Estado</Form.Label>
                          <Form.Select
                            defaultValue={"0"}
                            name="suspendido"
                            onChange={handleChange}
                            className="mb-3"
                          >
                            <option value="0">Publicado</option>
                            <option value="1">Suspendido</option>
                          </Form.Select>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          Galería del producto
                        </Accordion.Header>
                        <Accordion.Body>
                          <ImgProduct
                            formData={formData}
                            setFormData={setFormData}
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Form.Group>
                </Row>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default NewProduct;
