import DataTable from "react-data-table-component";
import Loader from "../../../../Common/Loader";
import Vacio from "../../../../Common/Vacio";
import axios from "axios";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { useEffect, useState } from "react";

const Totales = ({ handleChange, formData, setFormData }) => {
  const initialENDPOINT = process.env.REACT_APP_ENDPOINT;
  const [discount, setDiscount] = useState(true);
  const [ctaCte, setCtaCte] = useState(0);
  const [isMounted, setIsMounted] = useState(true);
  const token = localStorage.getItem("token");

  const queriesColumns = [
    {
      name: "Descripción",
      selector: (row) => row.descripcion,
      center: false,
      wrap: false,
    },
    // {
    //   name: "Cantidad",
    //   selector: (row) => row.cantidad,
    //   center: false,
    //   compact: true,
    //   wrap: false,
    // },
    {
      name: "Descuento Neto",
      selector: (row) => `USD ${row.montoDescuento}`,
      center: false,
      compact: true,
      wrap: false,
    },
  ];

  const subtotalDetalle = (array) => {
    return array?.reduce((acc, curr) => acc + curr.precio * curr.cantidad, 0);
  };

  const calcularTotales = () => {

    setFormData((prevFormData) => {
      const productos = prevFormData.detalle;
      const productosNN = prevFormData.detalleNN;
      const descuentosPromocion = prevFormData.descuentosPromocion; //array de promociones
      const descuento = prevFormData.descuentoPorcentual;
      const descuentoNeto = prevFormData.descuentoNeto;
      const totalEnvio = prevFormData.totalEnvio;
      const creditoCtaCte = ctaCte;
      const subtotalLista = parseFloat(subtotalDetalle(productos));
      const subtotalNN = parseFloat(subtotalDetalle(productosNN));

      // Subtotal
      const subtotal = subtotalLista + subtotalNN;

      // Descuento por promociones
      let descuentoPorPromociones = 0;
      if (descuentosPromocion !== undefined) {
        descuentoPorPromociones = descuentosPromocion.reduce(
          (acc, curr) => acc + parseFloat(curr.montoDescuento),
          0
        );
      }

      // Descuento por porcentaje
      const descuentoPorPorcentaje =
        (parseFloat(subtotal) * (parseFloat(descuento) / 100)).toFixed(2);

      // Subtotal con descuento
      const subtotalConDescuento = parseFloat(
        subtotal -
        subtotal * (descuento / 100) -
        descuentoNeto -
        descuentoPorPromociones
      ).toFixed(2);

      // Total del pedido
      const totalPedido =
        parseFloat(subtotalConDescuento) + parseFloat(totalEnvio) || parseFloat(subtotalConDescuento);
      // Total a abonar
      const total = parseFloat(totalPedido) + parseFloat(creditoCtaCte);
      return {
        ...prevFormData,
        subtotalLista,
        subtotalNN,
        subtotal,
        descuentoPorPromociones,
        subtotalConDescuento,
        descuentoPorPorcentaje,
        totalPedido: totalPedido.toFixed(2),
        total: total.toFixed(2),
      };
    });
  };

  const getCtaCte = async (userId) => {
    try {
      const res = await axios.get(`${initialENDPOINT}/cliente?id=${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      setCtaCte(res.data.results.ctacte);
    } catch (error) {
      setCtaCte(0);
    }
  };

  // Pedir descuentos
  const sendData = async () => {
    const dataToSend = JSON.stringify({
      detalle: formData.detalle,
    });

    try {
      const res = await axios({
        url: `${initialENDPOINT}/descuentos`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: dataToSend,
        redirect: "follow",
      });
      // Descuento por promociones
      const descuentoPorPromociones = res.data.reduce(
        (acc, curr) => acc + parseFloat(curr.montoDescuento),
        0
      );

      setFormData((prevalue) => {
        return {
          ...prevalue,
          descuentoPorPromociones, //descuentosPromocion
          discountData: res.data,
          descuentosPromocion: res.data
        };
      });

    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    isMounted &&
      formData.detalle &&
      formData.detalleNN &&
      (formData.detalle.length !== 0 || formData.detalleNN.length !== 0) &&
      calcularTotales();
  }, [
    formData.subtotal,
    formData.detalle,
    formData.detalleNN,
    formData.descuento,
    formData.descuentoPorPromociones,
    formData.descuentoPorcentual,
    formData.descuentoNeto,
    formData.totalEnvio,
    formData.creditoCtaCte,
    formData.discountData
  ]);

  useEffect(() => {
    formData.cliente !== undefined && getCtaCte(formData.cliente);
  }, [formData.cliente]);

  useEffect(() => {
    return () => {
      setIsMounted(false);
    };
  }, []);

  return (
    <Row className="bg-white mb-3 p-4 pb-2 border rounded m-5">
      <h6 className="py-3" onClick={calcularTotales}>
        Totales
      </h6>
      <Col xs={12} md={6} className="pe-5">
        <div className="d-flex justify-content-between align-items-end mb-4">
          <Form.Label className="m-0">Descuento por promociones</Form.Label>
          <Button onClick={sendData}>Calcular descuento</Button>
        </div>
        {discount && (
          <div className="my-3">
            <DataTable
              columns={queriesColumns}
              data={formData.descuentosPromocion}
              striped
              customStyles={{
                rows: {
                  highlightOnHoverStyle: {
                    backgroundColor: "rgb(230, 244, 244)",
                    borderBottomColor: "#FFFFFF",
                    outline: "1px solid #FFFFFF",
                  },
                },
                pagination: {
                  style: {
                    border: "none",
                  },
                },
              }}
              pointerOnHover
              highlightOnHover
              progressComponent={<Loader />}
              noDataComponent={<Vacio />}
              persistTableHead
            />
          </div>
        )}
        <Form.Group className="mb-3">
          <Form.Label>Observaciones</Form.Label>
          <Form.Control
            as="textarea"
            rows={8}
            name="observaciones"
            defaultValue={formData.observaciones}
            onChange={(e) => handleChange(e)}
          />
        </Form.Group>
      </Col>
      <Col xs={12} md={6}>
        <Form.Group className="mb-3 d-flex justify-content-between align-items-center">
          <Form.Label className="col-4">Subtotal</Form.Label>
          <div className="border-bottom col-4 me-5"></div>
          <div> $ </div>
          <Form.Control
            type="number"
            name="subtotal"
            value={formData.subtotal}
            className="col p-0 ps-3 fs-5 text-end"
            plaintext
            readOnly
          />
        </Form.Group>
        <Form.Group className="mb-3 d-flex justify-content-between align-items-center">
          <Form.Label className="col-4">Descuento por promociones</Form.Label>
          <div className="border-bottom col-4 me-5"></div>
          <div className="w-25 d-flex align-items-center">
            <div> $ </div>
            <Form.Control
              type="number"
              name="descuentoPorPromociones"
              value={formData.descuentoPorPromociones}
              className="col p-0 ps-3 fs-5 text-end"
              plaintext
              readOnly
            />
          </div>
        </Form.Group>
        <Form.Group className="mb-3 d-flex justify-content-between align-items-center">
          <Form.Label className="col-4 p-0 m-0">Descuento en %</Form.Label>
          <div className="col-4 me-5">
            <InputGroup>
              <InputGroup.Text>%</InputGroup.Text>
              <Form.Control
                type="number"
                name="descuentoPorcentual"
                defaultValue={formData.descuentoPorcentual}
                onChange={(e) => handleChange(e)}
                min="0"
              />
            </InputGroup>
          </div>
          <div className="w-25 d-flex align-items-center">
            <div> $ </div>
            <Form.Control
              type="number"
              name="descuentoPorPorcentaje"
              value={formData.descuentoPorPorcentaje}
              className="col p-0 ps-3 fs-5 text-end"
              plaintext
              readOnly
            />
          </div>
        </Form.Group>
        <Form.Group className="mb-3 d-flex justify-content-between align-items-end">
          <Form.Label className="col-4">Descuento neto</Form.Label>
          <div className="col-4 me-5">
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control
                type="number"
                name="descuentoNeto"
                value={formData.descuentoNeto}
                onChange={(e) => handleChange(e)}
                min="0"
              />
            </InputGroup>
          </div>
          <div className="w-25 d-flex align-items-center"></div>
        </Form.Group>
        <Form.Group className="mb-3 d-flex justify-content-between align-items-center">
          <Form.Label className="col-4">Subtotal con descuentos</Form.Label>
          <div className="border-bottom col-4 me-5"></div>
          <div> $ </div>

          <Form.Control
            type="number"
            name="subtotalConDescuento"
            value={formData.subtotalConDescuento}
            className="col p-0 ps-3 fs-5 text-end"
            plaintext
            readOnly
          />
        </Form.Group>
        <Form.Group className="mb-3 d-flex justify-content-between align-items-end">
          <Form.Label className="col-4">Costo de envío</Form.Label>
          <div className="col-4 me-5">
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control
                type="number"
                name="totalEnvio"
                value={formData.totalEnvio}
                onChange={(e) => handleChange(e)}
                min="0"
              />
            </InputGroup>
          </div>
          <div className="w-25 d-flex align-items-center"></div>
        </Form.Group>
        <Form.Group className="mb-3 d-flex justify-content-between align-items-center">
          <Form.Label className="col-4">Total pedido</Form.Label>
          <div className="border-bottom col-4 me-5"></div>
          <div> $ </div>
          <Form.Control
            type="number"
            name="totalPedido"
            value={formData.totalPedido}
            className="col p-0 ps-3 fs-5 text-end"
            plaintext
            readOnly
          />
        </Form.Group>
        <Form.Group className="mb-3 d-flex justify-content-between align-items-center">
          <Form.Label className="col-4">Crédito en Cta. Cte</Form.Label>
          <div className="border-bottom col-4 me-5"></div>
          <div> $ </div>
          <Form.Control
            type="number"
            name="ctaCte"
            value={ctaCte}
            className="col p-0 ps-3 fs-5 text-end"
            plaintext
            readOnly
            min="0"
          />
        </Form.Group>
        <Form.Group className="mb-3 d-flex justify-content-between align-items-center">
          <Form.Label className="col-4">Total a abonar</Form.Label>
          <div className="border-bottom col-4 me-5"></div>
          <div> $ </div>
          <Form.Control
            type="number"
            name="total"
            value={formData.total}
            className="col p-0 ps-3 fs-5 text-end"
            plaintext
            readOnly
            min="0"
          />
        </Form.Group>
      </Col>
    </Row>
  );
};

export default Totales;