import { useContext, useEffect, useState } from "react";
import {
  Form,
  Row,
  Button,
  Col,
  Accordion,
  Nav,
  Tab,
  InputGroup,
  Container,
} from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Breadcrumbs } from "../../../../AbstractElements";
import EditProductImages from "./EditProductImages";
import DataContext from "../../../../_helper/DataContext";
import ReactQuill from "react-quill";
import TreeSelect from "./TreeSelect";
import axios from "axios";
import "react-quill/dist/quill.snow.css";

const EditProduct = () => {

  const [disableButton, setDisableButton] = useState(false);
  const [descripcionLarga, setDescripcionLarga] = useState("");
  const [formData, setFormData] = useState({ imagenes: [] });
  const [isMounted, setIsMounted] = useState(true);
  const { productID } = useParams();
  const { dataGlobal } = useContext(DataContext);
  const token = localStorage.getItem("token");
  const initialENDPOINT = process.env.REACT_APP_ENDPOINT;
  const history = useNavigate();
  const notify = () => toast("¡Producto editado!");

  const handleChange = (e) => {
    let name = e.target.name;
    let value;
    if (name === "jet") {
      value = e.target.checked === true ? 1 : 0;
    } else {
      value = e.target.value;
    }

    if (e.target.type === "checkbox") {
      setFormData((prevalue) => {
        return {
          ...prevalue,
          [name]: e.target.checked,
        };
      });
    }else{
      setFormData((prevalue) => {
        return {
          ...prevalue,
          [name]: value,
        };
      });
    }
  };

  const handleChangeDescription = (value) => {
    setDescripcionLarga(value);
  };

  const getData = async () => {
    try {
      const res = await axios(`${initialENDPOINT}/producto/${productID}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        redirect: "follow",
      });
      isMounted && setFormData(res.data.data.message[0]);
      isMounted &&
        setDescripcionLarga(res.data.data.message[0]?.descripcionLarga  || '');
    } catch (error) {
      console.log(error);
    }
  };

  const sendData = async (e) => {
    e.preventDefault();
    setDisableButton(true); 
    // console.log("formData.suspendido", formData.suspendido);
    //////////////////////////////////////--------------------------------------------------------------
    const dataToSend = JSON.stringify({
      nombre: formData.nombre || "",
      descripcion: formData.descripcion || "",
      descripcionLarga:
      descripcionLarga !== "" ? descripcionLarga : "Sin descripción larga",
      precio: formData.precioLista || 0,
      precioPromocional: formData.precioPromocional || 0,
      costo: formData.costo || 0,
      posicion: formData.posicion || 0,
      destacado: formData.destacado || 0,
      nuevo: formData.nuevo === true ? "1" : "0",
      suspendido: formData.suspendido,
      // estado: formData.estado === "Suspendido" ? 1 : 0,
      UPCreal: formData.UPCreal || "",
      codigo: formData.codigo || 0,
      stock: formData.stock || 0,
      stockRoto: formData.stockRoto || 0,
      stockMinimo: formData.stockMinimo || 0,
      peso: formData.peso || 0,
      largo: formData.largo || 0,
      alto: formData.alto || 0,
      ancho: formData.ancho || 0,
      ubicacion: formData.ubicacion || "",
      tipo: formData.tipo || 0,
      mdoNet: formData.mdoNet || 0,
      categoriaFalabella: formData.categoriaFalabella || 0,
      verEnFalabella: formData.verEnFalabella || 0,
      marca: parseInt(formData.marca) || 0,
      imagenes: formData.imagenes || [],
      /////////////---------------------------------------////////////
      categoria: formData.categoria || 0,
      grupo: formData.grupo || 0,
      material: formData.material || "",
      color: formData.color || "",
      colorPrincipal: formData.colorPrincipal,
      colorSecundario: formData.colorSecundario,
      // colorPrincipal: formData.colorPrincipal || "",
      // colorSecundario: formData.colorSecundario || "",
      genero: formData.genero || 0,
      estuche: parseInt(formData.estuche) || 0,
      tamano: formData.tamano || 0,
      jet: formData.jet || 0,

      //FAX??
    });

    //////////////////////////////////////--------------------------------------------------------------
    // console.log("dataToSend.suspendido", dataToSend.suspendido);
    // console.log("formData.estuche", formData.estuche);

    //console.log('----> '+formData.material);return false;
    try {
      let res = await axios({
        url: `${initialENDPOINT}/producto/${productID}`,
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: dataToSend,
        redirect: "follow",
      });
      // console.log("res", res);
    } catch (error) {
      console.log(error);
    } finally {
      notify();
      history(`${process.env.PUBLIC_URL}/productos`);
    }
  };

  useEffect(() => {
    setFormData([]);
    getData();
    return () => {
      setIsMounted(false);
    };
  }, []);

  return (
    <Container fluid={true}>
      {/* <button onClick={getData}>getData</button> */}
      <Breadcrumbs
        mainTitle={formData.nombre || "Sin Nombre"}
        parent="Productos"
        title="Editar producto"
      />
      <Row>
        <Col xl="12" className="des-xl-100 box-col-12">
          <Form onSubmit={sendData}>
            <Row>
              <Col className="col-9">
                <Row>
                  {/* Nombre y descricion corta */}
                  <Form.Group className="col-12 bg-white mb-3 p-4 pb-2 border rounded">
                    <Form.Label>Nombre</Form.Label>
                    <Form.Control
                      type="text"
                      name="nombre"
                      placeholder="Ingresa el nombre"
                      defaultValue={formData.nombre}
                      onChange={handleChange}
                      className="mb-3"
                    />
                    <Form.Label>
                      Descripcion corta (Detalle del producto en la tienda)
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      name="descripcion"
                      defaultValue={formData.descripcion}
                      onChange={handleChange}
                      rows={3}
                      className="mb-3"
                    />
                  </Form.Group>
                  {/* Descripcion larga */}
                  <Form.Group className="col-12 mb-3 p-0">
                    <Accordion>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          Descripcion larga (Descripción del producto en la
                          tienda)
                        </Accordion.Header>
                        <Accordion.Body>
                          <ReactQuill
                            value={descripcionLarga}
                            onChange={handleChangeDescription}
                            modules={{ toolbar: true }}
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Form.Group>
                  {/* Datos del producto */}
                  <Form.Group className="col-12 mb-3 p-0">
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>Datos del producto</Accordion.Header>
                        <Accordion.Body className="p-0">
                          <Tab.Container
                            id="left-tabs-example"
                            defaultActiveKey="1"
                          >
                            <Row>
                              <Col
                                sm={3}
                                className="border-end border-bottom-0 pe-0"
                              >
                                <Nav variant="tabs" className="flex-column">
                                  <Nav.Item>
                                    <Nav.Link eventKey="1">General</Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item>
                                    <Nav.Link eventKey="2">Inventario</Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item>
                                    <Nav.Link eventKey="3">
                                      Características
                                    </Nav.Link>
                                  </Nav.Item>
                                </Nav>
                              </Col>
                              <Col sm={9}>
                                <Tab.Content>
                                  <Tab.Pane eventKey="1" className="py-3">
                                    <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Col className="col-3">
                                        <Form.Label>Precio de lista</Form.Label>
                                      </Col>
                                      <Col className="col-6">
                                        <InputGroup className="col-10 mb-3">
                                          <InputGroup.Text>$</InputGroup.Text>
                                          <Form.Control
                                            type="number"
                                            step="any"
                                            name="precioLista"
                                            defaultValue={formData.precioLista}
                                            onChange={handleChange}
                                          />
                                        </InputGroup>
                                      </Col>
                                    </Form.Group>
                                    <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Col className="col-3">
                                        <Form.Label>
                                          Precio de promoción
                                        </Form.Label>
                                      </Col>
                                      <Col className="col-6">
                                        <InputGroup className="mb-3">
                                          <InputGroup.Text>$</InputGroup.Text>
                                          <Form.Control
                                            type="number"
                                            step="any"
                                            name="precioPromocional"
                                            defaultValue={
                                              formData.precioPromocional
                                            }
                                            onChange={handleChange}
                                          />
                                        </InputGroup>
                                      </Col>
                                    </Form.Group>
                                    <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Col className="col-3">
                                        <Form.Label>Costo</Form.Label>
                                      </Col>
                                      <Col className="col-6">
                                        <InputGroup className="mb-3">
                                          <InputGroup.Text>$</InputGroup.Text>
                                          <Form.Control
                                            type="number"
                                            step="any"
                                            name="costo"
                                            defaultValue={formData.costo}
                                            onChange={handleChange}
                                          />
                                        </InputGroup>
                                      </Col>
                                    </Form.Group>
                                    <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Col className="col-3">
                                        <Form.Label>Posición en web</Form.Label>
                                      </Col>
                                      <Col className="col-6">
                                        <Form.Control
                                          type="number"
                                          name="posicion"
                                          placeholder="Ingresa la posición en web"
                                          defaultValue={formData.posicion}
                                          onChange={handleChange}
                                          className="mb-3"
                                        />
                                      </Col>
                                    </Form.Group>
                                    <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Col className="col-2">
                                        <Form.Check
                                          type="checkbox"
                                          id="destacado"
                                          name="destacado"
                                          label="Destacado"
                                          checked={formData.destacado}
                                          onChange={handleChange}
                                          className="mb-3"
                                        />
                                      </Col>
                                      <Col className="col-6">
                                        <Form.Check
                                          type="checkbox"
                                          id="nuevo"
                                          name="nuevo"
                                          label="Nuevo"
                                          checked={formData.nuevo}
                                          onChange={handleChange}
                                          className="mb-3"
                                        />
                                      </Col>
                                    </Form.Group>
                                  </Tab.Pane>
                                  <Tab.Pane eventKey="2" className="py-3">
                                    <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Col className="col-3">
                                        <Form.Label>UPC</Form.Label>
                                      </Col>
                                      <Col className="col-6">
                                        <Form.Control
                                          type="text"
                                          name="UPCreal"
                                          placeholder="Ingresa el UPC"
                                          defaultValue={formData.UPCreal}
                                          onChange={handleChange}
                                          className="mb-3"
                                        />
                                      </Col>
                                    </Form.Group>
                                    <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Col className="col-3">
                                        <Form.Label>Código</Form.Label>
                                      </Col>
                                      <Col className="col-6">
                                        <Form.Control
                                          type="text"
                                          name="codigo"
                                          placeholder="Ingresa el código"
                                          defaultValue={formData.codigo}
                                          onChange={handleChange}
                                          className="mb-3"
                                        />
                                      </Col>
                                    </Form.Group>
                                    <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Col className="col-3">
                                        <Form.Label>Stock</Form.Label>
                                      </Col>
                                      <Col className="col-6">
                                        <Form.Control
                                          type="number"
                                          name="stock"
                                          placeholder="Ingresa la cantidad de stock"
                                          defaultValue={formData.stock}
                                          onChange={handleChange}
                                          className="mb-3"
                                        />
                                      </Col>
                                    </Form.Group>
                                    <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Col className="col-3">
                                        <Form.Label>Stock dañado</Form.Label>
                                      </Col>
                                      <Col className="col-6">
                                        <Form.Control
                                          type="number"
                                          name="stockRoto"
                                          placeholder="Ingresa la cantidad de stock dañado"
                                          defaultValue={formData.stockRoto}
                                          onChange={handleChange}
                                          className="mb-3"
                                        />
                                      </Col>
                                    </Form.Group>
                                    <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Col className="col-3">
                                        <Form.Label>Stock mínimo</Form.Label>
                                      </Col>
                                      <Col className="col-6">
                                        <Form.Control
                                          type="number"
                                          name="stockMinimo"
                                          placeholder="Ingresa la cantidad de stock dañado"
                                          defaultValue={formData.stockMinimo}
                                          onChange={handleChange}
                                          className="mb-3"
                                        />
                                      </Col>
                                    </Form.Group>
                                    <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Col className="col-12">
                                        <Form.Check
                                          type="checkbox"
                                          id="alarmaStockMinimo"
                                          name="alarmaStockMinimo"
                                          label="Activar alarma para stock mínimo"
                                          checked={formData.alarmaStockMinimo}
                                          onChange={handleChange}
                                          className="mb-3"
                                        />
                                      </Col>
                                    </Form.Group>
                                  </Tab.Pane>
                                  <Tab.Pane eventKey="3" className="py-3">
                                    <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Col className="col-3">
                                        <Form.Label>
                                          Tipo de producto
                                        </Form.Label>
                                      </Col>
                                      <Col className="col-6">
                                        <Form.Select
                                          value={formData.tipo}
                                          name="tipo"
                                          onChange={handleChange}
                                          className="mb-3"
                                        >
                                          <option disabled>
                                            SELECCIONE UNA OPCIÓN
                                          </option>
                                          {dataGlobal.tiposProducto?.map(
                                            (item, i) => (
                                              <option key={i} value={item.id}>
                                                {item.nombre}
                                              </option>
                                            )
                                          )}
                                        </Form.Select>
                                      </Col>
                                    </Form.Group>
                                    <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Col className="col-3">
                                        <Form.Label>Tamaño</Form.Label>
                                      </Col>
                                      <Col className="col-6">
                                      <Form.Control
                                          type="text"
                                          name="tamano"
                                          placeholder="Ingresa el tamaño"
                                          defaultValue={formData.tamano}
                                          onChange={handleChange}
                                          className="mb-3"
                                        />
                                      </Col>
                                    </Form.Group>
                                    {/* <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Col className="col-3">
                                        <Form.Label>Categoría</Form.Label>
                                      </Col>
                                      <Col className="col-6">
                                        <Form.Select
                                          value={formData.categoria}
                                          name="categoria"
                                          onChange={handleChange}
                                          className="mb-3"
                                        >
                                          <option disabled>
                                            SELECCIONE UNA OPCIÓN
                                          </option>
                                          {dataGlobal.categoria?.map(
                                            (item, i) => (
                                              <option key={i} value={item.id}>
                                                {item.nombre}
                                              </option>
                                            )
                                          )}
                                        </Form.Select>
                                      </Col>
                                    </Form.Group> */}

                                    {/* <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Col className="col-3">
                                        <Form.Label>Grupo web</Form.Label>
                                      </Col>
                                      <Col className="col-6">
                                        <Form.Select
                                          name="grupo"
                                          value={formData.grupo}
                                          onChange={handleChange}
                                          className="mb-3"
                                        >
                                          <option disabled>
                                            SELECCIONE UNA OPCIÓN
                                          </option>
                                          {dataGlobal.grupo?.map((item, i) => (
                                            <option key={i} value={item.id}>
                                              {item.nombre}
                                            </option>
                                          ))}
                                        </Form.Select>
                                      </Col>
                                    </Form.Group> */}

                                    <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Col className="col-3">
                                        <Form.Label>Marca</Form.Label>
                                      </Col>
                                      <Col className="col-6">
                                        <Form.Select
                                          value={formData.marca}
                                          name="marca"
                                          onChange={handleChange}
                                          className="mb-3"
                                        >
                                          <option disabled>Seleccione</option>
                                          {dataGlobal.marcas?.map((item, i) => (
                                            <option key={i} value={item.id}>
                                              {item.nombre}
                                            </option>
                                          ))}
                                        </Form.Select>
                                      </Col>
                                    </Form.Group>

                                    <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Col className="col-3">
                                        <Form.Label>Material</Form.Label>
                                      </Col>
                                      <Col className="col-6">
                                      <Form.Control
                                          type="text"
                                          name="material"
                                          placeholder="Ingresa el material"
                                          defaultValue={formData.material}
                                          onChange={handleChange}
                                          className="mb-3"
                                        />
                                      </Col>
                                    </Form.Group>
                                    {/* <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Col className="col-3">
                                        <Form.Label>Material</Form.Label>
                                      </Col>
                                      <Col className="col-6">
                                        <Form.Select
                                          value={formData.material}
                                          name="material"
                                          onChange={handleChange}
                                          className="mb-3"
                                        >
                                          <option disabled>
                                            SELECCIONE UNA OPCIÓN
                                          </option>
                                          {dataGlobal.materiales?.map(
                                            (item, i) => (
                                              <option key={i} value={item.id}>
                                                {item.nombre}
                                              </option>
                                            )
                                          )}
                                        </Form.Select>
                                      </Col>
                                    </Form.Group> */}

                                    <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Col className="col-3">
                                        <Form.Label>
                                          Color de fábrica
                                        </Form.Label>
                                      </Col>
                                      <Col className="col-6">
                                        <Form.Control
                                          type="text"
                                          name="color"
                                          placeholder="Ingresa el color"
                                          defaultValue={formData.color}
                                          onChange={handleChange}
                                          className="mb-3"
                                        />
                                      </Col>
                                    </Form.Group>

                                    <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Col className="col-3">
                                        <Form.Label>Color</Form.Label>
                                      </Col>
                                      <Col className="col-6">
                                        <Form.Select
                                          value={formData.colorPrincipal}
                                          name="colorPrincipal"
                                          onChange={handleChange}
                                          className="mb-3"
                                        >
                                          <option disabled>
                                            SELECCIONE UNA OPCIÓN
                                          </option>
                                          {dataGlobal.colores?.map(
                                            (item, i) => (
                                              <option key={i} value={item.id}>
                                                {item.nombre}
                                              </option>
                                            )
                                          )}
                                        </Form.Select>
                                      </Col>
                                    </Form.Group>
                                    {/* <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Col className="col-3">
                                        <Form.Label>
                                          Color secundario
                                        </Form.Label>
                                      </Col>
                                      <Col className="col-6">
                                        <Form.Select
                                          value={formData.colorSecundario}
                                          name="colorSecundario"
                                          onChange={handleChange}
                                          className="mb-3"
                                        >
                                          <option disabled>
                                            SELECCIONE UNA OPCIÓN
                                          </option>
                                          {dataGlobal.colores?.map(
                                            (item, i) => (
                                              <option key={i} value={item.id}>
                                                {item.nombre}
                                              </option>
                                            )
                                          )}
                                        </Form.Select>
                                      </Col>
                                    </Form.Group> */}

                                    {/* <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Col className="col-3">
                                        <Form.Label>Genero</Form.Label>
                                      </Col>
                                      <Col className="col-6">
                                        <Form.Select
                                          value={formData.genero}
                                          name="genero"
                                          onChange={handleChange}
                                          className="mb-3"
                                        >
                                          <option disabled>
                                            SELECCIONE UNA OPCIÓN
                                          </option>
                                          {dataGlobal.genero?.map((item, i) => (
                                            <option key={i} value={item.id}>
                                              {item.nombre}
                                            </option>
                                          ))}
                                        </Form.Select>
                                      </Col>
                                    </Form.Group> */}
                                    <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Col className="col-3">
                                        <Form.Label>Estuche</Form.Label>
                                      </Col>
                                      <Col className="col-6">
                                        <Form.Select
                                          value={formData.estuche}
                                          name="estuche"
                                          onChange={handleChange}
                                          className="mb-3"
                                        >
                                          <option disabled>
                                            SELECCIONE UNA OPCIÓN
                                          </option>
                                          {dataGlobal.estuche?.map(
                                            (item, i) => (
                                              <option key={i} value={item.id}>
                                                {item.nombre}
                                              </option>
                                            )
                                          )}
                                        </Form.Select>
                                      </Col>
                                    </Form.Group>
                                    {/* <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Col className="col-3">
                                        <Form.Label>Tamaño</Form.Label>
                                      </Col>
                                      <Col className="col-6">
                                        <Form.Select
                                          value={formData.tamano}
                                          name="tamano"
                                          onChange={handleChange}
                                          className="mb-3"
                                        >
                                          <option disabled>
                                            SELECCIONE UNA OPCIÓN
                                          </option>
                                          {dataGlobal.tamanios?.map(
                                            (item, i) => (
                                              <option key={i} value={item.id}>
                                                {item.nombre}
                                              </option>
                                            )
                                          )}
                                        </Form.Select>
                                      </Col>
                                    </Form.Group> */}
                                  </Tab.Pane>
                                  {/* <Tab.Pane eventKey="4" className="py-3">
                                    <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Col className="col-3">
                                        <Form.Label>Peso (Kg)</Form.Label>
                                      </Col>
                                      <Col className="col-6">
                                        <Form.Control
                                          type="number"
                                          name="peso"
                                          placeholder="Ingresa el peso"
                                          defaultValue={formData.peso}
                                          onChange={handleChange}
                                          className="mb-3"
                                        />
                                      </Col>
                                    </Form.Group>
                                    <Form.Group
                                      as={Row}
                                      className="align-items-center mb-3"
                                    >
                                      <Col className="col-3">
                                        <Form.Label>Tamaño (Cm)</Form.Label>
                                      </Col>
                                      <Col className="col-2">
                                        <Form.Control
                                          type="number"
                                          name="largo"
                                          placeholder="Largo"
                                          defaultValue={formData.largo}
                                          onChange={handleChange}
                                        />
                                        <Form.Text className="text-muted mb-3">
                                          Largo
                                        </Form.Text>
                                      </Col>
                                      <Col className="col-2">
                                        <Form.Control
                                          type="number"
                                          name="ancho"
                                          placeholder="Ancho"
                                          defaultValue={formData.ancho}
                                          onChange={handleChange}
                                        />
                                        <Form.Text className="text-muted mb-3">
                                          Ancho
                                        </Form.Text>
                                      </Col>
                                      <Col className="col-2">
                                        <Form.Control
                                          type="number"
                                          name="alto"
                                          placeholder="Alto"
                                          defaultValue={formData.alto}
                                          onChange={handleChange}
                                        />
                                        <Form.Text className="text-muted mb-3">
                                          Alto
                                        </Form.Text>
                                      </Col>
                                    </Form.Group>
                                    <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Col className="col-3">
                                        <Form.Label>
                                          Ubicación en depósito
                                        </Form.Label>
                                      </Col>
                                      <Col className="col-6">
                                        <Form.Control
                                          type="text"
                                          name="ubicacion"
                                          placeholder="Ingresa la ubicación"
                                          defaultValue={formData.ubicacion}
                                          onChange={handleChange}
                                          className="mb-3"
                                        />
                                      </Col>
                                    </Form.Group>
                                  </Tab.Pane> */}
                                  {/* <Tab.Pane eventKey="5" className="py-3">
                                    <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Col className="col-3">
                                        <Form.Label>Publicar en:</Form.Label>
                                      </Col>
                                    </Form.Group>
                                    <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Col className="col-6 ms-4">
                                        <Form.Check
                                          type="checkbox"
                                          id="mdoNet"
                                          name="mdoNet"
                                          label="mdo.net"
                                          checked={formData.mdoNet}
                                          onChange={handleChange}
                                        />
                                      </Col>
                                    </Form.Group>
                                    <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Col className="col-6 ms-4">
                                        <Form.Check
                                          type="checkbox"
                                          id="jet"
                                          name="jet"
                                          label="jet.com"
                                          checked={formData.jet}
                                          onChange={handleChange}
                                        />
                                      </Col>
                                    </Form.Group>
                                    <Form.Group
                                      as={Row}
                                      className="align-items-center"
                                    >
                                      <Col className="col-6 ms-4">
                                        <Form.Check
                                          type="checkbox"
                                          id="verEnFalabella"
                                          name="verEnFalabella"
                                          label="Falabella"
                                          checked={formData.verEnFalabella}
                                          onChange={handleChange}
                                          className="mb-3"
                                        />
                                      </Col>
                                    </Form.Group>
                                  </Tab.Pane>
                                  <Tab.Pane eventKey="6" className="py-3">
                                    <TreeSelect
                                      formData={formData}
                                      setFormData={setFormData}
                                    />
                                  </Tab.Pane> */}
                                </Tab.Content>
                              </Col>
                            </Row>
                          </Tab.Container>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Form.Group>
                  {/* Guardar */}
                  <Form.Group className="d-flex justify-content-end">
                    <Button
                      disabled={disableButton}
                      type="submit"
                      className="mb-2 mb-md-4 col-6 col-lg-3"
                    >
                      Guardar
                    </Button>
                  </Form.Group>
                </Row>
              </Col>
              <Col className="col-3">
                <Row>
                  <Form.Group className="mb-3">
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>Publicar</Accordion.Header>
                        <Accordion.Body>
                          <Form.Label>Estado</Form.Label>
                          <Form.Select
                            value={formData.suspendido}
                            name="suspendido"
                            onChange={handleChange}
                            className="mb-3"
                          >
                            <option value={0}>Publicado</option>
                            <option value={1}>Suspendido</option>
                          </Form.Select>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          Galería del producto
                        </Accordion.Header>
                        <Accordion.Body>
                          <EditProductImages
                            getData={getData}
                            formData={formData}
                            setFormData={setFormData}
                            handleChange={handleChange}
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Form.Group>
                </Row>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default EditProduct;
