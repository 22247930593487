import ConfigDB from '../../../../Config/ThemeConfig';

const primary = localStorage.getItem('default_color') || ConfigDB.data.color.primary_color;
const secondary = localStorage.getItem('secondary_color') || ConfigDB.data.color.secondary_color;


export const crecimientoData = {
    series: [66, 17, 31, 29],
    options: {
      chart: {
        height: 400,
        type: 'radialBar',
      },
      plotOptions: {
        radialBar: {
          offsetY: 0,
          startAngle: 0,
          endAngle: 270,
          hollow: {
            margin: 5,
            size: '30%',
            background: 'transparent',
            image: undefined,
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              show: false,
            }
          }
        }
      },
      stroke: {
        lineCap: 'round'
      },
      colors: [primary, secondary, primary, secondary],
      labels: ['Consultas', 'Ventas', 'Visitas', 'Clientes'],
      legend: {
        show: true,
        floating: true,
        fontSize: '16px',
        position: 'left',
        offsetX: 60,
        offsetY: 15,
        labels: {
          useSeriesColors: true,
        },
        markers: {
          size: 0
        },
        formatter: function (seriesName, opts) {
          return seriesName + ':  ' + opts.w.globals.series[opts.seriesIndex];
        },
        itemMargin: {
          vertical: 3
        }
      },
      responsive: [{
        breakpoint: 481,
        options: {
          chart: {
            height: 330,
          },
          legend: {
            show: false
          }
        }
      }]
    },
  };