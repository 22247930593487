import { useEffect } from "react";
import { Row, Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";

const NewFilterForm = ({
  showFilter,
  setPage,
  setFilterENDPOINT,
  resetAll,
  formData,
  setFormData,
}) => {
  const { dateFrom } = formData;

  const notify = () => toast("¡Datos filtrados!");

  const itemFilter = (e) => {
    e.preventDefault();
    setPage(1);
    setFilterENDPOINT(
      `&nombre=${formData.nombre}&email=${formData.email}&telefono=${formData.tel}&contacto=${formData.contacto}&id=${formData.codigo}`
    );
    showFilter();
    notify();
  };

  const resetAllFilters = () => {
    resetAll();
    showFilter();
  };

  useEffect(() => {
    if (dateFrom !== "") {
      var d = new Date(),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      const today = [year, month, day].join("-");

      setFormData({ ...formData, dateTo: today });
    }
  }, [dateFrom]);

  const handleChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;

    setFormData((prevalue) => {
      return {
        ...prevalue,
        [name]: value,
      };
    });
  };

  return (
    <Form onSubmit={itemFilter} className="bg-white p-4 rounded mb-3 border">
      <Row>

      <Form.Group className="col-12 col-md-2 ">
          <Form.Label>Codigo</Form.Label>
          <Form.Control
            type="number"
            placeholder="Codigo"
            name="codigo"
            value={formData.codigo}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group className="col-12 col-md-3 ">
          <Form.Label>Nombre</Form.Label>
          <Form.Control
            type="text"
            placeholder="Nombre"
            name="nombre"
            value={formData.nombre}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group className="col-12 col-md-3 ">
          <Form.Label>E-mail</Form.Label>
          <div style={{ display: "flex" }}>
            <Form.Control
              type="email"
              placeholder="E-mail"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
        </Form.Group>
        <Form.Group className="col-12 col-md-2 ">
          <Form.Label>Teléfono</Form.Label>
          <div style={{ display: "flex" }}>
            <Form.Control
              type="tel"
              placeholder="Teléfono"
              name="tel"
              value={formData.tel === 0 ? "" : formData.tel}
              onChange={handleChange}
            />
          </div>
        </Form.Group>

        <Form.Group className="col-12 col-md-2 ">
          <Form.Label>Contacto</Form.Label>
          <Form.Control
            type="text"
            placeholder="Contacto"
            name="contacto"
            value={formData.contacto}
            onChange={handleChange}
          />
        </Form.Group>
      </Row>

      <Form.Group className="d-flex align-items-center justify-content-center">
        <Button
          variant="info"
          className="mt-2 mt-md-4 me-auto col-5 col-md-2 col-xxl-1 d-flex align-items-center justify-content-center"
          onClick={resetAllFilters}
        >
          Limpiar
        </Button>
        <Button
          type="submit"
          className="mt-2 mt-md-4 col-5 col-md-2 col-xxl-1 d-flex align-items-center justify-content-center"
        >
          Filtrar
        </Button>
      </Form.Group>
    </Form>
  );
};

export default NewFilterForm;
