import { Container, Row, Col } from "reactstrap";
import { Breadcrumbs } from "../../../AbstractElements";
import Statistics from "./Statistics";
import Crecimiento from "./Crecimiento";
import NuevosClientes from "./NuevosClientes";
import ConsultasVendidas from "./ConsultasVendidas";
import Graficos from "./Graficos";

const Escritorio = () => {
  return (
    <>
      <Breadcrumbs mainTitle="Escritorio" parent="General" title="Escritorio" />
      <Container fluid={true}>
        <Row>
          <Col xl="18">
            <Row>
              <Statistics />
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Escritorio;
