// dashbaord
import Escritorio from "../Components/Views/Escritorio";
import Products from "../Components/Views/Products";
import Sliders from "../Components/Views/Sliders";

import NewProduct from "../Components/Views/Products/NewProduct/NewProduct";
import EditProduct from "../Components/Views/Products/EditProduct/EditProduct";
import NewUser from "../Components/Views/Users/NewUser";
import NewCustomer from "../Components/Views/Customers/NewCustomer";
import EditCustomer from "../Components/Views/Customers/EditCustomer";
import Customers from "../Components/Views/Customers";
import NewProspect from "../Components/Views/Prospects/NewProspect";
import EditProspect from "../Components/Views/Prospects/EditProspect";
import Prospects from "../Components/Views/Prospects";
import Users from "../Components/Views/Users";
import EditUser from "../Components/Views/Users/EditUser";
import Purchases from "../Components/Views/Purchases";
import Configuraciones from "../Components/Views/Configuraciones";
import Orders from "../Components/Views/Orders";
import Invoice from "../Components/Views/Invoice";
import CtaCteDetail from "../Components/Views/Customers/CtaCteDetail";
import Proveedores from "../Components/Views/Providers";
import ErrorPage from "../Components/Pages/ErrorPage";
import MarketRate from "../Components/Views/MarketRate";
import NewMarketRate from "../Components/Views/MarketRate/NewMarketRate/NewMarketRate";
import EditMarketRate from "../Components/Views/MarketRate/EditMarketRate/EditMarketRate";
import TemplatePurchase from "../Components/Views/Purchases/TemplatePurchase";
import TemplateOrders from "../Components/Views/Orders/TemplateOrders";
import TemplateProveedores from "../Components/Views/Providers/TemplateProveedores";
import TemplateInvoice from "../Components/Views/Invoice/TemplateInvoice";
import DepositEntry from "../Components/Views/DepositEntry/index";
import TemplateDepositEntry from "../Components/Views/DepositEntry/TemplatePurchase";
import Recibos from "../Components/Views/Recibos";
// import NuevoRecibo from "../Components/Views/Recibos/TemplateRecibos";
import NuevoRecibo from "../Components/Views/Recibos/TemplateRecibos";
import DetalleRecibo from "../Components/Views/Recibos/TemplateRecibos/Detalle";
import NewSlider from "../Components/Views/Sliders/NewSlider/NewSlider";
import EditSlider from "../Components/Views/Sliders/EditSlider/EditSlider";
import Cupones from "../Components/Views/Cupones";
import EditCupon from "../Components/Views/Cupones/EditCupon";
import NewCupon from "../Components/Views/Cupones/NewCupon";
import EdicionGralProductos from "../Components/Views/EdicionGralProductos/index";
import ReportesStock from "../Components/Views/Reportes/Stock";
import ReportesProductos from "../Components/Views/Reportes/Products";
import ReportesInvoices from "../Components/Views/Reportes/Invoices";
import ReportesRecibos from "../Components/Views/Reportes/Recibos";
import ReportesTopTen from "../Components/Views/Reportes/TopTen";
import ReportesTopTenBrands from "../Components/Views/Reportes/TopTenBrands";

export const routes = [
  // Error
  { path: `${process.env.PUBLIC_URL}/404`, Component: <ErrorPage /> },

  // General
  { path: `${process.env.PUBLIC_URL}/escritorio`, Component: <Escritorio /> },

  // Productos
  { path: `${process.env.PUBLIC_URL}/productos`, Component: <Products /> },
  {
    path: `${process.env.PUBLIC_URL}/nuevo-producto`,
    Component: <NewProduct />,
  },
  {
    path: `${process.env.PUBLIC_URL}/editar-producto/:productID`,
    Component: <EditProduct />,
  },

  // Sliders

  { path: `${process.env.PUBLIC_URL}/sliders`, Component: <Sliders /> },
  { path: `${process.env.PUBLIC_URL}/nuevo-slider`, Component: <NewSlider /> },
  { path: `${process.env.PUBLIC_URL}/editar-slider/:sliderID`, Component: <EditSlider /> },

  // Cupones
  { path: `${process.env.PUBLIC_URL}/cupones`, Component: <Cupones /> },
  { path: `${process.env.PUBLIC_URL}/nuevo-cupon`, Component: <NewCupon /> },
  { path: `${process.env.PUBLIC_URL}/editar-cupon/:cuponID`, Component: <EditCupon /> },

  // Compras
  { path: `${process.env.PUBLIC_URL}/compras`, Component: <Purchases /> },
  {
    path: `${process.env.PUBLIC_URL}/nueva-compra/:purchaseID`,
    Component: <TemplatePurchase />,
  },
  {
    path: `${process.env.PUBLIC_URL}/editar-compra/:purchaseID`,
    Component: <TemplatePurchase />,
  },
  // ingreso a depósito
  {
    path: `${process.env.PUBLIC_URL}/ingreso-a-deposito`,
    Component: <DepositEntry />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ingreso-a-deposito/:purchaseID`,
    Component: <TemplateDepositEntry />,
  },

  // Usuarios
  { path: `${process.env.PUBLIC_URL}/usuarios`, Component: <Users /> },
  { path: `${process.env.PUBLIC_URL}/nuevo-usuario`, Component: <NewUser /> },
  {
    path: `${process.env.PUBLIC_URL}/editar-usuario/:userID/:userName`,
    Component: <EditUser />,
  },

  // Clientes
  { path: `${process.env.PUBLIC_URL}/clientes`, Component: <Customers /> },
  {
    path: `${process.env.PUBLIC_URL}/detalle-cta-cte/:id`,
    Component: <CtaCteDetail />,
  },
  {
    path: `${process.env.PUBLIC_URL}/nuevo-cliente`,
    Component: <NewCustomer />,
  },
  {
    path: `${process.env.PUBLIC_URL}/editar-cliente/:customerID`,
    Component: <EditCustomer />,
  },

    // Prospectos
    { path: `${process.env.PUBLIC_URL}/prospectos`, Component: <Prospects/> },
    {
      path: `${process.env.PUBLIC_URL}/detalle-cta-cte/:id`,
      Component: <CtaCteDetail />,
    },
    {
      path: `${process.env.PUBLIC_URL}/nuevo-prospecto`,
      Component: <NewProspect />,
    },
    {
      path: `${process.env.PUBLIC_URL}/editar-prospecto/:customerID`,
      Component: <EditProspect />,
    },

  // Pedidos
  { path: `${process.env.PUBLIC_URL}/pedidos`, Component: <Orders /> },
  // { path: `${process.env.PUBLIC_URL}/nuevo-pedido`, Component: <NewOrder /> },
  {
    path: `${process.env.PUBLIC_URL}/nuevo-pedido/:orderID`,
    Component: <TemplateOrders />,
  },
  {
    path: `${process.env.PUBLIC_URL}/editar-pedido/:orderID`,
    // Component: <EditOrder />,
    Component: <TemplateOrders />,
  },

  // Configuración
  {
    path: `${process.env.PUBLIC_URL}/configuraciones`,
    Component: <Configuraciones />,
  },

  //Recibos
  { path: `${process.env.PUBLIC_URL}/recibos`, Component: <Recibos /> },
  {
    path: `${process.env.PUBLIC_URL}/detalle-recibo/:reciboID`,
    Component: <DetalleRecibo />,
  },
  {
    path: `${process.env.PUBLIC_URL}/nuevo-recibo`,
    Component: <NuevoRecibo />,
  },
  //Invoice/Facturas
  { path: `${process.env.PUBLIC_URL}/invoice`, Component: <Invoice /> },
  {
    path: `${process.env.PUBLIC_URL}/detalle-invoice/:invoiceID`,
    Component: <TemplateInvoice />,
  },

  // Proveedores
  { path: `${process.env.PUBLIC_URL}/proveedores`, Component: <Proveedores /> },
  {
    path: `${process.env.PUBLIC_URL}/nuevo-proveedor/:providerID`,
    Component: <TemplateProveedores />,
  },
  {
    path: `${process.env.PUBLIC_URL}/editar-proveedor/:providerID`,
    Component: <TemplateProveedores />,
  },

  // Cotizaciones
  { path: `${process.env.PUBLIC_URL}/cotizaciones`, Component: <MarketRate /> },
  {
    path: `${process.env.PUBLIC_URL}/nueva-cotizacion`,
    Component: <NewMarketRate />,
  },
  {
    path: `${process.env.PUBLIC_URL}/editar-cotizacion/:cotizacionID`,
    Component: <EditMarketRate />,
  },
  {
    path: `${process.env.PUBLIC_URL}/editar-gral-productos`,
    Component: <EdicionGralProductos />,
  },

  {
    path: `${process.env.PUBLIC_URL}/reportes/stock`,
    Component: <ReportesStock />,
  },

  {
    path: `${process.env.PUBLIC_URL}/reportes/recibos`,
    Component: <ReportesRecibos />,
  },

  {
    path: `${process.env.PUBLIC_URL}/reportes/productos`,
    Component: <ReportesProductos />,
  },

  {
    path: `${process.env.PUBLIC_URL}/reportes/invoices`,
    Component: <ReportesInvoices />,
  },

  {
    path: `${process.env.PUBLIC_URL}/reportes/topten`,
    Component: <ReportesTopTen />,
  },

  {
    path: `${process.env.PUBLIC_URL}/reportes/toptenmarcas`,
    Component: <ReportesTopTenBrands />,
  },
];
