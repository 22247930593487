import ConfigDB from '../../../../Config/ThemeConfig';

const primary = localStorage.getItem('default_color') || ConfigDB.data.color.primary_color;
const secondary = localStorage.getItem('secondary_color') || ConfigDB.data.color.secondary_color;

export const consultasVendidasData = {
    series: [{
      name: 'Consultas',
      type: 'area',
      data: [44, 148, 38, 70, 112,44, 148, 38, 70, 112, 22, 65]
    }, {
      name: 'Ventas',
      type: 'line',
      data: [42, 38, 48, 30, 24, 44, 18, 8, 7, 12, 11, 45]
    }],
    options: {
      chart: {
        height: 400,
        type: 'area',
        toolbar: {
          show: true,
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth',
        width: [5, 2],
        dashArray: [0, 8]
      },
      colors: [primary, secondary],
      fill: {
        type:'solid',
        opacity: [0.35, 1],
      },
      labels: ['Diciembre', 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', "Noviembre"],
      markers: {
        size: 5
      },
      responsive: [
        {
          breakpoint: 991,
          options: {
            chart: {
              height: 300
            }
          }
        },
        {
          breakpoint: 1500,
          options: {
            chart: {
              height: 315
            }
          }
        }
      ],
      yaxis: [
        {
          labels: {
            formatter: function (value) {
              return value;
            },
          },
        },
      ],
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: function (y) {
            if (typeof y !== 'undefined') {
              return y.toFixed(0);
            }
            return y;
          }
        }
      },
      legend: {
        show: false,
      },
    }
  };