import { useContext, useEffect, useState } from "react";
import { Row, Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import DataContext from "../../../_helper/DataContext";
import axios from "axios";

const NewFilterForm = ({
  showFilter,
  setPage,
  setFilterENDPOINT,
  resetAll,
  formData,
  setFormData,
}) => {
  const [estados, setEstados] = useState([]);
  const token = localStorage.getItem("token");
  const { dataGlobal } = useContext(DataContext);
  const { id, client, seller, state, dateFrom, dateTo, codigoProducto } = formData;
  const initialENDPOINT = process.env.REACT_APP_ENDPOINT;

  const notify = () => toast("¡Productos filtrados!");

  const itemFilter = (e) => {
    e.preventDefault();
    setPage(1);
    const filteredCodigoProducto = codigoProducto ?? '';
    setFilterENDPOINT(
      `&id=${id}&nombreCliente=${client.trim()}&estado=${state}&desde=${dateFrom}&hasta=${dateTo}&codigoProducto=${filteredCodigoProducto}`
    );

    notify();
  };

  const resetAllFilters = () => {
    resetAll();
    showFilter();
  };

  const handleChange = (e) => {
    let value;
    e.target.type === "checkbox"
      ? (value = e.target.checked)
      : (value = e.target.value);

    let name = e.target.name;
    setFormData((prevalue) => {
      return {
        ...prevalue,
        [name]: value,
      };
    });
  };

  const getEstados = async () => {
    try {
      const res = await axios(`${initialENDPOINT}/estadopedido`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setEstados(res.data.data.original.results);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getEstados();
  }, []); 

  return (
    <Form onSubmit={itemFilter} className="bg-white p-4 rounded mb-3 border">
      <Row>
        <Form.Group className="col-12 col-md-2 mb-3">
          <Form.Label>Código</Form.Label>
          <Form.Control
            type="text"
            placeholder="Código de pedido"
            name="id"
            value={id}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group className="col-12 col-md-2 mb-3">
          <Form.Label>Cliente</Form.Label>
          <Form.Control
            type="text"
            placeholder="Nombre de cliente"
            name="client"
            value={client}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group className="col-12 col-md-2 mb-2">
          <Form.Label>Estado</Form.Label>
          <Form.Select value={state} name="state" onChange={handleChange}>
            <option value={0}>TODOS</option>
            {estados?.map((item, i) => (
              <option key={i} value={item.id}>
                {item.nombre}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group className="col-12 col-md-4 mb-3">
          <Form.Label>Fecha</Form.Label>
          <div style={{ display: "flex", gap: 10 }}>
            <Form.Control
              type="date"
              placeholder="Desde.."
              name="dateFrom"
              onChange={handleChange}
            />
            <Form.Control
              type="date"
              placeholder="Hasta.."
              name="dateTo"
              onChange={handleChange}
            />
          </div>
        </Form.Group>
        <Form.Group className="col-12 col-md-2 mb-3">
          <Form.Label>Código de producto</Form.Label>
          <Form.Control
            type="number"
            placeholder="Código de producto"
            name="codigoProducto"
            onChange={handleChange}
          />
        </Form.Group>
      </Row>
      <Form.Group className="d-flex justify-content-end">
        <Button
          variant="info"
          className="mt-2 mt-md-4 me-auto col-5 col-md-auto"
          onClick={resetAllFilters}
        >
          Limpiar
        </Button>
        <Button type="submit" className="mt-2 mt-md-4 col-5 col-md-auto">
          Filtrar
        </Button>
      </Form.Group>
    </Form>
  );
};

export default NewFilterForm;
