import { useState, useEffect } from "react";
import axios from "axios";

const useAxiosFilter = (endPoint) => {
  const token =localStorage.getItem("token");
  const [data, setData] = useState([]);
  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    if (token) {
      const getData = async () => {
        try {
          const res = await axios.get(endPoint, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });          
          setData(res.data.data);
        } catch (error) {
          setErrMsg(error.message);
        } finally {          
          setIsLoading(false);
        }
      };
      getData();
    }
    // eslint-disable-next-line
  }, [token]);

  return { data, errMsg, isLoading };
};

export default useAxiosFilter;
