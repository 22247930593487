import { useContext } from "react";
import { Row, Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import DataContext from "../../../_helper/DataContext";

const NewFilterForm = ({
  showFilter,
  setPage,
  setFilterENDPOINT,
  resetAll,
  formData,
  setFormData,
}) => {
  const { dataGlobal } = useContext(DataContext);
  const notify = () => toast("¡Productos filtrados!");

  const {
    suspended,
    name,
    code,
    marca,
    priceFrom,
    priceTo,
    stockFrom,
    stockTo,
    brand,
    product,
    color,
    category,
    material,
    size,
  } = formData;

  const marcaFilter = marca ? `&nombreMarca=${marca}` : "";


  const itemFilter = (e) => {
    e.preventDefault();
    setPage(1);

    setFilterENDPOINT(
      `${suspended ? `&suspendido=1` : ""}${
        code !== 0 ? `&codigo=${code}` : ""
      }${marcaFilter}&nombre=${name.trim()}&tipo=${product}&categoria=${category}&marca=${brand}&material=${material}&color=${color}${
        priceFrom !== 0 ? `&precioDesde=${priceFrom}` : ""
      }${priceTo !== 0 ? `&precioHasta=${priceTo}` : ""}${
        stockFrom !== 0 ? `&stockDesde=${stockFrom}` : ""
      }${stockTo !== 0 ? `&stockHasta=${stockTo}` : ""}`
    );
    notify();
  };

  const resetAllFilters = () => {
    resetAll();
    if (
      (suspended,
      name,
      code,
      marca,
      priceFrom,
      priceTo,
      stockFrom,
      stockTo,
      brand,
      product,
      color,
      category,
      material,
      size)
    ) {
      return;
    }

    showFilter();
  };

  const handleChange = (e) => {
    let value;
    e.target.type === "checkbox"
      ? (value = e.target.checked)
      : (value = e.target.value);

    let name = e.target.name;
    setFormData((prevalue) => {
      return {
        ...prevalue,
        [name]: value,
      };
    });
  };

  return (
    <Form onSubmit={itemFilter} className="bg-white p-4 rounded mb-3 border">
      <Row>
        <Form.Group className="col-12 col-md-2 mb-3">
          <Form.Label>Nombre</Form.Label>
          <Form.Control
            type="text"
            placeholder="Buscar.."
            name="name"
            value={name}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group className="col-12 col-md-2 mb-3">
          <Form.Label>Código</Form.Label>
          <Form.Control
            type="text"
            placeholder="Buscar.."
            name="code"
            value={code}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group className="col-12 col-md-2 mb-3">
          <Form.Label>Marcas</Form.Label>
          <Form.Control
            type="text"
            placeholder="Buscar.."
            name="marca"
            value={marca}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group className="col-12 col-md-2 mb-2">
          <Form.Label>Categoría</Form.Label>
          <Form.Select value={category} name="category" onChange={handleChange}>
            <option value={0}>TODAS</option>
            {dataGlobal.categoria?.map((item, i) => (
              <option key={i} value={item.id}>
                {item.nombre}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group className="col-12 col-md-2 mb-3">
          <Form.Label>Precio</Form.Label>
          <div style={{ display: "flex" }}>
            <Form.Control
              type="number"
              placeholder="Desde.."
              name="priceFrom"
              onChange={handleChange}
            />
            <Form.Control
              type="number"
              placeholder="Hasta.."
              name="priceTo"
              onChange={handleChange}
            />
          </div>
        </Form.Group>
        <Form.Group className="col-12 col-md-2 mb-3">
          <Form.Label>Stock</Form.Label>
          <div style={{ display: "flex" }}>
            <Form.Control
              type="number"
              placeholder="Desde.."
              name="stockFrom"
              onChange={handleChange}
            />
            <Form.Control
              type="number"
              placeholder="Hasta.."
              name="stockTo"
              onChange={handleChange}
            />
          </div>
        </Form.Group>
        <Form.Group className="col-12 col-md-2 mb-3">
          <Form.Label>Suspendidos</Form.Label>
          <Form.Check
            type="switch"
            id="suspended"
            name="suspended"
            className="mt-1"
            onChange={handleChange}
          />
        </Form.Group>
      </Row>
      <Form.Group className="d-flex justify-content-end">
        <Button
          variant="info"
          className="mt-2 mt-md-4 me-auto col-5 col-md-2 col-xxl-1 d-flex align-items-center justify-content-center"
          onClick={resetAllFilters}
        >
          Limpiar
        </Button>
        <Button
          type="submit"
          className="mt-2 mt-md-4 col-5 col-md-2 col-xxl-1 d-flex align-items-center justify-content-center"
        >
          Filtrar
        </Button>
      </Form.Group>
    </Form>
  );
};

export default NewFilterForm;
