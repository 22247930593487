import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Form, Row, Button, Col, Container } from "react-bootstrap";
import { toast } from "react-toastify";
import { Breadcrumbs } from "../../../../AbstractElements";
import MarketRateData from "./Client/MarketRateData";
import ProductDetails from "./Products/ProductDetails";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-quill/dist/quill.snow.css";
import axios from "axios";

const EditMarketRate = () => {
  const [estado, setEstado] = useState(0);
  const [formData, setFormData] = useState({
    cliente: "",
    clienteNombre: "",
    idProducto: "",
    precioProducto: "",
    cantidadProducto: 0,
    cantidadDeArticulos: 0,
    cantidadDeUnidades: 0,
    subtotal: 0,
    nombreProducto: "",
  });
  const [disableButton, setDisableButton] = useState(false);
  const [isMounted, setIsMounted] = useState(true);
  const { cotizacionID } = useParams();
  const token = localStorage.getItem("token");
  const initialENDPOINT = process.env.REACT_APP_ENDPOINT;
  const notify = () => toast("Cotización guardada!");
  const notifyDel = () => toast("Cotización eliminada!");
  const history = useNavigate();

  const handleChange = (e) => {
    let value;
    e.target.type === "checkbox"
      ? (value = e.target.checked)
      : (value = e.target.value);
    let name = e.target.name;

    setFormData((prevalue) => {
      return {
        ...prevalue,
        [name]: value,
      };
    });
  };

  const sendForm = (e) => {
    e.preventDefault();
    if (e.keyCode === 13) {
      return;
    }
    sendData();
    notify();
    history(`${process.env.PUBLIC_URL}/cotizaciones`);
  };

  let productosArray = formData.detalle?.map((p) => ({
    cantidad: p.cantidad,
    idProducto: p.idProducto,
    precio: p.precio,
  }));

  const sendData = async () => {
    setDisableButton(true);
    const dataToSend = JSON.stringify({
      cliente: formData.cliente,
      productos: productosArray,
    });
    try {
      await axios({
        url: `${initialENDPOINT}/cotizacion/${cotizacionID}`,
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: dataToSend,
        redirect: "follow",
      });
      history(`${process.env.PUBLIC_URL}/cotizaciones`);
    } catch (error) {
      console.log("error", error);
      setDisableButton(false);
    } finally {
    }
  };

  const handleBorrarCotizacion = async () => {
    try {
      await axios({
        url: `${initialENDPOINT}/cotizacion/${cotizacionID}`,
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        // data: dataToSend,
        redirect: "follow",
      });
      notifyDel();
      history(`${process.env.PUBLIC_URL}/cotizaciones`);
    } catch (error) {
      console.log("error", error);
      setDisableButton(false);
    }
  };

  const getMarketRate = async (marketRateID) => {

    try {
      const res = await axios.get(
        `${initialENDPOINT}/cotizacion/${marketRateID}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      isMounted && setFormData(res.data.data.message[0]);
      //const estado = res.data.data.message[0].estado;
      const estadoValue = res.data.data.message[0].estado;
    isMounted && setEstado(estadoValue);
    } catch (error) {
      console.log("error", error.response);
    }
  };

  const getMarketRateWithArrays = async (orderID) => {
    try {
      const res = await axios.get(
        `${initialENDPOINT}/cotizacion/${orderID}`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      // console.log("RES", res);
      setFormData((prevalue) => {
        return {
          ...prevalue,
          ...res.data.data.message[0],
        };
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const generarPedido = async () => {
    const dataToSend = JSON.stringify({
      //--------Armar objeto para crear pedido con la cotización
      cotizacion: cotizacionID,
    });

    try {
      const response = await axios({
        url: `${initialENDPOINT}/cotizacion/pedido`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        ////////// data: hardCode,
        data: dataToSend,
        redirect: "follow",
      });
      if (response.status === 200) {
        history(`${process.env.PUBLIC_URL}/editar-pedido/`+response.data.id);
        notify();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    isMounted && getMarketRate(cotizacionID);
    return () => {
      setIsMounted(false);
    };
  }, []);

  return (
    <Container fluid={true}>
      <Breadcrumbs
        mainTitle={`Editar cotización N° ${cotizacionID}: `}
        parent="Cotizaciones"
        title="Nueva cotización"
      />
      <Form>
        <Row>
          <Col className="col-12">
            {/* Cliente */}
            <MarketRateData
              handleChange={handleChange}
              formData={formData && formData}
              setFormData={setFormData}
            />
            <br />
            {/* Detalles del pedido */}

            <ProductDetails
              handleChange={handleChange}
              formData={formData && formData}
              setFormData={setFormData}
            />
          </Col>
          <Col className="col-12 col-lg-8 col-xxl-6"></Col>
        </Row>
        <div className="d-flex justify-content-between">
          <Link key={1} to={`/cotizaciones`}>
            <Button variant="danger" className="mb-5 mt-2">
              Cancelar
            </Button>
          </Link>
          <div>
          {estado === 3 || estado === 0 ? (
            <Button
              variant="outline-primary"
              onClick={(e) => generarPedido(e)}
              className="mb-5 mt-2"
            >
              Generar Pedido
            </Button>
            ) : null}
            <a href={`${initialENDPOINT}/pdf/cotizacion/${cotizacionID}`} target="_blank" >
              <Button
                variant="outline-primary"
                className="mb-5 mt-2 m-3"
              >
                PDF
              </Button>
            </a>
            <Button
              variant="outline-primary"
              onClick={(e) => handleBorrarCotizacion()}
              className="mb-5 mt-2"
            >
              Eliminar Cotización
            </Button>
            <Button
              disabled={disableButton}
              onClick={(e) => sendForm(e)}
              className="mb-5 mt-2 ms-3"
            >
              Guardar
            </Button>
          </div>
        </div>
      </Form>
    </Container>
  );
};

export default EditMarketRate;
