import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Form, Row, Button, Col, Container } from "react-bootstrap";
import { toast } from "react-toastify";
import { Breadcrumbs } from "../../../../AbstractElements";
import axios from "axios";
import Cliente from "./Cliente";
import Totales from "./Totales";
import Detalle from "./Detalle";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-quill/dist/quill.snow.css";

const TemplateInvoice = () => {
  const { invoiceID } = useParams();
  const history = useNavigate();
  const token = localStorage.getItem("token");
  const initialENDPOINT = process.env.REACT_APP_ENDPOINT;
  const [disableButton, setDisableButton] = useState(false);
  const [isMounted, setIsMounted] = useState(true);
  const [listaFormaDePago, setListaFormaDePago] = useState([]);
  const [formData, setFormData] = useState({
    // Cliente
    clienteNombre: "",
    billTo: "",
    shipVia: "",
    Terms: "",
    salesPerson: "",
    peso: "",
    codigoUPS: "",
    formaDePago: "",
    shipTo: "",
    FOB: "",
    fechaOrden: "",
    orden: "",
    UPS: "",
    // Detalle
    qordered: "",
    qshipped: "",
    qborder: "",
    itemNumber: "",
    Descripcion: "",
    listPrice: "",
    netPrice: "",
    detalle: [],
    // Totales
    subTotal: 0,
    DescuentoPorPromociones: 0,
    DescuentoPorcentual: 0,
    descuentoPorPorcentaje: 0,
    DescuentoNeto: 0,
    TotalEnvio: 0,
    totalPedido: 0,
  });

  const notify = () => toast("Invoice editado correctamente!");

  const handleChange = (e) => {
    let value;
    e.target.type === "checkbox"
      ? (value = e.target.checked)
      : (value = e.target.value);
    let name = e.target.name;

    setFormData((prevalue) => {
      return {
        ...prevalue,
        [name]: value,
      };
    });
  };

  const sendForm = (e) => {
    e.preventDefault();
    if (e.keyCode === 13) {
      return;
    }
    sendData();
    notify();
    history(`${process.env.PUBLIC_URL}/invoice`);
  };

  const sendData = async () => {
    setDisableButton(true);

    const dataToSend = JSON.stringify({
      // Cliente
      clienteNombre: formData.clienteNombre,
      billTo: formData.billTo,
      shipVia: formData.shipVia,
      Terms: formData.Terms,
      salesPerson: formData.salesPerson,
      peso: formData.peso,
      codigoUPS: formData.codigoUPS,
      formaDePago: formData.formaDePago,
      shipTo: formData.shipTo,
      FOB: formData.FOB,
      fechaOrden: formData.fechaOrden,
      orden: formData.orden,
      UPS: formData.UPS,
      // Detalle
      detalle: formData.detalle,
      // Totales
      subTotal: formData.subTotal,
      DescuentoPorPromociones: formData.DescuentoPorPromociones,
      DescuentoPorcentual: formData.DescuentoPorcentual,
      descuentoPorPorcentaje: formData.descuentoPorPorcentaje,
      DescuentoNeto: formData.DescuentoNeto,
      TotalEnvio: formData.TotalEnvio,
      totalPedido: formData.totalPedido,
      total: formData.total
    });

    try {
      await axios({
        url: `${initialENDPOINT}/invoice/${invoiceID}`,
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: dataToSend,
        redirect: "follow",
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const getData = async (invoiceID) => {
    try {
      const res = await axios.get(`${initialENDPOINT}/invoice/${invoiceID}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      setFormData(res.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const getFormasDePago = async () => {
    try {
      const res = await axios.get(`${initialENDPOINT}/formadepago`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      setListaFormaDePago(res.data.data.original.results);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    isMounted && invoiceID != 0 && getData(invoiceID) && getFormasDePago();
    return () => {
      setIsMounted(false);
    };
  }, [invoiceID]);

  return (
    <Container fluid={true}>
      <Breadcrumbs
        mainTitle={`Editar invoice - ${invoiceID}`}
        parent="Invoice"
        title="Editar invoice"
      />
      <Form>
        <Row>
          <Col className="col-12">
            <Cliente
              handleChange={handleChange}
              formData={formData && formData}
              setFormData={setFormData}
              listaFormaDePago={listaFormaDePago}
            />
            <br />
            <Detalle
              handleChange={handleChange}
              formData={formData && formData}
              setFormData={setFormData}
            />
            <br />
            <Totales
              handleChange={handleChange}
              formData={formData && formData}
              setFormData={setFormData}
            />
          </Col>
          <Col className="col-12 col-lg-8 col-xxl-6"></Col>
        </Row>
        <div className="d-flex justify-content-between">
          <Link key={1} to={`/invoice`}>
            <Button variant="danger" className="mb-5 mt-2">
              Cancelar
            </Button>
          </Link>
          <div>
            <Button
              disabled={disableButton}
              onClick={(e) => sendForm(e)}
              className="mb-5 mt-2 ms-3"
            >
              Guardar
            </Button>
          </div>
        </div>
      </Form>
    </Container>
  );
};

export default TemplateInvoice;
