import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Form, Row, Button, Col, Container } from "react-bootstrap";
import { toast } from "react-toastify";
import { Breadcrumbs } from "../../../../AbstractElements";
import axios from "axios";
import Cliente from "./Cliente";
import Totales from "./Totales";
import Productos from "./Productos";
import ProductosNN from "./ProductosNN";
import Envio from "./Envio";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-quill/dist/quill.snow.css";

const TemplateOrders = () => {
  const { orderID } = useParams();
  const history = useNavigate();
  const token = localStorage.getItem("token");
  const initialENDPOINT = process.env.REACT_APP_ENDPOINT;
  const [formData, setFormData] = useState({
    // Cliente
    cliente: "",
    nombreCliente: "",
    origen: 0,
    vendedor: 0,
    etapa: 0,
    // Productos
    detalle: [],
    idProducto: "",
    productoNombre: "",
    precioProducto: "",
    cantidadProducto: 0,
    cantidadDeArticulos: 0,
    cantidadDeUnidades: 0,
    // Productos NN
    detalleNN: [],
    // Totales
    descuentosPromocion: [],
    observaciones: "",
    subtotal: 0,
    descuentoPorPromociones: 0,
    //descuentoPorcentual: 0,
    descuentoPorPorcentaje: 0,
    descuentoNeto: 0,
    subtotalConDescuento: 0,
    totalEnvio: 0,
    totalPedido: 0,
    total: 0,
    // totalAbonar: 0,
    // Envio
    transportadoraNombre: "",
    transportadoraTelefono: "",
    codigoSeguimiento: "",
    idTransportadora: 0,
    tipoDeEnvio: 0,
    envioNombre: "",
    envioPais: 0,
    envioRegion: "",
    envioCiudad: "",
    envioDomicilio: "",
    envioCp: "",
  });
  const [disableButton, setDisableButton] = useState(false);
  const [isMounted, setIsMounted] = useState(true);
  const [emptyClient, setEmptyClient] = useState(false);

  const notify = () => toast("Pedido cargado!");

  const handleChange = (e) => {
    let { name, value } = e.target;

    e.target.type === "checkbox"
      ? (value = e.target.checked)
      : (value = e.target.value);

    name === "cliente" && setEmptyClient(false);

    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const sendForm = (e) => {
    e.preventDefault();
    if (e.keyCode === 13) {
      return;
    }
    if (formData.cliente === undefined || formData.cliente === "") {
      setEmptyClient(true);
      return;
    } else {
      setEmptyClient(false);
      sendData();
      notify();
      history(`${process.env.PUBLIC_URL}/pedidos`);
    }
  };

  const verInvoice = async () => {
    const pdfURL = `${initialENDPOINT}/pdf/invoice/${formData.invoice}`;
    window.open(pdfURL, '_blank');
  }

  const regenerarInvoice = async () => {
    
    setDisableButton(true);

    const dataToSend = JSON.stringify({
      cliente: formData.cliente,
      origen: formData.origen,
      vendedor: formData.vendedor,
      etapa: formData.etapa,

      detalle: formData.detalle,
      detalleNN: formData.detalleNN,
      descuentosPorPromocion: formData.discountData,
      observaciones: formData.observaciones,

      descuentoPorcentual: formData.descuento,
      descuentoPorPorcentaje: formData.descuentoPorcentual,
      //descuentoPorPromocionesOff: formData.descuentoPorPromocionesOff,
      descuentoNeto: formData.descuentoNeto,
      totalEnvio: formData.totalEnvio,
      total: formData.total,
      descuentoPorPromociones: formData.descuentoPorPromociones,
      transportadoraNombre: formData.transportadoraNombre,
      transportadoraTelefono: formData.transportadoraTelefono,
      codigoSeguimiento: formData.codigoSeguimiento,
      idTransportadora: formData.idTransportadora,
      tipoDeEnvio: formData.tipoDeEnvio,
      envioNombre: formData.envioNombre,
      envioPais: formData.envioPais,
      envioRegion: formData.envioRegion,
      envioCiudad: formData.envioCiudad,
      envioDomicilio: formData.envioDomicilio,
      envioCp: formData.envioCp,
    });

    try {
      const response = await axios({
        url: `${initialENDPOINT}/invoice/regenerar/${orderID != 0 ? `${orderID}` : ""}`,
        method: `${orderID != 0 ? "PUT" : "POST"}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: dataToSend,
      });
        history(`${process.env.PUBLIC_URL}/detalle-invoice/${response.data.id}`);
    } catch (error) {
      console.log("error", error);
    }

  }

  const guardadoDePedidoAntesDeGuardarElInvoice = async () => {

    setDisableButton(true);

    const dataToSend = JSON.stringify({
      cliente: formData.cliente,
      origen: formData.origen,
      vendedor: formData.vendedor,
      etapa: formData.etapa,

      detalle: formData.detalle,
      detalleNN: formData.detalleNN,
      descuentosPorPromocion: formData.discountData,
      observaciones: formData.observaciones,

      descuentoPorcentual: formData.descuento,
      descuentoPorPorcentaje: formData.descuentoPorcentual,
      //descuentoPorPromocionesOff: formData.descuentoPorPromocionesOff,
      descuentoNeto: formData.descuentoNeto,
      totalEnvio: formData.totalEnvio,
      total: formData.total,
      descuentoPorPromociones: formData.descuentoPorPromociones,
      transportadoraNombre: formData.transportadoraNombre,
      transportadoraTelefono: formData.transportadoraTelefono,
      codigoSeguimiento: formData.codigoSeguimiento,
      idTransportadora: formData.idTransportadora,
      tipoDeEnvio: formData.tipoDeEnvio,
      envioNombre: formData.envioNombre,
      envioPais: formData.envioPais,
      envioRegion: formData.envioRegion,
      envioCiudad: formData.envioCiudad,
      envioDomicilio: formData.envioDomicilio,
      envioCp: formData.envioCp,
    });

    try {
      const response = await axios({
        url: `${initialENDPOINT}/pedido${orderID != 0 ? `/${orderID}` : ""}`,
        method: `${orderID != 0 ? "PUT" : "POST"}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: dataToSend,
      });
      console.log(response.data.invoice);
      if (response.data.invoice == 0) {
        createInvoice(orderID);
      } else {
        history(`${process.env.PUBLIC_URL}/detalle-invoice/${response.data.invoice}`);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const sendData = async () => {

    setDisableButton(true);

    const dataToSend = JSON.stringify({
      cliente: formData.cliente,
      origen: formData.origen,
      vendedor: formData.vendedor,
      etapa: formData.etapa,

      detalle: formData.detalle,
      detalleNN: formData.detalleNN,
      descuentosPorPromocion: formData.discountData,
      observaciones: formData.observaciones,

      descuentoPorcentual: formData.descuento,
      descuentoPorPorcentaje: formData.descuentoPorcentual,
      //descuentoPorPromocionesOff: formData.descuentoPorPromocionesOff,
      descuentoNeto: formData.descuentoNeto,
      totalEnvio: formData.totalEnvio,
      total: formData.total,
      descuentoPorPromociones: formData.descuentoPorPromociones,
      transportadoraNombre: formData.transportadoraNombre,
      transportadoraTelefono: formData.transportadoraTelefono,
      codigoSeguimiento: formData.codigoSeguimiento,
      idTransportadora: formData.idTransportadora,
      tipoDeEnvio: formData.tipoDeEnvio,
      envioNombre: formData.envioNombre,
      envioPais: formData.envioPais,
      envioRegion: formData.envioRegion,
      envioCiudad: formData.envioCiudad,
      envioDomicilio: formData.envioDomicilio,
      envioCp: formData.envioCp,
    });

    try {
      await axios({
        url: `${initialENDPOINT}/pedido${orderID != 0 ? `/${orderID}` : ""}`,
        method: `${orderID != 0 ? "PUT" : "POST"}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: dataToSend,
        redirect: "follow",
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const getData = async (orderID) => {
    try {
      setDisableButton(true);
      const res = await axios.get(`${initialENDPOINT}/pedido/${orderID}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      setFormData(res.data.data);
      setDisableButton(false);
    } catch (error) {
      console.log("error", error);
    }
  };

  const createInvoice = async (id) => {
    const dataToSend = JSON.stringify({
      pedido: formData.id,
    });

    try {
      const res = await axios({
        url: `${initialENDPOINT}/invoice`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: dataToSend,
        redirect: "follow",
      });
      history(`${process.env.PUBLIC_URL}/detalle-invoice/${res.data.id}`);
    } catch (error) {
      console.log("error", error);
    }
  };

  // const verInvoice = async (id) => {
  //   guardadoDePedidoAntesDeGuardarElInvoice();
  // };

  useEffect(() => {
    isMounted && orderID != 0 && getData(orderID);
    return () => {
      setIsMounted(false);
    };
  }, [orderID]);

  return (
    <Container fluid={true}>
      <Breadcrumbs
        mainTitle={orderID == 0 ? "Nuevo pedido" : `Editar pedido: ${orderID}`}
        parent="Pedidos"
        title={orderID == 0 ? "Nuevo pedido" : `Editar pedido`}
      />
      <Form>
        <Row>
          <Col className="col-12">
            <Cliente
              emptyClient={emptyClient}
              handleChange={handleChange}
              formData={formData && formData}
              setFormData={setFormData}
            />
            <br />
            <Productos
              handleChange={handleChange}
              formData={formData && formData}
              setFormData={setFormData}
            />
            <br />
            <ProductosNN
              handleChange={handleChange}
              formData={formData && formData}
              setFormData={setFormData}
            />
            <br />
            <Totales
              handleChange={handleChange}
              formData={formData && formData}
              setFormData={setFormData}
            />
            <br />
            <Envio
              handleChange={handleChange}
              formData={formData}
              setFormData={setFormData}
            />
          </Col>
          <Col className="col-12 col-lg-8 col-xxl-6"></Col>
        </Row>
        <div className="d-flex justify-content-between">
          <Link key={1} to={`/pedidos`}>
            <Button variant="danger" className="mb-5 mt-2">
              Cancelar
            </Button>
          </Link>
          <div>
            {formData.invoice === 0 && (
              <Button
                variant="outline-primary"
                onClick={() => guardadoDePedidoAntesDeGuardarElInvoice(orderID)}
                className="mb-5 mt-2"
              >
                Generar Invoice
              </Button>
            )}

            {formData.invoice !== 0 && (
              <Button
                variant="outline-primary"
                onClick={() => verInvoice(orderID)}
                className="mb-5 mt-2 ms-3"
                disabled={disableButton}
              >
                Ver Invoice
              </Button>
            )}

            {formData.invoice !== 0 && (
              <Button
                variant="outline-primary"
                onClick={() => regenerarInvoice(orderID)}
                disabled={disableButton}
                className="mb-5 mt-2 ms-3"
              >
                Regenerar Invoice
              </Button>
            )}

            {/* <Button
              variant="outline-primary"
              onClick={(e) => alert("Descargar excel")}
              className="mb-5 mt-2 ms-3"
            >
              Excel
            </Button> */}
            <Button
              disabled={disableButton}
              onClick={(e) => sendForm(e)}
              className="mb-5 mt-2 ms-3"
            >
              Guardar
            </Button>
          </div>
        </div>
      </Form>
    </Container>
  );
};

export default TemplateOrders;
