import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Breadcrumbs } from "../../../AbstractElements";

import DataContext from "../../../_helper/DataContext";
import { Col, Row } from "react-bootstrap";
import SingleCustomerTable from "./SingleCustomerTable";

const CtaCteDetail = () => {
  const initialENDPOINT = process.env.REACT_APP_ENDPOINT;
  const token = localStorage.getItem("token");
  const id = useParams();
  const [formData, setFormData] = useState([]);
  const {
    errMsg,
    loading,
    totalRows,
    perPage,
    setPerPage,
    page,
    setPage,
    setENDPOINT,
  } = useContext(DataContext);

  async function getDetailCtaCte(idUsr) {
    try {
      const response = await axios({
        url: `${initialENDPOINT}/cuentacorriente/${idUsr.id}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        redirect: "follow",
      });
      setFormData({ ...response.data.original.results, cliente: response.data.original.cliente, ctacteTotal: response.data.original.ctacteTotal});
    } catch (error) {
      setFormData([]);
    }
  }

  useEffect(() => {
    getDetailCtaCte(id);
  }, []);

  return (
    <Row>
      <Col>
        {formData !== undefined ? (
          <SingleCustomerTable
            data={formData}
            errMsg={errMsg}
            loading={loading}
            totalRows={totalRows}
            perPage={perPage}
            setPerPage={setPerPage}
            page={page}
            setPage={setPage}
            setENDPOINT={setENDPOINT}
          />
        ) : (
          <SingleCustomerTable
            data={{}}
            errMsg={errMsg}
            loading={loading}
            totalRows={totalRows}
            perPage={perPage}
            setPerPage={setPerPage}
            page={page}
            setPage={setPage}
            setENDPOINT={setENDPOINT}
          />
        )}
      </Col>
    </Row>
  );
};

export default CtaCteDetail;
