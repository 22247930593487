import React, { useState } from "react";
import { Button, Col, Form, InputGroup } from "react-bootstrap";
import Table from "./Table";

const Gastos = ({ handleChange, formData, setFormData }) => {
  const [inputMsg, setInputMsg] = useState("");

  // Agrega el gasto a la tabla
  const handleProductAdd = () => {
    if (!formData.descripcion || !formData.precioGasto) {
      setInputMsg("Debes completar todos los campos.");
      return;
    }

    setFormData((prevalue) => {
      const newExpense = {
        descripcion: formData.descripcion,
        precioGasto: formData.precioGasto,
      };

      return {
        ...prevalue,
        gastos: [...prevalue.gastos, newExpense],
        descripcion: "",
        precioGasto: 0,
      };
    });

    setInputMsg("");
  };
  // Borra un porducto de la tabla
  const handleProductDelete = ({ descripcion }) => {
    const gastosActualizados = formData.gastos?.filter(
      (gasto) => gasto.descripcion != descripcion
    );
    setFormData((prevalue) => ({
      ...prevalue,
      gastos: gastosActualizados,
    }));

    setInputMsg("");
  };

  return (
    <Col className="col-12 col-xl-6">
      <div className="bg-white p-4 border rounded" style={{ height: "100%" }}>
        <h6 className="pb-3">Detalle de gastos</h6>
        <Form.Group className="d-flex align-items-end mb-3">
          <Form.Label className="col-4 col-md-4">Descripcion</Form.Label>
          <Form.Control
            type="text"
            name="descripcion"
            onChange={handleChange}
            value={formData.descripcion}
          />
        </Form.Group>
        <Form.Group className="d-flex align-items-end mb-3">
          <Form.Label className="col-4 col-md-4">Precio</Form.Label>
          <InputGroup>
            <InputGroup.Text>$</InputGroup.Text>
            <Form.Control
              type="number"
              name="precioGasto"
              onChange={handleChange}
              defaultValue={formData.precioGasto === 0 && ""}
            />
            <Button onClick={handleProductAdd}>Agregar</Button>
          </InputGroup>
        </Form.Group>
        {inputMsg && (
          <Form.Text className="text-danger mt-0">{inputMsg}</Form.Text>
        )}
        <Table
          formData={formData}
          setFormData={setFormData}
          inputMsg={inputMsg}
          handleProductAdd={handleProductAdd}
          handleProductDelete={handleProductDelete}
        />
        <Form.Group className="mb-3">
          <Form.Label>Observaciones</Form.Label>
          <Form.Control
            as="textarea"
            name="observaciones"
            onChange={handleChange}
            rows={4}
            defaultValue={formData.observaciones}
          />
        </Form.Group>
      </div>
    </Col>
  );
};

export default Gastos;
