import { useMemo } from "react";
import { Link } from "react-router-dom";
import { Badge } from "react-bootstrap";
import { downloadCSV, Export } from "../../../_helper/hooks/useExportCsv";
import DataTable from "react-data-table-component";
import Loader from "../../Common/Loader";
import Vacio from "../../Common/Vacio";
import TableTitle from "../../Common/TableTitle";

const CuponesTable = ({
  data,
  errMsg,
  loading,
  totalRows,
  perPage,
  setPerPage,
  setPage,
  setENDPOINT,
  filterENDPOINT,
  handleShowCancellations,
}) => {
  const initialENDPOINT = process.env.REACT_APP_ENDPOINT;

  const queriesColumns = [
    {
      name: "id",
      selector: (row) => row.id,
      center: true,
      wrap: true,
      compact: true,
    },
    {
      name: "Nombre",
      selector: (row) => row.nombre,
      center: true,
      wrap: true,
      compact: true,
    },
    {
      name: "Descuento fijo",
      selector: (row) => (row.descuentoFijo),
      center: false,
      wrap: true,
      //grow: 2,
    },
    {
      name: "Descuento porcentual",
      selector: (row) => row.descuentoPorcentual,
      center: false,
      wrap: true,
      compact: true,
    },
    {
      name: "Vencimiento",
      selector: (row) => (row.vencimiento),
      center: false,
      wrap: true,
      //grow: 2,
    },
    {
      name: "Acciones",
      selector: (row) => [
        <Link key={1} to={`/editar-cupon/${row.id}`}>
          <button
            key={2}
            className="btn text-dark w-auto p-1 mx-1 my-0"
            type="button"
            title="Editar"
          >
            <i className="fa fa-pencil-square-o f-16 px-1" />
          </button>
        </Link>,
      ],
      center: true,
      hide: "md",
      compact: true,
    },
  ];

  const handlePageChange = (pag) => {
    setENDPOINT(
      `${initialENDPOINT}/cupondescuento?pagina=${pag}&cantidad=${perPage}${filterENDPOINT}`
    );
  };

  const handlePerRowsChange = async (newPerPage, pag) => {
    setPerPage(newPerPage);
    setPage(pag);

    await setENDPOINT(
      `${initialENDPOINT}/banner?pagina=${pag}&cantidad=${newPerPage}${filterENDPOINT}`
    );
  };

  const actionsMemo = useMemo(
    () => (
      <Export
        onExport={() => downloadCSV(data, "Consultas")}
        cancallations={handleShowCancellations}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  return (
    <div
      className="App mb-5 table-queries border rounded "
      class="overflow-hidden overflow-x-auto"
      // style={{ overflow: "hidden", overflowX: "auto" }}
    >
      {/* {errMsg && <p>{errMsg}</p>} */}
      {/* {console.log("data", data)} */}
      <DataTable
        title={<TableTitle value="Lista de cupones" />}
        columns={queriesColumns}
        data={data}
        striped
        customStyles={{
          rows: {
            highlightOnHoverStyle: {
              backgroundColor: "rgb(230, 244, 244)",
              borderBottomColor: "#FFFFFF",
              outline: "1px solid #FFFFFF",
            },
          },
          pagination: {
            style: {
              border: "none",
            },
          },
        }}
        highlightOnHover="..."
        progressPending={loading}
        progressComponent={<Loader />}
        noDataComponent={<Vacio />}
        pagination
        paginationServer
        paginationComponentOptions={{
          rowsPerPageText: "Filas por página",
          rangeSeparatorText: "de",
        }}
        paginationDefaultPage
        paginationTotalRows={totalRows}
        contextMessage={{
          singular: "",
          plural: "",
          message: "",
        }}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerRowsChange}
        actions={actionsMemo}
        persistTableHead
      />
    </div>
  );
};

export default CuponesTable;
