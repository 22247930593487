import { Container, Row, Col } from "reactstrap";
import LoginTab from "./LoginTab";

const Logins = () => {

  return (
    <Container fluid={true} className="p-0">
      <Row>
        <Col xs="12">
          <div className="login-card">
            <div
              className="login-main login-tab"
              style={{ width: "80%", maxWidth: "500px", height: "auto" }}
            >
              <LoginTab />
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Logins;
