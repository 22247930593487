import { useState, useEffect } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import CountUp from "react-countup";
import axios from "axios";
import useGoToLogin from "../../../../_helper/hooks/useGoToLogin";

const StatisticsList = () => {
  const [clientes, setClientes] = useState(0);
  const [productos, setProductos] = useState(0);
  const [compras, setCompras] = useState(0);
  const [pedidos, setPedidos] = useState(0);
  const [isMounted, setIsMounted] = useState(true);
  const token = localStorage.getItem("token");
  const initialENDPOINT = process.env.REACT_APP_ENDPOINT;
  const { goToLogin } = useGoToLogin();

  const getData = async (url, setState) => {
    try {
      const res = await axios(`${initialENDPOINT}/${url}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      if (isMounted) {
        res.status === 200 && setState(res.data.data.original.cantidad_total);
      }
    } catch (error) {
      let status = error.response.status;
      goToLogin(status);
    }
  };

  const StatisticsData = [
    {
      id: 1,
      title: "Productos",
      icon: (
        <i
          className="fa fa-file-text"
          style={{ fontSize: 22, marginBottom: 4, color: "grey" }}
        ></i>
      ),
      num: productos,
      class: "progress-bar bg-primary",
    },
    {
      id: 2,
      title: "Compras",
      icon: (
        <i
          className="fa fa-clock-o"
          style={{ fontSize: 22, marginBottom: 4, color: "grey" }}
        ></i>
      ),
      num: compras,
      class: "progress-bar bg-secondary",
    },
    {
      id: 3,
      title: "Pedidos",
      icon: (
        <i
          className="fa fa-shopping-cart"
          style={{ fontSize: 22, marginBottom: 4, color: "grey" }}
        ></i>
      ),
      num: pedidos,
      class: "progress-bar bg-warning",
    },
    {
      id: 4,
      title: "Clientes",
      icon: (
        <i
          className="fa fa-user"
          style={{ fontSize: 22, marginBottom: 4, color: "grey" }}
        ></i>
      ),
      num: clientes,
      class: "progress-bar bg-info",
    },
  ];

  useEffect(() => {
    getData("producto", setProductos);
    getData("cliente", setClientes);
    getData("compra", setCompras);
    getData("pedido", setPedidos);

    return () => {
      // Se ejecuta cuando el componente se desmonta
      setIsMounted(false);
    };
  }, []);

  return (
    <Row>
      {StatisticsData.map((item) => {
        return (
          <Col xl="3" xs="6" key={item.id} className="g-2">
            <Card className="ecommerce-widget pro-gress m-0">
              <CardBody className="support-ticket-font p-2">
                <Row>
                  <Col xs="12" className="text-center">
                    {item.icon}
                    <h6 className="align-center p-0">{item.title}</h6>
                    <h4 className="total-num counter">
                      <CountUp end={item.num} duration={5} />
                    </h4>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        );
      })}
    </Row>
  );
};
export default StatisticsList;
