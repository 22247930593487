// import { useEffect } from "react";
// import { useContext, useState } from "react";
// import { Button } from "react-bootstrap";
// import { Link } from "react-router-dom";
// import { Container, Row, Col } from "reactstrap";
// import { Breadcrumbs } from "../../../../AbstractElements";
// import DataContext from "../../../../_helper/DataContext";
// import useGoToLogin from "../../../../_helper/hooks/useGoToLogin";
// import NewFilterForm from "./NewFilterForm";
// import RecibosTable from "./RecibosTable";

// const initialForm = {
//   nombre: "",
//   id: "",
//   perfil: 0,
// };

// const Recibos = () => {
//   const initialENDPOINT = process.env.REACT_APP_ENDPOINT;
//   const [newFilter, setNewFilter] = useState(false);
//   const [newQuery, setNewQuery] = useState(false);
//   const [formData, setFormData] = useState(initialForm);
//   const [showCancellations, setShowCancellations] = useState(false);
//   const token = localStorage.getItem("token");
//   const { goToLogin } = useGoToLogin();

//   const {
//     data,
//     dataGlobal,
//     errMsg,
//     loading,
//     totalRows,
//     perPage,
//     setPerPage,
//     page,
//     setPage,
//     setENDPOINT,
//     setFilterENDPOINT,
//     filterENDPOINT,
//   } = useContext(DataContext);

//   const resetAll = () => {
//     const random = Date.now();
//     setFormData(initialForm);
//     setFilterENDPOINT(`&cantidad=10&random=${random}`);
//     // setFilterENDPOINT(`/invoice?pagina=1&random=${random}`);
//     setPage(1);
//   };
//   const handleShowCancellations = () => {
//     setShowCancellations((prev) => !prev);
//     setENDPOINT(`${initialENDPOINT}/reportes/recibos/list?pagina=1&cantidad=10`);
//   };
//   const showFilter = () => {
//     setNewFilter(!newFilter);
//     setENDPOINT(`${initialENDPOINT}/reportes/recibos/list?pagina=1`);
//   };
//   useEffect(() => {
//     const random = Date.now();
//     !token && goToLogin();
//     setENDPOINT(`${initialENDPOINT}/reportes/recibos/list?pagina=1&random=${random}`);
//     setFilterENDPOINT("");
//   }, []);

//   return (
//     <Container fluid={true}>
//       <Breadcrumbs mainTitle="Recibos" parent="Recibos" title="Listado" />
//       <div className="d-flex justify-content-around justify-content-sm-end gap-2">
//         <a target="_blank" href={`${initialENDPOINT}/reportes/recibos/report`}>
//           <Button
//             className="mb-3 px-3"
//             variant={newQuery ? "primary" : "outline-primary"}
//           >
//             <i className="fa fa-plus f-12 pe-2 m-0" />
//             Descargar Reporte
//           </Button>
//         </a>
//         <Button
//           onClick={showFilter}
//           className="mb-3 px-3"
//           variant={newFilter ? "primary" : "outline-primary"}
//         >
//           <i className="fa fa-filter f-12 pe-2 m-0" />
//           Filtros avanzados
//         </Button>
//         <Button
//           onClick={resetAll}
//           className="mb-3 px-3"
//           variant={newFilter ? "primary" : "outline-primary"}
//         >
//           <i className="fa fa-refresh f-12 m-0" />
//         </Button>
//       </div>
//       <Row>
//         <Col xl="12" className="des-xl-100 box-col-12">
//           {newFilter && (
//             <NewFilterForm
//               showFilter={showFilter}
//               dataGlobal={dataGlobal}
//               setPage={setPage}
//               setFilterENDPOINT={setFilterENDPOINT}
//               resetAll={resetAll}
//               formData={formData}
//               setFormData={setFormData}
//             />
//           )}
//           <RecibosTable
//             data={data}
//             errMsg={errMsg}
//             loading={loading}
//             totalRows={totalRows}
//             perPage={perPage}
//             setPerPage={setPerPage}
//             page={page}
//             setPage={setPage}
//             setENDPOINT={setENDPOINT}
//             filterENDPOINT={filterENDPOINT}
//             showCancellations={showCancellations}
//             handleShowCancellations={handleShowCancellations}
//           />
//         </Col>
//       </Row>
//     </Container>
//   );
// };

// export default Recibos;

import { useEffect } from "react";
import { useContext, useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { Breadcrumbs } from "../../../../AbstractElements";
import DataContext from "../../../../_helper/DataContext";
import useGoToLogin from "../../../../_helper/hooks/useGoToLogin";
import NewFilterForm from "./NewFilterForm";
import RecibosTable from "./RecibosTable";

const initialForm = {
  nombre: "",
  id: "",
  perfil: 0,
  desde: "", // Add desde
  hasta: ""  // Add hasta
};

const Recibos = () => {
  const initialENDPOINT = process.env.REACT_APP_ENDPOINT;
  const [newFilter, setNewFilter] = useState(false);
  const [newQuery, setNewQuery] = useState(false);
  const [formData, setFormData] = useState(initialForm);
  const [showCancellations, setShowCancellations] = useState(false);
  const token = localStorage.getItem("token");
  const { goToLogin } = useGoToLogin();

  const {
    data,
    dataGlobal,
    errMsg,
    loading,
    totalRows,
    perPage,
    setPerPage,
    page,
    setPage,
    setENDPOINT,
    setFilterENDPOINT,
    filterENDPOINT,
  } = useContext(DataContext);

  const resetAll = () => {
    const random = Date.now();
    setFormData(initialForm);
    setFilterENDPOINT(`&cantidad=10&random=${random}`);
    setPage(1);
  };

  const handleShowCancellations = () => {
    setShowCancellations((prev) => !prev);
    setENDPOINT(`${initialENDPOINT}/reportes/recibos/list?pagina=1&cantidad=10`);
  };

  const showFilter = () => {
    setNewFilter(!newFilter);
    setENDPOINT(`${initialENDPOINT}/reportes/recibos/list?pagina=1`);
  };

  useEffect(() => {
    const random = Date.now();
    !token && goToLogin();
    setENDPOINT(`${initialENDPOINT}/reportes/recibos/list?pagina=1&random=${random}`);
    setFilterENDPOINT("");
  }, []);

  // Construir URL dinámico para el reporte
  const reportURL = `${initialENDPOINT}/reportes/recibos/report?fecha_desde=${formData.desde}&fecha_hasta=${formData.hasta}`;

  return (
    <Container fluid={true}>
      <Breadcrumbs mainTitle="Recibos" parent="Recibos" title="Listado" />
      <div className="d-flex justify-content-around justify-content-sm-end gap-2">
        <a target="_blank" href={reportURL}>
          <Button
            className="mb-3 px-3"
            variant={newQuery ? "primary" : "outline-primary"}
          >
            <i className="fa fa-plus f-12 pe-2 m-0" />
            Descargar Reporte
          </Button>
        </a>
        <Button
          onClick={showFilter}
          className="mb-3 px-3"
          variant={newFilter ? "primary" : "outline-primary"}
        >
          <i className="fa fa-filter f-12 pe-2 m-0" />
          Filtros avanzados
        </Button>
        <Button
          onClick={resetAll}
          className="mb-3 px-3"
          variant={newFilter ? "primary" : "outline-primary"}
        >
          <i className="fa fa-refresh f-12 m-0" />
        </Button>
      </div>
      <Row>
        <Col xl="12" className="des-xl-100 box-col-12">
          {newFilter && (
            <NewFilterForm
              showFilter={showFilter}
              dataGlobal={dataGlobal}
              setPage={setPage}
              setFilterENDPOINT={setFilterENDPOINT}
              resetAll={resetAll}
              formData={formData}
              setFormData={setFormData}
            />
          )}
          <RecibosTable
            data={data}
            errMsg={errMsg}
            loading={loading}
            totalRows={totalRows}
            perPage={perPage}
            setPerPage={setPerPage}
            page={page}
            setPage={setPage}
            setENDPOINT={setENDPOINT}
            filterENDPOINT={filterENDPOINT}
            showCancellations={showCancellations}
            handleShowCancellations={handleShowCancellations}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Recibos;
