import React, { useState } from "react";
import { Form } from "react-bootstrap";

function Checks() {
  const [isProspectoChecked, setIsProspectoChecked] = useState(false);
  const [isClienteChecked, setIsClienteChecked] = useState(false);

  const handleProspectoChange = () => {
    setIsProspectoChecked(true);
    setIsClienteChecked(false);
  };

  const handleClienteChange = () => {
    setIsProspectoChecked(false);
    setIsClienteChecked(true);
  };

  return (
    <div>
      <Form.Check
        type="checkbox"
        label="Prospecto"
        checked={isProspectoChecked}
        onChange={handleProspectoChange}
      />

      <Form.Check
        type="checkbox"
        label="Cliente"
        checked={isClienteChecked}
        onChange={handleClienteChange}
      />
    </div>
  );
}

export default Checks;
