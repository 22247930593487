import { useContext, useEffect, useState } from "react";
import { Button, Form, InputGroup, Col, Row } from "react-bootstrap";
import ProductsTable from "./ProductsTable";
import MyModal from "../../../../Common/MyModal";
import Loader from "../../../../Common/Loader";
import Vacio from "../../../../Common/Vacio";
import DataContext from "../../../../../_helper/DataContext";
import useGoToLogin from "../../../../../_helper/hooks/useGoToLogin";
import axios from "axios";
// import DataTable from "react-data-table-component";

const Productos = ({ handleChange, formData, setFormData, emptyProductos }) => {
  const initialENDPOINT = process.env.REACT_APP_ENDPOINT;
  const token = localStorage.getItem("token");
  const [newQuery, setNewQuery] = useState(false);
  const [inputMsg, setInputMsg] = useState("");
  const [isMounted, setIsMounted] = useState(true);
  const [isLoadingExcel, setIsLoadingExcel] = useState(false);
  const { goToLogin } = useGoToLogin();
  const {
    data,
    errMsg,
    loading,
    page,
    totalRows,
    perPage,
    setPerPage,
    setPage,
    setENDPOINT,
  } = useContext(DataContext);

  // Calcula las cantidades de artículos y unidades
  const handleQuantity = () => {
    const cantidadDeArticulos = formData.productos?.reduce(
      (acc, curr) => acc + 1,
      0
    );
    const cantidadDeUnidades = formData.productos?.reduce(
      (acc, curr) => acc + parseInt(curr.cantidad),
      0
    );

    const precioUnitario = formData.precioUnitario?.reduce(
      (acc, curr) => acc + parseInt(curr.cantidad),
      0
    );

    setFormData((prevalue) => {
      return {
        ...prevalue,
        cantidadDeArticulos: cantidadDeArticulos,
        cantidadDeUnidades: cantidadDeUnidades,
        precioUnitario: precioUnitario
      };
    });
  };

  useEffect(() => {
    !token && goToLogin();
    const random = Date.now();
    newQuery &&
      setENDPOINT(`${initialENDPOINT}/producto?pagina=1&random=${random}`);
    setInputMsg("");
  }, [newQuery]);

  useEffect(() => {
    return () => {
      setIsMounted(false);
    };
  }, []);

  useEffect(() => {

  }, [formData.productos, page]);

  return (
    <Col className="col-12">
      {/* {console.log("emptyProductos", emptyProductos)} */}
      <div
        className="bg-white p-4 rounded"
        style={
          emptyProductos === true
            ? { border: "1px solid red" }
            : { border: "1px solid #E6EDEF" }
        }
      >
        <div className="row">
          <div className="mb-3 d-flex justify-content-between ">
            <h6>Detalles del pedido</h6>
          </div>
          <ProductsTable
            formData={formData}
            setFormData={setFormData}
            setENDPOINT={setENDPOINT}
            totalRows={totalRows}
            perPage={perPage}
            setPerPage={setPerPage}
            page={page}
            setPage={setPage}
            // handleProductDelete={handleProductDelete}
            isLoadingExcel={isLoadingExcel}
          />
          {/* <div>
            <Form.Text className="me-2">
              Cantidad de articulos: {formData.cantidadDeArticulos || 0}
            </Form.Text>
            <Form.Text> | </Form.Text>
            <Form.Text className="ms-2">
              Cantidad de unidades: {formData.cantidadDeUnidades || 0}
            </Form.Text>
          </div> */}
          {emptyProductos === true ? (
            <small className="text-danger">Debe cargar productos</small>
          ) : (
            ""
          )}
        </div>
      </div>
    </Col>
  );
};

export default Productos;
