import { useState, useEffect, useContext } from "react";
import { Row, Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";
import DataContext from "../../../_helper/DataContext";

const NewFilterForm = ({
  showFilter,
  setPage,
  setFilterENDPOINT,
  resetAll,
  formData,
  setFormData,
}) => {
  const { dataGlobal } = useContext(DataContext);
  const [tiposBanner, setTiposBanner] = useState([]);
  const notify = () => toast("¡Productos filtrados!");

  const {
    suspended,
    name,
    code,
    priceFrom,
    priceTo,
    stockFrom,
    stockTo,
    brand,
    product,
    color,
    category,
    material,
    size,
  } = formData;

  useEffect(() => {
    const fetchTiposBanner = async () => {
      try {
        const response = await axios.get("https://phpstack-1091339-3819555.cloudwaysapps.com/api/tipobanner");
        setTiposBanner(response.data.data.original.results);
      } catch (error) {
        console.error("Error fetching tipos banner:", error);
      }
    };

    fetchTiposBanner();
  }, []);

  const itemFilter = (e) => {
    e.preventDefault();
    setPage(1);

    setFilterENDPOINT(
      category ? `&tipo=${category}` : ""
    );
    notify();
  };

  const resetAllFilters = () => {
    resetAll();
    if (
      (suspended,
      name,
      code,
      priceFrom,
      priceTo,
      stockFrom,
      stockTo,
      brand,
      product,
      color,
      category,
      material,
      size)
    ) {
      return;
    }

    showFilter();
  };

  const handleChange = (e) => {
    let value;
    e.target.type === "checkbox"
      ? (value = e.target.checked)
      : (value = e.target.value);

    let name = e.target.name;
    setFormData((prevalue) => {
      return {
        ...prevalue,
        [name]: value,
      };
    });
  };

  return (
    <Form onSubmit={itemFilter} className="bg-white p-4 rounded mb-3 border">
      <Row>
        <Form.Group className="col-12 col-md-2 mb-2">
          <Form.Label>Ubicación</Form.Label>
          <Form.Select value={category} name="category" onChange={handleChange}>
            <option value={0}>TODAS</option>
            {tiposBanner.map((item) => (
              <option key={item.id} value={item.id}>
                {item.nombre}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Row>
      <Form.Group className="d-flex justify-content-end">
        <Button
          variant="info"
          className="mt-2 mt-md-4 me-auto col-5 col-md-2 col-xxl-1 d-flex align-items-center justify-content-center"
          onClick={resetAllFilters}
        >
          Limpiar
        </Button>
        <Button
          type="submit"
          className="mt-2 mt-md-4 col-5 col-md-2 col-xxl-1 d-flex align-items-center justify-content-center"
        >
          Filtrar
        </Button>
      </Form.Group>
    </Form>
  );
};

export default NewFilterForm;
