import { Fragment } from "react";
import { Form, Image } from "react-bootstrap";

const ImgProduct = ({ formData, setFormData }) => {
  const onImageChange = (event) => {
    let name = event.target.name;

    if (name === "imagenes" && event.target.files && event.target.files[0]) {
      setFormData((prevalue) => {
        const arr = [];
        const arrToSend = [];
        for (let i = 0; i < event.target.files.length; i++) {
          arr.push({
            id: event.target.files[i].lastModified,
            url: URL.createObjectURL(event.target.files[i]),
          });
        }
        for (let i = 0; i < event.target.files.length; i++) {
          arrToSend.push(event.target.files[i]);
        }
        return {
          ...prevalue,
          imagenes: arr,
          // imagenes: arrToSend,
          imagenesParaEnviar: arrToSend,
          imagenesParaEnviar2: event.target.files[0],
        };
      });
    }
  };

  const handleGalery = () => {
    setFormData((prevalue) => {
      return {
        ...prevalue,
        imagenes: "",
      };
    });
  };

  return (
    <Form.Group>
      <div
        className={`btn btn-rounded p-0 w-100 pt-3 ${
          !formData.imagenes && "btn-primary px-3 py-2"
        }`}
      >
        <label style={{ cursor: "pointer", color: "white" }} htmlFor="image">
          {formData.imagenes && (
            <Image
              src={formData.imagenes[0].url || formData.imagenPrincipal}
              alt={formData.nombre}
              title="Imagen destacada 0"
              style={{ width: "100%", marginBottom: 20 }}
            />
          )}
          {formData.imagenes ? (
            formData.imagenes.map((image, i) => {
              return (
                <Fragment key={i}>
                  {i !== 0 && (
                    <Image
                      src={image.url}
                      alt={formData.nombre}
                      title={`Imagen destacada ${image.nombre}`}
                      style={{ width: "25%" }}
                    />
                  )}
                </Fragment>
              );
            })
          ) : (
            <p>Seleccionar imágenes</p>
          )}
        </label>
        <input
          type="file"
          onChange={onImageChange}
          className="form-control d-none filetype"
          id="image"
          name="imagenes"
          multiple
        />
      </div>
      {formData.imagenes && (
        <p className="mt-3 text-danger btn w-100" onClick={handleGalery}>
          Eliminar imágenes
        </p>
      )}
    </Form.Group>
  );
};

export default ImgProduct;
