import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Row, Button, Col, Container } from "react-bootstrap";
import { toast } from "react-toastify";
import { Breadcrumbs } from "../../../AbstractElements";
import { useParams } from "react-router-dom";
import { getEmojiDataFromNative } from "emoji-mart";
import DataContext from "../../../_helper/DataContext";
import axios from "axios";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { formatDate } from "@fullcalendar/react";

const EditProspect = () => {
  const { customerID } = useParams();
  const initialENDPOINT = process.env.REACT_APP_ENDPOINT;
  const token = localStorage.getItem("token");
  const history = useNavigate();
  const [formData, setFormData] = useState({});

  const [dataPaisesEndpoint, setDataPaisesEndpoint] = useState([]);
  const [dataVendedoresEndpoint, setDataVendedoresEndpoint] = useState([]);
  const [disableButton, setDisableButton] = useState(false);

  const [metodosDeEnvio, setMetodosDeEnvio] = useState([]);
  const [userAlreadyExist, setUserAlreadyExist] = useState();

  const notify = () =>
    toast(`Cambios guardados para el cliente ${formData.nombre}!`);

  const getMetodosDeEnvio = async () => {
    try {
      const res = await axios(`${initialENDPOINT}/tipodeenvio`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setMetodosDeEnvio(res.data.data?.original.results);
    } catch (error) {
      console.log(error);
    }
  };

  const sendForm = async (e) => {

    e.preventDefault();
    setDisableButton(true);

    const dataToSend = JSON.stringify({
      nombre: formData.nombre || "",
      direccion: formData.direccion || "",
      codigoPostal: formData.codigoPostal || "",
      telefono: formData.telefono || 0,
      email: formData.email || 0,
      contacto: formData.contacto || 0,
      contactoApellido: formData.contactoApellido || 0,
      puestoContacto: formData.contactoPuesto || 0,
      transportadora: formData.transportadora || 0,
      telefonoTransportadora: formData.telefonoTransportadora || "",
      observaciones: formData.observaciones || "",
      usuario: formData.usuario || 0,
      web: formData.web || 0,
      direccionShape: formData.direccionShape || 0,
      direccionBill: formData.direccion || "0",
      vendedor: formData.vendedor || 0,
      ciudad: formData.ciudad || 0,
      ciudadEnvio: formData.ciudadEnvio || "",
      pais: formData.pais || 0,
      cpShape: formData.cpShape || 0,
      paisShape: formData.paisShape || "",
      montoMaximoDePago: formData.montoMaxCompra || 0,
      WhatsApp: formData.WhatsApp || 0,
      Notas: formData.observaciones || 0,
      tipoDeEnvio: formData.tipoDeEnvio || 0,
      prospecto: formData.prospecto ? 1 : 0,
      clave: formData.clave || 0,
      notification: formData.notification ? 1 : 0,
      suspendido: formData.suspendido ? 1 : 0,
      nombreUsuario: formData.nombreUsuario
    });

    try {
      let resp = await axios({
        url: `${initialENDPOINT}/cliente/${customerID}`,
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: dataToSend,
        redirect: "follow",
      });

      if (resp.data.status == 203) {
        setUserAlreadyExist(true);
      } else {
        setUserAlreadyExist(false);
        notify();
        history(`${process.env.PUBLIC_URL}/prospectos`);
      }

      // notify();
      // history(`${initialENDPOINT}/cliente`);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    // console.log("value", value);
    // console.log("name", name);

    setDisableButton(false);
    //e.target.name === "clave" && (value = btoa(e.target.value));

    e.target.name === "prospecto" &&
      (e.target.checked === true ? (value = 1) : (value = 0));
    e.target.name === "prospecto" &&
      (e.target.checked === true
        ? (formData.prospecto = "1")
        : (formData.prospecto = "0"));

    e.target.name === "suspendido" &&
      (e.target.checked === true ? (value = 1) : (value = 0));
    e.target.name === "suspendido" &&
      (e.target.checked === true
        ? (formData.suspendido = "1")
        : (formData.suspendido = "0"));

    e.target.name === "notification" &&
      (e.target.checked === true ? (value = 1) : (value = 0));
    e.target.name === "notification" &&
      (e.target.checked === true
        ? (formData.notification = "1")
        : (formData.notification = "0"));

    setFormData((prevalue) => {
      return {
        ...prevalue,
        [name]: value,
      };
    });
  };

  const pedidoPaises = async () => {
    try {
      const pedido = await axios(`${initialENDPOINT}/pais?cantidad=240`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        redirect: "follow",
      });
      setDataPaisesEndpoint(pedido.data?.data.original.results);
    } catch (error) {
      console.log("Ha ocurrido un error", error);
    }
  };

  const pedidoVendedor = async () => {
    try {
      let pedido = await axios(`${initialENDPOINT}/encargadodeventa`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        redirect: "follow",
      });
      setDataVendedoresEndpoint(pedido.data?.data.original.results);
    } catch (error) {
      console.log("Ha ocurrido un error", error);
    }
  };

  const getUserInfo = async (id) => {
    try {
      let pedido = await axios(`${initialENDPOINT}/cliente/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        redirect: "follow",
      });

      setFormData(pedido.data.data);
    } catch (error) {
      console.log("Ha ocurrido un error", error);
    }
  };

  useEffect(() => {
    getUserInfo(customerID);
    getMetodosDeEnvio();
    pedidoPaises();
    pedidoVendedor();
  }, []);
  return (
    <Container fluid={true}>
      <Breadcrumbs mainTitle="Editar cliente" parent="Cliente" title="Editar" />
      <Form onSubmit={sendForm} className="container">
        <Row>
          <Col className="col-12 col-lg-12 col-xxl-11 bg-white p-4 mb-5 rounded border shadow">
            <Form.Group as={Row} className="align-items-center mb-3">
              <Col className="col-4 col-md-2">
                <Form.Label>Nombre</Form.Label>
              </Col>
              <Col className="col-8 col-md-4">
                <Form.Control
                  type="text"
                  name="nombre"
                  onChange={handleChange}
                  defaultValue={formData.nombre}
                />
              </Col>
              <Col className="col-4 col-md-2">
                <Form.Label>Dirección</Form.Label>
              </Col>
              <Col className="col-8 col-md-4">
                <Form.Control
                  type="text"
                  name="direccion"
                  onChange={handleChange}
                  defaultValue={formData.direccion}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="align-items-center mb-3">
              <Col className="col-4 col-md-2">
                <Form.Label>Teléfono</Form.Label>
              </Col>
              <Col className="col-8 col-md-4">
                <Form.Control
                  type="tel"
                  name="telefono"
                  onChange={handleChange}
                  defaultValue={formData.telefono}
                />
              </Col>
              <Col className="col-4 col-md-2">
                <Form.Label>WhatsApp</Form.Label>
              </Col>
              <Col className="col-8 col-md-4">
                <Form.Control
                  type="tel"
                  name="WhatsApp"
                  onChange={handleChange}
                  defaultValue={formData.WhatsApp}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="align-items-center mb-3">
              <Col className="col-4 col-md-2">
                <Form.Label>País</Form.Label>
              </Col>
              <Col className="col-8 col-md-4">
                <Form.Select
                  value={formData.pais}
                  name="pais"
                  onChange={handleChange}
                >
                  <option disabled>Elija su país</option>
                  {dataPaisesEndpoint.map((item, i) => (
                    <option key={i} value={item.id}>
                      {item.nombre}
                    </option>
                  ))}
                </Form.Select>
              </Col>
              <Col className="col-4 col-md-2">
                <Form.Label>Ciudad</Form.Label>
              </Col>
              <Col className="col-8 col-md-4">
                <Form.Control
                  type="text"
                  name="ciudad"
                  onChange={handleChange}
                  defaultValue={formData.ciudad}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="align-items-center mb-3">
              <Col className="col-4 col-md-2">
                <Form.Label>Código postal</Form.Label>
              </Col>
              <Col className="col-8 col-md-3">
                <Form.Control
                  type="number"
                  name="codigoPostal"
                  onChange={handleChange}
                  defaultValue={formData.codigoPostal}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="align-items-center mb-3">
              <Col className="col-4 col-md-2">
                <Form.Label>Email</Form.Label>
              </Col>
              <Col className="col-8 col-md-5">
                <Form.Control
                  type="email"
                  name="email"
                  onChange={handleChange}
                  defaultValue={formData.email}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="align-items-center mb-3">
              <Col className="col-4 col-md-2">
                <Form.Label>Web</Form.Label>
              </Col>
              <Col className="col-8 col-md-5">
                <Form.Control
                  type="text"
                  name="web"
                  onChange={handleChange}
                  defaultValue={formData.web}
                />
              </Col>
            </Form.Group>
            <br />
            <br />
            <Form.Group as={Row} className="align-items-center mb-3">
              <Col className="col-4 col-md-2">
                <Form.Label>Contacto</Form.Label>
              </Col>
              <Col className="col-4 col-md-3">
                <Form.Label>Nombre Contacto</Form.Label>
                <Form.Control
                  type="text"
                  name="contacto"
                  placeholder="Nombre"
                  onChange={handleChange}
                  defaultValue={formData.contacto}
                />
              </Col>
              <Col className="col-4 col-md-3">
                <Form.Label>Apellido Contacto</Form.Label>
                <Form.Control
                  type="text"
                  name="contactoApellido"
                  placeholder="Apellido"
                  onChange={handleChange}
                  defaultValue={formData.contactoApellido}
                />
              </Col>
              <Col className="col-4 col-md-3">
                <Form.Label>Puesto Contacto</Form.Label>
                <Form.Control
                  type="text"
                  name="contactoPuesto"
                  placeholder="Puesto"
                  onChange={handleChange}
                  defaultValue={formData.puestoContacto}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="align-items-center mb-3">
              <Col className="col-4 col-md-2">
                <Form.Label>Vendedor</Form.Label>
              </Col>
              <Col className="col-8 col-md-4">
                <Form.Select
                  value={formData.vendedor}
                  name="vendedor"
                  onChange={handleChange}
                >
                  <option disabled>Elija su vendedor</option>
                  {dataVendedoresEndpoint?.map((item, i) => (
                    <option key={i} value={item.id}>
                      {item.nombre}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="align-items-center mb-3">
              <Col className="col-4 col-md-2">
                <Form.Label>Monto máximo de compra</Form.Label>
              </Col>
              <Col className="col-8 col-md-4">
                <Form.Control
                  type="number"
                  name="montoMaxCompra"
                  onChange={handleChange}
                  defaultValue={formData.montoMaximoDePago}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="align-items-center mb-3">
              <Col className="col-4 col-md-2">
                <Form.Label>Usuario</Form.Label>
              </Col>
              <Col className="col-8 col-md-4">
                <Form.Control
                  type="text"
                  name="nombreUsuario"
                  value={formData.nombreUsuario}
                  onChange={handleChange}
                  className={userAlreadyExist ? "is-invalid" : ""}
                />
                {userAlreadyExist ? (
                  <small style={{ color: "red" }}>Ese usuario ya existe</small>
                ) : (
                  ""
                )}
              </Col>
              <Col className="col-4 col-md-2">
                <Form.Label>Contraseña</Form.Label>
              </Col>
              <Col className="col-8 col-md-4">
                <Form.Control
                  type="text"
                  name="clave"
                  value={formData.clave}
                  placeholder={"Nuevo Password"}
                  onChange={handleChange}
                  autoComplete="off"
                />
              </Col>
            </Form.Group>
            <div className="d-flex gap-5">
              <br />
              <Form.Check
                type="checkbox"
                id="prospecto"
                label="Prospecto"
                name="prospecto"
                checked={formData.prospecto}
                onChange={handleChange}
              />
              <Form.Group as={Row} className="align-items-center mb-3">
                <Col className="col-8 col-md-9">
                  <Form.Check
                    type="checkbox"
                    id="suspendido"
                    label="Suspendido"
                    name="suspendido"
                    checked={formData.suspendido}
                    onChange={handleChange}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="align-items-center mb-3">
                <Col className="col-8 col-md-12">
                  <Form.Check
                    type="checkbox"
                    id="notification"
                    label="Notificar al usuario"
                    name="notification"
                    checked={formData.notification}
                    onChange={handleChange}
                  />
                </Col>
              </Form.Group>
            </div>
            <br />
            <br />

            <h6 className="pb-3">Envío</h6>
            <Form.Group as={Row} className="align-items-center mb-3">
              <Col className="col-4 col-md-2">
                <Form.Label>Tipo de envío</Form.Label>
              </Col>
              <Col className="col-8 col-md-5">
                <Form.Group className="mb-3 col-9">
                  <Form.Select
                    className="mb-3 col-4"
                    defaultValue={formData.tipoDeEnvio}
                    name="tipoDeEnvio"
                    onChange={(e) => handleChange(e)}
                  >
                    <option disabled>Seleccione</option>
                    {metodosDeEnvio?.map((item, i) => (
                      <option key={i} value={item.id}>
                        {item.nombre}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="align-items-center mb-3">
              <Col className="col-4 col-md-2">
                <Form.Label>Transportadora</Form.Label>
              </Col>
              <Col className="col-8 col-md-5">
                <Form.Label>Nombre</Form.Label>
                <Form.Control
                  type="text"
                  name="transportadora"
                  onChange={handleChange}
                  defaultValue={formData.transportadora}
                />
              </Col>
              <Col className="col-8 col-md-5">
                <Form.Label>Teléfono</Form.Label>
                <Form.Control
                  type="tel"
                  name="telefonoTransportadora"
                  onChange={handleChange}
                  defaultValue={formData.telefonoTransportadora}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="align-items-center mb-3">
              <Col className="col-4 col-md-2">
                <Form.Label>Dirección</Form.Label>
              </Col>
              <Col className="col-8 col-md-4">
                <Form.Control
                  type="text"
                  name="direccionShape"
                  onChange={handleChange}
                  defaultValue={formData.direccionShape}
                />
              </Col>
              <Col className="col-4 col-md-2">
                <Form.Label>Código postal</Form.Label>
              </Col>
              <Col className="col-8 col-md-4">
                <Form.Control
                  type="number"
                  name="cpShape"
                  onChange={handleChange}
                  defaultValue={formData.cpShape}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="align-items-center mb-3">
              <Col className="col-4 col-md-2">
                <Form.Label>País</Form.Label>
              </Col>
              <Col className="col-8 col-md-4">
                <Form.Select
                  defaultValue={formData.paisShape}
                  name="paisShape"
                  onChange={handleChange}
                >
                  <option disabled>Elija país de destino</option>
                  {dataPaisesEndpoint.map((item, i) => (
                    <option key={i} value={item.id}>
                      {item.nombre}
                    </option>
                  ))}
                </Form.Select>
              </Col>
              <Col className="col-4 col-md-2">
                <Form.Label>Ciudad</Form.Label>
              </Col>
              <Col className="col-8 col-md-4">
                <Form.Control
                  type="text"
                  name="ciudadEnvio"
                  onChange={handleChange}
                  defaultValue={formData.ciudadEnvio}
                />
              </Col>
            </Form.Group>
            <Form.Group>
              <Form.Label>Observaciones</Form.Label>
              <Form.Control
                as="textarea"
                name="observaciones"
                onChange={handleChange}
                rows={3}
                defaultValue={formData.observaciones}
              />
            </Form.Group>
            <div className="d-flex justify-content-end">
              <Button
                disabled={disableButton}
                type="submit"
                className="mt-2 mt-md-4 col-2 col-lg-2"
              >
                Guardar
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default EditProspect;
