import React, { useState, useEffect } from "react";
import { Row, Form, Button, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";

const NewFilterForm = ({
  showFilter,
  setPage,
  setFilterENDPOINT,
  resetAll,
  formData,
  setFormData,
}) => {
  const [marcas, setMarcas] = useState([]);
  const notify = () => toast("¡Datos filtrados!");
  const initialENDPOINT = process.env.REACT_APP_ENDPOINT;

  useEffect(() => {
    async function fetchMarcas() {
      try {
        const response = await axios.get(`${initialENDPOINT}/web/marcaproducto?cantidad=500000`);
        const marcasData = response.data.data.original.results;
        setMarcas(marcasData);
      } catch (error) {
        console.error("Error fetching marcas:", error);
      }
    }
  
    fetchMarcas();
  }, []);

  const itemFilter = (e) => {
    e.preventDefault();
    setPage(1);

    setFilterENDPOINT(
      `${
        formData.desde === undefined ? "" : `&desde=${formData.desde}`
      }${
        formData.hasta === undefined ? "" : `&hasta=${formData.hasta}`
      }${
        formData.marca === "" ? "" : `&marca=${formData.marca}`
      }`
    );
    showFilter();
    notify();
  };

  const resetAllFilters = () => {
    resetAll();
    showFilter();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <Form
      onSubmit={itemFilter}
      className="bg-white p-4 rounded mb-3 border justify-content-evenly"
    >
<Row>
  <Form.Group className="col-12 col-md-4 mb-3">
    <div style={{ display: "flex" }}>
      <Col>
        <Form.Label>Desde Fecha</Form.Label>
        <Form.Control
          type="date"
          placeholder="Desde.."
          name="desde"
          onChange={handleChange}
          value={formData.desde || ""}
        />
      </Col>
      &nbsp;&nbsp;
      <Col>
        <Form.Label>Hasta Fecha</Form.Label>
        <Form.Control
          type="date"
          placeholder="Hasta.."
          name="hasta"
          onChange={handleChange}
          value={formData.hasta || ""}
        />
      </Col>
    </div>
  </Form.Group>

  <Form.Group className="col-12 col-md-4 mb-3">
    <Form.Label>Marcas</Form.Label>
    <Form.Control
      as="select"
      name="marca"
      onChange={handleChange}
      value={formData.marca || ""}
    >
      <option value="">Seleccione una marca</option>
      {marcas && marcas.map((marca) => (
        <option key={marca.id} value={marca.id}>
          {marca.nombre}
        </option>
      ))}
    </Form.Control>
  </Form.Group>
</Row>


      <Form.Group className="d-flex align-items-center justify-content-center">
        <Button
          variant="info"
          className="mt-2 mt-md-4 me-auto col-5 col-md-2 col-xxl-1 d-flex align-items-center justify-content-center"
          onClick={resetAllFilters}
        >
          Limpiar
        </Button>
        <Button
          type="submit"
          className="mt-2 mt-md-4 col-5 col-md-2 col-xxl-1 d-flex align-items-center justify-content-center"
        >
          Filtrar
        </Button>
      </Form.Group>
    </Form>
  );
};

export default NewFilterForm;
