import { useState, useEffect } from "react";
import { Form, Row, Button, Col, Container, Image } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Breadcrumbs } from "../../../../AbstractElements";

const NewSlider = () => {
  const history = useNavigate();
  const token = localStorage.getItem("token");
  const initialENDPOINT = process.env.REACT_APP_ENDPOINT;
  const [formData, setFormData] = useState({
    suspendido: false,
    tipoUbicacion: "",
    orden: "",
    link: "",
    nombre: "",
    textoPrincipal: "",
    textoSecundario: "",
  });
  const [disableButton, setDisableButton] = useState(false);
  const [tipoUbicacionOptions, setTipoUbicacionOptions] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${initialENDPOINT}/tipobanner`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const parsedData = response.data.data.original.results.map(({ id, nombre }) => ({
          id,
          nombre,
        }));
        setTipoUbicacionOptions(parsedData);
      } catch (error) {
        console.error("Error fetching tipoUbicacionOptions", error);
      }
    };

    fetchData();
  }, [initialENDPOINT, token]);

  const suspendidoOptions = [
    { id: 1, nombre: "si" },
    { id: 0, nombre: "no" },
  ];

  const notify = () => toast("Slider creado!");

  const sendForm = async (e) => {
    e.preventDefault();

    const myHeaders = {
      Authorization: `Bearer ${token}`,
    };

    const formdata = new FormData();
    formdata.append("imagen", selectedImage, selectedImage.name);
    formdata.append("tipo", formData.tipoUbicacion);
    formdata.append("orden", formData.orden);
    formdata.append("link", formData.link);
    formdata.append("nombre", formData.nombre);
    formdata.append("textoPrincipal", formData.textoPrincipal);
    formdata.append("textoSecundario", formData.textoSecundario);
    formdata.append("suspendido", formData.suspendido);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
    };

    try {
      const response = await axios.post(`${initialENDPOINT}/banner`, formdata, {
        headers: myHeaders,
      });

      console.log(response.data);

      notify();

      history('/sliders');
    } catch (error) {
      console.error("Error creating slider", error);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData((prevValues) => {
      if (type === "checkbox") {
        return {
          ...prevValues,
          [name]: checked,
        };
      }

      return {
        ...prevValues,
        [name]: value,
      };
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      setSelectedImage(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Container fluid={true}>
      <Breadcrumbs
        mainTitle={formData.nombre || "Crear Slider"}
        parent="Sliders"
        title="Crear slider"
      />
      <Form onSubmit={sendForm} className="container">
        <Row>
          <Col className="col-11 col-lg-9 col-xxl-8 bg-white p-4 mb-5 rounded border shadow">
            <Form.Group className="d-flex align-items-center mb-3">
              <Form.Label className="col-4 col-md-4">Tipo de Ubicacion</Form.Label>
              <Form.Select
                defaultValue={"Seleccione"}
                name="tipoUbicacion"
                onChange={handleChange}
                className="w-100"
              >
                <option disabled>Seleccione</option>
                {tipoUbicacionOptions.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.nombre}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="d-flex align-items-center mb-3">
              <Form.Label className="col-4 col-md-4">Link</Form.Label>
              <Form.Control
                type="text"
                name="link"
                value={formData.link}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="d-flex align-items-center mb-3">
              <Form.Label className="col-4 col-md-4">Nombre</Form.Label>
              <Form.Control
                type="text"
                name="nombre"
                value={formData.nombre}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="d-flex align-items-center mb-3">
              <Form.Label className="col-4 col-md-4">Orden</Form.Label>
              <Form.Control
                type="number"
                name="orden"
                value={formData.orden}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="d-flex align-items-center mb-3">
              <Form.Label className="col-4 col-md-4">Suspendido</Form.Label>
              <Form.Select
                value={formData.suspendido}
                name="suspendido"
                onChange={handleChange}
                className="w-100"
              >
                <option disabled>Seleccione</option>
                {suspendidoOptions.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.nombre}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="d-flex align-items-center mb-3">
              <Form.Label className="col-4 col-md-4">Texto Principal</Form.Label>
              <Form.Control
                type="text"
                name="textoPrincipal"
                value={formData.textoPrincipal}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="d-flex align-items-center mb-3">
              <Form.Label className="col-4 col-md-4">Texto Secundario</Form.Label>
              <Form.Control
                type="text"
                name="textoSecundario"
                value={formData.textoSecundario}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Subir Imagen</Form.Label>
              <Form.Control type="file" name="imagen" onChange={handleImageChange} />
              {imagePreview && (
                <Image src={imagePreview} alt="Preview" className="mt-2" thumbnail />
              )}
            </Form.Group>

            <Button
              disabled={disableButton}
              type="submit"
              className="mt-2 mt-md-2 col-6 col-lg-2"
            >
              Guardar
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default NewSlider;
