import ErrorPage from '../Components/Pages/ErrorPage';
import Logins from '../Login/Signin';

export const authRoutes = [
  // Login
  { path: `${process.env.PUBLIC_URL}/login`, Component: <Logins /> },

  //Error
  { path: `${process.env.PUBLIC_URL}/error-page`, Component: <ErrorPage /> },
];
