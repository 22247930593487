import { useEffect } from "react";
import { useContext, useState } from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Breadcrumbs } from "../../../AbstractElements";
import DataContext from "../../../_helper/DataContext";
import useGoToLogin from "../../../_helper/hooks/useGoToLogin";
import NewFilterForm from "./NewFilterForm";
import ProductsTable from "./ProductsTable";
import OrdersProductModal from "../../Common/OrdersProductModal";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const initialForm = {
  suspended: 0,
  name: "",
  code: "",
  priceFrom: 0,
  priceTo: 0,
  stockFrom: 0,
  stockTo: 0,
  brand: 0,
  product: 0,
  color: 0,
  category: 0,
  material: 0,
  size: 0,
};

const Products = () => {
  const history = useNavigate();
  const notify = (message) => toast(message);
  const [showModal, setShowModal] = useState(false);
  const [newQuery, setNewQuery] = useState(false);
  const [newFilter, setNewFilter] = useState(false);
  const [formData, setFormData] = useState(initialForm);
  const [showCancellations, setShowCancellations] = useState(false);
  const [isMounted, setIsMounted] = useState(true);
  const token = localStorage.getItem("token");
  const initialENDPOINT = process.env.REACT_APP_ENDPOINT;
  const { goToLogin } = useGoToLogin();

  const {
    data,
    errMsg,
    loading,
    totalRows,
    perPage,
    setPerPage,
    page,
    setPage,
    setENDPOINT,
    setFilterENDPOINT,
    filterENDPOINT,
  } = useContext(DataContext);

  const resetAll = () => {
    const random = Date.now();
    setFormData(initialForm);
    // setFilterENDPOINT(`&random=${random}`);
    setENDPOINT(
      `${initialENDPOINT}/producto?pagina=1&cantidad=10&random=${random}`
    );
    setFilterENDPOINT(``);
    setPage(1);
  };
  const handleShowCancellations = () => {
    setShowCancellations((prev) => !prev);
    setENDPOINT(`${initialENDPOINT}/producto?pagina=1&cantidad=10`);
  };
  const showFilter = () => {
    setNewFilter(!newFilter);
    setNewQuery(false);
    setENDPOINT(`${initialENDPOINT}/producto?pagina=1`);
  };

  const handleProductoDelete = async ({ id }) => {

    setFormData({ ...formData, idToDelete: id });
    setShowModal(true);
  };

  const confirmDelete = async () => {

    const id = formData.idToDelete;

    axios.delete(`${initialENDPOINT}/producto/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(response => {
        //Orders();
        notify(`Producto eliminado!`);
        history(`/productos`)
      });

  };

  useEffect(() => {
    !token && goToLogin();
    setPage(1);
    const random = Date.now();
    if (isMounted) {
      setENDPOINT(
        `${initialENDPOINT}/producto?pagina=1&cantidad=10&random=${random}`
      );
      setFilterENDPOINT("");
    }
    return () => {
      setIsMounted(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container fluid={true}>
      <Breadcrumbs mainTitle="Productos" parent="Productos" title="Listado" />
      <div className="d-flex justify-content-around justify-content-sm-end gap-2">
        <Link to="/nuevo-producto">
          <Button
            className="mb-3 px-3"
            variant={newQuery ? "primary" : "outline-primary"}
          >
            <i className="fa fa-plus f-12 pe-2 m-0" />
            Nuevo producto
          </Button>
        </Link>
        <Button
          onClick={showFilter}
          className="mb-3 px-3"
          variant={newFilter ? "primary" : "outline-primary"}
        >
          <i className="fa fa-filter f-12 pe-2 m-0" />
          Filtros avanzados
        </Button>
        <Button
          onClick={resetAll}
          className="mb-3 px-3"
          variant={newFilter ? "primary" : "outline-primary"}
        >
          <i className="fa fa-refresh f-12 m-0" />
        </Button>
      </div>
      <Row>
        <Col xl="12" className="des-xl-100 box-col-12">
          {newFilter && (
            <NewFilterForm
              showFilter={showFilter}
              setPage={setPage}
              setFilterENDPOINT={setFilterENDPOINT}
              resetAll={resetAll}
              formData={formData}
              setFormData={setFormData}
            />
          )}
          <ProductsTable
            data={data}
            errMsg={errMsg}
            loading={loading}
            totalRows={totalRows}
            perPage={perPage}
            setPerPage={setPerPage}
            page={page}
            setPage={setPage}
            setENDPOINT={setENDPOINT}
            filterENDPOINT={filterENDPOINT}
            showCancellations={showCancellations}
            handleShowCancellations={handleShowCancellations}
            handleProductoDelete={handleProductoDelete}
          />
        </Col>
      </Row>
      <Row>
        <OrdersProductModal
          show={showModal}
          onHide={() => setShowModal(false)}
          title="¿Seguro que deseas eliminar este producto?"
        >
          <Row>
            <div className="d-flex align-items-end justify-content-center">
              <Button
                type="submit"
                className="mt-auto mb-2 col-4 me-4"
                onClick={confirmDelete}
              >
                Eliminar
              </Button>
              <Button
                variant="danger"
                onClick={() => setShowModal(false)}
                className="mt-auto mb-2 col-4"
              >
                Cancelar
              </Button>
            </div>
          </Row>
        </OrdersProductModal>
      </Row>
    </Container>
  );
};

export default Products;
