import { Link } from "react-router-dom";
import { Container, Col } from "reactstrap";
import { Error4 } from "../Common/Data/SvgIcons";

const ErrorPage = () => {
  const clearData = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("name");
    localStorage.removeItem("idUser");
  };

  return (
    <>
      <div className="page-wrapper" id="pageWrapper">
        <div className="error-wrapper" style={{ minHeight: "50vh" }}>
          <Container>
            <div className="error-page1">
              <div className="svg-wrraper mb-0 w-75 mx-auto">
                <Error4 />
              </div>
              <Col md="8" className="offset-md-2 mt-5">
                <h3 style={{ textTransform: "none" }}>
                  Se ha producido un error inesperado
                </h3>
                <p className="sub-content">
                  La página a la que intenta acceder no está disponible en este
                  momento. Si experimentas este error recurrentemente te
                  recomendamos que vuelvas a iniciar sesión.
                </p>
                <Link
                  onClick={clearData}
                  to={`${process.env.PUBLIC_URL}/login`}
                  className="btn btn-primary btn-lg"
                >
                  Cerrar sesión
                </Link>
              </Col>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
