import { useContext, useEffect, useState } from "react";
import { Button, Form, InputGroup, Row } from "react-bootstrap";
import MyModal from "../../../../Common/MyModal";
import Loader from "../../../../Common/Loader";
import Vacio from "../../../../Common/Vacio";
import DataContext from "../../../../../_helper/DataContext";
import useGoToLogin from "../../../../../_helper/hooks/useGoToLogin";
import DataTable from "react-data-table-component";
import axios from "axios";

const Cliente = ({ handleChange, formData, setFormData, emptyClient }) => {
  const initialENDPOINT = process.env.REACT_APP_ENDPOINT;

  const [newQuery, setNewQuery] = useState(false);
  const [origenes, setOrigenes] = useState([]);
  const [vendedores, setVendedores] = useState([]);
  const [etapas, setEtapas] = useState([]);
  const [isMounted, setIsMounted] = useState(true);
  const token = localStorage.getItem("token");
  const { goToLogin } = useGoToLogin();
  const {
    data,
    errMsg,
    loading,
    totalRows,
    perPage,
    setPerPage,
    setPage,
    setENDPOINT,
  } = useContext(DataContext);

  const queriesColumns = [
    {
      name: "ID",
      selector: (row) => row.id,
      center: false,
      wrap: true,
      grow: 0,
    },
    {
      name: "Nombre",
      selector: (row) => row.nombre,
      center: false,
      wrap: false,
    },
    {
      name: "Seleccionar",
      selector: (row) => [
        <button
          key={2}
          className="btn text-dark w-auto p-1 mx-1 my-0"
          type="button"
          title="Seleccionar"
          onClick={(e) => handleClient(row.id, row.nombre, row.ctacte)}
        >
          <i className="fa fa-check f-16 px-1" />
        </button>,
      ],
      center: true,
      compact: true,
    },
  ];

  const getData = async (url, setState) => {
    try {
      const res = await axios(`${initialENDPOINT}/${url}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      isMounted && setState(res.data.data?.original.results);
    } catch (error) {
      let status = error.response.status;
      console.log("error.response", error.response);
      goToLogin(status);
    }
  };

  const getCliente = async (e) => {
    if (e.keyCode !== 13) {
      return;
    }
    try {
      const response = await axios(
        `${initialENDPOINT}/cliente?id=${formData.cliente}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          redirect: "follow",
        }
      );

      const dataResponse = response.data.data.original.results[0];

      if (response.data.data.original.status === 200) {
        setFormData((prevalue) => {
          return {
            ...prevalue,
            cliente: dataResponse.id,
            nombreCliente: dataResponse.nombre,
            creditoCtaCte: dataResponse.ctacte,
          };
        });
      } else {
        setFormData((prevalue) => {
          return {
            ...prevalue,
            nombreCliente: "Cliente no encontrado",
          };
        });
      }
    } catch (error) {
      console.log(error);
      setFormData((prevalue) => {
        return {
          ...prevalue,
          nombreCliente: "Cliente no encontrado",
        };
      });
    }
  };

  const handleClient = (id, nombre, ctacte) => {
    setFormData((prevalue) => {
      return {
        ...prevalue,
        cliente: id,
        nombreCliente: nombre,
        creditoCtaCte: ctacte,
      };
    });
    setNewQuery(false);
  };

  const handlePageChange = (pag) => {
    setENDPOINT(
      `${initialENDPOINT}/cliente?pagina=${pag}&cantidad=${perPage}${formData.cliente ? "&nombre=" + formData.cliente : ""
      }&asdasd`
    );
  };

  const handlePerRowsChange = async (newPerPage, pag) => {
    setPerPage(newPerPage);
    setPage(pag);
    await setENDPOINT(
      `${initialENDPOINT}/cliente?pagina=${pag}&cantidad=${newPerPage}${formData.cliente ? "&nombre=" + formData.cliente : ""
      }`
    );
  };

  useEffect(() => {
    !token && goToLogin();
    const random = Date.now();
    setENDPOINT(`${initialENDPOINT}/cliente?pagina=1&random=${random}`);
  }, [newQuery]);

  useEffect(() => {
    getData("origenpedido?cantidad=10000", setOrigenes);
    getData("encargadodeventa?cantidad=10000", setVendedores);
    getData("etapapedido?cantidad=10000", setEtapas);

    return () => {
      setIsMounted(false);
    };
  }, []);

  return (
    <Row className="bg-white mb-3 p-4 pb-2 border rounded m-5">
      <Form.Group >
        <Form.Label>ID Cliente</Form.Label>
        <InputGroup>
          <Form.Control
            type="text"
            name="cliente"
            id="cliente"
            defaultValue={formData.cliente}
            onChange={(e) => handleChange(e)}
            onKeyDown={getCliente}
            className={emptyClient && "is-invalid"}
          ></Form.Control>
          <InputGroup.Text
            style={{ cursor: "pointer" }}
            onClick={() => setNewQuery(true)}
          >
            <i className="fa fa-search" aria-hidden="true"></i>
          </InputGroup.Text>
        </InputGroup>
        {formData.nombreCliente && (
          <Form.Text className="text-success ms-2">
            {formData.nombreCliente}
          </Form.Text>
        )}
      </Form.Group>
      {/* <Form.Group className="mb-3 col-3">
        <Form.Label>Orígen</Form.Label>
        <Form.Select
          name="origen"
          value={formData.origen || 0}
          onChange={(e) => handleChange(e)}
          className="mb-3"
        >
          <option disabled value={0}>
            SELECCIONE UN ORÍGEN
          </option>
          {origenes.map((item, i) => (
            <option key={i} value={item.id}>
              {item.nombre}
            </option>
          ))}
        </Form.Select>
      </Form.Group> */}
      {/* <Form.Group className="mb-3 col-3">
        <Form.Label>Vendedor</Form.Label>
        <Form.Select
          name="vendedor"
          value={formData.vendedor || 0}
          onChange={(e) => handleChange(e)}
          className="mb-3"
        >
          <option disabled value={0}>
            SELECCIONE UN VENDEDOR
          </option>
          {vendedores?.map((item, i) => (
            <option key={i} value={item.id}>
              {item.nombre}
            </option>
          ))}
        </Form.Select>
      </Form.Group> */}
      {/* <Form.Group className="mb-3 col-3">
        <Form.Label>Etapa de Preparación</Form.Label>
        <Form.Select
          name="etapa"
          value={formData.etapa || 0}
          onChange={(e) => handleChange(e)}
          className="mb-3"
        >
          <option disabled value={0}>
            SELECCIONE UNA ETAPA
          </option>
          {etapas?.map((item, i) => (
            <option key={i} value={item.id}>
              {item.nombre}
            </option>
          ))}
        </Form.Select>
      </Form.Group> */}
      <MyModal
        show={newQuery}
        onHide={() => setNewQuery(false)}
        title="Buscar cliente"
      >
        <Row>
          <Form.Group className="mb-3 col-6">
            <Form.Label>Cliente</Form.Label>
            <Form.Control
              type="text"
              name="cliente"
              placeholder="Ingrese el nombre"
              onChange={(e) => handleChange(e)}
            ></Form.Control>
          </Form.Group>
          <Button
            type="submit"
            className="mt-auto mb-3 col-3"
            onClick={() =>
              setENDPOINT(
                `${initialENDPOINT}/cliente?pagina=1&nombre=${formData.cliente}`
              )
            }
          >
            Buscar
          </Button>
        </Row>
        {errMsg && <p>{errMsg}</p>}
        <DataTable
          columns={queriesColumns}
          data={data}
          striped
          customStyles={{
            rows: {
              highlightOnHoverStyle: {
                backgroundColor: "rgb(230, 244, 244)",
                borderBottomColor: "#FFFFFF",
                outline: "1px solid #FFFFFF",
              },
            },
            pagination: {
              style: {
                border: "none",
              },
            },
          }}
          pointerOnHover
          highlightOnHover
          progressPending={loading}
          progressComponent={<Loader />}
          noDataComponent={<Vacio />}
          pagination
          paginationServer
          paginationComponentOptions={{
            rowsPerPageText: "Filas por página",
            rangeSeparatorText: "de",
          }}
          paginationDefaultPage
          paginationTotalRows={totalRows}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handlePerRowsChange}
          persistTableHead
        />
      </MyModal>
    </Row>
  );
};

export default Cliente;
