const TableTitle = ({ value, show }) => {
  return (
    <>
      {show ? (
        <div className="d-none d-md-block">{value}</div>
      ) : (
        <div className="d-md-block">{value}</div>
      )}
    </>
  );
};

export default TableTitle;
