import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Form, Row, Button, Col, Container } from "react-bootstrap";
import { toast } from "react-toastify";
import { Breadcrumbs } from "../../../../AbstractElements";
import axios from "axios";
import Cliente from "./Cliente";
// import Totales from "./Totales";
// import Detalle from "./Detalle";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-quill/dist/quill.snow.css";

const TemplateRecibos = () => {
  const { invoiceID } = useParams();
  const history = useNavigate();
  const token = localStorage.getItem("token");
  const initialENDPOINT = process.env.REACT_APP_ENDPOINT;
  const [disableButton, setDisableButton] = useState(false);
  const [isMounted, setIsMounted] = useState(true);
  const [listaFormaDePago, setListaFormaDePago] = useState([]);

  const [formData, setFormData] = useState({
    // Cliente
    clienteNombre: "",
    billTo: "",
    shipVia: "",
    Terms: "",
    salesPerson: "",
    peso: "",
    codigoUPS: "",
    formaDePago: "",
    shipTo: "",
    FOB: "",
    fechaOrden: "",
    orden: "",
    UPS: "",
    // Detalle
    qordered: "",
    qshipped: "",
    qborder: "",
    itemNumber: "",
    Descripcion: "",
    listPrice: "",
    netPrice: "",
    detalle: [],
    // Totales
    subTotal: 0,
    DescuentoPorPromociones: 0,
    DescuentoPorcentual: 0,
    descuentoPorPorcentaje: 0,
    DescuentoNeto: 0,
    TotalEnvio: 0,
    totalPedido: 0,
  });

  const notify = () => toast("Recibo creado!");

  const handleChange = (e) => {
    let value;
    e.target.type === "checkbox"
      ? (value = e.target.checked)
      : (value = e.target.value);
    let name = e.target.name;

    setFormData((prevalue) => {
      return {
        ...prevalue,
        [name]: value,
      };
    });
  };

  const sendForm = (e) => {
    e.preventDefault();
    if (e.keyCode === 13) {
      return;
    }
    sendData();
    notify();
    // history(`${process.env.PUBLIC_URL}/pedidos`);
  };

  const sendData = async () => {

    // setDisableButton(true);

    const dataToSend = JSON.stringify({
      cliente: `${formData.cliente}`,
      formaDePago: `${formData.formaDePago}`,
      total: `${formData.total}`,
      observaciones: `${formData.observaciones}`,
      garantia: `${formData.garantia}`
    });

    var requestOptions = {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: dataToSend,
      redirect: 'follow'
    };

    fetch(`${initialENDPOINT}/recibo/manual`, requestOptions)
      .then(response => response.json())
      .then(result => result)
      .catch(error => console.log('error', error));

    history(`${process.env.PUBLIC_URL}/recibos`);

  };

  const getFormasDePago = async () => {
    try {
      const res = await axios.get(`${initialENDPOINT}/formadepago`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      // console.log("res", res.data.data.original.results);

      setListaFormaDePago(res.data.data.original.results);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    isMounted && getFormasDePago();
    return () => {
      setIsMounted(false);
    };
  }, []);

  return (
    <Container fluid={true} className="mb-3 col-6">
      <Breadcrumbs mainTitle={`Nuevo Recibo`} parent="Recibos" title="Crear" />
      <Form>
        <Row className="bg-white mb-3 p-4 pb-2 border rounded m-5">
          <Col className="col-12">
            <Cliente
              handleChange={handleChange}
              formData={formData && formData}
              setFormData={setFormData}
            />
            <br />
            <Form.Group className="d-flex align-items-end mb-3">
              <Form.Label className="col-4 col-md-4">Forma de pago</Form.Label>
              <Form.Select
                value={formData.formaDePago || 0}
                name="formaDePago"
                onChange={handleChange}
              // className={emptyFormaDePago ? `is-invalid` : ""}
              >
                <option value={0}>Seleccione</option>
                {listaFormaDePago.map((item, i) => (
                  <option key={i} value={item.id}>
                    {item.nombre}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="d-flex align-items-center mb-3">
              <Form.Label className="col-4 col-md-4">Observaciones</Form.Label>
              <Form.Control
                type="textarea"
                name="observaciones"
                onChange={handleChange}
                defaultValue={formData?.observaciones}
              />
            </Form.Group>
            <Form.Group className="d-flex align-items-center mb-3">
              <Form.Label className="col-4 col-md-4">Total</Form.Label>
              <Form.Control
                type="number"
                name="total"
                onChange={handleChange}
                defaultValue={formData?.total}
              />
            </Form.Group>
            <Form.Group className="d-flex align-items-center mb-3">
            <Form.Check
              type="checkbox"
              label="Garantía"
              name="garantia"
              onChange={handleChange}
              defaultValue={formData?.garantia}
            />
          </Form.Group>
            {/* <Detalle
              handleChange={handleChange}
              formData={formData && formData}
              setFormData={setFormData}
            />
            <br />
            <Totales
              handleChange={handleChange}
              formData={formData && formData}
              setFormData={setFormData}
            /> */}
          </Col>
          <Col className="col-12 col-lg-8 col-xxl-6"></Col>
        </Row>
        <div className="d-flex justify-content-between">
          <Link key={1} to={`/recibos`}>
            <Button variant="danger" className="mb-5 mt-2">
              Cancelar
            </Button>
          </Link>
          <div>
            <Button
              disabled={disableButton}
              onClick={(e) => sendForm(e)}
              className="mb-5 mt-2 ms-3"
            >
              Guardar
            </Button>
          </div>
        </div>
      </Form>
    </Container>
  );
};

export default TemplateRecibos;
