import { useContext, useEffect, useState } from "react";
import { Button, Form, InputGroup, Row } from "react-bootstrap";
import ProductsTable from "./ProductsTable";
import MyModal from "../../../../Common/MyModal";
import Loader from "../../../../Common/Loader";
import Vacio from "../../../../Common/Vacio";
import DataContext from "../../../../../_helper/DataContext";
import useGoToLogin from "../../../../../_helper/hooks/useGoToLogin";
import axios from "axios";
import DataTable from "react-data-table-component";

const ProductDetails = ({ handleChange, formData, setFormData }) => {
  const initialENDPOINT = process.env.REACT_APP_ENDPOINT;
  const token = localStorage.getItem("token");
  const [newQuery, setNewQuery] = useState(false);
  const [inputMsg, setInputMsg] = useState("");
  const [total, setTotal] = useState(0);
  const { goToLogin } = useGoToLogin();
  const {
    data,
    errMsg,
    loading,
    totalRows,
    perPage,
    setPerPage,
    setPage,
    setENDPOINT,
  } = useContext(DataContext);

  const queriesColumns = [
    {
      name: "ID",
      selector: (row) => row.id,
      center: false,
      wrap: true,
      grow: 0,
    },
    {
      name: "SKU",
      selector: (row) => row.codigo,
      center: false,
      wrap: true,
      grow: 0,
    },
    {
      name: "Producto",
      selector: (row) => row.nombre,
      center: false,
      wrap: false,
      grow: 4
    },
    {
      name: "Marca",
      selector: (row) => row.nombreMarca,
      center: false,
      wrap: false,
      grow: 3
    },
    {
      name: "Color",
      selector: (row) => row.colorNombre,
      center: false,
      wrap: false,
      grow: 2
    },
    {
      name: "Seleccionar",
      selector: (row) => [
        row.stock <= 0 ? (
          <p key={1}>Sin stock</p>
        ) : (
          <button
            key={2}
            className="btn text-dark w-auto p-1 mx-1 my-0"
            type="button"
            title="Seleccionar"
            onClick={(e) => handleProduct(row)}
          >
            <i className="fa fa-check f-16 px-1" />
          </button>
        ),
        ,
      ],
      center: true,
      compact: true,
    },
  ];

  const getId = async (e) => {
    if (e.keyCode !== 13) {
      return;
    }
    try {
      const response = await axios(
        `${initialENDPOINT}/producto/${formData.idProducto}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          redirect: "follow",
        }
      );

      if (response.data.status === 201) {
        setFormData((prevalue) => {
          return {
            ...prevalue,
            nombreProducto: "Producto no encontrado",
            codigoProducto: "",
            precioProducto: "",
            marcaProducto: "",
            colorProducto: "",
            tamanoProducto: "",
            cantidadProducto: "",
            stockProducto: "",
          };
        });
      }
      if (response.data.status === 200) {
        // console.log("response", response);
        setFormData((prevalue) => {
          return {
            ...prevalue,
            idProducto: response.data.message.id,
            nombreProducto: response.data.message.nombre,
            codigoProducto: response.data.message.codigo,
            precioProducto: response.data.message.precio,
            marcaProducto: response.data.message.marca,
            colorProducto: response.data.message.color,
            stockProducto: response.data.message.stock,
            producto: response.data.message,
          };
        });
      }
    } catch (error) {
      console.log(error.response);
      setFormData((prevalue) => {
        return {
          ...prevalue,
          nombreProducto: "Producto no encontrado",
          codigoProducto: "",
          precioProducto: "",
          marcaProducto: "",
          colorProducto: "",
          tamanoProducto: "",
          cantidadProducto: "",
          stockProducto: "",
        };
      });
    }
  };

  const handleProduct = ({
    id,
    codigo,
    nombre,
    precio,
    marca,
    color,
    tamano,
    stock,
  }) => {
    setFormData((prevalue) => {
      return {
        ...prevalue,
        idProducto: id,
        codigoProducto: codigo,
        nombreProducto: nombre,
        precioProducto: precio,
        marcaProducto: marca,
        colorProducto: color,
        tamanoProducto: tamano,
        stockProducto: stock,
      };
    });
    setNewQuery(false);
  };

  const handleProductAdd = () => {
    if (
      !formData.idProducto ||
      !formData.precioProducto ||
      !formData.cantidadProducto
    ) {
      setInputMsg("Debes completar todos los campos.");
      return;
    }

    // Verificar si el producto con el mismo código ya existe
    const productoExistenteIndex = formData.productos?.findIndex(
      (producto) => producto.idProducto === formData.idProducto
    );
    if (productoExistenteIndex >= 0) {
      const productosActualizados = [...formData.productos];
      const productoExistente = productosActualizados[productoExistenteIndex];

      const cantidadPrevia = productoExistente.cantidad;
      const cantidadTotal =
        parseInt(productoExistente.cantidad) +
        parseInt(formData.cantidadProducto);

      if (cantidadTotal > formData.stockProducto) {
        setInputMsg(
          `Solo hay stock suficiente de ${
            formData.stockProducto - cantidadPrevia
          } productos más.`
        );
        return;
      } else {
        productoExistente.cantidad =
          parseInt(productoExistente.cantidad) +
          parseInt(formData.cantidadProducto);
        setFormData((prevalue) => ({
          ...prevalue,
          productos: productosActualizados,
          idProducto: "",
          nombreProducto: "",
          codigoProducto: "",
          precioProducto: "",
          marcaProducto: "",
          colorProducto: "",
          tamanoProducto: "",
          cantidadProducto: "",
        }));
        setInputMsg("");

        return;
      }
      return;
    }

    if (formData.cantidadProducto > formData.stockProducto) {
      setInputMsg(
        `Solo hay stock suficiente de ${formData.stockProducto} productos.`
      );
      return;
    } else {
      console.log(formData);
      setFormData((prevalue) => {
        const newProduct = {
          idProducto: formData.idProducto,
          cantidad: parseInt(formData.cantidadProducto),
          precio: formData.precioProducto,
          codigo: formData.codigoProducto,
          producto: formData.nombreProducto,
          color: formData.colorProducto,
          marca: formData.marcaProducto,
          tamano: formData.tamanoProducto,
        };

        return {
          ...prevalue,
          productos: [...prevalue?.productos, newProduct],
          idProducto: "",
          nombreProducto: "",
          codigoProducto: "",
          precioProducto: "",
          marcaProducto: "",
          colorProducto: "",
          cantidadProducto: "",
        };
      });
    }

    setInputMsg("");
  };

  const handleProductDelete = ({ codigo }) => {
    const productosActualizados = formData.productos.filter(
      (producto) => producto.codigo !== codigo
    );
    setTotal(0);
    setFormData((prevalue) => ({
      ...prevalue,
      productos: productosActualizados,
    }));
  };

  const handleQuantity = () => {
    const cantidadDeArticulos = formData.productos.reduce(
      (acc, curr) => acc + 1,
      0
    );
    const cantidadDeUnidades = formData.productos.reduce(
      (acc, curr) => acc + parseInt(curr.cantidad),
      0
    );

    setFormData((prevalue) => {
      return {
        ...prevalue,
        cantidadDeArticulos: cantidadDeArticulos,
        cantidadDeUnidades: cantidadDeUnidades,
      };
    });
  };

  const handlePageChange = (page) => {
    setENDPOINT(
      `${initialENDPOINT}/producto?pagina=${page}&cantidad=${perPage}&yo=1&nombre=${encodeURIComponent(formData.productoNombre || '')}&nombreMarca=${encodeURIComponent(formData.nombreMarca || '')}`
    );
  };
  
  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    setPage(page);
    await setENDPOINT(
      `${initialENDPOINT}/producto?pagina=${page}&cantidad=${newPerPage}&yo=2&nombre=${encodeURIComponent(formData.productoNombre || '')}&nombreMarca=${encodeURIComponent(formData.nombreMarca || '')}`
    );
  };

  const subtotalProductos = (array) => {
    return array?.reduce(
      (acc, curr) => acc + parseFloat(curr.precio) * parseFloat(curr.cantidad),
      0.0
    );
  };

  const calcularTotales = () => {
    setFormData((prevFormData) => {
      const productos = prevFormData.productos;
      const subtotal = subtotalProductos(productos)?.toFixed(2);

      return {
        ...prevFormData,
        subtotal: subtotal,
      };
    });
  };

  useEffect(() => {
    !token && goToLogin();
    const random = Date.now();
    newQuery &&
    setENDPOINT(`${initialENDPOINT}/producto?pagina=1&random=${random}`);
    setInputMsg("");
  }, [newQuery]);

  useEffect(() => {
    setFormData((prevalue) => {
      return {
        ...prevalue,
        cantidadDeArticulos: 0,
        cantidadDeUnidades: 0,
        productos: [],
      };
    });
  }, []);

  useEffect(() => {
    formData.productos && handleQuantity();
    calcularTotales();
  }, [formData.productos]);

  return (
    <Row className="bg-white mb-3 p-4 pb-2 border rounded m-5">
      <h6 className="pb-3">Detalles del pedido</h6>
      <Form.Group className="mb-3 col-4">
        <Form.Label>ID de producto</Form.Label>
        <InputGroup>
          <Form.Control
            type="number"
            name="idProducto"
            value={formData.idProducto || ""}
            placeholder="Seleccione el producto"
            onChange={(e) => handleChange(e)}
            onKeyDown={getId}
          ></Form.Control>
          <InputGroup.Text
            style={{ cursor: "pointer" }}
            onClick={() => setNewQuery(true)}
          >
            <i className="fa fa-search" aria-hidden="true"></i>
          </InputGroup.Text>
        </InputGroup>
        {formData.nombreProducto && (
          <Form.Text
            className={
              formData.nombreProducto !== "Producto no encontrado"
                ? `text-success ms-2`
                : `text-danger ms-2`
            }
          >
            {formData.nombreProducto}
          </Form.Text>
        )}
      </Form.Group>
      <Form.Group className="mb-3 col-3">
        <Form.Label>Precio</Form.Label>
        <InputGroup>
          <InputGroup.Text>$</InputGroup.Text>
          <Form.Control
            type="number"
            name="precioProducto"
            value={formData.precioProducto || ""}
            onChange={(e) => handleChange(e)}
          />
        </InputGroup>
      </Form.Group>
      <Form.Group className="mb-3 col-3">
        <Form.Label>Cantidad</Form.Label>
        <Form.Control
          type="number"
          name="cantidadProducto"
          value={formData.cantidadProducto || ""}
          onChange={(e) => handleChange(e)}
        />
      </Form.Group>
      <Form.Group className="d-flex align-items-end justify-content-end mb-3 col-2">
        <Button onClick={handleProductAdd}>Agregar</Button>
      </Form.Group>
      {inputMsg && (
        <Form.Text className="text-danger mt-0">{inputMsg}</Form.Text>
      )}
      <ProductsTable
        formData={formData}
        handleProductDelete={handleProductDelete}
      />
      <div>
        <Form.Text className="me-2">
          Cantidad de articulos: {formData.cantidadDeArticulos || 0}
        </Form.Text>
        <Form.Text> | </Form.Text>
        <Form.Text className="ms-2">
          Cantidad de unidades: {formData.cantidadDeUnidades || 0}
        </Form.Text>
        {/* <Form.Text> | </Form.Text> */}
        {/* <Form.Text className="ms-2">
          <strong>Monto total:{total || 0}</strong> 
        </Form.Text> */}
        <InputGroup className="mt-3 w-25 ms-auto">
          <InputGroup.Text>Total USD</InputGroup.Text>
          <Form.Control
            aria-label="Total"
            type="number"
            value={formData.subtotal}
            readOnly
            className="text-end"
          />
        </InputGroup>
      </div>
      <MyModal
        show={newQuery}
        onHide={() => setNewQuery(false)}
        title="Buscar producto"
      >
        <Row>
          <Form.Group className="mb-3 col-6">
            <Form.Label>Productos</Form.Label>
            <Form.Control
              type="text"
              name="nombreProducto"
              id="nombreProducto"
              placeholder="Ingrese el nombre"
              onChange={(e) => handleChange(e)}
            ></Form.Control>
          </Form.Group>
          <Button
            type="button"
            className="mt-auto mb-3 col-3"
            onClick={() =>
              setENDPOINT(
                `${initialENDPOINT}/producto?pagina=1&nombre=${formData.nombreProducto}`
              )
            }
          >
            Buscar
          </Button>
        </Row>
        {errMsg && <p>{errMsg}</p>}
        <DataTable
          columns={queriesColumns}
          data={data}
          striped
          customStyles={{
            rows: {
              highlightOnHoverStyle: {
                backgroundColor: "rgb(230, 244, 244)",
                borderBottomColor: "#FFFFFF",
                outline: "1px solid #FFFFFF",
              },
            },
            pagination: {
              style: {
                border: "none",
              },
            },
          }}
          pointerOnHover
          highlightOnHover
          progressPending={loading}
          progressComponent={<Loader />}
          noDataComponent={<Vacio />}
          pagination
          paginationServer
          paginationComponentOptions={{
            rowsPerPageText: "Filas por página",
            rangeSeparatorText: "de",
          }}
          paginationDefaultPage
          paginationTotalRows={totalRows}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handlePerRowsChange}
          persistTableHead
        />
      </MyModal>
    </Row>
  );
};

export default ProductDetails;
