import { useEffect, useState } from "react";
import { Button, Form, InputGroup, Row } from "react-bootstrap";
import DetalleTable from "./DetalleTable";

const Detalle = ({ handleChange, formData, setFormData }) => {
  const [inputMsg, setInputMsg] = useState("");

  const handleProductAdd = () => {
    if (
      // !formData.qordered ||
      // !formData.qshipped ||
      !formData.qborder ||
      !formData.itemNumber ||
      !formData.Descripcion ||
      !formData.listPrice ||
      !formData.netPrice
    ) {
      setInputMsg("Debes completar todos los campos");
      return;
    }

    //alert(1);
    // let id;
    // if (formData.detalle.length === 0) {
    //   id = 1;
    // } else {
    //   const ultimoElemento = formData.detalle[formData.detalle.length - 1];
    //   const ultimoId = parseInt(ultimoElemento.id);
    //   if (!isNaN(ultimoId)) {
    //     id = ultimoId + 1;
    //   } else {
    //     id = Date.now();
    //   }
    // }

    setFormData((prevalue) => {
      return {
        ...prevalue,
        detalle: [
          ...prevalue?.detalle,
          {
            // qordered: formData.qordered,
            // qshipped: formData.qshipped,
            qborder: formData.qborder,
            itemNumber: formData.itemNumber,
            Descripcion: formData.Descripcion,
            listPrice: formData.listPrice,
            netPrice: formData.netPrice,
          },
        ],
      };
    });

    // setFormData((prevalue) => {
    //   return {
    //     ...prevalue,
    //     qordered: "",
    //     qshipped: "",
    //     qborder: "",
    //     itemNumber: "",
    //     Descripcion: "",
    //     listPrice: "",
    //     netPrice: "",
    //   };
    // });

    setInputMsg("");

  };

  const handleProductDelete = ({ id }) => {

    const productosActualizados = formData.detalle?.filter(
      (producto) => producto.id !== id
    );
    setFormData((prevalue) => ({
      ...prevalue,
      detalle: productosActualizados,
    }));
  };

  const handleProductEdit = (row) => {
    handleProductDelete(row);
    setFormData((prevalue) => {
      return {
        ...prevalue,
        // qordered: row.qordered,
        // qshipped: row.qshipped,
        qborder: row.qborder,
        itemNumber: row.itemNumber,
        Descripcion: row.Descripcion,
        listPrice: row.listPrice,
        netPrice: row.netPrice,
      };
    });
  };

  useEffect(() => {
    setFormData((prevalue) => {
      return {
        ...prevalue,
        detalleNN: [],
      };
    });
  }, []);

  return (
    <Row className="bg-white mb-3 p-4 pb-2 border rounded m-5">
      <h6 className="pb-3">Detalle</h6>
      {/* <Form.Group className="mb-3 col-4">
        <Form.Label>Ordered: </Form.Label>
        <Form.Control
          type="number"
          name="qordered"
          value={formData.qordered || ""}
          onChange={(e) => handleChange(e)}
        ></Form.Control>
      </Form.Group> */}
      {/* <Form.Group className="mb-3 col-4">
        <Form.Label>Shipped: </Form.Label>
        <Form.Control
          type="number"
          name="qshipped"
          value={formData.qshipped || ""}
          onChange={(e) => handleChange(e)}
        ></Form.Control>
      </Form.Group> */}
      <Form.Group className="mb-3 col-4">
        <Form.Label>Cantidad: </Form.Label>
        <Form.Control
          type="number"
          name="qborder"
          value={formData.qborder || ""}
          onChange={(e) => handleChange(e)}
        ></Form.Control>
      </Form.Group>
      <Form.Group className="mb-3 col-4">
        <Form.Label>Item number</Form.Label>
        <Form.Control
          type="text"
          name="itemNumber"
          value={formData.itemNumber || ""}
          onChange={(e) => handleChange(e)}
        ></Form.Control>
      </Form.Group>
      <Form.Group className="mb-3 col-4">
        <Form.Label>Descripción</Form.Label>
        <Form.Control
          type="text"
          name="Descripcion"
          value={formData.Descripcion || ""}
          onChange={(e) => handleChange(e)}
        ></Form.Control>
      </Form.Group>
      <Form.Group className="mb-3 col-4">
        <Form.Label>List price</Form.Label>
        <InputGroup>
          <InputGroup.Text>$</InputGroup.Text>
          <Form.Control
            type="number"
            name="listPrice"
            value={formData.listPrice || ""}
            onChange={(e) => handleChange(e)}
            min="0"
          />
        </InputGroup>
      </Form.Group>
      <Form.Group className="mb-3 col-4">
        <Form.Label>Net price</Form.Label>
        <InputGroup>
          <InputGroup.Text>$</InputGroup.Text>
          <Form.Control
            type="number"
            name="netPrice"
            value={formData.netPrice || ""}
            onChange={(e) => handleChange(e)}
            min="0"
          />
        </InputGroup>
      </Form.Group>
      <Form.Group className="mb-3 col-4"></Form.Group>
      <Form.Group className="d-flex align-items-end justify-content-end mb-3 col-4">
        <Button onClick={handleProductAdd}>Agregar</Button>
      </Form.Group>
      {inputMsg && (
        <Form.Text className="text-danger mt-0">{inputMsg}</Form.Text>
      )}
      <DetalleTable
        formData={formData.detalle}
        handleProductDelete={handleProductDelete}
        handleProductEdit={handleProductEdit}
      />
    </Row>
  );
};

export default Detalle;
