import { useEffect, useState } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";

const Totales = ({ handleChange, formData, setFormData }) => {
  const [isMounted, setIsMounted] = useState(true);

  const subtotalDetalle = (array) => {
    return array?.reduce((acc, curr) => acc + curr.netPrice * curr.qordered, 0);
  };

  const calcularTotales = () => {


    setFormData((prevFormData) => {
      let subTotal = prevFormData.subTotal;
      const productos = prevFormData.detalle;
      const descuentosPromocion = prevFormData.DescuentoPorPromociones ? prevFormData.DescuentoPorPromociones : 0;
      const descuento = prevFormData.DescuentoPorcentual ? prevFormData.DescuentoPorcentual : 0;
      const descuentoNeto = prevFormData.DescuentoNeto;
      const totalEnvio = prevFormData.TotalEnvio ? prevFormData.TotalEnvio : 0;

      const subtotalLista = parseFloat(subtotalDetalle(productos));

      // Subtotal
      subTotal = subtotalLista;

      // Descuento por porcentaje
      const descuentoPorPorcentaje = (
        parseFloat(subTotal) *
        (parseFloat(descuento) / 100)
      ).toFixed(2);

      // Subtotal con descuento
      const subtotalConDescuento = parseFloat(
        subTotal - descuentoPorPorcentaje
      );

      // Total del pedido
      const totalPedido = parseFloat(
        subtotalConDescuento - parseFloat(descuentosPromocion) - descuentoNeto + parseFloat(totalEnvio)
      ).toFixed(2);

      return {
        ...prevFormData,
        descuentosPromocion,
        subTotal,
        descuentoPorPorcentaje,
        total: totalPedido,
        totalEnvio
      };
    });
  };

  useEffect(() => {
    isMounted &&
      formData.detalle &&
      calcularTotales();
  }, [
    formData.detalle,
    formData.DescuentoPorcentual,
    formData.DescuentoNeto,
    formData.TotalEnvio,
    formData.subTotal,
    formData.DescuentoPorPromociones,
  ]);

  useEffect(() => {
    return () => {
      setIsMounted(false);
    };
  }, []);

  return (
    <Row className="col-6 bg-white mb-3 p-4 pb-2 border rounded m-5">
      <h6 className="py-3" onClick={calcularTotales}>
        Totales
      </h6>
      <Col>
        <Form.Group className="mb-3 d-flex justify-content-between align-items-center">
          <Form.Label className="col-4">Subtotal</Form.Label>
          <div className="border-bottom col-4 me-5"></div>
          <div> $ </div>
          <Form.Control
            type="number"
            name="subTotal"
            value={formData.subTotal}
            className="col p-0 ps-3 fs-5 text-end"
            plaintext
            readOnly
          />
        </Form.Group>
        <Form.Group className="mb-3 d-flex justify-content-between align-items-end">
          <Form.Label className="col-4">Descuento por promociones</Form.Label>
          <div className="col-4 me-5">
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control
                type="number"
                name="DescuentoPorPromociones"
                value={formData.DescuentoPorPromociones}
                onChange={(e) => handleChange(e)}
                min="0"
              />
            </InputGroup>
          </div>
          <div className="w-25 d-flex align-items-center"></div>
        </Form.Group>
        <Form.Group className="mb-3 d-flex justify-content-between align-items-center">
          <Form.Label className="col-4 p-0 m-0">Descuento en %</Form.Label>
          <div className="col-4 me-5">
            <InputGroup>
              <InputGroup.Text>%</InputGroup.Text>
              <Form.Control
                type="number"
                name="DescuentoPorcentual"
                value={formData.DescuentoPorcentual}
                onChange={(e) => handleChange(e)}
                min="0"
              />
            </InputGroup>
          </div>
          <div className="w-25 d-flex align-items-center">
            <div> $ </div>
            <Form.Control
              type="number"
              name="descuentoPorPorcentaje"
              value={formData.descuentoPorPorcentaje || 0}
              className="col p-0 ps-3 fs-5 text-end"
              plaintext
              readOnly
            />
          </div>
        </Form.Group>
        <Form.Group className="mb-3 d-flex justify-content-between align-items-end">
          <Form.Label className="col-4">Descuento neto</Form.Label>
          <div className="col-4 me-5">
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control
                type="number"
                name="DescuentoNeto"
                value={formData.DescuentoNeto}
                onChange={(e) => handleChange(e)}
                min="0"
              />
            </InputGroup>
          </div>
          <div className="w-25 d-flex align-items-center"></div>
        </Form.Group>
        <Form.Group className="mb-3 d-flex justify-content-between align-items-end">
          <Form.Label className="col-4">Costo de envío</Form.Label>
          <div className="col-4 me-5">
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control
                type="number"
                name="TotalEnvio"
                value={formData.TotalEnvio}
                onChange={(e) => handleChange(e)}
                min="0"
              />
            </InputGroup>
          </div>
          <div className="w-25 d-flex align-items-center"></div>
        </Form.Group>
        <Form.Group className="mb-3 d-flex justify-content-between align-items-center">
          <Form.Label className="col-4">Total pedido</Form.Label>
          <div className="border-bottom col-4 me-5"></div>
          <div> $ </div>
          <Form.Control
            type="number"
            name="totalPedido"
            value={formData.total || 0}
            className="col p-0 ps-3 fs-5 text-end"
            plaintext
            readOnly
          />
        </Form.Group>
      </Col>
    </Row>
  );
};

export default Totales;
