import {
  // ShoppingBag,
  Clipboard,
  Home,
  Settings,
  File,
  User,
  Users,
  FileText,
  Truck,
  LogIn,
  ShoppingBag,
  TrendingUp,
  Gift,
  Package,
  Codepen,
} from "react-feather";

import { slider } from "./slider.svg"; 
import Prospects from "../../Components/Views/Prospects";


export const MENUITEMS = [
  {
    menutitle: "General",
    // menucontent: 'Dashboards,Widgets',
    Items: [
      {
        title: "Escritorio",
        icon: Home,
        type: "link",
        path: `${process.env.PUBLIC_URL}/escritorio`,
      },
      {
        title: "Productos",
        icon: Codepen,
        type: "link",
        path: `${process.env.PUBLIC_URL}/productos`,
      },
      {
        title: "Compras",
        icon: ShoppingBag,
        type: "link",
        path: `${process.env.PUBLIC_URL}/compras`,
      },
      {
        title: "Ingreso a Depósito",
        icon: Package,
        type: "link",
        path: `${process.env.PUBLIC_URL}/ingreso-a-deposito`,
      },
      {
        title: "Pedidos",
        icon: LogIn,
        type: "link",
        path: `${process.env.PUBLIC_URL}/pedidos`,
      },
      {
        title: "Invoice",
        icon: FileText,
        type: "link",
        path: `${process.env.PUBLIC_URL}/invoice`,
      },
      {
        title: "Recibos",
        icon: Clipboard,
        type: "link",
        path: `${process.env.PUBLIC_URL}/recibos`,
      },
      {
        title: "Clientes",
        icon: Users,
        type: "link",
        path: `${process.env.PUBLIC_URL}/clientes`,
      },
      {
        title: "Prospectos",
        icon: Users,
        type: "link",
        path: `${process.env.PUBLIC_URL}/prospectos`,
      },
      {
        title: "Proveedores",
        icon: Truck,
        type: "link",
        path: `${process.env.PUBLIC_URL}/proveedores`,
      },
      {
        title: "Cotizaciones",
        icon: TrendingUp,
        type: "link",
        path: `${process.env.PUBLIC_URL}/cotizaciones`,
      },
      {
        title: "Cupones",
        icon: Gift,
        type: "link",
        path: `${process.env.PUBLIC_URL}/cupones`,
      },
      {
        title: "Configuraciones",
        icon: Settings,
        type: "sub",
        active: false,
        children: [
          {
            title: "General",
            icon: Settings,
            type: "link",
            path: `${process.env.PUBLIC_URL}/configuraciones`,
          },
          // {
          //   title: "Usuarios",
          //   icon: User,
          //   type: "link",
          //   path: `${process.env.PUBLIC_URL}/usuarios`,
          // },
          {
            title: "Slider",
            icon: <slider />,
            type: "link",
            path: `${process.env.PUBLIC_URL}/sliders`,
          },
          {
            title: "Cambios Generales",
            icon: Settings,
            type: "link",
            path: `${process.env.PUBLIC_URL}/editar-gral-productos`,
          },
        ],
      },
      {
        title: "Reportes",
        icon: File,
        type: "sub",
        active: false,
        children: [
          {
            title: "Stock",
            icon: Settings,
            type: "link",
            path: `${process.env.PUBLIC_URL}/reportes/stock`,
          },
          {
            title: "Recibos",
            icon: Settings,
            type: "link",
            path: `${process.env.PUBLIC_URL}/reportes/recibos`,
          },
          {
            title: "Productos",
            icon: <slider />,
            type: "link",
            path: `${process.env.PUBLIC_URL}/reportes/productos`,
          },
          {
            title: "Invoices",
            icon: Settings,
            type: "link",
            path: `${process.env.PUBLIC_URL}/reportes/invoices`,
          },
          {
            title: "Top 10 Clientes",
            icon: Settings,
            type: "link",
            path: `${process.env.PUBLIC_URL}/reportes/topten  `,
          },
          {
            title: "Top 10 Marcas",
            icon: Settings,
            type: "link",
            path: `${process.env.PUBLIC_URL}/reportes/toptenMarcas  `,
          },
        ],
      },
    ],
  },
];
