import { useNavigate } from "react-router-dom";

const useGoToLogin = () => {
  const history = useNavigate();

  const goToLogin = (status) => {
    // console.log("status", status);
    if (status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("name");
      localStorage.removeItem("idUser");
      history(`${process.env.PUBLIC_URL}/login`);
    } else {
      history(`${process.env.PUBLIC_URL}/404`);
    }
  };

  return { goToLogin };
};

export default useGoToLogin;
