import { useContext, useEffect, useState } from "react";
import { Form, Row, Button, Col, Container } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import DataContext from "../../../_helper/DataContext";
import { Breadcrumbs } from "../../../AbstractElements";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const EditUser = () => {
  const token = localStorage.getItem("token");
  const initialENDPOINT = process.env.REACT_APP_ENDPOINT;
  const { userID, userName } = useParams();
  const [formData, setFormData] = useState({});
  const [perfiles, setPerfiles] = useState();
  const [disableButton, setDisableButton] = useState(false);
  const [userAlreadyExist, setUserAlreadyExist] = useState();
  const history = useNavigate();

  const notify = () => toast(`Usuario ${formData.nombre} editado!`);

  const sendForm = async (e) => {
    e.preventDefault();
    setDisableButton(true);
    let dataToSend = JSON.stringify({
      nombre: formData.nombre.trim(),
      // apellido: formData.apellido.trim(),
      // email: formData.email.trim(),
      clave: formData.clave,
      permisos: formData.permisos,
      suspendido: formData.suspendido === true ? 1 : 0,
    });
    try {
      const response = await axios({
        url: `${initialENDPOINT}/usuario/${userID}}`,
        // const response = await axios({
        //   url: `${initialENDPOINT}/usuario?id=${userID}&nombre=${
        //     formData.nombre
        //   }&apellido=${formData.apellido}&perfil=${formData.perfil}${
        //     formData.clave &&
        //     formData.clave.length > 4 &&
        //     formData.clave.length < 20
        //       ? "&clave=" + btoa(formData.clave)
        //       : ""
        //   }`,
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: dataToSend,
        redirect: "follow",
      });
      notify();
      history(`${process.env.PUBLIC_URL}/usuarios`);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    setFormData((prevalue) => {
      return {
        ...prevalue,
        [name]: value,
      };
    });
  };

  const getData = async () => {
    try {
      const res = await axios(`${initialENDPOINT}/usuario/${userID}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        redirect: "follow",
      });

      setFormData(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getPerfiles = async () => {
    try {
      const res = await axios(`${initialENDPOINT}/perfil`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        redirect: "follow",
      });
      // console.log(res.data.data.original.results);
      setPerfiles(res.data.data.original.results);
    } catch (error) {
      console.log(error);
    }
  };

  const getUserInfo = async (id) => {
    try {
      let pedido = await axios(
        `${initialENDPOINT}/usuario/${id}`,

        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          redirect: "follow",
        }
      );
      setFormData(pedido.data.data);
    } catch (error) {
      console.log("Ha ocurrido un error", error);
    }
  };

  useEffect(() => {
    getData();
    getPerfiles();
    getUserInfo(userID);
  }, []);

  return (
    <Container fluid={true}>
      <Breadcrumbs
        mainTitle="Editar usuario"
        parent="Usuarios"
        title="Editar usuario"
      />
      <Form onSubmit={sendForm} className="container">
        <Row>
          <Col className="p-4 col-12 col-sm-8 col-lg-8 col-xxl-8 bg-white rounded border shadow">
            <Form.Group className="d-flex align-items-center mb-3">
              <Form.Label className="col-3 col-md-3">
                Nombre de usuario
              </Form.Label>
              {/* <Form.Control
                type="text"
                name="nombre"
                onChange={handleChange}
                value={formData.nombre}
              /> */}

              <Col className="col-8 col-md-9">
                <Form.Control
                  type="text"
                  name="nombre"
                  onChange={handleChange}
                  className={userAlreadyExist ? "is-invalid" : ""}
                  defaultValue={formData.nombre}
                />
                {userAlreadyExist ? (
                  <small style={{ color: "red" }}>Ese usuario ya existe</small>
                ) : (
                  ""
                )}
              </Col>
            </Form.Group>

            {/* <Form.Group className="d-flex align-items-center mb-3">
              <Form.Label className="col-3 col-md-3">Apellido</Form.Label>
              <Form.Control
                type="text"
                name="surname"
                defaultValue={formData.apellido}
                onChange={handleChange}
              />
            </Form.Group> */}
            {/* <Form.Group className="d-flex align-items-center mb-3">
              <Form.Label className="col-3 col-md-3">Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                defaultValue={formData.email}
                onChange={handleChange}
              />
            </Form.Group> */}
            <Form.Group className="d-flex align-items-center mb-3">
              <Form.Label className="col-3 col-md-3">
                Nueva Contraseña
              </Form.Label>
              <div className="w-100">
                <Form.Control
                  type="password"
                  name="clave"
                  onChange={handleChange}
                />
                <Form.Text id="passwordHelpBlock" muted>
                  Su contraseña debe tener entre 8 y 20 caracteres.
                </Form.Text>
              </div>
            </Form.Group>
            <Form.Group className="d-flex align-items-center mb-3">
              <Form.Label className="col-3 col-md-3">Perfil</Form.Label>
              <Form.Select
                name="permisos"
                value={formData.permisos}
                onChange={handleChange}
              >
                <option disabled>Seleccione</option>
                {/* <option value={formData.permisos}>
                  {formData.nombrePerfil}
                </option> */}
                {perfiles?.map((item, i) => (
                  <option key={i} value={item.id}>
                    {item.nombre}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Button
              disabled={disableButton}
              type="submit"
              className="mt-2 mt-md-2 col-6 col-lg-3"
            >
              Guardar
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default EditUser;
