import { useMemo } from "react";
import DataTable from "react-data-table-component";
import { downloadCSV, Export } from "../../../../_helper/hooks/useExportCsv";
import Loader from "../../../Common/Loader";
import Vacio from "../../../Common/Vacio";
import TableTitle from "../../../Common/TableTitle";
import { Link } from "react-router-dom";

const paginacionOpciones = {
  rowsPerPageText: "Filas por página",
  rangeSeparatorText: "de",
};
const contextOpciones = {
  singular: "",
  plural: "",
  message: "",
};
const customStyles = {
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: "rgb(230, 244, 244)",
      borderBottomColor: "#FFFFFF",
      outline: "1px solid #FFFFFF",
    },
  },
  pagination: {
    style: {
      border: "none",
    },
  },
};

const RecibosTable = ({
  data,
  errMsg,
  loading,
  totalRows,
  perPage,
  setPerPage,
  page,
  setPage,
  setENDPOINT,
  filterENDPOINT,
  showCancellations,
  handleShowCancellations,
}) => {
  const initialENDPOINT = process.env.REACT_APP_ENDPOINT;
  const queriesColumns = [
    {
      name: "Código",
      selector: (row) => row.id,
      sortable: true,
      center: false,
      wrap: true,
      reorder: true,
      grow: 1,
    },
    {
      name: "Fecha de Emisión",
      selector: (row) => row.fecha,
      center: false,
      wrap: true,
    },
    {
      name: "Nombre de Cliente",
      selector: (row) => row.clienteNombre,
      center: false,
      wrap: true,
    },
    {
      name: "Total",
      selector: (row) => row.total,
      center: false,
      wrap: true,
    }
  ];

  const handlePageChange = (pag) =>
    setENDPOINT(
      `${initialENDPOINT}/recibo?pagina=${pag}&cantidad=${perPage}${filterENDPOINT}&mostrarBajas=${showCancellations}`
    );
  const handlePerRowsChange = async (newPerPage, pag) => {
    setPerPage(newPerPage);
    setPage(pag);
    await setENDPOINT(
      `${initialENDPOINT}/recibo?pagina=${pag}&cantidad=${newPerPage}${filterENDPOINT}&mostrarBajas=${showCancellations}`
    );
  };
  const actionsMemo = useMemo(
    () => (
      <Export
        onExport={() => downloadCSV(data, "Consultas")}
        cancallations={handleShowCancellations}
      />
    ),
    [data]
  );

  return (
    <div
      className="App mb-5 table-queries border rounded"
      class="overflow-hidden overflow-x-auto"
    >
      {errMsg && <p>{errMsg}</p>}
      <DataTable
        title={<TableTitle value="Lista de Recibos" />}
        columns={queriesColumns}
        data={data}
        striped
        customStyles={customStyles}
        highlightOnHover
        progressPending={loading}
        progressComponent={<Loader />}
        noDataComponent={<Vacio />}
        pagination
        paginationServer
        paginationComponentOptions={paginacionOpciones}
        paginationDefaultPage
        paginationTotalRows={totalRows}
        contextMessage={contextOpciones}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerRowsChange}
        actions={actionsMemo}
        persistTableHead
      />
    </div>
  );
};

export default RecibosTable;
